import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import ButtonPlusBackground from './../../components/button/ButtonPlusBackground';
import MiniModalQuestion from './../../components/modal/MiniModalQuestion';
import { FullModal } from './../../components/MaterialUi/FullModal';
import { setHideModalCart } from './../../reducers/cart.reducer';
import GrayButton from '../../components/button/GrayButton';
import EmptyCartContainer from './EmptyCartContainer';
import { removeCartItem } from '../../actions';
import Loader from './../../components/Loader';
import CartItem from './CartItem';

const RenderCartModal = ({
    cartItems,
    showCart,
    loading,
}) => {
    const dispatch = useDispatch()

    const [showMiniModalHref, setShowMiniModalHref] = useState(false)

    const onRemoveCartItem = (_id) => {
        dispatch(removeCartItem({ productId: _id }));
    }
    const onClose = () => {
        dispatch(setHideModalCart())
    }

    return (
        <div>
            <FullModal
                typeCloseIcon="insideCloseIcon"
                title="Корзина"
                type="cartFullMini"
                visible={showCart}
                onClose={onClose}
            >
                <div className="renderCartModal">
                    {cartItems.length > 0
                        ? <div className="renderCartModal__cartContainer">
                            <div className="renderCartModal__topTitle">
                                Корзина
                            </div>
                            <div className="renderCartModal__cartItemsLength">
                                Всего {cartItems.length} позиций в корзине
                            </div>
                            {loading
                                ? <Loader loadText={"Обновление корзины..."} />
                                : <>
                                    <div className="renderCartModal__cartItemBox">
                                        {cartItems.map((item, index) => (
                                            <CartItem
                                                onRemoveCartItem={onRemoveCartItem}
                                                key={index}
                                                cartItem={item}
                                            />
                                        ))}
                                    </div>
                                    <div className="renderCartModal__priceContainer">
                                        <GrayButton
                                            onClick={onClose}
                                            type="miniGrayButton"
                                            text="Продолжить покупку"
                                        />
                                        <ButtonPlusBackground
                                            buttonOn
                                            setShow={() => setShowMiniModalHref(true)}
                                        />
                                    </div> </>}
                        </div>
                        : <EmptyCartContainer type="full" />
                    }
                </div>
                <MiniModalQuestion
                    visible={showMiniModalHref}
                    handleClose={() => setShowMiniModalHref(false)}
                    text={"Перейти"}
                    description={"Перейти на страницу оформления заказа"}
                />
            </FullModal>
        </div>
    )
}

export default RenderCartModal