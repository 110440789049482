import { deliveryInfoConstants, mainActionsConstants } from '../../actions/constants';



const initialState = {
    deliveryInfo: [],
    deliveryInfoObj: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case deliveryInfoConstants.GET_DELIVERY_INFO_SUCCESS:
            state = {
                ...state,
                deliveryInfo: action.payload.deliveryInfo
            }
            break
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                deliveryInfoObj: action.payload?.item,
                // loading: false,
            };
            break;
        default:
            return state
    }
    return state
}