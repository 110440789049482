import React, { useState } from 'react'
import RenderProductContainer from './../../CabinetPage/components/RenderProductContainer';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/Loader';

const RenderViewedProduct = () => {
    const historyProduct = useSelector((state) => state.historyProduct)
    if (historyProduct.historyProductItems.length === 0) {
        return <Loader />
    }
    return (
        <div>
            <RenderProductContainer
                items={historyProduct.historyProductItems}
                sliderProd
            />
        </div>
    )
}

export default RenderViewedProduct