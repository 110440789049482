import React, { useState } from 'react'
import { BsChevronUp, BsChevronDown, } from 'react-icons/bs';


const RenderDropdownDetails = ({
    items,
    setShowEdit,
    handleCloseEditForm,
    setClicked,
    clicked
}) => {


    const toggle = index => {
        if (clicked === index) {
            return setClicked(null);
        }
        setClicked(index);
        setShowEdit(false);
        handleCloseEditForm()
    };

    return (
        <div>
            {items.map((item, index) => (
                <div
                    key={index}
                    className="dropdownDetails"
                >
                    <div>
                        <div className="dropdownDetails__container"
                            onClick={() => { toggle(index) }}
                        >
                            <div className="dropdownDetails__header-left"
                            >
                                <div className="dropdownDetails__icon">
                                    {item.icon}
                                </div>
                                <div className="dropdownDetails__header-left__text">
                                    {item.text}
                                </div>
                            </div>
                            <div className='dropdownDetails__icons'>
                                <span>{clicked === index
                                    ? <BsChevronDown size='17px' color='#3e77aa' />
                                    : <BsChevronUp size='17px' color="#3e77aa" />}</span>
                            </div>
                        </div>
                    </div>
                    {clicked === index ? <div className="dropdownDetails__contentBox">
                        {item.content}

                        <div
                            className="dropdownDetails__container-button"
                            onClick={e => e.stopPropagation()}
                        >
                            {item.button && item.button}
                        </div>

                    </div> : null}
                </div>
            )
            )}
        </div>
    )

}

export default RenderDropdownDetails
