import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { setShowModalUser } from '../../reducers/auth.reducer';
import IconButtonClose from '../IconButtons/IconButtonClose';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "350px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    overflowY: "auto",
    maxHeight: "95%"
};

export default function BasicModal({
    children
}) {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch()

    const onClose = () => {
        dispatch(setShowModalUser())
    }

    useEffect(() => {

    }, [auth.authenticate])

    return (
        <div className="basicModal">
            <Modal
                style={{ zIndex: "999920000", }}
                open={auth.showModalUser}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <IconButtonClose
                        onClick={onClose} />
                    {children}
                </Box>
            </Modal>
        </div>
    );
}