import React from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Loader from '../Loader';

const RenderFormControl = ({
    sortArray,
    setSort,
    sort,
    titleText,
    pageSize,
    setPageSize,
    setSortMaterial,
    sortMaterial,
}) => {

    const handleMaterialChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            if (!sortMaterial.includes(value)) {
                setSortMaterial([...sortMaterial, value]);
            }
        } else {
            if (sortMaterial.includes(value)) {
                setSortMaterial(sortMaterial.filter((item) => item !== value));
            }
        }
    };
    const renderFormControl = (item) => {
        switch (titleText) {
            case "Сортировать по":
                return (
                    <FormControlLabel
                        control={
                            <Radio
                                checked={sort === item.value}
                                onChange={() => setSort(item.value)}
                            />
                        }
                        label={item.text || item.value}
                    />
                );
            case "Количество":
                return (
                    <FormControlLabel
                        control={
                            <Radio
                                checked={pageSize == item.value}
                                onChange={() => setPageSize(item.value)}
                            />
                        }
                        label={item.value}
                    />
                );
            case "Материалы":
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={sortMaterial.includes(item.name)}
                                onChange={handleMaterialChange}
                                value={item.name}
                            />
                        }
                        label={item.name}
                    />
                );
            default:
                return null;
        }
    }

    return (
        <div>
            {!sortArray
                ? <Loader />
                : sortArray.map((item, index) => (
                    <div key={index}>
                        {renderFormControl(item)}
                    </div>
                ))
            }
        </div>
    )
}

export default RenderFormControl