import React from 'react'

const RenderSuccessDataContent = ({
    type,
    title,
    content,
    description
}) => {
    return (
        <div className={`renderSuccessDataContent ${type === "center" && "center"}`}>
            <div className="renderSuccessDataContent__title">
                {title}:
            </div>
            <div className="renderSuccessDataContent__content">
                {content}
            </div>
        </div>
    )
}

export default RenderSuccessDataContent