import React, { useState, useEffect, useRef } from 'react'
import RenderModalApplication from './RenderModalApplication';
import { motion } from 'framer-motion'
import { opacityAnimation } from './../Animation/Animation';
import { useSelector, useDispatch } from 'react-redux';

const OrderCatalogButton = (props) => {
    const { modalType } = useSelector((state) => state.application)

    const [showModal, setShowModal] = useState(false)
    const rootEl = useRef(null);

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setShowModal(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);

    if (props.whiteBackground) {
        return (
            <>
                <motion.div
                    initial="hidden"
                    viewport={{ amount: 0.3, once: true }}
                    whileInView="visible"
                    style={{ overflow: "hidden" }}
                    variants={opacityAnimation}
                    className="orderCatalog-white"
                    onClick={props.onClick ? props.onClick : () => setShowModal(true)}
                    ref={rootEl}>
                    <motion.span
                    >
                        {props.text}
                    </motion.span>
                </motion.div>
                <RenderModalApplication
                    showModal={showModal}
                    setShowModal={setShowModal}

                />
            </>

        )
    } else {
        return (
            <motion.div
                initial="hidden"
                whileInView="visible"
            >
                <div
                    className="orderCatalog"
                    onClick={props.onClick ? props.onClick : () => setShowModal(true)}
                    ref={rootEl}>
                    <span>
                        {props.text}
                    </span>
                </div>
                <RenderModalApplication
                    modalType={modalType === "password" ? "password" : ""}
                    showModal={showModal}
                    setShowModal={setShowModal} />
            </motion.div>



        )
    }

}

export default OrderCatalogButton
