import React, { useState } from 'react';
import HandleQuantityChange from '../CartPage/QuantityControlCart';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const MinusPlusComponent = ({
    onQuantityQtyFunc,
    cartItem,
    setQty,
    qty,
}) => {
    const [isQtyChanged, setIsQtyChanged] = useState(false);
    const [isInputActive, setIsInputActive] = useState(false);

    const handleChangeQty = (e) => {
        const newValue = parseInt(e.target.value) || 0;
        setQty(newValue);
        setIsQtyChanged(newValue !== cartItem.qty);
    }

    const handleInputFocus = () => {
        setIsInputActive(true);
    }

    const handleCancelChanges = (e) => {
        setQty(cartItem.qty);
        setIsQtyChanged(false);
        setIsInputActive(false);
    }

    const isQtyValid = qty >= 1;
    return (
        <div className="minusPlusComponent">
            <div className="minusPlusComponent__row">

                {!isInputActive
                    ? <HandleQuantityChange
                        _id={cartItem._id}
                        isIncrement={false}
                        qty={qty}
                        setQty={setQty}
                        isQtyValid={isQtyValid}
                    />
                    : <div className="minusPlusComponent__closeIconBlock">
                        <CloseIcon onClick={handleCancelChanges} />
                    </div>
                }
                <div className="minusPlusComponent__numberBlock">
                    <input
                        type="text"
                        min={1}
                        value={qty}
                        onChange={(e) => handleChangeQty(e)}
                        onFocus={handleInputFocus}
                    />
                </div>
                {!isInputActive
                    ? <HandleQuantityChange
                        _id={cartItem._id}
                        isIncrement={true}
                        qty={qty}
                        setQty={setQty}
                        isQtyValid={isQtyValid}
                        setIsInputActive={setIsInputActive}
                    />
                    : <div className={`minusPlusComponent__successBlock ${isQtyChanged && "disabledCheckedIcon"}`}>
                        <IconButton
                            children={<CheckIcon />}
                            onClick={() => {
                                onQuantityQtyFunc();
                                setIsInputActive(false); // Закрываем input при нажатии на галочку
                            }}
                            disabled={!isQtyChanged}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default MinusPlusComponent;
