import React from 'react'
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

const SocialMediaIcons = (props) => {
    const items = [
        {
            icon: <TelegramIcon />,
            href: "https://www.google.com/",
            text: "google"
        },
        {
            icon: <YouTubeIcon />,
            href: "https://www.youtube.com/",
            text: "youtube"
        },
        {
            icon: <SubscriptionsIcon />,
            href: "https://www.google.com/",
            text: "google"
        },
    ]


    const backgroundNone = () => props.backgroundNone ? null : "rgba(255,255,255,.5)"
    return (
        <div className="socialMediaIcons">

            {items.map((item, index) => (
                <a
                    key={index}
                    // style={{
                    //     : backgroundNone()
                    // }}
                    className="socialMediaIcons__container" href={item.href}>
                    <div>
                        {item.icon}
                    </div>
                </a>
            )

            )}

        </div>
    )
}
export default SocialMediaIcons
