import { portfolioConstants } from "../../actions/constants"


const initialState = {
    portfolio: [],
    portfolioDetails: {}
}


export default (state = initialState, action) => {
    switch (action.type) {
        case portfolioConstants.GET_ALL_PORTFOLIO_SUCCESS:
            state = {
                ...state,
                portfolio: action.payload.portfolio,
            }
            break
        case portfolioConstants.GET_PORTFOLIO_DETAILS_BY_ID_SUCCESS:
            state = {
                ...state,
                portfolioDetails: action.payload.portfolioDetails
            }
            break;

    }
    return state
}