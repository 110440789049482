import category from './category.reducer';
import product from './product.reducer';
import auth from './auth.reducer';
import cart from './cart.reducer';
import user from './user.reducer';
import filters from './filter.reducer';
import manufacture from './components/manufacture'
import comeToUs from './components/comeToUs'
import mainImage from './components/mainImage'
import promotion from './components/promotion'
import weWorkOnline from './components/weWorkOnline'
import contact from './components/contact'
import paymentLI from './components/paymentLI'
import costDelivery from './components/costDelivery'
import application from './application.reducer'
import blog from './components/blog'
import gallery from './components/gallery'
import portfolio from './components/portfolio'
import warranty from './components/warranty'
import deliveryInfo from './components/deliveryInfo'
import paymentList from './components/paymentList'
import aboutUs from './components/aboutUs'
import benefits from './components/benefits'
import architect from './components/architect'
import architectLi from './components/architectLi'
import architectSkills from './components/architectSkills'
import partner from './components/partner'
import material from './components/material'
import allQuestion from './components/allQuestion'
import desires from './desires'
import historyProduct from './historyProduct'
import order from './order.reducer'
import pickupPoints from './components/pickupPoints'
import region from './components/region'
import contactM from './components/contactM'
import elementPaymentPage from './components/elementPaymentPage'
import roleChangeRequest from './roleChangeRequest'
import applicationCallMe from './applicationCallMe'
import applicationChangeProfile from './applicationChangeProfile'
import mainPage from './mainPage'
import applicationGallery from './applicationGallery'
import galleryImgUser from './galleryImgUser'
import productListLink from './productListLink'

import { combineReducers } from 'redux';


const rootReducer = combineReducers({
    category,
    blog,
    portfolio,
    product,
    auth,
    cart,
    user,
    filters,
    manufacture,
    comeToUs,
    mainImage,
    promotion,
    weWorkOnline,
    contact,
    paymentLI,
    costDelivery,
    gallery,
    application,
    deliveryInfo,
    warranty,
    benefits,
    paymentList,
    aboutUs,
    architect,
    architectLi,
    architectSkills,
    partner,
    material,
    allQuestion,
    desires,
    historyProduct,
    pickupPoints,
    order,
    region,
    roleChangeRequest,
    contactM,
    elementPaymentPage,
    applicationCallMe,
    mainPage,
    applicationChangeProfile,
    applicationGallery,
    galleryImgUser,
    productListLink,
});

export default rootReducer; 