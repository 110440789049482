import React, { useState, useEffect, useRef } from 'react'
import GridViewIcon from '@mui/icons-material/GridView';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { generatePublicUrl } from '../../urlConfig';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useNavigate } from 'react-router-dom';
import { goToTop } from '../GoToTop';
import useBodyOverflow from '../useBodyOverflow';
import Loader from '../Loader';

const GrayButtonIcon = () => {

    const { categories, loading } = useSelector((state) => state.category)
    const [slideIndex, setSlideIndex] = useState(null)
    const [showBlock, setShowBlock] = useState(false)
    const [showMiniContent, setShowMiniContent] = useState(false)
    const [mainCategoryIndex, setMainCategoryIndex] = useState(null)
    const [categoryObj, setCategoryObj] = useState(null)

    const rootEl = useRef(null);
    const navigate = useNavigate()

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setShowBlock(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);


    useBodyOverflow(showBlock)
    useEffect(() => {
        window.innerWidth < "500px"
            ? setShowMiniContent(true)
            : setShowMiniContent(false)
    }, [])

    useEffect(() => {
        const storedData = window.localStorage.getItem('TEST');
        let data = null;

        try {
            data = JSON.parse(storedData);
        } catch (error) {
            console.error('Ошибка при парсинге JSON:', error);
        }
        setCategoryObj(data)
    }, [])

    useEffect(() => {
        window.localStorage.setItem('TEST', JSON.stringify(categoryObj))
    }, [categoryObj])

    const handleClose = () => {
        setShowBlock(!showBlock)
        setMainCategoryIndex(null)
        setSlideIndex(null)
    }

    const handleShowCategoryList = (index) => {
        if (showMiniContent) {
            navigate("/productsCategory/all")
            goToTop("onlyTop", "500")
        } else {
            mainCategoryIndex === index + 1
                ? setMainCategoryIndex(null)
                : setMainCategoryIndex(index + 1)
        }

    }
    const handleClick = (obj, index) => {
        if (obj.name === "Галерея всей продукции") {
            navigate("/galleryPage")
            goToTop("onlyTop", "100")
        }
        else {
            handleShowCategoryList(index)
        }
    }

    return (
        <div >
            <div
                ref={rootEl}
                onClick={handleClose}
                className="grayButtonIcon">
                <span> {!showBlock ? "Каталог" : "Закрыть"}</span>
                {!showBlock
                    ? <GridViewIcon />
                    : <CloseIcon style={{
                        height: "20px",
                        width: "20px",
                        animation: "none"
                    }} />}

            </div>
            {!showBlock ? null : <>
                <div className="menuCategoryList__blur">
                </div>
                <div className="menuCategoryList"
                    onClick={e => e.stopPropagation()}
                >
                    <div className="menuCategoryList__list">
                        {loading
                            ? <Loader />
                            : categories.map((obj, index) => (
                                <>
                                    <div
                                        key={obj._id}
                                        onClick={() => handleClick(obj, index)}
                                        onMouseEnter={() => setMainCategoryIndex(index + 1)}
                                        className={mainCategoryIndex === index + 1
                                            ? "menuCategoryList__row active"
                                            : "menuCategoryList__row"
                                        }>
                                        <div className="menuCategoryList__imgContainer">
                                            <img
                                                src={generatePublicUrl(obj.categoryImage)}
                                                alt={obj.name}
                                            />
                                        </div>
                                        <div className="menuCategoryList__name">
                                            {obj.name}
                                        </div>
                                        <ArrowForwardIosIcon />
                                    </div>

                                    <div className={`menuCategoryList__childrenBlock 
                                ${mainCategoryIndex === index + 1
                                            ? obj.name !== "Галерея всей продукции" && "active"
                                            : ""}`}>
                                        {
                                            obj.children.map((childrenCategory, index) => (
                                                <a
                                                    className={slideIndex === index + 1
                                                        ? "menuCategoryList__listChildCategory active"
                                                        : "menuCategoryList__listChildCategory"
                                                    }

                                                    href={`/productsCategory/${childrenCategory.slug}?${childrenCategory._id}`}
                                                    onClick={() => setCategoryObj(childrenCategory)}
                                                >
                                                    <div className="menuCategoryList__imgContainer">
                                                        <img
                                                            src={generatePublicUrl(childrenCategory.categoryImage)}
                                                            alt={childrenCategory.name}
                                                        />
                                                    </div>
                                                    <div className="menuCategoryList__text">
                                                        {childrenCategory.name}
                                                    </div>
                                                    <ArrowForwardIosIcon />

                                                </a>
                                            ))
                                        }
                                    </div>
                                </>
                            ))}
                    </div>
                    <a
                        href="/aboutUs"
                        className="menuCategoryList__list newRow"
                        onMouseEnter={() => setMainCategoryIndex(categories.length + 1)}
                    >
                        <LocalOfferIcon />

                        <div className="menuCategoryList__text">
                            Акции которые сейчас на сайте
                        </div>
                        <div
                            style={{
                                opacity: "0"
                            }}>
                            <ArrowForwardIosIcon />
                        </div>

                    </a>
                </div>
            </>}
        </div >
    )
}

export default GrayButtonIcon