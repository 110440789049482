

export const stylesBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "350px",
    maxWidth: "500px",
    minHeight: "100px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
};
export const stylesSelect = {
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    color: '#495057',
    fontSize: '17px',
    fontWeight: '400',
    lineHeight: '1.5',
    padding: '0.375rem 0.75rem',
    width: '100%',
    maxWidth: "100px",
    marginTop: "10px"
}