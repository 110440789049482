import { applicationChangeProfileConstants } from '../actions/constants';

const initState = {
  applications: [],
  applicationObj: {},
  visibleRegModal: false,
  loading: false,
  modalType: "",
  message: "",
  showAlert: false,
  variantAlert: "success",
};
export default (state = initState, action) => {
  switch (action.type) {
    case applicationChangeProfileConstants.ADD_SAMPLE_PHOTO_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationChangeProfileConstants.ADD_SAMPLE_PHOTO_SUCCESS:
      state = {
        ...state,
        applicationObj: action.payload?.applicationChangeProfile,
        loading: false,
        showAlert: true,
        variantAlert: "success",
        message: action.payload?.message,
      };
      break;
    case applicationChangeProfileConstants.ADD_SAMPLE_PHOTO_FAILURE:
      state = {
        ...state,
        loading: false,
        showAlert: true,
        variantAlert: "warning",
        message: action.payload?.message,
      };
      break;
    case applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_ID_SUCCESS:
      state = {
        ...state,
        applicationObj: action.payload.applicationsChangeProfile,
        loading: false,
      };
      break;
    case applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_USER_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_USER_SUCCESS:
      state = {
        ...state,
        applications: action.payload?.applicationsChangeProfile.reverse(),
        loading: false,
      };
      break;
    case applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationChangeProfileConstants.CREATE_APPLICATION_CHANGE_PROFILE_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationChangeProfileConstants.CREATE_APPLICATION_CHANGE_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationChangeProfileConstants.CREATE_APPLICATION_CHANGE_PROFILE_FAILURE:
      state = {
        ...state,
        loading: false
      };
      break;
    case applicationChangeProfileConstants.VISIBLE_REGISTER_MODAL_APPLICATION_CHANGE_PROFILE:
      state = {
        ...state,
        visibleRegModal: !state.visibleRegModal
      };
      break;
    case applicationChangeProfileConstants.SET_MODAL_TYPE_APPLICATION_CHANGE_PROFILE:
      state = {
        ...state,
        modalType: action.payload
      }
      break;
    case applicationChangeProfileConstants.CLOSE_ALERT_TO_APPLICATION_CHANGE_PROFILE:
      state = {
        ...state,
        showAlert: false
      }
      break;
    default:
      return state
  }

  return state;
};
export const setModalType = (payload) => ({
  type: applicationChangeProfileConstants.SET_MODAL_TYPE_APPLICATION_CHANGE_PROFILE,
  payload
})
export const setVisibleRegModal = (payload) => ({
  type: applicationChangeProfileConstants.VISIBLE_REGISTER_MODAL_APPLICATION_CHANGE_PROFILE,
  payload
})
export const setCloseAlertApplication = (payload) => ({
  type: applicationChangeProfileConstants.CLOSE_ALERT_TO_APPLICATION_CHANGE_PROFILE,
  payload
})


