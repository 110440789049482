import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMainImage } from './../actions/components/mainImage';
import MainImageHeaderItem from './MainImageHeaderItem';

const MainImageHeader = () => {
    const mainImage = useSelector((state) => state.mainImage.mainImage)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMainImage())
    }, [])

    return (
        <>
            {mainImage ? mainImage.map((item, index) => (
                <MainImageHeaderItem
                    item={item}
                    key={index}
                    type=""
                />
            )) : "Загрузка..."
            }
        </>

    )
}

export default MainImageHeader
