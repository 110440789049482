import React, { useEffect, useState } from 'react'
import ContentLabelP from '../../../components/ContentLabelP'
import { Row, Col } from 'react-bootstrap';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import PasswordError from '../../../components/PasswordError';
import { filterApplicationArray } from './filterApplicationArray';
import FileUploadModal from '../ApplicationPage/FileUploadModal';
import { getApplicationsChangeProfileById } from '../../../actions';
import { useDispatch } from 'react-redux';
import ButtonBlock from './ButtonBlock';

const LoginData = ({
    filterApplicationPassword,
    filterApplicationLogin,
    setApplicationItem,
    setUserLoginForm,
    setPasswordMatch,
    setPasswordForm,
    applicationItem,
    applicationObj,
    setVariantForm,
    passwordMatch,
    userLoginForm,
    handleSubmit,
    passwordForm,
    setForm,
    form,
    user,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [typeModal, setTypeModal] = useState("");
    const [editLogin, setEditLogin] = useState(false)
    const [editPassword, setEditPassword] = useState(false)
    const [plusItemsLogin, setPlusItemsLogin] = useState(false);
    const [plusItemsPassword, setPlusItemsPassword] = useState(false);
    const [arrayItems, setArrayItems] = useState([]);
    const [disabledVariant, setDisabledVariant] = useState(false);

    const [typeArrayToShow, setTypeArrayToShow] = useState("");

    const dispatch = useDispatch()

    const handleShowEditBlock = (type) => {
        if (type === 'login' && editLogin) {
            handleSubmit()
        }
    }

    const loginFormStr = JSON.stringify(form);
    const userLoginFormStr = JSON.stringify(userLoginForm);

    const applicationLoginSuccess = filterApplicationArray("success", filterApplicationLogin)
    const applicationLoginReject = filterApplicationArray("reject", filterApplicationLogin)

    const applicationPasswordSuccess = filterApplicationArray("success", filterApplicationPassword)
    const applicationPasswordReject = filterApplicationArray("reject", filterApplicationPassword)

    const disabledPasswordSubmit = passwordMatch && passwordForm.password ? false : true

    useEffect(() => {
        setUserLoginForm({
            email: user.email || "",
        });
    }, [editLogin])

    useEffect(() => {
        setDisabledVariant(loginFormStr === userLoginFormStr)
    }, [editLogin, loginFormStr])

    useEffect(() => {
        if (editPassword) {
            setVariantForm("password")
        }
        if (editLogin) {
            setVariantForm("login")
        }
    }, [editLogin, editPassword])

    useEffect(() => {
        const allItemsLoginCount = applicationLoginSuccess.length + applicationLoginReject.length === filterApplicationLogin.length
        const allItemsPasswordCount = applicationPasswordSuccess.length + applicationPasswordReject.length === filterApplicationPassword.length
        if (allItemsLoginCount) {
            setPlusItemsLogin(true)
        }
        if (allItemsPasswordCount) {
            setPlusItemsPassword(true)
        }
    }, [applicationLoginSuccess, applicationLoginReject, applicationPasswordSuccess, applicationPasswordReject])

    const handleShowPasswordEdit = (type) => {
        if (editPassword && type === "password") return handleSubmit()
        setEditLogin(false)
        setEditPassword(!editPassword)
    }

    useEffect(() => {
        if (typeArrayToShow === "login" && arrayItems.length > 0) {
            const applicationIdLogin = arrayItems[0]._id;
            dispatch(getApplicationsChangeProfileById(applicationIdLogin)).then(() => {
                setIsModalOpen(true);
            });
        }
        if (typeArrayToShow === "password" && arrayItems.length > 0) {
            const applicationIdPassword = arrayItems[0]._id;
            dispatch(getApplicationsChangeProfileById(applicationIdPassword)).then(() => {
                setIsModalOpen(true);
            });
        }
    }, [arrayItems, typeArrayToShow]);

    const handleShowStatusModal = (type, typeArray, item) => {
        if (item) {
            setApplicationItem(item)
        }
        if (typeArray === "login") {
            setArrayItems(filterApplicationLogin);
            setTypeArrayToShow("login");
        }
        if (typeArray === "password") {
            setArrayItems(filterApplicationPassword);
            setTypeArrayToShow("password");
        } else {
            setIsModalOpen(true);
        }
        setTypeModal(type);
    };
    return (
        <div onClick={e => e.stopPropagation()}>
            <Row>
                <Col md="6">
                    <ContentLabelP
                        fullWidth
                        labelText="Логин(Email)"
                        valueText={form.email}
                        showEdit={editLogin}
                        handleChange={(e) =>
                            setForm({ ...form, email: e.target.value })
                        }
                    />
                    {plusItemsLogin
                        ? <>
                            <Button
                                style={{
                                    margin: "5px"
                                }}
                                disabled={!editLogin ? false : disabledVariant}
                                endIcon={!editLogin ? <EditIcon /> : <SendIcon />}
                                onClick={!editLogin
                                    ? () => setEditLogin(true)
                                    : () => handleShowEditBlock("login")}
                            >
                                {!editLogin ? "Редактировать" : "Изменить логин"}
                            </Button>
                            {editLogin ? <Button
                                style={{
                                    margin: "5px"
                                }}
                                onClick={() => setEditLogin(false)}
                            >
                                Назад
                            </Button>
                                : null
                            }
                            {!plusItemsLogin

                            }
                        </>
                        : <>
                            <ButtonBlock
                                onClick={() => handleShowStatusModal("applicationLogin", "login")}
                            />
                            <ButtonBlock
                                onClick={() => handleShowStatusModal("statusModal", "login")}
                                type="status"
                            />
                        </>
                    }
                    {filterApplicationLogin.length > 0
                        ? <ButtonBlock
                            onClick={() => handleShowStatusModal("allApplication", "login")}
                            type="allApplication"
                        />
                        : null}
                </Col>
                <Col md="6">
                    <ContentLabelP
                        fullWidth
                        labelText="Пароль"
                        valueText={user.password}
                        showEdit={editPassword}
                        type="password"
                        passwordForm={passwordForm}
                        setPasswordForm={setPasswordForm}
                    />

                    {plusItemsPassword
                        ? <>
                            <PasswordError
                                password={passwordForm.newPassword}
                                passwordCheck={passwordForm.passwordCheck}
                                passwordMatch={passwordMatch}
                                setPasswordMatch={setPasswordMatch}
                            />
                            <Button
                                variant='text'
                                disabled={!editPassword ? false : disabledPasswordSubmit}
                                style={{
                                    margin: "10px auto"
                                }}
                                endIcon={!editPassword ? <EditIcon /> : <SendIcon />}
                                onClick={() => handleShowPasswordEdit("password")}
                            >
                                {!editPassword ? "Редактировать" : "Изменить пароль"}
                            </Button>
                            {editPassword ? <Button
                                style={{
                                    margin: "5px"
                                }}
                                onClick={() => setEditPassword(false)}
                            >
                                Назад
                            </Button>
                                : null
                            }
                        </>
                        : <>
                            <ButtonBlock
                                onClick={() => handleShowStatusModal("applicationPassword", "password")}
                            />

                            <ButtonBlock
                                onClick={() => handleShowStatusModal("statusModal", "password")}
                                type="status"
                            />
                        </>
                    }
                    {filterApplicationPassword.length > 0
                        ? <ButtonBlock
                            onClick={() => handleShowStatusModal("allApplication", "password")}
                            type="allApplication"
                        />
                        : null
                    }
                </Col>

            </Row>
            <FileUploadModal
                applicationItem={applicationItem}
                itemObjStatus={applicationObj.applicationStatus}
                handleShowStatusModal={handleShowStatusModal}
                onClose={() => setIsModalOpen(false)}
                filterApplication={arrayItems}
                itemObj={applicationObj}
                typeModal={typeModal}
                isOpen={isModalOpen}
            />
        </div >
    )
}

export default LoginData