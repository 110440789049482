import React, { useState, useEffect, useRef } from 'react'
import { generatePublicUrl } from '../../urlConfig'
import { FullModal } from './../MaterialUi/FullModal';
import BtnSlider from './../Slider/BtnSlider';
import FixedButtonsFullScreenImg from '../button/FixedButtonsFullScreenImg';
import FixedButtonsBottomFullScreen from '../button/FixedButtonsBottomFullScreen';

const RenderFullImgModal = ({
    visibleButtonsBottom = false,
    setCurrentIdPicture,
    currentIdPicture,
    setShowModalImg,
    showModalImg,
    items,
    typePage,
    product
}) => {
    const [slideIndex, setSlideIndex] = useState(1)
    useEffect(() => {
        if (currentIdPicture) {
            const currentPictureIndex = items.findIndex((obj) => obj._id === currentIdPicture)
            setSlideIndex(currentPictureIndex + 1)
        }
    }, [currentIdPicture, items])

    const nextSlide = () => {
        if (slideIndex !== items.length) {
            setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex === items.length) {
            setSlideIndex(1)

        }
    }
    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1) {
            setSlideIndex(items.length)
        }
    }
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === "ArrowLeft") {
                prevSlide()
            } else if (event.code === "ArrowRight") {
                nextSlide()
            }
        }

        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [prevSlide, nextSlide])

    if (!items) {
        return null
    }
    const closeModal = () => {
        setSlideIndex(1)
        setShowModalImg(false)
    }

    const handleCloseFullPicture = () => {
        closeModal()
        setCurrentIdPicture(null)
    }
    return (
        <FullModal
            typeCloseIcon="outsideCloseIcon"
            type="transparent"
            onClose={handleCloseFullPicture}
            visible={showModalImg}
        >
            <div className="renderFullImgModal">
                <div className="renderFullImgModal__contentRow" >
                    <div className="renderFullImgModal__indexNumberContainer">
                        Отображена {slideIndex}  из {items.length}
                    </div>
                    <div className="renderFullImgModal__imgContainers">
                        {items?.map((obj, index) => (
                            <div
                                key={index}
                                className={slideIndex === index + 1
                                    ? "renderFullImgModal__imgContainer active-anim"
                                    : "renderFullImgModal__imgContainer"}
                            >
                                <img
                                    onClick={() => nextSlide()}
                                    src={generatePublicUrl(obj.img ? obj.img : typePage === "galleryPage" ? obj.picture.img : null)} alt="...Загрузка" />
                            </div>
                        ))}
                    </div>
                </div>
                <FixedButtonsFullScreenImg
                    prevSlide={prevSlide}
                    nextSlide={nextSlide}
                    closeModal={handleCloseFullPicture}
                    items={items}
                />
                {visibleButtonsBottom
                    ? <FixedButtonsBottomFullScreen
                        product={product}
                        handleCloseFullPicture={handleCloseFullPicture}
                    />
                    : null
                }
            </div>
        </FullModal>
    )
}

export default RenderFullImgModal