import React from 'react'
import { NavLink } from 'react-router-dom';
import { generatePublicUrl } from '../../urlConfig';

const RenderPortfolio = ({ portfolio }) => {




    return (
        <div className="renderPortfolio">
            <div className="row">
                {portfolio.map((item, index) => (
                    <div key={index} className="col-12 col-sm-12 col-md-4 col-lg-3">
                        <div className="renderPortfolio__imgContainer">
                            <img src={generatePublicUrl(item.images[0].img)} alt={item.city} />
                            <a href={`/portfolioDetails/${item._id}`} className="renderPortfolio__blockText">
                                <div className="renderPortfolio__blockText__city">
                                    {item.city}
                                </div>
                                <div className="renderPortfolio__blockText__name">
                                    {item.name}
                                </div>
                            </a>
                        </div>
                    </div>
                ))}


            </div>
        </div>
    )
}

export default RenderPortfolio