import axios from "../../helpers/axios"
import { warrantyConstants } from '../constants';


export const getWarranty = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: warrantyConstants.GET_ALL_WARRANTY_REQUEST })
            const res = await axios.get("warranty/get")
            if (res.status === 200) {
                const { warranty } = res.data
                dispatch({
                    type: warrantyConstants.GET_ALL_WARRANTY_SUCCESS,
                    payload: {
                        warranty
                    }
                })
            } else {
                dispatch({ type: warrantyConstants.GET_ALL_WARRANTY_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}