import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import MiniModalQuestion from './../modal/MiniModalQuestion';
import ConfirmDialog from '../ConfirmDialog';

const SettingIconBlock = ({
    typeModal,
    handleExecute,
    setCurrentId,
    handleClose,
    description,
    title,
    textDelete,
    handleShow,
    children,
    tooltip,
    visible,
    onClick,
    right,
    icon,
    obj,
    top,
}) => {
    const handleClick = (e) => {
        e.stopPropagation();
        setCurrentId(obj._id)
        handleShow();
    }
    const handleClickClose = (e) => {
        e.stopPropagation();
        handleClose();
    }
    const handleDelete = (e) => {
        e.stopPropagation();
        handleExecute();
    }
    return (
        <div>
            <div className="settingIconBlock"
                style={{
                    position: children ? null : "absolute",
                    top: top ? top : null,
                    right: right ? right : null
                }}
                onClick={onClick ? onClick : handleClick}
            >
                <Tooltip title={tooltip}>
                    {children
                        ? children
                        : icon ? icon : <SettingsIcon />
                    }
                </Tooltip>
            </div>
            {
                typeModal !== "buttonBox" && <ConfirmDialog
                    title={title ? title : "Вы уверены в удалении?"}
                    description={description ? description : "Подтвердить удаление"}
                    open={visible}
                    handleClose={handleClickClose}
                    handleSubmit={handleDelete}
                />
            }
        </div>
    )
}

export default SettingIconBlock