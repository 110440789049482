import React from 'react'

const GrayButton = ({ type, onClick, text, href }) => {

    let propsType
    switch (type) {
        case "miniGrayButton":
            propsType = <div
                className="miniGrayButton"
                onClick={onClick}
            >
                <span>
                    {text}
                </span>
            </div>
            break;
        case "medium":
            propsType = <div
                className="miniGrayButton medium"
                onClick={onClick}
            >
                <span>
                    {text}
                </span>
            </div>
            break;
        case "greenButton":
            propsType = <div
                className="miniGrayButton greenButton"
                onClick={onClick}
            >
                <span>
                    {text}
                </span>
            </div>
            break;
        case "greenButtonHref":
            propsType = <a
                className="miniGrayButton greenButtonHref"
                href={href ? href : "/maf"}
            >
                <span>
                    {text}
                </span>
            </a>
            break;
        case "miniGrayHref":
            propsType = <a
                className="miniGrayButton miniGrayHref"
                href={href ? href : "/maf"}
            >
                <span>
                    {text}
                </span>
            </a>
            break;
        default:
            propsType = <a
                href={href ? href : "/maf"}
                className="grayButton"
                onClick={onClick}
            >
                <span>
                    {text}
                </span>
            </a>
    }
    return propsType
}

export default GrayButton