import axios from "../../helpers/axios"
import { pickupPointsConstants } from '../constants';


export const getPickupPoints = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: pickupPointsConstants.GET_PICKUP_POINTS_REQUEST })
            const res = await axios.get("pickupPoints/get")
            if (res.status === 200) {
                const { pickupPoints } = res.data
                dispatch({
                    type: pickupPointsConstants.GET_PICKUP_POINTS_SUCCESS,
                    payload: {
                        pickupPoints
                    }
                })
            } else {
                dispatch({ type: pickupPointsConstants.GET_PICKUP_POINTS_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}
