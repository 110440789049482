import axios from "../../helpers/axios"
import { aboutUsConstants } from '../constants';

export const getAboutUs = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: aboutUsConstants.GET_ABOUT_US_REQUEST })
            const res = await axios.get("/aboutUs/get")
            if (res.status === 200) {
                const { aboutUs } = res.data
                dispatch({
                    type: aboutUsConstants.GET_ABOUT_US_SUCCESS,
                    payload: {
                        aboutUs
                    }
                })
            } else {
                dispatch({ type: aboutUsConstants.GET_ABOUT_US_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
} 