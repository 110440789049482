import React from 'react'
import ContainerCheckoutList from './ContainerCheckoutList';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import CartPage from '../../CartPage/CartPage';
import RenderSuccessDataContent from './RenderSuccessDataContent';


const CheckoutItemsOrder = ({
    cartItems,
    disabledButtonCartItems,
    setSuccessData,
    successData,
    showSuccessButtons
}) => {

    const renderTitle = () => {
        return (
            <div className="renderTitle">
                <div className="renderTitle__nameBox">
                    <div className="name">
                        Наименование
                    </div>
                </div>
                <div className="renderTitle__quantityBox">
                    <div className="renderTitle__quantity">
                        Кол-во
                    </div>
                </div>
            </div>
        )
    }

    const renderSuccessDataContent = () => {
        return cartItems.length > 0 && cartItems.map((item, index) => <RenderSuccessDataContent
            key={index}
            title={item.fullName}
            content={item.quantity}
            type="center"
        />)
    }
    const renderChildren = () => {
        return successData
            ? renderSuccessDataContent()
            : <> {renderTitle()}
                <div className="cartPageItemsOrder">
                    <CartPage onlyCartItems />
                </div>
            </>
    }
    return (
        <ContainerCheckoutList
            disabledButton={disabledButtonCartItems}
            setSuccessData={setSuccessData}
            successData={successData}
            showSuccessButtons={showSuccessButtons}
            titleChildren={renderChildren()}
            icon={<Inventory2Icon />}
            title="Товары в заказе"
        />
    )
}

export default CheckoutItemsOrder