import { contactMConstants } from "../../actions/constants";

const initialState = {
  contactM: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case contactMConstants.GET_CONTACT_M_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case contactMConstants.GET_CONTACT_M_SUCCESS:
      state = {
        ...state,
        contactM: action.payload.contactM,
        loading: false,
      };
      break;
    case contactMConstants.GET_CONTACT_M_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      return state;
  }
  return state;
};
