import { setProduct } from '../reducers/product.reducer'
import axios from '../helpers/axios'
import { productConstants } from './constants'



export const getGalleryProducts = (page, excludedIds = [], trafficState) => {
    return async (dispatch) => {
        try {
            dispatch({ type: productConstants.GET_GALLERY_PRODUCTS_REQUEST });
            const res = await axios.get("/galleryProducts/get", {
                params: {
                    page,
                    excludedIds: JSON.stringify(excludedIds),
                    trafficSavings: trafficState
                },
            });

            if (res.status === 200) {
                dispatch({
                    type: productConstants.GET_GALLERY_PRODUCTS_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: productConstants.GET_GALLERY_PRODUCTS_FAILURE,
                    payload: res.data,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const getProductsBySlug = (slug, sort, pageNumber, pageSize, sortMaterial) => {
    return async dispatch => {
        try {
            dispatch({ type: productConstants.GET_PRODUCTS_BY_SLUG_REQUEST })
            let url = `/products/${slug}`
            let urlTwo = `/products/${slug}?sort=${sort}`
            let urlThree = `/products/${slug}?sort=${sort}&page=${pageNumber}&pageSize=${pageSize}&sortMaterial=${sortMaterial}`
            let urlPageNumber = `/products/${slug}?sort=${sort}&page=${pageNumber}`

            if (sort) {
                url = urlTwo
            }
            if (pageNumber) {
                url = urlPageNumber
            }
            if (pageSize) {
                url = urlThree
            }
            if (sort && pageNumber && pageSize) {
                url = urlThree
            }
            const res = await axios.get(url)
            if (res.status === 200) {
                const {
                    products,
                    totalPages,
                    totalAll,
                    perPage,
                    pageSize,
                    message,
                    countPage
                } = res.data;
                dispatch({
                    type: productConstants.GET_PRODUCTS_BY_SLUG_SUCCESS,
                    payload: {
                        products,
                        totalPages,
                        totalAll,
                        perPage,
                        pageSize,
                        message,
                        countPage
                    },
                })
            } else {
                dispatch({
                    type: productConstants.GET_PRODUCTS_BY_SLUG_FAILURE,
                })
            }
        } catch (e) {
            console.log(e)
        }

    }
}
export const getProducts = (sort, pageNumber, pageSize, sortMaterial) => {
    return async (dispatch) => {
        try {
            dispatch({ type: productConstants.GET_ALL_PRODUCTS_REQUEST });
            let url = "/product/getProducts"
            let urlTwo = `product/getProducts?sort=${sort}`
            let urlThree = `product/getProducts?sort=${sort}&page=${pageNumber}&pageSize=${pageSize}&sortMaterial=${sortMaterial}`
            let urlPageNumber = `product/getProducts?sort=${sort}&page=${pageNumber}`
            if (sort) {
                url = urlTwo
            }
            if (pageNumber) {
                url = urlPageNumber
            }
            if (pageSize) {
                url = urlThree
            }
            if (sort && pageNumber && pageSize) {
                url = urlThree
            }
            const res = await axios.get(url);
            if (res.status === 200) {
                const {
                    products,
                    totalPages,
                    totalAll,
                    perPage,
                    pageSize,
                    message,
                    countPage
                } = res.data;
                dispatch({
                    type: productConstants.GET_ALL_PRODUCTS_SUCCESS,
                    payload: {
                        products,
                        totalPages,
                        totalAll,
                        perPage,
                        pageSize,
                        message,
                        countPage
                    },
                });
            } else {
                dispatch({ type: productConstants.GET_ALL_PRODUCTS_FAILURE });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const searchProducts = (search, sort, userId, productLength) => {
    return async dispatch => {
        try {
            dispatch({ type: productConstants.SEARCH_PRODUCTS_REQUEST });
            const res = await axios.get(`product/getProducts/search?search=${search}&sort=${sort}&userId=${userId}&productLength=${productLength}`)
            if (res.status === 200) {
                dispatch({
                    type: productConstants.SEARCH_PRODUCTS_SUCCESS,
                    payload: res.data
                })
                dispatch(setProduct(res.data))
            } else {
                dispatch({
                    type: productConstants.SEARCH_PRODUCTS_FAILURE,
                    payload: res.data
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export const searchProductsBySlug = (slug, search) => {
    return async dispatch => {
        try {
            // dispatch({ type: productConstants.SEARCH_PRODUCTS_REQUEST });
            const res = await axios.get(`/products/${slug}/search?search=${search}`)
            // if (res.status === 200) {
            //     dispatch({
            //         type: productConstants.SEARCH_PRODUCTS_SUCCESS,
            //         payload: res.data
            //     })
            dispatch(setProduct(res.data))
            // } else {
            //     dispatch({
            //         type: productConstants.SEARCH_PRODUCTS_FAILURE,
            //         payload: res.data
            //     })
            // }
        } catch (e) {
            alert(e?.res?.data?.message)
        }
    }
}

export const getProductPage = (payload) => {
    return async dispatch => {
        try {
            const { cid, type } = payload.params
            const res = await axios.get(`/page/${cid}/${type}`)
            dispatch({ type: productConstants.GET_PRODUCT_PAGE_REQUEST })
            if (res.status === 200) {
                const { page } = res.data
                dispatch({
                    type: productConstants.GET_PRODUCT_PAGE_SUCCESS,
                    payload: { page }
                })
            } else {
                const { error } = res.data
                dispatch({
                    type: productConstants.GET_PRODUCT_PAGE_FAILURE,
                    payload: { error }
                })
            }
        } catch (error) {
            console.log(error)
        }

    }
}


export const updateProductView = (payload) => {
    return async dispatch => {
        dispatch({ type: productConstants.UPDATE_PRODUCT_VIEW_REQUEST });
        let res;
        try {
            res = await axios.post("/product/updateProductView", payload);
            dispatch({
                type: productConstants.UPDATE_PRODUCT_VIEW_SUCCESS,
                payload: res.data
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: productConstants.UPDATE_PRODUCT_VIEW_FAILURE,
                payload: { error: res.data.error }
            });
        }
    }
}
export const getProductDetailsById = (payload) => {

    return async dispatch => {
        dispatch({ type: productConstants.GET_PRODUCT_DETAILS_BY_ID_REQUEST });
        let res;
        try {
            const { productId } = payload.params;
            res = await axios.get(`/product/${productId}`);

            dispatch({
                type: productConstants.GET_PRODUCT_DETAILS_BY_ID_SUCCESS,
                payload: res.data
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: productConstants.GET_PRODUCT_DETAILS_BY_ID_FAILURE,
                payload: { error: res.data.error }
            });
        }
    }
}


