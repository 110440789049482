import React from 'react'
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AddTaskIcon from '@mui/icons-material/AddTask';

const ArchitectSkills = () => {
    const items = [
        {
            icon: <AcUnitIcon />,
            description: "СОБСТВЕННОЕ КОНСТРУКТОРСКОЕ БЮРО И ПРОИЗВОД-СТВЕННЫЙ КОМ-ПЛЕКС"
        },
        {
            icon: <AdUnitsIcon />,
            description: "РЕАЛИЗУЕМ СЛОЖНЫЕ КОМПЛЕКСНЫЕ ПРОЕКТЫ ОФОРМЛЕНИЯ"
        },
        {
            icon: <AlignVerticalCenterIcon />,
            description: "КРЕАТИВНЫЕ МАФЫ И АРТ-ОБЪЕКТЫ ИЗ ЭКОЛОГИЧНЫХ МАТЕРИАЛОВ"
        },
        {
            icon: <AddTaskIcon />,
            description: "ПЕРВЫЕ В СТРАНЕ СОЗДАЕМ КОНСТРУКЦИИ С ИНТЕРАКТИВНЫМИ ТЕХНОЛОГИЯМИ"
        },
    ]

    return (
        <div className="architectSkills">
            <div className="architectSkills__container">
                <div className="row">
                    {items.map((item, index) => (
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                            <div className="architectSkills__block" key={index}>
                                <div className="architectSkills__icon">
                                    {item.icon}
                                </div>
                                <div className="architectSkills__description">
                                    {item.description}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ArchitectSkills