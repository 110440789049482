import { applicationGalleryConstants } from "./constants";
import axios from '../helpers/axios'
import swal from 'sweetalert';
import { getGalleryById } from "./components/gallery";

export const createApplicationGallery = (payload) => {
    return async dispatch => {
        try {
            dispatch({ type: applicationGalleryConstants.CREATE_APPLICATION_GALLERY_REQUEST });
            const res = await axios.post("/applicationGallery/create", payload)
            if (res.status === 201) {
                dispatch({
                    type: applicationGalleryConstants.CREATE_APPLICATION_GALLERY_SUCCESS,
                    payload: res.data
                })
                const galleryId = payload.applicationForm.galleryId
                if (galleryId) {
                    dispatch(getGalleryById(galleryId))
                }

                swal({
                    title: "Успешно отправено",
                    text: "Оператор свяжется с вами в ближайшее время",
                    icon: "success",
                });
            } else {
                dispatch({
                    type: applicationGalleryConstants.CREATE_APPLICATION_GALLERY_FAILURE,
                    payload: res.data
                })
                swal({
                    title: "Ошибка отправки",
                    text: "Попробуйте снова",
                    icon: "warning",
                });
            }
        } catch (error) {
            console.log(error)
        }
    }
}


export const getApplicationGalleryById = (id) => {
    return async (dispatch) => {
        try {
            const res = await axios.get(`/applicationGallery/getById/${id}`);
            dispatch({ type: applicationGalleryConstants.GET_BY_ID_REQUEST });
            if (res.status === 200) {
                dispatch({
                    type: applicationGalleryConstants.GET_BY_ID_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: applicationGalleryConstants.GET_BY_ID_FAILURE,
                    payload: res.data,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};
export const getApplicationGalleryByUser = () => {
    return async (dispatch) => {
        try {
            const res = await axios.get("/applicationGallery/getByUser");
            dispatch({ type: applicationGalleryConstants.GET_BY_USER_REQUEST });
            if (res.status === 200) {
                dispatch({
                    type: applicationGalleryConstants.GET_BY_USER_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: applicationGalleryConstants.GET_BY_USER_FAILURE,
                    payload: res.data,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};
