import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { setShowModalCart } from '../../reducers/cart.reducer';
import { addToCart, updateProductView } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { goToTop } from '../GoToTop';
import activeAddButton from '../../containers/Products/ActiveAddButton';

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
    padding: theme.spacing(1, 2),
    backgroundColor: '#349a87',
    color: 'white',
    '&:hover': {
        backgroundColor: '#393838',
    },
}));

const FixedButtonsBottomFullScreen = ({
    handleCloseFullPicture,
    product
}) => {
    const { user } = useSelector((state) => state.auth)
    const { cartItems } = useSelector((state) => state.cart)
    const [showButtonsBlock, setShowButtonsBlock] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleClick = async () => {
        const payload = {
            productId: product._id,
            userId: user._id
        }
        await dispatch(updateProductView(payload))
        navigate(`/productDetails/${product._id}`)
        goToTop("onlyTop", 0)
    };

    const handleAddToCart = () => {
        dispatch(setShowModalCart())
        const payload = {
            _id: product._id,
            fullName: product.fullName,
            img: product.reducedImage
        }
        dispatch(addToCart(payload))
        handleCloseFullPicture()
    }
    const buttonsArray = [
        {
            onClick: handleClick,
            text: "Перейти"
        },
        {
            onClick: handleAddToCart,
            text: activeAddButton(product._id, cartItems) === product._id
                ? "Уже в корзине"
                : "Купить"

        },
    ];

    const toggleButtonsBlock = () => {
        setShowButtonsBlock(!showButtonsBlock);
    };

    return (
        <div className="fixedButtonsBottomFullScreen">
            {!showButtonsBlock ? (
                <div
                    className={`fixedButtonsBottomFullScreen__icon ${!showButtonsBlock && "showBlock"}`}
                    onClick={toggleButtonsBlock}
                >
                    <KeyboardArrowUpIcon color="secondary" />
                </div>
            ) : (
                <div>
                    <div
                        className="fixedButtonsBottomFullScreen__icon"
                        onClick={toggleButtonsBlock}
                    >
                        <KeyboardArrowDownIcon color="secondary" />
                    </div>
                    <div className="fixedButtonsBottomFullScreen__buttons">
                        {buttonsArray.map((item, index) =>
                            <StyledButton
                                key={index}
                                onClick={() => item.onClick()}
                                variant="contained"
                            >
                                {item.text}
                            </StyledButton>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FixedButtonsBottomFullScreen;
