import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import BRenderAddPictures from './../../../components/button/BRenderAddPictures';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { addSamplePhotoApplication } from '../../../actions';
import RenderStatusArray from './RenderStatusArray';
import { generatePublicUrl } from '../../../urlConfig';
import { useNavigate } from 'react-router-dom';
import StatusColorText from '../StatusColorText';
import { Tooltip } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

const FileUploadModal = ({
    handleShowStatusModal,
    filterApplication,
    applicationItem,
    productDetails,
    itemObjStatus,
    typeModal,
    itemObj,
    onClose,
    isOpen,
}) => {
    const [files, setFiles] = useState([]);
    const [disabledButtonSendNewPhotos, setDisabledButtonSendNewPhotos] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (files.length > 0) {
            setDisabledButtonSendNewPhotos(false)
        } else {
            setDisabledButtonSendNewPhotos(true)
        }
    }, [files])

    const handleButtonClick = (type) => {
        if (type === "product") {
            navigate(`/productDetails/${productDetails._id}`);
        } else {
            navigate(`/productsCategory/${productDetails.categorySlug}?${productDetails.category}`);
        }
    };

    const handleSubmit = () => {
        if (files.length > 0) {
            dispatch(addSamplePhotoApplication(itemObj._id, files))
        }
        onClose();
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: "350px",
        maxWidth: "600px",
        minHeight: "100px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        overflowY: "auto"
    };
    const applicationItemContent = [
        applicationItem && applicationItem.backupNumber && {
            name: "Доп-номер",
            text: applicationItem?.backupNumber
        },
        applicationItem && applicationItem.contactNumber && {
            name: "Основной номер",
            text: applicationItem?.contactNumber
        },
        applicationItem && applicationItem.firstName && {
            name: "Имя",
            text: applicationItem?.firstName
        },
        applicationItem && applicationItem.lastName && {
            name: "Фамилия",
            text: applicationItem?.lastName
        },
        applicationItem && applicationItem.birthData && {
            name: "Дата рождения",
            text: applicationItem?.birthData
        },
        applicationItem && applicationItem.gender &&
        {
            name: "Пол",
            text: applicationItem?.gender
        },
        applicationItem && applicationItem.email && {
            name: "Email",
            text: applicationItem.email
        },
    ]
    const renderProductDetailsArray = [
        {
            name: "Имя товара",
            text: <Button onClick={() => handleButtonClick("product")}>{productDetails?.fullName}</Button>
        },
        {
            name: "Категория",
            text: <Button onClick={() => handleButtonClick("category")}>{productDetails?.categoryName}</Button>
        },
        {
            name: "Фото",
            img: productDetails?.productPictures ? productDetails?.productPictures[0]?.img : null
        },
    ]
    const applicationPersonalArray = [
        {
            name: "Имя",
            text: itemObj.firstName
        },
        {
            name: "Фамилия",
            text: itemObj.lastName
        },
        {
            name: "Дата рождения",
            text: itemObj.birthData
        },
        {
            name: "Пол",
            text: itemObj.gender
        },
    ]

    const applicationContactArray = [
        {
            name: "Основной номер",
            text: itemObj.contactNumber
        },
        {
            name: "Доп-номер",
            text: itemObj.backupNumber
        },
    ]
    const applicationLoginArray = [
        {
            name: "Email",
            text: itemObj.email
        },
    ]
    const renderContent = () => {
        let content, title;
        switch (typeModal) {
            case "itemFullInfo":
                content = <>
                    <Button onClick={() => handleShowStatusModal("allApplication")}>Назад</Button>
                    {applicationItemContent.map((item, index) => (
                        item &&
                        <div className="applicationPage__detailsContent"
                            key={index}
                        >
                            <div className="applicationPage__detailsLabel">
                                {item.name}
                            </div>
                            <div className="applicationPage__detailsText">
                                {item.text ? item.text : "Пусто"}
                            </div>
                        </div>
                    ))}
                </>
                title = "Подробнее об заявке"
                break;
            case "allApplication":
                content = <>
                    {filterApplication.map((item, index) => (
                        <div className={`applicationPage__detailsContent changeProfile`}
                            key={index}
                        >
                            <StatusColorText
                                status={item.applicationStatus}
                                mini
                            />
                            <div className="applicationPage__detailsLabel changeProfile">
                                {item.applicationNumber}
                            </div>
                            <div className="applicationPage__detailsText changeProfile">
                                {item.createdAt.slice(0, 10) + " " + item.createdAt.slice(11, 19)}
                            </div>
                            <div className="applicationPage__detailsContentSvg changeProfile">
                                <Tooltip
                                    title="Подробнее о заявке"
                                    onClick={() => handleShowStatusModal("itemFullInfo", "", item)}
                                >
                                    <DescriptionIcon />
                                </Tooltip>
                            </div>
                        </div>
                    ))}
                </>
                title = "Все созданные заявки"
                break;
            case "applicationLogin":
                content = <>
                    {applicationLoginArray.map((item, index) => (
                        <div className="applicationPage__detailsContent"
                            key={index}
                        >
                            <div className="applicationPage__detailsLabel">
                                {item.name}
                            </div>
                            <div className="applicationPage__detailsText">
                                {item.text ? item.text : "Пусто"}
                            </div>
                        </div>
                    ))}
                </>
                title = "Желаемый логин"
                break;
            case "applicationPassword":
                content = <div className="applicationPage__detailsContent">
                    <div className="applicationPage__detailsLabel">
                        Вы оставили запрос на смену пароля
                    </div>
                </div>
                title = "Смена пароля"
                break;
            case "applicationContact":
                content = <>
                    {applicationContactArray.map((item, index) => (
                        <div className="applicationPage__detailsContent"
                            key={index}
                        >
                            <div className="applicationPage__detailsLabel">
                                {item.name}
                            </div>
                            <div className="applicationPage__detailsText">
                                {item.text ? item.text : "Пусто"}
                            </div>
                        </div>
                    ))}
                </>
                title = "Ваши контакты"
                break;
            case "applicationPersonal":
                content = <>
                    {applicationPersonalArray.map((item, index) => (
                        <div className="applicationPage__detailsContent"
                            key={index}
                        >
                            <div className="applicationPage__detailsLabel">
                                {item.name}
                            </div>
                            <div className="applicationPage__detailsText">
                                {item.text}
                            </div>
                        </div>
                    ))}
                </>
                title = "Персональные данные"
                break;
            case "productDetailsModal":
                content = <>
                    {renderProductDetailsArray.map((item, index) => (
                        <div className="applicationPage__detailsContent"
                            key={index}
                        >
                            <div className="applicationPage__detailsLabel">
                                {item.name}
                            </div>
                            {!item.text && item.img
                                ? <div className="applicationPage__productImgBox">
                                    <img src={generatePublicUrl(item.img)} />
                                </div>
                                : <div className="applicationPage__detailsText">
                                    {item.text}
                                </div>
                            }
                        </div>
                    ))}
                </>
                title = "Товар по которому задан вопрос"
                break;
            case "statusModal":
                content = <>
                    <RenderStatusArray
                        statusArray={itemObjStatus}
                    />
                    <div style={{
                        marginTop: "15px",
                        textAlign: "right"
                    }}>
                        <Button variant="outlined" color="error" onClick={onClose}>Закрыть</Button>
                    </div>
                </>
                title = "Статус заявки"
                break;
            default:
                content = <>
                    <BRenderAddPictures
                        setPictures={setFiles}
                    />
                    <Button
                        variant="outlined"
                        color="success"
                        onClick={handleSubmit}
                        disabled={disabledButtonSendNewPhotos}
                    >
                        Отправить
                    </Button>
                    <Button variant="outlined" color="error" onClick={onClose}>Отменить</Button>
                </>
                title = "Добавить фото"
        }
        return { content, title }
    }

    return (
        <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 style={{ margin: "10px auto", textAlign: "center" }}>
                    {renderContent().title}
                </h3>
                {renderContent().content}
            </Box>
        </Modal>
    );
};

export default FileUploadModal;
