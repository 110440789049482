import React from 'react'
import { generatePublicUrl } from '../../urlConfig';
import activeAddButton from '../../containers/Products/ActiveAddButton';
import ZoomIcon from '../Icon/ZoomIcon';
import FavoriteIconComponent from '../Icon/FavoriteIconComponent';
import MoreIconFunctionalityProduct from '../Search/MoreIconFunctionalityProduct';

const ItemGallery = ({
    typePage,
    localStorageLiked,
    item,
    handleClick,
    contentItemType,
    addGalleryImgUser,
    galleryImagesUser,
    authenticate,
    handleMouseClose,
    handleShowButton,
    currentId,
}) => {

    const renderContent = () => {
        let content;
        switch (contentItemType) {
            case "mainPages":
                content = (
                    <>
                        <FavoriteIconComponent
                            item={item}
                            addGalleryImgUser={addGalleryImgUser}
                            galleryImagesUser={authenticate ? galleryImagesUser.galleryImgUserItems : localStorageLiked}
                            activeAddButton={activeAddButton}
                        />
                        <ZoomIcon
                            item={item}
                            currentId={currentId}
                            handleClick={handleClick}
                        />
                        <img
                            src={generatePublicUrl(item.picture?.img || item?.img)}
                            alt={item._id}
                        />
                    </>
                )
                break;
            default: content =
                <div className="itemGallery__galleryPage">
                    <img
                        src={generatePublicUrl(item.picture?.img || item?.img)}
                        alt={item._id}
                    />
                    <ZoomIcon
                        item={item}
                        currentId={currentId}
                        handleClick={handleClick}
                    />
                    <div className="itemGallery__galleryPage__moreIcon">
                        <MoreIconFunctionalityProduct
                            obj={item}
                        />
                    </div>
                </div>

        }
        return content
    }


    return (
        <div className="itemGallery"
            onMouseEnter={() => handleShowButton(item._id)}
            onClick={() => handleShowButton(item._id)}
            onMouseLeave={() => handleMouseClose()}
        >
            {renderContent()}
        </div>
    )

}

export default ItemGallery