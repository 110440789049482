import axios from "../helpers/axios";
import { desiresConstants } from "./constants";
import store from "../store";

const getDesiresItems = (sort) => {
  return async (dispatch) => {
    try {
      dispatch({ type: desiresConstants.GET_TO_DESIRES_REQUEST });
      const { auth: { authenticate } } = store.getState();
      if (authenticate) {
        const res = await axios.post(`/user/getDesiresItems?sort=${sort}`);
        if (res.status === 200) {
          const { desiresItems } = res.data;
          if (desiresItems) {
            dispatch({
              type: desiresConstants.GET_TO_DESIRES_SUCCESS,
              payload: { desiresItems },
            });
          }
        }
      } else {
        const localDesiresItems = JSON.parse(localStorage.getItem('desires')) || [];
        dispatch({
          type: desiresConstants.GET_TO_DESIRES_SUCCESS,
          payload: { desiresItems: localDesiresItems },
        });
      }
    } catch (error) {
      dispatch({
        type: desiresConstants.GET_TO_DESIRES_FAILURE,
        payload: { error },
      });
    }
  };
};

export const addToDesires = (product) => {
  return async (dispatch) => {
    try {

      const { desires: { desiresItems }, auth } = store.getState();

      desiresItems[product._id] = { ...product };

      if (auth.authenticate) {
        dispatch({ type: desiresConstants.ADD_TO_DESIRES_REQUEST });

        const payload = {
          desiresItems: [{ product: product._id }],
        };
        const res = await axios.post("/user/desires/addToDesires", payload);

        if (res.status === 201) {
          dispatch(getDesiresItems());
        }
      } else {
        localStorage.setItem("desires", JSON.stringify(Object.values(desiresItems)));
        dispatch(getDesiresItems());
      }
      dispatch({
        type: desiresConstants.ADD_TO_DESIRES_SUCCESS,
        payload: { desiresItems },
      });
    } catch (error) {
      dispatch({
        type: desiresConstants.ADD_TO_DESIRES_FAILURE,
        payload: { message: error.response.data.message },
      });
    }
  };
};

export const removeDesiresItem = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: desiresConstants.REMOVE_DESIRES_ITEM_REQUEST });
      const { desires: { desiresItems }, auth: { authenticate } } = store.getState();
      if (authenticate) {

        const res = await axios.post("/user/desires/removeItem", payload);
        if (res.status === 202) {
          dispatch({
            type: desiresConstants.REMOVE_DESIRES_ITEM_SUCCESS,
            payload: res.data
          });
          dispatch(getDesiresItems());
        } else {
          dispatch({
            type: desiresConstants.REMOVE_DESIRES_ITEM_FAILURE,
            payload: res.data
          });
        }
      } else {
        if (desiresItems.length > 0) {
          const { productId } = payload;
          const updatedDesiresItems = desiresItems.filter(item => item._id !== productId);
          localStorage.setItem("desires", JSON.stringify(updatedDesiresItems));
          dispatch({
            type: desiresConstants.REMOVE_DESIRES_ITEM_SUCCESS,
          });
          dispatch(getDesiresItems());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateDesires = () => {
  return async (dispatch) => {
    const { auth } = store.getState();
    let desiresItems = localStorage.getItem("desires")
      ? JSON.parse(localStorage.getItem("desires"))
      : null;
    if (auth.authenticate) {
      localStorage.removeItem("desires");
      if (desiresItems) {
        const payload = {
          desiresItems: Object.keys(desiresItems).map((key, index) => {
            return {
              quantity: desiresItems[key].qty,
              product: desiresItems[key]._id,
            };
          }),
        };
        if (Object.keys(desiresItems).length > 0) {
          const res = await axios.post(`/user/desires/addToDesires`, payload);
          if (res.status === 201) {
            dispatch(getDesiresItems());
          }
        }
      } else {
        dispatch(getDesiresItems());
      }
    } else {
      if (desiresItems) {
        dispatch({
          type: desiresConstants.ADD_TO_DESIRES_SUCCESS,
          payload: { desiresItems },
        });
        dispatch(getDesiresItems());

      }
    }
  };
};

export { getDesiresItems };