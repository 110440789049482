import React, { useEffect, useState } from 'react'
import { getProducts, updateProductView } from '../../../actions'
import { useDispatch, useSelector } from 'react-redux';
import { generatePublicUrl } from '../../../urlConfig';
import { Checkbox } from '@mui/material';
import CarouselComponent from '../../../components/CarouselComponent';
import { getHistoryProductItems } from './../../../actions/historyProduct';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import SettingIconBlock from '../../../components/button/SettingIconBlock';
import DescContainer from './DescContainer';
import HrefButtonBox from '../../../components/Search/HrefButtonBox';

const RenderProductContainer = ({
    setCheckedItems,
    setCurrentId,
    currentId,
    checkedItems,
    sliderProd,
    deleteObj,
    items,
}) => {
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [typeModal, setTypeModal] = useState("");

    const { _id } = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e, id) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedItems([...checkedItems, id]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== id));
        }
    };
    useEffect(() => {
        dispatch(getProducts());
    }, [dispatch]);

    const handleOpenModal = (id, type) => {
        setTypeModal(type)
        setSelectedItemId(id);
        setCurrentId(id);
    };

    const handleCloseModal = () => {
        setTypeModal("")
        setSelectedItemId(null);
    };

    const handleHistoryProduct = (obj) => {
        if (!checkedItems.includes(obj._id)) {
            const payload = {
                productId: obj._id,
                userId: _id
            }
            dispatch(updateProductView(payload))
            navigate(`/productDetails/${obj._id}`);
        }
    };
    if (sliderProd) {
        return (
            <CarouselComponent
                get={getHistoryProductItems}
                textName="Просмотренные"
                itemsType="content"
                items={items}
                content
            />
        );
    } else {
        return (
            <div className="renderDesiresProduct">
                {items.map((obj, index) => (
                    <div
                        className="renderDesiresProduct__mainContainer"
                        key={index}
                    >
                        <div
                            className="renderDesiresProduct__imgNameBox"
                            onClick={() => handleOpenModal(obj._id, "buttonBox")}
                        >
                            <div className="renderDesiresProduct__imgContainer">
                                <img
                                    src={generatePublicUrl(obj.img)}
                                    alt={obj.fullName}
                                />
                                <SettingIconBlock
                                    typeModal={typeModal}
                                    obj={obj}
                                    setCurrentId={setCurrentId}
                                    description="Действительно ли вы хотите удалить позицию, вернуть это будет невозможно!"
                                    handleClose={handleCloseModal}
                                    handleShow={() => handleOpenModal(obj._id, "deleteModal")}
                                    handleExecute={deleteObj}
                                    visible={selectedItemId === obj._id}
                                    tooltip="Удалить с истории"
                                    icon={<DeleteIcon />}
                                    top="-5px"
                                    right="-10px"
                                />
                                <div className="renderDesiresProduct__checkboxContainer">
                                    <Checkbox
                                        checked={checkedItems?.includes(obj._id)}
                                        name={obj.name}
                                        value={obj._id}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleChange(e, obj._id);
                                        }}
                                    />
                                </div>
                                {obj.topProduct && (
                                    <div className="renderDesiresProduct__imgTopText">
                                        Топ продаж
                                    </div>
                                )}
                            </div>
                        </div>
                        <DescContainer
                            obj={obj}
                        />
                        {typeModal === "buttonBox"
                            ? <HrefButtonBox
                                handleProduct={handleHistoryProduct}
                                setCurrentId={setCurrentId}
                                currentId={currentId}
                                obj={obj}
                            />
                            : null}

                    </div>
                ))}
            </div>
        );
    }
}

export default RenderProductContainer;
