import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../components/UI/Card";
import { getCartItems, removeCartItem } from "../../actions";
import PurpleButton from './../../components/button/PurpleButton';
import ContainerMainComponent from './../ContainerMainComponent';
import CartItem from "../Header/CartItem";
import EmptyCartContainer from "../Header/EmptyCartContainer";


const CartPage = ({
    history,
    onlyCartItems
}) => {
    const { cartItems } = useSelector((state) => state.cart);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCartItems());
    }, []);

    const onRemoveCartItem = (_id) => {
        dispatch(removeCartItem({ productId: _id }));
    };

    const renderEmptyCartItems = () => {
        return <EmptyCartContainer />
    }

    if (onlyCartItems) {
        return cartItems.length > 0
            ? cartItems.map((item, index) => (
                <CartItem
                    key={index}
                    cartItem={item}
                    onRemoveCartItem={onRemoveCartItem}
                />
            ))
            : renderEmptyCartItems()
    }

    return (
        <ContainerMainComponent
            title="Ваши заказы"
            description="Как с нами связаться"
        >

            <div className="cartContainer" style={{ alignItems: "flex-start" }}>
                <Card
                    headerLeft={`Мой заказ`}
                    headerRight={<div>Время производста</div>}
                    style={{ width: "calc(100% - 400px)", overflow: "hidden" }}
                >
                    {cartItems.map((item, index) => (
                        <CartItem
                            key={index}
                            cartItem={item}
                            onRemoveCartItem={onRemoveCartItem}
                        />
                    ))}

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            background: "#ffffff",
                            justifyContent: "flex-end",
                            boxShadow: "0 0 10px 10px #eee",
                            padding: "10px 0",
                            boxSizing: "border-box",
                        }}
                    >
                        <div style={{ width: "250px" }}>
                            <PurpleButton
                                title="Подтвердить"
                                onClick={() => history.push(`/checkout`)}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        </ContainerMainComponent>
    );
};

export default CartPage;
