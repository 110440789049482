import React from 'react'
import { motion } from 'framer-motion'
import { upAnimation } from './Animation/Animation';
import BlackButton from './button/BlackButton';

const BlackLine = () => {
    return (
        <motion.div
            style={{
                overflow: "hidden"
            }}
            initial="hidden"
            whileInView="visible"
            className="requestForCalculating">
            <div className="requestForCalculating__containerButton" >
                <motion.a
                    href={"/productsCategory/all"}
                    variants={upAnimation}
                >
                    <BlackButton
                        onClick={() => { }}
                        text="Перейти в каталог"
                    />
                </motion.a>
            </div>
        </motion.div>
    )
}

export default BlackLine