import { galleryImgUserConstants } from "../actions/constants";

const initState = {
    galleryImagesUser: [],
    updatingDesires: false,
    showModal: false,
    showAlert: false,
    textAlert: "",
    variantAlert: "success",
    error: null
};

export default (state = initState, action) => {
    switch (action.type) {

        case galleryImgUserConstants.REMOVE_ITEM_REQUEST:
            state = {
                ...state,
                updatingDesires: true
            }
            break;
        case galleryImgUserConstants.REMOVE_ITEM_SUCCESS:
            state = {
                ...state,
                textAlert: "Удален из списка",
                showAlert: true,
                variantAlert: "success",
                updatingDesires: false,
            }
            break;
        case galleryImgUserConstants.REMOVE_ITEM_FAILURE:
            state = {
                ...state,
                variantAlert: "warning",
                textAlert: "Что-то пошло не так!",
                showAlert: true,
                updatingDesires: false,
            }
            break;

        case galleryImgUserConstants.ADD_TO_GALLERY_IMG_REQUEST:
            state = {
                ...state,
                updatingDesires: true
            }
            break;
        case galleryImgUserConstants.ADD_TO_GALLERY_IMG_SUCCESS:
            state = {
                ...state,
                textAlert: "Добавлен в список",
                showAlert: true,
                variantAlert: "success",
                updatingDesires: false,
            }
            break;
        case galleryImgUserConstants.ADD_TO_GALLERY_IMG_FAILURE:
            state = {
                ...state,
                variantAlert: "warning",
                textAlert: "Уже добавлен в список!",
                showAlert: true,
                updatingDesires: false,
            }
            break;
        case galleryImgUserConstants.GET_GALLERY_IMAGES_REQUEST:
            state = {
                ...state,
                updatingDesires: true,
            }
            break;
        case galleryImgUserConstants.GET_GALLERY_IMAGES_SUCCESS:
            state = {
                ...state,
                galleryImagesUser: action.payload.galleryImagesUser,
                updatingDesires: false,
            }
            break;
        case galleryImgUserConstants.GET_GALLERY_IMAGES_FAILURE:
            state = {
                ...state,
                updatingDesires: false,
                error: action.payload.error
            }
            break;
        case galleryImgUserConstants.CLOSE_ALERT_SUCCESS:
            state = {
                ...state,
                showAlert: !state.showAlert
            }
            break;
        // case galleryImgUserConstants.REMOVE_DESIRES_ITEM_REQUEST:
        //     state = {
        //         ...state,
        //         updatingDesires: true,

        //     }
        //     break;
        // case galleryImgUserConstants.REMOVE_DESIRES_ITEM_SUCCESS:
        //     state = {
        //         ...state,
        //         updatingDesires: false,
        //         textAlert: "Успешно удален!",
        //         showAlert: true,
        //     }
        //     break;
        // case galleryImgUserConstants.REMOVE_DESIRES_ITEM_FAILURE:
        //     state = {
        //         ...state,
        //         updatingDesires: false,
        //         variantAlert: "warning",
        //         textAlert: "Проблемы с удалением!",
        //         showAlert: true,
        //     }
        //     break;

        // case galleryImgUserConstants.SHOW_MODAL_DESIRES:
        //     state = {
        //         ...state,
        //         showModal: !state.showModal
        //     }
        //     break;
        // case galleryImgUserConstants.RESET_DESIRES:
        //     state = {
        //         ...initState
        //     }
    }
    return state;
}

// export const setShowModalDesires = (payload) => ({ type: galleryImgUserConstants.SHOW_MODAL_DESIRES, payload })
export const setShowAlertGalleryImgUser = (payload) => ({ type: galleryImgUserConstants.CLOSE_ALERT_SUCCESS, payload })