import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonMaterialUi } from '../../../components/MaterialUi';
import RenderMiniModalCabinet from '../RenderMiniModalCabinet';
import ContainerCabinetComponent from '../../ContainerCabinetComponent';
import { motion } from 'framer-motion';
import { opacityAnimation } from './../../../components/Animation/Animation';
import { getItemById, getRoleChangeRequestProfileByUser } from '../../../actions';
import PartnerPageItem from './PartnerPageItems';
import RenderDetailsPartnerPage from './RenderDetailsPartnerPage';

const PartnerPage = () => {
    const {
        roleChangeRequests,
        roleChangeRequestObj,
        hasIncompleteStatus
    } = useSelector((state) => state.roleChangeRequest);
    const { auth } = useSelector((state) => state)
    const [showModal, setShowModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [showNewPartnerBlock, setShowNewPartnerBlock] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        let userId = auth.user._id
        dispatch(getRoleChangeRequestProfileByUser(userId));
    }, []);

    // useEffect(() => {
    //     if (hasIncompleteStatus) {
    //         setShowNewPartnerBlock(false)
    //     } else {
    //         setShowNewPartnerBlock(true)
    //     }
    // }, [hasIncompleteStatus]);

    const toggle = (id) => {
        if (selectedItemId === id) {
            setSelectedItemId(null);
        } else {
            setSelectedItemId(id);
            dispatch(getItemById("roleChangeRequest", id));
        }
    };

    return (
        <ContainerCabinetComponent
            title={"Страница для партнеров"}
        >
            {hasIncompleteStatus && (
                <motion.div
                    className="partnerPage"
                    whileInView="visible"
                    initial="hidden"
                >
                    <motion.div
                        className="partnerPage__newPartnerBlock"
                        variants={opacityAnimation}
                    >
                        <div className="partnerPage__contentBlock">
                            <h2>Подать заявку на партнерство</h2>
                            <p>Если вы еще не стали нашим партнером, то самое время оставить заявку на партнерство</p>
                        </div>
                        <ButtonMaterialUi
                            title="Подать"
                            onClick={() => setShowModal(true)}
                        />
                    </motion.div>
                </motion.div>
            )}

            <div className="partnerPage__mainContainer">
                {roleChangeRequests && roleChangeRequests.length > 0 ? (
                    roleChangeRequests.map((item) => (
                        <PartnerPageItem
                            key={item._id}
                            item={item}
                            selectedItemId={selectedItemId}
                            toggle={toggle}
                        >
                            {selectedItemId === item._id && (
                                <RenderDetailsPartnerPage
                                    roleChangeRequestObj={roleChangeRequestObj}
                                />
                            )}
                        </PartnerPageItem>
                    ))
                ) : (
                    <p className="partnerPage__noneContent"> Нет созданых заявок</p>
                )}
            </div>
            <RenderMiniModalCabinet
                setShowModal={setShowModal}
                showModalCabinet={showModal}
                setShowModalCabinet={setShowModal}
                closeButton
                description="Укажите свой номер телефона, если есть вопросы
                опишите их в форме ниже"
                onClickClose={() => setShowModal(false)}
            />
        </ContainerCabinetComponent>
    );
}

export default PartnerPage;
