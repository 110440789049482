import React from 'react'

const DescriptionItemLine = ({
    item,
}) => {
    if (!item) {
        return null;
    }
    return (
        <div className="descriptionItemLine">
            <div className="descriptionItemLine__title">
                {item.title}
            </div>
            <div className="descriptionItemLine__line" />
            <div className="descriptionItemLine__content">
                {item.content}
            </div>
        </div>
    )
}

export default DescriptionItemLine