import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getByIdProductListLink, getProducts } from '../../actions';
import Slider from '../Slider/Slider';
import Loader from './../Loader';


const Landscaping = () => {
    const dispatch = useDispatch()
    const products = useSelector((state) => state.product.productMoreThreeImg)
    const { productListLinkObj } = useSelector((state) => state.productListLink)
    const [textName, setTextName] = useState("Товар")
    const [prodObjHref, setProdObjHref] = useState(null)

    useEffect(() => {
        dispatch(getProducts())
    }, [])

    useEffect(() => {
        dispatch(getByIdProductListLink("647a4f42cc8857b613b1661b"))
    }, [])


    if (!products) {
        return <Loader />
    }

    const productsArray = productListLinkObj.featuredProductItemObj?.products

    return (
        <div className="landscaping">
            <div className="landscaping__containerRow">
                <Slider
                    renderHrefContent
                    obj={productListLinkObj.featuredProductItemObj}
                    prodObjHref={prodObjHref}
                    setProdObjHref={setProdObjHref}
                    textName={textName}
                    setTextName={setTextName}
                    itemTwo={productsArray}
                />
            </div>
        </div>
    )
}

export default Landscaping
