import { materialConstants } from '../../actions/constants';



const initialState = {
    material: [],
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case materialConstants.GET_MATERIAL_REQUEST:
            state = {
                ...state,
                loading: true

            }
            break
        case materialConstants.GET_MATERIAL_SUCCESS:
            state = {
                ...state,
                material: action.payload.material,
                loading: false

            }
            break
        case materialConstants.GET_MATERIAL_FAILURE:
            state = {
                ...state,
                loading: false

            }
            break
    }
    return state
}