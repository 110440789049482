import axios from "axios";
import { api } from "../urlConfig";
import store from "../store";
import { authConstants } from "../actions/constants";

// Добавленная константа для siteName
const SITE_NAME = 'alotb.ru';

const token = window.localStorage.getItem("token");
const axiosIntance = axios.create({
  baseURL: api,
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
    // Добавляем siteName к заголовкам для всех запросов
    siteName: SITE_NAME,
  },
});

axiosIntance.interceptors.request.use((req) => {
  const { auth } = store.getState();
  if (auth.token) {
    req.headers.Authorization = `Bearer ${auth.token}`;
  }

  return req;
});

axiosIntance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    console.error(error.response);
    // const status = error.response ? error.response.status : 500;
    // if (status && status === 500) {
    //   localStorage.clear();
    //   store.dispatch({ type: authConstants.LOGOUT_SUCCESS });
    // }
    // return Promise.reject(error);
  }
);

export default axiosIntance;
