import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CircleIcon from "@mui/icons-material/Circle";
import { getElementPaymentPage, getProductDetailsById } from "../../actions";
import { generatePublicUrl } from "../../urlConfig";
import ContainerMainComponent from "../ContainerMainComponent";
import { useLocation } from 'react-router-dom';
import DarkLineComponent from "../../components/DarkLineComponent";

const ElementPaymentPage = () => {
  const { elementPaymentPages, loading } = useSelector(
    (state) => state.elementPaymentPage
  );
  const { fullName, categorySlug, categoryName } = useSelector((state) => state.product.productDetails)
  const [productItem, setProductItem] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(getElementPaymentPage());
  }, []);

  const productId = new URLSearchParams(location.search).get('productId');

  useEffect(() => {
    setProductItem(JSON.parse(localStorage.getItem('breadcrumbData')) || {});
    if (productId) {
      dispatch(getProductDetailsById(productId));
    }
  }, [productId]);
  return (
    <ContainerMainComponent
      title="Условия оплаты"
      categoryName={categoryName}
      bread
      titleItem={fullName}
      categorySlug={categorySlug}
      productId={productId}
      type="page"
    >
      <div className="elementPaymentPage">
        <div className="elementPaymentPage__content">
          {elementPaymentPages.map((item) => (
            <div
              className="elementPaymentPage__contentMain"
              key={item._id}
            >
              <div className="elementPaymentPage__mainTitle">{item.mainTitle}</div>
              <div className="elementPaymentPage__mainDescription">{item.mainDescription}</div>
              <div className="elementPaymentPage__imgBoxRow">
                {!item.pictures.length > 0
                  ? null
                  : item.pictures.map((imgItem) => (
                    <div className="elementPaymentPage__imgBox">
                      <img src={generatePublicUrl(imgItem.img)} alt="" />
                    </div>
                  ))}
              </div>
              <div className="elementPaymentPage__itemInfo">
                {item.itemsInfo.map((itemInfo) => (
                  <div className="elementPaymentPage__itemInfoContent" key={itemInfo._id}>
                    <span> {itemInfo.title}</span>
                    <div className="elementPaymentPage__itemInfoDescArray">
                      {itemInfo.itemsDescription.map((itemDescription) => (
                        <div
                          className="elementPaymentPage__itemDescriptionContent"
                          key={itemDescription._id}
                        >
                          {itemDescription.li ? (
                            <CircleIcon fontSize="small" />
                          ) : (
                            ""
                          )}
                          {itemDescription.text}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <DarkLineComponent
        buttonBlock
        titleTop="НАШИ ИЗДЕЛИЯ"
        titleCenter="В КАТЕГОРИИ"
        titleBottom={categoryName || productItem.categoryName}
        categorySlug={categorySlug || productItem.categorySlug}
      />
    </ContainerMainComponent>
  );
};

export default ElementPaymentPage;
