import React from 'react'
import ProductStore from './Feature/ProductStore';
import ProductPage from './Feature/ProductPage';
import ProductFeatureDefault from './Feature/ProductFeatureDefault';

const FeaturePage = (props) => {
  const renderProduct = () => {
  
    let content = null;
    switch (props.type) {
      case "store":
        content = <ProductStore {...props} />;
        break;
      case "page":
        content = <ProductPage {...props} />;
        break; 
      default:
        content = <ProductFeatureDefault {...props} />;
    }

    return content;
  };
  return renderProduct()
}




export default FeaturePage