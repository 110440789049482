import React, { useEffect, useRef } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import activeAddButton from './../../containers/Products/ActiveAddButton';

const MoreIconButtonCurrentId = ({
    itemArray,
    objId,
    item,
    closeMenu,
    setCloseMenu
}) => {
    const menuRef = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setCloseMenu(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [menuRef, setCloseMenu]);

    const handleClick = (obj) => {
        if (obj.titleAdd === "Уже добавлено" || "Удалить с корзины") {
            activeAddButton(objId, obj.activeAddButtonItems) === objId
                ? obj.onClickDisable(objId)
                : obj.onClick(item)
        } else {
            obj.onClick(item)
        }
    }

    const renderMoreMenu = () => {
        if (!closeMenu) return null;

        return (
            <div className="renderMoreMenu__mainContainer" ref={menuRef}>
                <ul>
                    {itemArray.map((obj, index) => (
                        <li key={index} onClick={() => handleClick(obj)}>
                            <div className={`renderMoreMenu__renameIconBox
                            ${activeAddButton(objId, obj.activeAddButtonItems) === objId
                                    ? "active"
                                    : ""}`}>
                                {obj.icon}
                            </div>
                            <div className={`renderMoreMenu__rename
                            ${activeAddButton(objId, obj.activeAddButtonItems) === objId
                                    ? "active"
                                    : ""}`}>
                                {activeAddButton(objId, obj.activeAddButtonItems) === objId ? obj.titleAdd : obj.title}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };
    return (
        <div className="renderMoreMenu">
            {renderMoreMenu()}
            {!closeMenu
                ? <MoreVertIcon onClick={() => setCloseMenu(true)} /> // Открываем меню по клику на иконку
                : <CloseIcon onClick={() => setCloseMenu(false)} />}
        </div>
    )
}

export default MoreIconButtonCurrentId;
