import React, { useState, useEffect } from 'react'
import { ButtonMaterialUi } from '../../components/MaterialUi'
import { FullModal } from './../../components/MaterialUi/FullModal';
import { useDispatch, useSelector } from 'react-redux';
import { createRoleChangeRequest } from '../../actions';
import { MaterialInput } from './../../components/MaterialUi/index';
import { goToTop } from '../../components/GoToTop';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const RenderMiniModalCabinet = ({
    hasIncompleteStatus,
    setShowModalCabinet,
    roleChangeRequests,
    showModalCabinet,
    setShowModal,
    onClickClose,
    description,
    closeButton,
}) => {
    const auth = useSelector((state) => state.auth)
    const { user, authenticate } = auth

    const [partnerForm, setPartnerForm] = useState({
        description: "",
        contactNumber: ""
    })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        if (authenticate) {
            setPartnerForm({
                contactNumber: user.contactNumber
            })
        }
    }, [authenticate])

    useEffect(() => {
        goToTop("onlyTop", "0")
    }, [])


    const clear = () => {
        setPartnerForm({
            description: "",
            contactNumber: ""
        })
    }
    const handleClose = () => {
        setShowModal(false)
        clear()
    }
    const submitApplicationForm = () => {
        const payload = {
            description: partnerForm.description,
            contactNumber: partnerForm.contactNumber
        }
        dispatch(createRoleChangeRequest(payload, user._id)).then(() => handleClose())
    };

    return (
        <FullModal
            type="miniModal"
            minH
            typeCloseIcon="insideCloseIcon"
            visible={showModalCabinet}
            onClose={() => setShowModalCabinet(false)}
            setShowModal={setShowModalCabinet}
        >
            <div className="renderMiniModalCabinet">
                <div className="renderMiniModalCabinet__title">
                    Подать заявку
                </div>
                {roleChangeRequests && roleChangeRequests.length > 0 && !hasIncompleteStatus
                    ? <div className="renderMiniModalCabinet__description">
                        У вас уже есть одна активная заявка, дождитесь ответа по этой заявке
                        <div className="renderMiniModalCabinet__buttonPartnerPageHref">
                            <Button
                                variant="outlined"
                                onClick={() => navigate("/partnerPage")}
                            >
                                Перейти на страницу с заявками</Button>
                        </div>
                    </div>
                    : <>

                        <div className="renderMiniModalCabinet__description">
                            {description
                                ? description
                                : <p>Для того чтобы стать нашим партнером, вы можете оставить
                                    заявку в поле ниже, и наш модератор расмотрит вашу заявку
                                    и свяжется с вами в ближайшее время.
                                </p>
                            }
                        </div>
                        <div className="renderMiniModalCabinet__contentBox">
                            <div>
                                <MaterialInput
                                    blackText
                                    label="Ваш номер"
                                    type="text"
                                    value={partnerForm.contactNumber}
                                    onChange={(e) => setPartnerForm({ ...partnerForm, contactNumber: e.target.value })}
                                />
                                <textarea
                                    style={{
                                        width: "100%",
                                        minHeight: "200px"
                                    }}
                                    label="Подробное описание"
                                    name="description"
                                    value={partnerForm.description}
                                    placeholder={`Подробнее(необязательно)`}
                                    onChange={(e) => setPartnerForm({ ...partnerForm, description: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="renderMiniModalCabinet__buttonBox">
                            <ButtonMaterialUi
                                onClick={submitApplicationForm}
                                title="Отправить"
                            />
                            {closeButton ? <div
                                className="renderMiniModalCabinet__closeButtonText"
                                onClick={onClickClose}
                            >
                                Закрыть
                            </div> : null}
                        </div>
                    </>}
            </div>
        </FullModal>
    )
}

export default RenderMiniModalCabinet