import { elementPaymentPageConstants } from "../constants";
import axios from "../../helpers/axios"

export const getElementPaymentPage = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_REQUEST,
      });
      const res = await axios.get("elementPaymentPage/get");
      if (res.status === 200) {
        dispatch({
          type: elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_FAILURE,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
