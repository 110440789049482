import axios from "../helpers/axios"
import { productListLinkConstants } from "./constants";


export const getByIdProductListLink = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_REQUEST })
            const res = await axios.get(`/productListLink/user/getById/${id}`)
            if (res.status === 200) {
                dispatch({
                    type: productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}