import React from 'react'
import { goToTop } from './../../components/GoToTop';
import { generatePublicUrl } from '../../urlConfig';
import { NavLink } from 'react-router-dom';

const RenderAllCategories = ({
    category,
    mainCategoryIndex,
    currentCategory,
    handlerCategoryList,
    slideIndex,
}) => {
    return <>
        <div className="productList__containerMainCategory">
            {
                category ? category.map((obj, index) => (
                    <div
                        key={index}
                        className="productList__containerIcon"
                    >
                        <div
                            className={mainCategoryIndex === index + 1
                                ? "productList__mainCategory active"
                                : "productList__mainCategory"}
                            onClick={() => currentCategory(obj, index)}
                        >
                            {obj.name}
                        </div>
                    </div>
                )) : null}
        </div >
        <div className="productList__container">
            <div className="productList__mainItemBox">
                {category.map((obj, index) => (
                    <div
                        key={index}
                        className="productList__categoryMainContainer"

                    >
                        <div
                            className={mainCategoryIndex === index + 1
                                ? "productList__categoryItemContainer active"
                                : "productList__categoryItemContainer"}
                        >

                            {obj.children.map((childrenCategory, index) => (
                                <NavLink
                                    key={index}
                                    to={`/productsCategory/${childrenCategory.slug}?${childrenCategory._id}`}
                                    onClick={() => handlerCategoryList(index, childrenCategory)}
                                    className={slideIndex === index + 1
                                        ? "productList__categoryItemBox active"
                                        : "productList__categoryItemBox"}
                                >
                                    <div className="productList__imgContainer">
                                        <img
                                            src={generatePublicUrl(childrenCategory.categoryImage)}
                                            alt={childrenCategory.name}
                                        />
                                    </div>
                                    <div className="productList__text">
                                        {childrenCategory.name}
                                    </div>
                                </NavLink>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div>
            </div>
        </div>
    </>
}

export default RenderAllCategories