import React, { useMemo, useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { getItemById, getRegions } from './../../actions';

const SelectCountryList = ({
    setValue,
    setFocus,
    setTouch,
    setFilled,
    focus,
    label,
    value
}) => {

    const { regions, regionObj } = useSelector((state) => state.region);
    const { user } = useSelector((state) => state.auth);
    const { region } = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRegions());
    }, []);

    useEffect(() => {
        if (region !== undefined) {
            dispatch(getItemById("region", region, "_id name", "components"));
        }
    }, [region]);
    const allRegions = useMemo(() => {
        if (!regions) {
            return [];
        }
        return regions.map(item => ({
            label: item.name,
            value: item._id,
        }));
    }, [regions]);

    const defaultRegion = useMemo(() => {
        if (regionObj) {
            setValue({ label: regionObj.name, value: regionObj._id })
            return { label: regionObj.name, value: regionObj._id };
        }
        return null;
    }, [regionObj]);

    const changeHandler = (selectedOption) => {
        setValue(selectedOption);
    };

    return (
        <div style={{ marginBottom: "35px" }}>
            <label>{label}</label>
            <Select
                className={`inputFullBorder__input ${focus ? "focus" : ""}`}
                style={{
                    height: "100%",
                }}
                options={allRegions}
                value={value || defaultRegion}
                onChange={changeHandler}
                onFocus={() => {
                    setFocus(true);
                    setTouch(true);
                }}
                onBlur={() => {
                    if (value === "") {
                        setFocus(false);
                        setFilled(false);
                    } else {
                        setTouch(false);
                        setFilled(true);
                    }
                }}
            />
        </div>
    );
};

export default SelectCountryList;
