import { Button } from '@mui/material'
import React from 'react'

const ButtonContainer = ({
    hour,
    minutes,
    handleTime,
    handleAnyTime,

}) => {
    return (
        <div style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            marginTop: "20px",
            justifyContent: 'space-between'
        }}>
            <Button
                style={{
                    margin: "5px auto",
                    textAlign: "center"
                }}
                variant="contained"
                onClick={handleTime}
                disabled={hour !== "Часы" && minutes !== "Минуты" ? false : true}
            >
                Выбрать
            </Button>
            <Button
                style={{
                    margin: "5px auto",
                    textAlign: "center"
                }}
                variant="contained"
                onClick={handleAnyTime}
            >
                В любое время
            </Button>
        </div>
    )
}

export default ButtonContainer