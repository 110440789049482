import React, { useRef, useEffect, useState } from 'react'
import LoggedInMenu from './LoggedInMenu';
import IconButtonClose from '../../components/IconButtons/IconButtonClose';
import Backdrop from '../../mainPage/BackDrop';
import SocialMediaIcons from '../../components/miniButtons/SocialMediaIcons';
import { Button } from '@mui/material';
import ConfirmDialog from '../../components/ConfirmDialog';

const MiniUserModal = ({
    auth,
    logout,
    visible,
    buttonIndex,
    contactNumber,
    closeShowMiniModal,
    handleShowUserModal,
    setShowMiniUserModal,
    typeModal,
    fullName
}) => {
    const [showConfirm, setShowConfirm] = useState(false)
    const modalRef = useRef(null);

    // useEffect(() => {
    //     const handleOutsideClick = (event) => {
    //         if (modalRef.current && !modalRef.current.contains(event.target)) {
    //             closeShowMiniModal();
    //         }
    //     };
    //     if (visible) {
    //         document.addEventListener('mousedown', handleOutsideClick);
    //     } else {
    //         document.removeEventListener('mousedown', handleOutsideClick);
    //     }
    //     return () => {
    //         document.removeEventListener('mousedown', handleOutsideClick);
    //     };
    // }, [visible, closeShowMiniModal]);

    useEffect(() => {
        if (!visible) {
            setShowConfirm(false)
        }
    }, [visible])
    useEffect(() => {
        if (!auth) {
            setShowConfirm(false)
        }
    }, [logout])
    useEffect(() => {
        if (!auth) {
            setShowConfirm(false)
        }
    }, [auth])

    const handleShowConfirm = () => {
        setShowConfirm(true)
    }

    if (!visible) {
        return null
    }


    const itemButton = [
        {
            text: "Войти",
        },
        {
            text: "Регистрация",
        },
    ]
    const renderContent = () => {
        let content;
        switch (typeModal) {
            case "rightModal":
                content = <>
                    {/* <Backdrop visible={visible} closeShowMiniModal={closeShowMiniModal} />  */}
                    <div
                        ref={modalRef}
                        className={`miniModalRight ${visible ? "visible" : ""}`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >

                        <div className="miniModalRight__contentModal">
                            <IconButtonClose
                                onClick={closeShowMiniModal}
                            />
                            <div className="miniModalRight__MHeaderTitle">
                                {auth
                                    ? <div className="miniModalRight__userFullName">
                                        {fullName}
                                    </div>
                                    : "Может войдете на наш сайт"}
                            </div>
                            <div className="miniModalRight__buttonContainer"
                                onClick={e => e.stopPropagation()}
                            >
                                {auth
                                    ?
                                    <Button
                                        onClick={handleShowConfirm}
                                        variant="outlined"
                                    >
                                        выйти с аккаунта
                                    </Button>
                                    : itemButton.map((item, index) => (
                                        <Button
                                            color="secondary"
                                            key={index}
                                            onClick={() => handleShowUserModal(item, index)}
                                            variant="outlined"
                                        >
                                            {item.text}
                                        </Button>
                                    ))}

                            </div>
                            <LoggedInMenu
                                auth={auth}
                                typeModal={typeModal}
                            />
                        </div>
                        <div className="miniModalRight__MiniModalNumber">
                            <a href={`tel:${contactNumber}`}>
                                <span>
                                    {contactNumber ? contactNumber : null}
                                </span>
                            </a>
                        </div>
                        <SocialMediaIcons />
                    </div>
                </>
                break;
            default: content = <div
                // onMouseLeave={closeShowMiniModal}
                onClick={closeShowMiniModal}
                className="headerNav__miniModal"
                ref={modalRef}
            >
                <div className="headerNav__contentModal"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className="headerNav__MHeaderTitle">
                        {auth ? null : "Может войдете на наш сайт"}
                    </div>
                    <div className="headerNav__buttonContainer"
                        onClick={e => e.stopPropagation()}
                    >
                        {auth ? <div
                            onClick={handleShowConfirm}
                            className="headerNav__button">
                            выйти с аккаунта
                        </div> : itemButton.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleShowUserModal(item, index)}
                                className={buttonIndex === index + 1 ? "headerNav__button active" : "headerNav__button"}
                            >
                                {item.text}
                            </div>
                        ))}
                    </div>
                    <LoggedInMenu
                        auth={auth}
                        setShowMiniUserModal={setShowMiniUserModal}
                    />
                </div>
                <div className="headerNav__MiniModalNumber">
                    <a href={`tel:${contactNumber}`}>
                        <span>
                            {contactNumber ? contactNumber : null}
                        </span>
                    </a>
                </div>
            </div>
        }
        return content
    }
    return (
        <>
            <div className="headerNav__renderContentContainer">
                {renderContent()}
                <Backdrop visible={visible} closeShowMiniModal={closeShowMiniModal} />
            </div>
            <ConfirmDialog
                title="Вы точно хотите выйти со своего профиля?"
                description="Подтвердить выход"
                open={showConfirm}
                handleClose={() => setShowConfirm(false)}
                handleSubmit={logout}
            />
        </>
    )
}

export default MiniUserModal