import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const IconDownUpContainer = ({
    up,
    fixedButton,
}) => {

    const renderFixedButton = () => {
        return (
            <div className="renderFixedButton">


            </div>
        )
    }
    return (
        <div className="renderItemOrder__iconDownUpContainer">
            {fixedButton
                ? renderFixedButton()
                : <>
                    {up
                        ? <KeyboardArrowDownIcon
                            color="secondary" />
                        : <KeyboardArrowUpIcon
                            color="secondary" />}
                </>}
        </div>
    )
}

export default IconDownUpContainer