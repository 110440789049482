import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { getProducts, getProductsBySlug } from '../../actions';
import { setPerPage } from '../../reducers/product.reducer';
import MultipleSelectChip from '../Sort/MultipleSelectChip';
import { getMaterial } from '../../actions/components/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SortProductModal from './SortProductModal';

const SortProduct = ({
  setSortMaterial,
  setSort,
  sort,
  setPageNumber,
  sortMaterial,
  setPageSize,
  refCategory,
  pageNumber,
  pageSize
}) => {
  const material = useSelector((state) => state.material.material)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [showFilterModalChildren, setShowFilterModalChildren] = useState(false)
  const dispatch = useDispatch();

  const { slug } = useParams()

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  const handlePaginateChange = (event) => {
    setPageSize(event.target.value);
  };

  const selectItem = [
    {
      value: "name",
      text: "По названию от А-Я"
    },
    {
      value: "nameMinus",
      text: "По названию от Я-А"
    },
    {
      value: "price",
      text: "По цене от дорогого к дешевому"
    },
    {
      value: "priceMinus",
      text: "По цене от дешового в дорогому"
    },
    {
      value: "updatedAtMinus",
      text: "Самые последние изменения"
    },
    {
      value: "updatedAt",
      text: "Самые старые изменения"
    },
    {
      value: "createdAtMinus",
      text: "Самые новые"
    },
    {
      value: "createdAt",
      text: "Самые старые"
    },
  ]
  const selectPageSizeItem = [
    {
      value: 12,
    },
    {
      value: 25,
    },
    {
      value: 50,
    },
    {
      value: 75,
    },
    {
      value: 100,
    }
  ]

  useEffect(() => {
    setPageNumber(0)
  }, [pageSize, sort, sortMaterial])

  useEffect(() => {
    if (showFilterModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showFilterModal]);

  useEffect(() => {
    setPerPage(pageSize)
    if (slug === "all") {
      dispatch(getProducts(sort, pageNumber, pageSize, sortMaterial))
    }
    if (slug !== "all") {
      dispatch(getProductsBySlug(slug, sort, pageNumber, pageSize, sortMaterial))
    }
  }, [sort, pageNumber, pageSize, sortMaterial])

  const sortType = (minWidth) => {
    return (
      <div className="sortProduct__selectContainer1">
        <FormControl sx={{ m: 1, minWidth: minWidth ? minWidth : 250 }}>
          <InputLabel id="demo-simple-select-helper-label">
            Показывать
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={pageSize}
            label="Показывать"
            onChange={handlePaginateChange}
          >
            {selectPageSizeItem.map((item, index) => (
              <MenuItem key={index} value={item.value}>{item.value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  }

  const handelShowFilterModal = () => {
    setShowFilterModal(!showFilterModal)
  }
  const modalListArray = [
    {
      text: "Количество",
      itemsArray: selectPageSizeItem,
    },
    {
      text: "Сортировать по",
      itemsArray: selectItem,
    },
    {
      text: "Материалы",
      itemsArray: material,
    },
  ]

  return (
    <>
      <SortProductModal
        visible={showFilterModal}
        closeShowMiniModal={() => setShowFilterModal(false)}
        modalListArray={modalListArray}
        showFilterModalChildren={showFilterModalChildren}
        setShowFilterModalChildren={setShowFilterModalChildren}
        setSortMaterial={setSortMaterial}
        sortMaterial={sortMaterial}
        sort={sort}
        setSort={setSort}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      <div className="sortProduct"
        ref={refCategory}
      >
        <div className="sortProduct__leftModal">
          <div className="sortProduct__buttonModalBlock">
            <div className="sortProduct__buttonFilter"
              onClick={handelShowFilterModal}
            >
              <FilterAltIcon />
              <div className="sortProduct__buttonFilter__text">
                Фильтры
              </div>
            </div>
            {sortType(100)}
          </div>

        </div>
        <div className="sortProduct__mainSortContent">

          <div className="sortProduct__multipleSelect">
            <MultipleSelectChip
              setSortMaterial={setSortMaterial}
              sortMaterial={sortMaterial}
              items={material}
              get={getMaterial}
              title="Материалы"
            />
          </div>
          <div className='sortProduct__row'>
            <div className="sortProduct__selectContainer1">
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <InputLabel id="demo-simple-select-helper-label">сортировка</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={sort}
                  label="Сортировка"
                  onChange={handleChange}
                >
                  {selectItem.map((item, index) => (
                    <MenuItem key={index} value={item.value}>{item.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {sortType()}
          </div>
        </div>
      </div>
    </>
  )
}

export default SortProduct
