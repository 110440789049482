import React from 'react'
import { generatePublicUrl } from '../../urlConfig';
import PurpleButton from './../../components/button/PurpleButton';
import Loader from './../../components/Loader';

const ArchitectContentBlock = (props) => {


  const RenderArchitectContent = () => {

    if (!props.items) {
      return <Loader />
    }
    return (
      <>
        {!props.rightImage ? <>

          <div className={props.sameWidth ? "col-12 col-sm-12 col-md-6 col-lg-6" : "col-12 col-sm-12 col-md-7 col-lg-7"}>
            <div className="architectContentBlock__imgContainer">
              <div className="architectContentBlock__imgBlock">
                <img src={generatePublicUrl(props.items.mainImg)} alt="blabla" />
              </div>
            </div>
          </div>
          <div className={props.sameWidth ? "col-12 col-sm-12 col-md-6 col-lg-6" : "col-12 col-sm-12 col-md-5 col-lg-5"} >
            <div className="architectContentBlock__textContainer">
              <h3>
                {props.items.miniTitle}
              </h3>
              <p>
                {props.items.descriptionOne}
              </p>
              <p>
                {props.items.descriptionTwo}
              </p>
              <p>
                {props.items.descriptionThree}
              </p>
              <div className={props.rightImage ? "architectContentBlock__buttonContainer-rightImage" : "architectContentBlock__buttonContainer"}>
                <PurpleButton
                  whiteBackground
                  text="Оформить заказ"
                />
              </div>
            </div>
          </div>
        </>
          :
          <>
            <div className={props.sameWidth ? "col-12 col-sm-12 col-md-6 col-lg-6" : "col-12 col-sm-12 col-md-5 col-lg-5"}>
              <div className="architectContentBlock__textContainer">
                <h3>
                  {props.items.miniTitle}
                </h3>
                <p>
                  {props.items.descriptionOne}
                </p>
                <p>
                  {props.items.descriptionTwo}
                </p>
                <p>
                  {props.items.descriptionThree}
                </p>
                <div className={props.rightImage ? "architectContentBlock__buttonContainer-rightImage" : "architectContentBlock__buttonContainer"}>

                  <PurpleButton
                    whiteBackground
                    text="Оформить заказ"
                  />
                </div>
              </div>
            </div>
            <div className={props.sameWidth ? "col-12 col-sm-12 col-md-6 col-lg-6" : "col-12 col-sm-12 col-md-7 col-lg-7"} >
              <div className="architectContentBlock__imgContainer">
                <div className="architectContentBlock__imgBlock">
                  <img src={generatePublicUrl(props.items.mainImg)} alt={props.items.title} />
                </div>
              </div>
            </div>
          </>

        }
      </>
    )
  }
  return (
    <div className="architectContentBlock">
      <div className="architectContentBlock__mainTitle">
        {props.items?.title}
      </div>
      <div className="row">

        <RenderArchitectContent />

      </div>
    </div>
  )
}

export default ArchitectContentBlock