import React, { useState, useRef, useEffect } from 'react'
import ContainerCabinetComponent from '../../ContainerCabinetComponent';
import { useSelector, useDispatch } from 'react-redux';
import RenderMiniModalCabinet from '../RenderMiniModalCabinet';
import { getApplicationsChangeProfileByUser, getRoleChangeRequestProfileByUser, updateRegion } from '../../../actions';
import Loader from '../../../components/Loader';
import ItemsBlock from './ItemsBlock';

const PersonalInformation = () => {
    const {
        user,
        authenticate
    } = useSelector((state) => state.auth);
    const {
        applications,
        applicationObj
    } = useSelector((state) => state.applicationChangeProfile);
    const { loading } = useSelector((state) => state.region);
    const {
        roleChangeRequests,
        hasIncompleteStatus,
    } = useSelector((state) => state.roleChangeRequest)
    const [showModalCabinet, setShowModalCabinet] = useState(false)
    const [regionId, setRegionId] = useState(false)
    const [show, setShow] = useState(false)
    const [region, setRegion] = useState("")
    const [showEdit, setShowEdit] = useState(false)
    const [nameForm, setNameForm] = useState({
        firstName: "",
        lastName: "",
        birthData: "",
        gender: ""
    })

    const [numberForm, setNumberForm] = useState({
        contactNumber: "",
        backupNumber: "",
    })

    const [loginForm, setLoginForm] = useState({
        email: "",
    })
    const [passwordForm, setPasswordForm] = useState({
        password: "",
        newPassword: "",
        passwordCheck: ""
    });
    const rootEl = useRef(null);
    const dispatch = useDispatch()

    const handleShow = () => {
        setShow(true)
    }
    useEffect(() => {
        dispatch(getRoleChangeRequestProfileByUser())
    }, [])

    useEffect(() => {
        setRegion(user.region)
        setNameForm({
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            birthData: user.birthData || "",
            gender: user.gender || ""
        })
        setNumberForm({
            contactNumber: user.contactNumber || "",
            backupNumber: user.backupNumber || "",
        })
        setLoginForm({
            email: user.email || ""
        })
    }, [authenticate, user.region])

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setShowModalCabinet(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);

    useEffect(() => {
        dispatch(getApplicationsChangeProfileByUser())
    }, [])

    const handleUpdateRegion = (regionId) => {
        if (!showEdit) return setShowEdit(true)
        dispatch(updateRegion(regionId))
        setRegionId(regionId)
        setShowEdit(false)
    }
    return (
        <ContainerCabinetComponent
            title={"Персональная информация"}
        >
            <div className="personalInformation">
                <ItemsBlock
                    setShowModalCabinet={setShowModalCabinet}
                    handleUpdateRegion={handleUpdateRegion}
                    setPasswordForm={setPasswordForm}
                    applicationObj={applicationObj}
                    setNumberForm={setNumberForm}
                    applications={applications}
                    passwordForm={passwordForm}
                    setLoginForm={setLoginForm}
                    setShowEdit={setShowEdit}
                    setNameForm={setNameForm}
                    handleShow={handleShow}
                    numberForm={numberForm}
                    setRegion={setRegion}
                    loginForm={loginForm}
                    nameForm={nameForm}
                    showEdit={showEdit}
                    region={region}
                    user={user}
                />
                <div className="rsndkl"
                    ref={rootEl}>
                </div>
                {loading
                    ? <Loader />
                    : <RenderMiniModalCabinet
                        onClickClose={() => setShowModalCabinet(false)}
                        hasIncompleteStatus={hasIncompleteStatus}
                        setShowModalCabinet={setShowModalCabinet}
                        roleChangeRequests={roleChangeRequests}
                        showModalCabinet={showModalCabinet}
                        closeButton
                    />
                }
            </div>
        </ContainerCabinetComponent>
    )
}

export default PersonalInformation