import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { generatePublicUrl } from '../urlConfig';
import TitleHeaderComponent from './TitleHeaderComponent';
import { useNavigate } from 'react-router-dom';
import { addToHistoryProduct, updateProductView } from '../actions';
import { setShowModalUser } from '../reducers/auth.reducer';
import { goToTop } from './GoToTop';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { setProductDetailsList, setCurrentProductImgSlide } from './../reducers/product.reducer';
import DescContainer from './../containers/CabinetPage/components/DescContainer';

const CarouselComponent = ({
    buttonMainText,
    typeCarousel,
    itemsType,
    titleNone,
    mainTitle,
    textName,
    itemSlug,
    loading,
    items = [], // Инициализация items как пустого массива
    aHref,
    get,
}) => {
    const auth = useSelector((state) => state.auth);
    const { productDetails } = useSelector((state) => state.product)
    const { currentProductImgSlider } = useSelector((state) => state.product);

    const [showSameProduct, setShowSameProduct] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const carouselRef = useRef(null); // Создаем реф для карусели

    useEffect(() => {
        dispatch(get(itemSlug && itemSlug));
    }, [dispatch, get, itemSlug]);

    const handleProduct = (obj) => {
        const { _id } = obj;
        if (_id === productDetails._id) {
            setShowSameProduct(true);
            setTimeout(() => {
                setShowSameProduct(false);
            }, 5000);
            console.log("Вы уже на этом товаре");
        } else {
            const payload = {
                productId: obj._id,
                userId: auth.user._id,
            };
            dispatch(setCurrentProductImgSlide(1));
            dispatch(addToHistoryProduct({ _id }))

            dispatch(updateProductView(payload))
                .then(() => {
                    navigate(`/productDetails/${obj._id}`)

                    dispatch(setProductDetailsList("Все о товаре"));
                })
            goToTop("onlyTop");

        }
    };

    const handleShowModalUser = () => {
        dispatch(setShowModalUser());
    };

    const handleScrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({
                left: -carouselRef.current.offsetWidth,
                behavior: 'smooth',
            });
        }
    };

    const handleScrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({
                left: carouselRef.current.offsetWidth,
                behavior: 'smooth',
            });
        }
    };

    if (loading) return <Loader />;

    const itemsTypeObj = (obj) => {
        let _content;
        switch (itemsType) {
            case "content":
                _content = <div className="carouselComponent__content">
                    <DescContainer obj={obj} />
                </div>;
                break;
            default:
                _content = <div className="carouselComponent__contentFull">
                    <DescContainer obj={obj} />
                </div>;
        }
        return _content;
    };

    return (
        <div className="carouselComponent">
            {textName ? (
                <TitleHeaderComponent
                    description={textName}
                    greyColorTitle
                    onClick={!auth.authenticate ? handleShowModalUser : () => { }}
                    onlyButton={!auth.authenticate}
                    title={mainTitle ? mainTitle : titleNone ? null : "Последние посещенные товары"}
                    text={buttonMainText ? buttonMainText : titleNone ? null : !auth.authenticate ? "Войти" : "Страница истории"}
                    aHref={aHref ? aHref : '/historyProductPage'}
                />
            ) : null}
            <div className="carousel__arrowButtonContainer">
                <div className="carouselComponent__arrowBtnLeft" onClick={handleScrollLeft}>
                    <ArrowBackIosNewIcon />
                </div>
                <div className="carouselComponent__arrowBtnRight" onClick={handleScrollRight}>
                    <ArrowForwardIosIcon />
                </div>
            </div>
            <div className="carouselComponent__row" ref={carouselRef}>
                {auth.authenticate
                    ? Array.isArray(items) && items.map((obj, index) => (
                        <div className="carouselComponent__mainContainer"
                            onClick={() => handleProduct(obj)}
                            key={obj._id}
                        >
                            <div
                                className={currentProductImgSlider === index + 1
                                    ? "carouselComponent__imgBox active-img"
                                    : "carouselComponent__imgBox"}
                            >
                                <img
                                    src={generatePublicUrl(obj.reducedImage ? obj.reducedImage : obj.img || (obj.productPictures?.length > 0 && obj.productPictures[0].img))}
                                />
                                {itemsTypeObj(obj)}
                            </div>
                            {showSameProduct
                                && obj._id === productDetails._id
                                && <div className="carouselComponent__sameProductBlock">
                                    Вы уже на этом товаре
                                </div>
                            }
                        </div>
                    ))
                    : typeCarousel === "similar" ? Array.isArray(items) && items.map((obj, index) => (
                        <div className="carouselComponent__mainContainer"
                            onClick={() => handleProduct(obj)}
                            key={obj._id}
                        >
                            <div
                                className={currentProductImgSlider === index + 1
                                    ? "carouselComponent__imgBox active-img"
                                    : "carouselComponent__imgBox"}
                            >
                                <img
                                    src={generatePublicUrl(obj.reducedImage ? obj.reducedImage : obj.img || (obj.productPictures?.length > 0 && obj.productPictures[0].img))}
                                />
                                {itemsTypeObj(obj)}
                            </div>
                            {showSameProduct
                                && obj._id === productDetails._id
                                && <div className="carouselComponent__sameProductBlock">
                                    Вы уже на этом товаре
                                </div>
                            }
                        </div>
                    )) : <h4>Вам нужно войти для просмотра истории!</h4>}
            </div>
        </div>
    );
};

export default CarouselComponent;
