import React, { useState } from 'react'

const RenderDeliveryPG = ({
  items,
  setTypeItem
}) => {
  const [slideIndex, setSlideIndex] = useState(1)

  const showBlock = (index, type) => {
    setSlideIndex(index)
    setTypeItem(type)
  }

  return (
    <div className="renderDeliveryPG">

      {items.map((item, index) => (
        <div key={index}>
          <div className={slideIndex === index + 1 ? "renderDeliveryPG__blockTitles active" : "renderDeliveryPG__blockTitles"}>
            <div
              className="renderDeliveryPG__containerBlock"
              key={index}
              onClick={() => showBlock(index + 1, item.type)}
            >
              {item.name}
            </div>
          </div>
          <div className={slideIndex === index + 1 ? "renderDeliveryPG__container active-block" : "renderDeliveryPG__container"}>

            {item.description}

          </div>
        </div>
      ))}

    </div>
  )
}

export default RenderDeliveryPG