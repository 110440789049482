import React from "react";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function BtnSlider({ direction, moveSlide, fullHeight }) {

  const replaceImg = (e) => {
    e.preventDefault()
    moveSlide()
  }
  return (
    <>
      {!fullHeight
        ?
        <button
          onClick={(e) => { replaceImg(e) }}
          className={direction === "next" ? "btn-slide1 next" : "btn-slide1 prev"}
        >
          {direction === "next" ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
        </button>
        :
        <div className="slide1FullHeight">
          <button
            onClick={(e) => { replaceImg(e) }}
            className={direction === "next" ? "btn-slide1FullHeight next" : "btn-slide1FullHeight prev"}
          >
            {direction === "next" ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
          </button>
        </div>
      }

    </>
  );
}
