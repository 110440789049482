import { applicationGalleryConstants } from '../actions/constants';

const initState = {
  applicationsGallery: [],
  applicationGalleryObj: {},
  hasIncompleteStatus: false,
  loading: false,
  message: "",
  showAlert: false,
  variantAlert: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case applicationGalleryConstants.GET_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationGalleryConstants.GET_BY_ID_SUCCESS:
      state = {
        ...state,
        applicationGalleryObj: action.payload?.item,
        loading: false,
      };
      break;
    case applicationGalleryConstants.GET_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationGalleryConstants.CREATE_APPLICATION_GALLERY_REQUEST:
      state = {
        ...state,
        loading: true
      };
    case applicationGalleryConstants.CREATE_APPLICATION_GALLERY_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationGalleryConstants.CREATE_APPLICATION_GALLERY_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationGalleryConstants.GET_BY_USER_REQUEST:
      state = {
        ...state,
        loading: true
      };
    case applicationGalleryConstants.GET_BY_USER_SUCCESS:
      state = {
        ...state,
        applicationsGallery: action.payload?.applicationsGallery,
        loading: false,
      };
      break;
    case applicationGalleryConstants.GET_BY_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      return state
  }
  return state;
};