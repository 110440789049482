import React, { useState, useEffect } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ContainerCabinetComponent from '../../ContainerCabinetComponent';
import { useSelector, useDispatch } from 'react-redux';
import { getDesiresItems, removeDesiresItem } from '../../../actions/desires';
import Loader from '../../../components/Loader';
import RenderProductContainer from './../components/RenderProductContainer';

const DesiresPage = () => {
    const { desiresItems, updatingDesires } = useSelector((state) => state.desires)

    const dispatch = useDispatch()

    const [sort, setSort] = useState("newest");
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [currentId, setCurrentId] = useState("");

    const handleChange = (event) => {
        setSort(event.target.value);
    };

    const selectItem = [
        {
            value: "newest",
            text: "Самые новые"
        },
        {
            value: "oldest",
            text: "Самые старые"
        },
        {
            value: "name",
            text: "От а-я"
        },
        {
            value: "nameMinus",
            text: "От я-а"
        },
    ]
    useEffect(() => {
        dispatch(getDesiresItems(sort))
    }, [sort])

    const clear = () => {
        setCheckedItems([])
    }
    const removeDesiresProd = () => {
        if (checkedItems.length > 0) {
            let payload;
            if (currentId !== "" && checkedItems.length === 0) {
                payload = {
                    productId: currentId
                }
            } else if (checkedItems && Array.isArray(checkedItems) && checkedItems.length > 0) {
                payload = {
                    productIds: checkedItems,
                }
            }
            dispatch(removeDesiresItem(payload)).then(() => clear())
        }
    }
    return (
        <ContainerCabinetComponent
            title={"Список желаний"}
        >
            <div className="desiresPage">
                <div className="desiresPage__desiresNameAndMoreRow">
                    <div className="desiresPage__nameListDesires">
                        <h2>Мой список</h2>
                        <div className="desiresPage__defaultNameGrayText">
                            ( Всего {desiresItems.length} шт. )
                        </div>
                    </div>
                </div>
                <div className="desiresPage__mainContainer">
                    <div className="desiresPage__headerMenu">
                        <div className="desiresPage__settingContainer">
                            <div
                                className="desiresPage__chooseAll"
                                onClick={() => {
                                    if (!checkedAll) {
                                        setCheckedItems(desiresItems.map((item) => item._id));
                                    } else {
                                        setCheckedItems([]);
                                    }
                                    setCheckedAll(!checkedAll);
                                }}
                            >
                                {checkedAll ? "Отменить" : "Выбрать все"}
                            </div>
                            {checkedItems.length > 0 && <div className="desiresPage__delete"
                                onClick={removeDesiresProd}>
                                <DeleteOutlineIcon />
                                Удалить
                            </div>}
                        </div>
                        <div className="orderMenuSearchSort__selectContainer">
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="demo-simple-select-helper-label">сортировка</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={sort}
                                    label="Сортировка"
                                    onChange={handleChange}
                                >
                                    {selectItem.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item.text}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>

                {updatingDesires
                    ? <Loader />
                    : desiresItems.length ? <div className="desiresPage__desiresProduct">
                        <RenderProductContainer
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            setCheckedItems={setCheckedItems}
                            deleteObj={removeDesiresProd}
                            checkedItems={checkedItems}
                            items={desiresItems}
                        />
                    </div> : <p className="desiresPage__emptyProd">Ваш список желаний пуст!</p>
                }
            </div>
        </ContainerCabinetComponent>
    )
}

export default DesiresPage