import axios from "../helpers/axios";
import { categoryConstants } from "./constants";

export const getAllCategory = () => {
    return async dispatch => {

        dispatch({ type: categoryConstants.GET_ALL_CATEGORIES_REQUEST });
        const res = await axios.get(`category/getClient`);
        if (res.status === 200) {

            const { categoryList } = res.data;

            dispatch({
                type: categoryConstants.GET_ALL_CATEGORIES_SUCCESS,
                payload: { categories: categoryList }
            });
        } else {
            dispatch({
                type: categoryConstants.GET_ALL_CATEGORIES_FAILURE,
                payload: { error: res.data.error }
            });
        }


    }
}
export const getCategoryDetailsById = (id) => {
    return async dispatch => {
        try {
            const res = await axios.get(`/category/${id}`);
            // localStorage.setItem("token", token);
            localStorage.setItem('TEST', JSON.stringify(res))
            dispatch({
                type: categoryConstants.GET_CATEGORY_DETAILS_BY_ID_SUCCESS,
                payload: { categoryDetails: res.data.category }
            });
        } catch (error) {
            console.log(error);

        }
    }
}


