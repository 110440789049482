import axios from "../../helpers/axios"
import { architectConstants } from "../constants"

export const getArchitect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: architectConstants.GET_ARCHITECT_REQUEST })
            const res = await axios.get("architect/get")
            if (res.status === 200) {
                const { architect } = res.data
                dispatch({
                    type: architectConstants.GET_ARCHITECT_SUCCESS,
                    payload: {
                        architect
                    }
                })
            } else {
                dispatch({ type: architectConstants.GET_ARCHITECT_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}


