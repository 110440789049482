import { architectConstants } from '../../actions/constants';



const initialState = {
    architect: [],
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case architectConstants.GET_ARCHITECT_REQUEST:
            state = {
                ...state,
                loading: true

            }
            break
        case architectConstants.GET_ARCHITECT_SUCCESS:
            state = {
                ...state,
                architect: action.payload.architect,
                loading: false

            }
            break
        case architectConstants.GET_ARCHITECT_FAILURE:
            state = { 
                ...state,
                loading: false

            }
            break
    }
    return state
}