import React, { useEffect, useState, createRef } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import GrayButton from './../components/button/GrayButton';

const EmailNewsletterPage = () => {
    const [callback, setCallback] = useState("not fired")
    const [value, setValue] = useState("[empty]")
    // const [load, setLoad] = useState(false)
    const [expired, setExpired] = useState("false")
    const _reCaptchaRef = createRef();


    const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

    useEffect(() => {

    }, [])
    const handleChange = e => {
        setValue(e)
        if (value === null) setExpired(true);
    };

    const asyncScriptOnLoad = () => {
        setCallback("called!")
    };


    document.body.style.overflow = "hidden"
    return (
        <div className="emailNewsletterPage">
            <a href="/maf">Back</a>
            <div className="emailNewsletterPage__contentContainer">
                <h1>Чтобы получать письма, подтвердите, что вы – человек.</h1>
                <div className="emailNewsletterPage__title">
                    <h2>
                        Вы попали на эту страницу, потому что наши роботы говорят, что вы робот :'(.

                    </h2>
                    <div className="emailNewsletterPage__captcha">
                        <ReCAPTCHA
                            style={{ display: "flex", justifyContent: "center" }}
                            sitekey={TEST_SITE_KEY}
                            onChange={handleChange}
                            asyncScriptOnLoad={asyncScriptOnLoad}
                        />

                    </div>
                    <p>
                        Чтобы получать письма, подтвердите, что вы – человек.
                    </p>
                </div>
                <GrayButton
                    type
                    text="Подписаться"
                />
            </div>
        </div>
    )
}

export default EmailNewsletterPage