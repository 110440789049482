const activeAddButton = (productId, items, type) => {
    const itemsProd = items ? Object.keys(items).map((key) => items[key]) : [];

    let prod;
    if (type === "img") {
        for (let i = 0; i < itemsProd.length; i++) {
            if (itemsProd[i].img === productId) {
                prod = itemsProd[i].img;
                break;
            }
        }
        return prod;
    } else {
        for (let i = 0; i < itemsProd.length; i++) {
            if (itemsProd[i]._id === productId) {
                prod = itemsProd[i]._id;
                break;
            }
        }
        return prod;
    }
};

export default activeAddButton;
