import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../../../actions";
import { ButtonMaterialUi, MaterialInput } from "../../../components/MaterialUi";
import GrayButton from './../../../components/button/GrayButton';
import RadioInputs from './RadioInputs';
import TitleHeaderComponent from './../../../components/TitleHeaderComponent';

const AddressForm = ({
  disableAddressEditForm,
  withoutLayout,
  setNewAddress,
  onSubmitForm,
  initialData,
  auth,
}) => {

  const [name, setName] = useState(initialData ? initialData.name : "");
  const [mobileNumber, setMobileNumber] = useState(
    auth.authenticate ? auth.user.number : initialData ? initialData.mobileNumber : ""
  );
  const [pinCode, setPinCode] = useState(
    initialData ? initialData.pinCode : ""
  );
  const [locality, setLocality] = useState(
    initialData ? initialData.locality : ""
  );
  const [address, setAddress] = useState(
    initialData ? initialData.address : ""
  );
  const [cityDistrictTown, setCityDistrictTown] = useState(
    initialData ? initialData.cityDistrictTown : ""
  );
  const [state, setState] = useState(initialData ? initialData.state : "");
  const [landmark, setLandmark] = useState(
    initialData ? initialData.landmark : ""
  );
  const [alternatePhone, setAlternatePhone] = useState(
    initialData ? initialData.alternatePhone : ""
  );
  const [addressType, setAddressType] = useState(
    initialData ? initialData.addressType : ""
  );
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [id, setId] = useState(initialData ? initialData._id : "");

  const onAddressSubmit = (e) => {
    const payload = {
      address: {
        name,
        mobileNumber,
        pinCode,
        locality,
        address,
        cityDistrictTown,
        state,
        landmark,
        alternatePhone,
        addressType,
      },
    };
    if (id) {
      payload.address._id = id;
    }
    dispatch(addAddress(payload));
    setSubmitFlag(true);
  };

  useEffect(() => {
    if (submitFlag) {
      let _address = {};
      if (id) {
        _address = {
          _id: id,
          name,
          mobileNumber,
          pinCode,
          locality,
          address,
          cityDistrictTown,
          state,
          landmark,
          alternatePhone,
          addressType,
        };
      } else {
        _address = user.address.slice(user.address.length - 1)[0];
      }

      onSubmitForm(_address);
    }
  }, [user.address]);


  const close = () => {
    setNewAddress(false)
    disableAddressEditForm(initialData)
  }
  const renderAddressForm = () => {

    const arrayRadio = [
      {
        inputValue: "work",
        inputText: "Рабочий"
      },
      {
        inputValue: "home",
        inputText: "Домашний"
      }
    ]
    return (
      <div style={{
        margin: "10px",
      }}>
        <h4 style={{
          marginBottom: "20px"
        }}>
          Вы можете внести правки и сохранить адрес
        </h4>
        <div className="flexRow">
          <MaterialInput
            requiredColorRed
            blackText
            label="почтовый индекс"
            value={pinCode}
            onChange={(e) => setPinCode(e.target.value)}
          />
          <MaterialInput
            requiredColorRed
            blackText
            label="Город"
            value={locality}
            onChange={(e) => setLocality(e.target.value)}
          />
        </div>
        <div className="flexRow">
          <MaterialInput
            requiredColorRed
            blackText
            label="Адрес"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        <div className="flexRow">
          <MaterialInput
            requiredColorRed
            blackText
            label="ориентир"
            value={landmark}
            onChange={(e) => setLandmark(e.target.value)}
          />
        </div>
        <RadioInputs
          title="Тип адреса"
          arrayRadio={arrayRadio}
          value={addressType}
          checked={addressType}
          setAddressType={setAddressType}
        />
        <div className="addressForm__buttonBox">
          <ButtonMaterialUi
            title="СОХРАНИТЬ И ПЕРЕЙТИ"
            onClick={onAddressSubmit}
            style={{
              width: "250px",
              margin: "20px 0",
            }}
          />
          <GrayButton
            type="medium"
            text="Закрыть"
            onClick={close}
          />
        </div>
      </div>
    );
  };

  if (withoutLayout) {
    return <div>{renderAddressForm()}</div>;
  }

  return (
    <div className="checkoutStep" style={{ background: "#f5faff" }}>
      <TitleHeaderComponent
        description="Куда доставить?"
        title="Добавьте новый адресс доставки"
        text="Закрыть"
        onlyButton
        onClick={close}
      />
      <div
        style={{
          padding: "0 60px",
          paddingBottom: "20px",
          boxSizing: "border-box",
        }}
      >
        {renderAddressForm()}
      </div>
    </div>
  );
};

export default AddressForm;
