import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import UserDataInput from '../../../components/button/UserDataInput';
import { ButtonMaterialUi, PersonalDataCheckbox } from '../../../components/MaterialUi';
import RenderMiniImg from '../../../components/Slider/RenderMiniImg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#5b5b5b',
    border: '1px solid gray',
    boxShadow: 24,
    p: 2,
    maxHeight: '100ch',
    overflowY: 'auto',
};

const ApplicationGalleryModal = ({
    submitApplicationGallery,
    showModal,
    setShowModal,
    itemsLength,
    applicationForm,
    setApplicationForm,
    dataProcessingConsent,
    setDataProcessingConsent,
    slideIndex,
    galleryPictures,
    removeGalleryImgItem,
    setDeleteAllItem,
    deleteAllItem
}) => {
    const [animationDisabled, setAnimationDisabled] = useState(false);
    const handleOpen = () => setShowModal(true);


    useEffect(() => {
        if (itemsLength === 1) {
            handleMouseLeave()
        }
    }, [itemsLength === 1])

    const handleClose = () => {
        setShowModal(false)

    };
    const handleMouseEnter = () => {
        setAnimationDisabled(true);
    };

    const handleMouseLeave = () => {
        setAnimationDisabled(false);
    };
    return (
        <div className="applicationGalleryModal">
            {itemsLength > 0
                ? <div
                    className={`applicationGalleryModal__button ${animationDisabled ? 'animation-disabled' : ''}`}
                    onClick={handleOpen}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseEnter}
                >
                    Нравится?
                </div>
                : null
            }

            <Modal
                open={showModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <h1 className="text-center" style={{ marginBottom: "50px" }}>Возникли вопросы?</h1>
                    <UserDataInput
                        setForm={setApplicationForm}
                        form={applicationForm}
                    />
                    <RenderMiniImg
                        itemObj={galleryPictures}
                        slideIndex={slideIndex}
                        iconFunctional
                        onClickIcon={removeGalleryImgItem}
                    />
                    <textarea
                        style={{ height: "100px", width: "100%" }}
                        placeholder="Дополнительно"
                        type="text"
                        value={applicationForm.descriptionProblem}
                        onChange={(e) => setApplicationForm({ ...applicationForm, descriptionProblem: e.target.value })}
                    />
                    <PersonalDataCheckbox
                        data={dataProcessingConsent}
                        setData={setDataProcessingConsent}
                        checked1="dscdscdsc"
                        fullWidth
                    />
                    <PersonalDataCheckbox
                        data={deleteAllItem}
                        setData={setDeleteAllItem}
                        labelText="Удалить все фото из понравившихся?"
                        fullWidth
                    />
                    <ButtonMaterialUi
                        BDisabled={dataProcessingConsent ? false : true}
                        title="Задать вопрос"
                        onClick={submitApplicationGallery}
                    />
                </Box>
            </Modal>
        </div>
    );
}

export default ApplicationGalleryModal