import React from 'react'
import ModalForEveryThing from '../../../components/modal/ModalForEverything';
import SubscriptionsContent from './SubscriptionsContent';
import AlertComponent from '../../../components/Alert';
import { useSelector } from 'react-redux';
import { setCloseAlertAuth } from '../../../reducers/auth.reducer'


const RenderModal = ({
    visible,
    handleClose,
    typeModal,
    subscriptions
}) => {
    const {
        loading,
        colorAlert,
        showAlert,
        message
    } = useSelector((state) => state.auth)

    const renderContent = () => {
        let content, title;
        switch (typeModal) {
            case "subscriptions":
                title = "Настройка рассылки"
                content = <SubscriptionsContent
                    items={subscriptions}
                />
                break;
            default:
                title = "Подписка на обновления"
                content = <div>
                    Скоро будет на сайте, наши разработчики уже работают над этим!
                </div>
        }
        return { content, title }
    }

    return (
        <div style={{ position: "relative" }}>
            <ModalForEveryThing
                visible={visible}
                handleClose={handleClose}
            >
                <h3>
                    {renderContent().title}
                </h3>

                <div>
                    {renderContent().content}
                </div>

            </ModalForEveryThing>
            <AlertComponent
                dispatchCloseAlert={setCloseAlertAuth}
                color={colorAlert}
                showObj={showAlert}
                text={message}
            />
        </div>
    )
}

export default RenderModal