import { contactConstants } from "../../actions/constants"


const initialState = {
    contact: [],
    loading: false

}

export default (state = initialState, action) => {
    switch (action.type) {
        case contactConstants.GET_CONTACT_REQUEST:
            state = {
                ...state,
                loading: true

            }
            break
        case contactConstants.GET_CONTACT_SUCCESS:
            state = {
                ...state,
                contact: action.payload.contact,
                loading: false
            }
            break
        case contactConstants.GET_CONTACT_FAILURE:
            state = {
                ...state,
                loading: false

            }
            break
        default:
            return state
    }
    return state
}
