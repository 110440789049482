import { roleChangeRequest, mainActionsConstants } from '../actions/constants';

const initState = {
  roleChangeRequests: [],
  roleChangeRequestObj: {},
  hasIncompleteStatus: false,
  loading: false,
  message: "",
  showAlert: false,
  variantAlert: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case roleChangeRequest.CREATE_ROLE_CHANGE_REQUEST:
      state = {
        ...state,
        loading: true
      };
    case roleChangeRequest.CREATE_ROLE_CHANGE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case roleChangeRequest.CREATE_ROLE_CHANGE_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case roleChangeRequest.GET_ROLE_CHANGE_BY_USER_REQUEST:
      state = {
        ...state,
        loading: true
      };
    case roleChangeRequest.GET_ROLE_CHANGE_BY_USER_SUCCESS:
      state = {
        ...state,
        roleChangeRequests: action.payload?.roleChangeRequests,
        hasIncompleteStatus: action.payload?.hasIncompleteStatus,
        loading: false,
      };
      break;
    case roleChangeRequest.GET_ROLE_CHANGE_BY_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case mainActionsConstants.GET_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true
      };
    case mainActionsConstants.GET_BY_ID_SUCCESS:
      state = {
        ...state,
        roleChangeRequestObj: action.payload?.item,
        loading: false,
      };
      break;
    case mainActionsConstants.GET_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      return state
  }
  return state;
};