import React, { useEffect } from 'react'
import { Button } from '@mui/material';
import CheckboxItem from './CheckboxItem';

const RenderSubscriptionsMainPage = ({
    items,
    title,
    explanation,
    setSubscriptionsArray,
    subscriptionsArray,
    setTypeContent
}) => {

    console.log('subscriptionsArray', subscriptionsArray)
    const handleCheckboxChange = (e, itemId, isChecked) => {
        let updatedItems = [];
        const existingItem = subscriptionsArray.find((item) => item.mainPage === itemId);

        if (existingItem) {
            updatedItems = subscriptionsArray.map((item) => {
                if (item.mainPage === itemId) {
                    return {
                        ...item,
                        isCompleted: isChecked,
                    };
                }
                return item;
            });
        } else {
            updatedItems = [
                ...subscriptionsArray,
                {
                    mainPage: itemId,
                    isCompleted: isChecked,
                },
            ];
        }

        setSubscriptionsArray(updatedItems);
    };

    useEffect(() => {
        const updatedItems = items.map((item) => ({
            mainPage: item._id,
            isCompleted: true,
        }));
        setSubscriptionsArray(updatedItems);
    }, []);

    return (
        <div className="renderSubscriptionsMainPage">
            <Button
                onClick={() => setTypeContent("")}
            >
                Назад
            </Button>
            <div className="renderSubscriptionsMainPage__title">
                {title
                    ? title
                    : "Вы можете выбрать то на что вы хотите оформить подписку для отправки оповещений на вашу почту раз в неделю каждый понедельник"
                }
            </div>
            {items.map((item) => (
                <CheckboxItem
                    key={item._id}
                    item={item}
                    subscriptionsArray={subscriptionsArray}
                    handleCheckboxChange={handleCheckboxChange}
                />
            ))}
            <div className="renderSubscriptionsMainPage__explanation">
                {explanation
                    ? explanation
                    : "В списке который выше поставьте галочку в том месте что вам интересно"
                }
            </div>
        </div>
    );
}

export default RenderSubscriptionsMainPage