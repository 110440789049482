import { regionConstants, mainActionsConstants } from '../../actions/constants';

const initialState = {
    regions: [],
    regionObj: {},
    loading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case regionConstants.GET_REGION_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case regionConstants.GET_REGION_SUCCESS:
            if (action.payload && Array.isArray(action.payload.regions)) {
                state = {
                    ...state,
                    regions: action.payload.regions.reverse(),
                    loading: false
                };
            }
            break;
        case regionConstants.GET_REGION_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                regionObj: action.payload?.item,
                // loading: false,
            };
            break;
        default:
            return state
    }
    return state
}