import React, { useState } from 'react'
import InfoModal from '../../../components/modal/InfoModal';
import RenderStatusArray from '../ApplicationPage/RenderStatusArray';
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
import { Button } from '@mui/material';

const OrderInfoModal = ({
    itemObj,
    isOpen,
    onClose,
    type
}) => {
    const { paymentListObj, loading } = useSelector((state) => state.paymentList)
    const { deliveryInfoObj } = useSelector((state) => state.deliveryInfo)
    const { pickupPointsObj } = useSelector((state) => state.pickupPoints)

    const renderContent = () => {
        switch (type) {
            case "payment":
                return <div>
                    <p>
                        {paymentListObj.title}
                    </p>
                    <p>
                        {paymentListObj.description}
                    </p>
                </div>
            case "delivery":
                return <div>
                    <p>
                        {deliveryInfoObj.title}
                    </p>
                    <p>
                        {deliveryInfoObj.description}
                    </p>
                    <div>
                        {itemObj?.orderStatus.some(item => item.type === "shipped" && item.isCompleted === true) ? <Button>Перейти на сайт и отследить</Button> : null}
                    </div>
                    <div>
                        Вот ТТН:
                    </div>
                </div>
            case "pickupPoints":
                return <div>
                    <p>
                        {pickupPointsObj.address}
                    </p>
                    <p>
                        {pickupPointsObj.city}
                    </p>
                    <p>
                        {pickupPointsObj.number}
                    </p>
                </div>
            case "leaveFeedback":
                return <div>
                    <p>
                        В скором времени мы добавим возможность оставлять отзывы к каждому товару
                    </p>
                </div>
            case "orderStatus":
                return <RenderStatusArray
                    statusArray={itemObj?.orderStatus}
                />
            default:
                return <RenderStatusArray
                    statusArray={itemObj?.orderStatus}
                />
        }
    }

    const renderTitle = () => {
        switch (type) {
            case "payment":
                return "Способ оплаты"
            case "delivery":
                return "Способ доставки"
            case "pickupPoints":
                return "Точка самовывоза"
            case "leaveFeedback":
                return "Оставить отзыв"
            default:
                return "Статус заказа"
        }
    }
    return (
        <InfoModal
            title={renderTitle()}
            isOpen={isOpen}
            onClose={onClose}
        >
            {loading ? <Loader height="300px" /> : renderContent()}

        </InfoModal>
    )
}

export default OrderInfoModal
