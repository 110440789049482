import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { searchProducts } from '../../actions';
import SearchIcon from '@mui/icons-material/Search';
import SearchModalContainer from './SearchModalContainer';
import AlertComponent from './../Alert';
import { setCloseAlertProduct } from '../../reducers/product.reducer';
import { setSearchName } from './../../reducers/product.reducer';

export function SearchProductComponent({
    setSearchClassName,
    windowWidth,
    handleSearchShow,
}) {

    const dispatch = useDispatch();
    const [searchTimeout, setSearchTimeout] = useState(false);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    // const [searchName, setSearchName] = useState("");
    const [sortSearch, setSortSearch] = useState("categoryNamePlusMaterialText");
    const productLengthRef = useRef(0);

    const {
        products,
        variantAlert,
        showAlert,
        message,
        loading,
        searchName
    } = useSelector((state) => state.product)
    const { user } = useSelector((state) => state.auth)
    const userId = user._id

    const searchChangeHandler = (e) => {
        dispatch(setSearchName(e.target.value))
        if (searchTimeout !== false) {
            clearTimeout(searchTimeout)
        }
        if (e.target.value !== "") {
            setSearchTimeout(setTimeout((value) => {
                dispatch(searchProducts(value, sortSearch, userId, productLengthRef.current))
            }, 1000, e.target.value))
        }
    }
    useEffect(() => {
        if (searchName !== "") {
            dispatch(searchProducts(searchName, sortSearch, userId, productLengthRef.current))
        }
    }, [sortSearch])

    useEffect(() => {
        if (searchName !== "") {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [searchName]);

    useEffect(() => {
        productLengthRef.current = products.length;
    }, [products]);

    useEffect(() => {
        if (windowWidth > 700) {
            if (searchName === "") {
                handleSearchShow(false)
            } else {
                handleSearchShow(true)
            }
            setSearchClassName("headerNav__WPlus")
        } else {
            setSearchClassName("headerNav__WPlusAbsolute")
        }

    }, [windowWidth])

    return (
        <>
            <div className="searchProductMainContainer">
                <div
                    className="orderMenuSearchSort__inputContainer"
                    onClick={() => setOpenSearchModal(true)}
                >
                    <div className="orderMenuSearchSort__searchIcon">
                        <SearchIcon />
                    </div>
                    <input
                        className="orderMenuSearchSort__search"
                        type="text"
                        placeholder="Поиск по продуктам"
                        value={searchName}
                        onChange={(e) => searchChangeHandler(e)}
                    />
                </div>
                {searchName !== "" && openSearchModal
                    ? <SearchModalContainer
                        sortSearch={sortSearch}
                        setSortSearch={setSortSearch}
                        setSearchName={() => dispatch(setSearchName())}
                        loading={loading}
                        items={products}
                        searchName={searchName}
                        userId={userId}
                    />
                    : null}
            </div>
            <AlertComponent
                dispatchCloseAlert={setCloseAlertProduct}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
        </>
    )
}
