import React from 'react'
import { useMatch, useNavigate } from 'react-router-dom';
import { goToTop } from './GoToTop';

const CustomLink = ({
    to,
    children,
    top
}) => {

    const match = useMatch(to)
    const navigate = useNavigate()

    const handleHref = () => {
        navigate(to)
        goToTop("onlyTop", top ? top : "0")
    }
    return (
        <div
            onClick={handleHref}
            className={`containerCabinetComponent__content ${match && "active"}`}
        >
            {children}
        </div>
    )
}

export default CustomLink