import axios from "../helpers/axios";
import { historyProductConstants } from "./constants";
import store from "../store";

export const getHistoryProductItems = (sort, type = "afll") => {
  return async (dispatch) => {
    try {
      dispatch({ type: historyProductConstants.GET_TO_HISTORY_PRODUCT_REQUEST });
      let url = `/user/getHistoryProduct?type=${type}`
      let urlTwo = `/user/getHistoryProduct?sort=${sort}&type=${type}`
      if (sort) {
        url = urlTwo
      }
      const res = await axios.post(url);

      if (res.status === 200) {
        const { historyProductItems } = res.data;
        if (historyProductItems) {
          dispatch({
            type: historyProductConstants.GET_TO_HISTORY_PRODUCT_SUCCESS,
            payload: { historyProductItems },
          });
        }
      }
    } catch (error) {
      dispatch({
        type: historyProductConstants.GET_TO_HISTORY_PRODUCT_FAILURE,
        payload: { error },
      });
    }
  };
};

export const addToHistoryProduct = (product) => {
  return async (dispatch) => {
    const {
      historyProduct: { historyProductItems },
      auth,
    } = store.getState();

    historyProductItems[product._id] = {
      ...product,
    };

    if (auth.authenticate) {
      dispatch({ type: historyProductConstants.ADD_TO_HISTORY_PRODUCT_REQUEST });
      const payload = {
        historyProductItems: [
          {
            product: product._id,
          },
        ],
      };
      const res = await axios.post("/user/historyProduct/addToHistoryProduct", payload);
      // console.log(res);
      if (res.status === 201) {
        dispatch(getHistoryProductItems());
      }
    } else {
      localStorage.setItem("historyProduct", JSON.stringify(historyProductItems));
    }

    dispatch({
      type: historyProductConstants.ADD_TO_HISTORY_PRODUCT_SUCCESS,
      payload: { historyProductItems },
    });
  };
};


export const removeHistoryProductItem = (payload) => {

  return async (dispatch) => {
    try {
      dispatch({ type: historyProductConstants.REMOVE_HISTORY_PRODUCT_REQUEST });
      const res = await axios.post("/user/historyProduct/removeItem", payload);
      if (res.status === 200) {
        dispatch({
          type: historyProductConstants.REMOVE_HISTORY_PRODUCT_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: historyProductConstants.REMOVE_HISTORY_PRODUCT_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getHistoryProductItems());
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateHistoryProduct = () => {
  return async (dispatch) => {
    const { auth } = store.getState();
    let historyProductItem = localStorage.getItem("historyProduct")
      ? JSON.parse(localStorage.getItem("historyProduct"))
      : null;
    if (auth.authenticate) {
      localStorage.removeItem("historyProduct");
      // dispatch(getHistoryProductItems());
      if (historyProductItem) {
        const payload = {
          historyProductItem: Object.keys(historyProductItem).map((key, index) => {
            return {
              quantity: historyProductItem[key].qty,
              product: historyProductItem[key]._id,
            };
          }),
        };
        if (Object.keys(historyProductItem).length > 0) {
          const res = await axios.post(`/user/historyProduct/addToHistoryProduct`, payload);
          if (res.status === 201) {
            dispatch(getHistoryProductItems());
          }
        }
      } else {
        dispatch(getHistoryProductItems());
      }
    } else {
      if (historyProductItem) {
        dispatch({
          type: historyProductConstants.ADD_TO_HISTORY_PRODUCT_SUCCESS,
          payload: { historyProductItem },
        });
      }
    }
  };
};

