import { blogConstants } from "../../actions/constants"


const initialState = {
    blog: [],
    blogDetails: {},
    loading: false
}


export default (state = initialState, action) => {
    switch (action.type) {
        case blogConstants.GET_ALL_BLOG_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case blogConstants.GET_ALL_BLOG_SUCCESS:
            state = {
                ...state,
                blog: action.payload.blog,
                loading: false
            }
            break
        case blogConstants.GET_ALL_BLOG_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        case blogConstants.GET_BLOG_DETAILS_BY_ID_SUCCESS:
            state = {
                ...state,
                blogDetails: action.payload.blogDetails
            }
            break;

    }
    return state
}