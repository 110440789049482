import axios from "../../helpers/axios"
import { blogConstants } from '../constants';


export const getBlog = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: blogConstants.GET_ALL_BLOG_REQUEST })
            const res = await axios.get("blog/get")
            if (res.status === 200) {
                const { blog } = res.data
                dispatch({
                    type: blogConstants.GET_ALL_BLOG_SUCCESS,
                    payload: {
                        blog
                    }
                })
            } else {
                dispatch({ type: blogConstants.GET_ALL_BLOG_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const getBlogDetailsById = (payload) => {
    return async dispatch => {
        dispatch({ type: blogConstants.GET_BLOG_DETAILS_BY_ID_REQUEST });
        let res;
        try {
            const { blogId } = payload.params;
            res = await axios.get(`/blog/blogDetails/${blogId}`);
            console.log(res);
            dispatch({
                type: blogConstants.GET_BLOG_DETAILS_BY_ID_SUCCESS,
                payload: { blogDetails: res.data.blog }
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: blogConstants.GET_BLOG_DETAILS_BY_ID_FAILURE,
                payload: { error: res.data.error }
            });
        }
    }
}