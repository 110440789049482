import React from 'react';

const Backdrop = ({
    visible,
    closeShowMiniModal
}) => {
    return visible ? (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 999999,
                transition: " all 0.5s",
            }}
            onClick={closeShowMiniModal}
        />
    ) : null;
};

export default Backdrop;