import React, { useEffect } from 'react'
import ContainerMainComponent from './../ContainerMainComponent';
import ArchitectSkills from './ArchitectSkills';
import RenderModalApplication from './../../components/button/RenderModalApplication';
import { useSelector, useDispatch } from 'react-redux';
import { getArchitect } from '../../actions';
import Loader from '../../components/Loader';
import { generatePublicUrl } from '../../urlConfig';
import ArchitectLi from './../ArchitectLi/ArchitectLi';

const Architect = () => {

    const architect = useSelector((state) => state.architect.architect)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getArchitect())
    }, [])



    const architectTitle = architect[0]
    if (!architectTitle) {
        return null
    }
    return (
        <ContainerMainComponent
            title={architectTitle.headerTitle}
        >
            <div className="architect">
                <div

                    style={{
                        backgroundImage: `url(${generatePublicUrl(architectTitle.mainImg)})`
                    }}
                    className="architect__mainImage">
                    <div className="architect__mainImage__text">
                        {architectTitle.textImage}

                    </div>
                </div>
                <div className="architect__descriptionContainer">
                    <div className="architect__descriptionContainer__row">
                        <div className="architect__descriptionContainer__left">
                            {architectTitle.contentTextLeft}
                        </div>
                        <div className="architect__descriptionContainer__right">
                            <h2>{architectTitle.contentTitle}</h2>
                            <p>
                                {architectTitle.contentDescription}
                            </p>
                        </div>
                    </div>
                </div>
                <ArchitectSkills />
                <ArchitectLi />
                <RenderModalApplication modalType="openModal" />
            </div>
        </ContainerMainComponent>
    )
}

export default Architect