
export const opacityAnimation = {
    hidden: {
        y: -20,
        opacity: 0,
    },
    visible: custom => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.3, duration: 0.3 }
    }),
}
export const downAnimation = {
    hidden: {
        y: -50,
        opacity: 0,
    },
    visible: custom => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.3, duration: 0.3 }
    }),
}
export const upAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: custom => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.3, duration: 0.3 }

    }),
}
export const rightAnimation = {
    hidden: {
        x: -50,
        opacity: 0,
    },
    visible: custom => ({
        x: 0,
        opacity: 1,
        transition: { delay: custom * 0.3, duration: 0.3 }
    }),
}