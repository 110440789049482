
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '@mui/material';
import { generatePublicUrl } from '../../../urlConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { goToTop } from '../../../components/GoToTop';

const DeliveryPWDetails = ({
    itemContent,
}) => {
    const navigate = useNavigate()
    const { productId } = useParams()

    const dispatch = useDispatch()

    const handleNavigate = () => {
        navigate(`/elementPaymentPage?productId=${productId}`)
        goToTop("onlyTop",)

    }

    if (!itemContent) return null
    return (
        <div className="paymentList">

            <div className="warranty__row">
                {itemContent.map((item, index) => (
                    <div
                        key={index}
                        className="warranty__component">
                        <div className="warranty__titleRow">
                            <div className="warranty__imgBox">
                                <img src={generatePublicUrl(item.iconImg)} alt={item.title} />
                            </div>
                            <div className="warranty__title">
                                {item.title}
                            </div>
                        </div>
                        <p>
                            {item.description}
                        </p>
                    </div>
                ))}
            </div>
            <div className="renderDeliveryPG__buttonBlock">
                <Button
                    variant="outlined"
                    onClick={handleNavigate}
                >Прочитать подробнее</Button>
            </div>
        </div>
    )
}

export default DeliveryPWDetails