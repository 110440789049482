import React, { useEffect, useRef } from 'react'
import PhoneIcon from '@mui/icons-material/Phone';
import { ButtonMaterialUiOval } from '../MaterialUi';
import CloseIcon from '@mui/icons-material/Close';

const CallMeButton = ({ handleShowModal, setShowModal, showBlock, handleShowBlock, handleCloseBlock }) => {
    const rootEl = useRef(null);

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setShowModal(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);


    return (
        <div>
            {
                showBlock ? <div className="callMeBlock">
                    <div>
                        <div className="callMeBlock__contentBlock">
                            <h4>Нужна помощь?</h4>
                            <span>Оставьте заявку с вашим вопросом, и наши менеджеры позвонят вам в ближайшее время!</span>
                        </div>
                        <div className='mt-2'>
                            <ButtonMaterialUiOval
                                icon={<PhoneIcon />}
                                title="Позвонить мне"
                                onClick={handleShowModal}
                            />
                        </div>
                    </div>
                    <div className='callMeBlock__closeButton' onClick={handleCloseBlock}>
                        <CloseIcon fontSize='large' />
                    </div>
                </div> : <div
                    className='callMe'
                    ref={rootEl}
                    onMouseEnter={handleShowBlock}

                >
                    <PhoneIcon />
                </div>
            }

        </div>
    )
}

export default CallMeButton
