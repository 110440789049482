import React, { useState } from 'react'

const HrefButtonBox = ({
    currentId,
    setCurrentId,
    handleProduct,
    obj
}) => {

    return (
        <div
            className={`hrefButtonBox ${obj._id === currentId ? "active" : ""} `}>
            <div className="hrefButtonBox__buttonsBox">
                <button onClick={() => handleProduct(obj)}>Перейти</button>
                <button style={{
                    background: "red"
                }} onClick={() => setCurrentId(null)}> Назад</button>
            </div>
        </div >
    )
}

export default HrefButtonBox