import React from 'react'

const TransparentButton = (props) => {

    return (
        <div className="transparentButton">
            <span>{props.text}</span>
        </div>
    )
}

export default TransparentButton
