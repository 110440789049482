import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { generatePublicUrl } from "../../urlConfig";
import { getContactM } from "../../actions";
import ContainerMainComponent from "../ContainerMainComponent";

const ContactM = () => {
  const { contactM, loading } = useSelector((state) => state.contactM);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getContactM());
  }, []);

  const handleButtonHref = (href) => {
    navigate(href);
  };
  return (
    <ContainerMainComponent
      title="Наши мессенджеры"
    >
      <div className="contactM">
        <Button onClick={() => handleButtonHref("/maf")}>На главную</Button>
        {contactM.map((item) => (
          <div className="contactM__mainContainer" key={item._id}>
            <div className="contactM__img">
              <img src={generatePublicUrl(item.itemPictures[0].img)} alt="" />
            </div>
            <div className="contactM__contentName">
              {" "}
              <div className="contactM__name">{item.name}</div>
              <div className="contactM__descriptionMain">
                <div className="contactM__desc">{item.description}</div>
              </div>
              <div className="contactM__href">
                <Button
                  variant="contained"
                  // color="red"
                  onClick={() => handleButtonHref(item.href)}
                >
                  {item.href}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ContainerMainComponent>
  );
};

export default ContactM;
