import React, { useEffect } from 'react'
import ContainerMainComponent from '../ContainerMainComponent'
import PurpleButton from './../../components/button/PurpleButton';
import RenderPortfolio from './RenderPortfolio';
import { NavLink } from 'react-router-dom';
import { getPortfolio } from './../../actions/components/portfolio';
import { useSelector, useDispatch } from 'react-redux';

const PortfolioPage = () => {
    const portfolio = useSelector((state) => state.portfolio.portfolio)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getPortfolio())
    }, [])
    const items = {
        title: "РЕАЛИЗОВАННЫЕ ПРОЕКТЫ",
        description: "В КОНТЕКСТЕ ТЕРРИТОРИИ. В КРЕАТИВНОМ ИСПОЛНЕНИИ",
        buttonText: "посмотреть видео проектов",
        titleBlockText: "Наша основная задача — создание единой свето-цветовой среды города и комфортных условий для его жителей. Каждый проект оформления разрабатывается индивидуально с учетом всех требований локации. Производственная и материально-техническая база позволяют нам реализовывать все этапы работ от создания дизайн-концепции до выполнения монтажа и последующего технического обслуживания конструкций."
    }

    return (
        <ContainerMainComponent
            title={items.title}
            description={items.description}
        >

            <div className="portfolioPage">
                <div className="portfolioPage__titleContainer">

                    <div className="portfolioPage__titleContainer__leftTitleBlock">
                        <p>
                            {items.titleBlockText}
                        </p>
                    </div>
                    <div className="portfolioPage__titleContainer__rightButtonBlock">
                        <NavLink to="/videoPortfolioPage">
                            <PurpleButton onClick={() => { }} text={items.buttonText} />
                        </NavLink>
                    </div>
                </div>
                <RenderPortfolio portfolio={portfolio} />

            </div>
        </ContainerMainComponent>
    )
}

export default PortfolioPage
