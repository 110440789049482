import React from 'react'
import SettingIconBlock from './button/SettingIconBlock'

const TitleComponent = ({
    greyColorTitle,
    title,
    description,
    handleClick,
    tooltip,
    icon,
    iconView
}) => {
    return (
        <div className="titleComponent">
            <h6 className={greyColorTitle ? "titleComponent__grey" : "titleComponent__title"}>
                {title}
            </h6>

            <div className="titleComponent__descriptionBlock">
                {description && <h2>{description}</h2>
                }
                {iconView && <SettingIconBlock
                    tooltip={tooltip}
                    icon={icon}
                    onClick={handleClick}
                    top="2px"
                    right="-20px"
                />
                }
            </div>
        </div>
    )
}

export default TitleComponent
