import React, { useState } from 'react'
import Loader from './../Loader';
import IconButtonClose from './../IconButtons/IconButtonClose';
import { useDispatch, } from 'react-redux';
import { addToHistoryProduct, updateProductView } from '../../actions';
import { useNavigate } from 'react-router-dom';
import { goToTop } from './../GoToTop';
import { setProductDetailsList, setSearchName } from './../../reducers/product.reducer';
import { setSearchShow } from './../../reducers/auth.reducer';
import { setShowModalCart } from './../../reducers/cart.reducer';
import HrefButtonBox from './HrefButtonBox';
import MoreIconFunctionalityProduct from './MoreIconFunctionalityProduct';
import { generatePublicUrl } from '../../urlConfig';

const SearchModalContainer = ({
    setSortSearch,
    searchName,
    sortSearch,
    loading,
    items,
    userId
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [currentId, setCurrentId] = useState(null)

    const handleChange = (event) => {
        setSortSearch(event.target.value);
    };
    const handleClose = () => {
        dispatch(setSearchName(""))
        dispatch(setSearchShow(false))
    }
    if (!items) return null

    const selectItem = [
        {
            value: "productsFullName",
            text: "Полное имя(СДМ 0001)"
        },
        {
            value: "productsD",
            text: "По подробному описанию"
        },
        {
            value: "productsCatN",
            text: "Имя категории"
        },
        {
            value: "categoryNamePlusMaterialText",
            text: "По категории + материал(бетон)"
        },
        {
            value: "allParameters",
            text: "По всем параметрам сразу"
        },
    ]
    const handleProduct = (obj) => {
        const { _id } = obj
        dispatch(addToHistoryProduct({ _id }))
        navigate(`/productDetails/${obj._id}`)
        const payload = {
            productId: obj._id,
            userId: userId
        }
        dispatch(updateProductView(payload))
        goToTop("onlyTop",)
        setSearchName("")
        dispatch(setProductDetailsList("Все о товаре"))
    }

    const RenderSelect = () => {
        return (
            <select
                className="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                onChange={(event) => handleChange(event)}
                value={sortSearch}
                style={{
                    backgroundColor: '#fff',
                    border: '1px solid #ced4da',
                    borderRadius: '0.25rem',
                    color: '#495057',
                    display: 'inline-block',
                    fontSize: '17px',
                    fontWeight: '400',
                    lineHeight: '1.5',
                    padding: '0.375rem 0.75rem',
                    width: '100%',
                    maxWidth: "500px"
                }}
            >
                {selectItem.map((item) => (
                    <option
                        value={item.value}
                        style={{
                            backgroundColor: '#fff',
                            color: '#495057'
                        }}>
                        {item.text}
                    </option>
                ))}
            </select>
        )
    }

    return (
        <div className='searchModalContainer'>
            <div className="searchModalContainer__modal">
                <IconButtonClose
                    onClick={handleClose}
                />
                <h3>Результаты поиска "{searchName}", всего "{items.length}" товарa(-ов)</h3>
                <h6>Пожалуйста, выберите параметр для поиска в выпадающем списке ниже</h6>
                <RenderSelect />
                {loading
                    ? <Loader />
                    : <div className="searchModalContainer__productMainContainer">
                        <div className="searchModalContainer__rowProduct title">
                            <div className="searchModalContainer__imgContainerTitle">
                                Фото
                            </div>
                            <div className="searchModalContainer__descBox">
                                <div className="searchModalContainer__nameTitle">
                                    Имя товара
                                </div>
                            </div>
                            <div className="searchModalContainer__nameTitle">
                                Категория
                            </div>
                            <div className="searchModalContainer__nameTitleMini">
                                Категория + имя
                            </div>
                        </div>
                        {items.length > 0 ? items.map((obj) => (
                            <div
                                className="searchModalContainer__productBox"
                                key={obj._id}
                            >
                                <div onClick={() => setCurrentId(obj._id)}>
                                    <div className="searchModalContainer__rowProduct">
                                        <div className="searchModalContainer__imgContainer">
                                            <img
                                                src={generatePublicUrl(obj.reducedImage)}
                                                alt={obj.fullName}
                                            />
                                        </div>
                                        <div className="searchModalContainer__flexContainer">
                                            <div className="searchModalContainer__descBox">
                                                <div className="searchModalContainer__name">
                                                    {obj.fullName}
                                                </div>
                                            </div>
                                            <div className="searchModalContainer__category">
                                                {obj.categoryName}
                                            </div>
                                        </div>
                                        <div className="searchModalContainer__descBox">
                                            <div className="searchModalContainer__name">
                                                {obj.fullName}
                                            </div>
                                        </div>
                                        <div className="searchModalContainer__category">
                                            {obj.categoryName}
                                        </div>
                                    </div>

                                </div>
                                <div className="searchModalContainer__moreIcon">
                                    <MoreIconFunctionalityProduct
                                        obj={obj}
                                        setShowModalCart={setShowModalCart}
                                    />
                                </div>
                                <HrefButtonBox
                                    handleProduct={handleProduct}
                                    setCurrentId={setCurrentId}
                                    currentId={currentId}
                                    obj={obj}
                                />
                            </div>
                        ))
                            : <Loader allFunctional />}
                    </div>}
            </div>
        </div >
    )
}

export default SearchModalContainer