import React, { useState } from 'react';
import Loader from '../Loader';

const ShowMoreButton = ({
    onClick,
    text,
    loading,
    currentPage,
    icon
}) => {
    const [rotating, setRotating] = useState(false);

    const handleClick = () => {
        setRotating(true);
        setTimeout(() => setRotating(false), 3000);
        onClick()
    };
    if (loading && currentPage > 1) return <Loader />
    return (
        <div className={`showMoreButton ${rotating ? 'rotating' : ''}`} onClick={handleClick}>
            <div className="showMoreButton__content">
                <div className="showMoreButton__content__textBlock">
                    <span>{text ? text : 'Показать еще'}</span>
                </div>
            </div>
        </div>
    );
};

export default ShowMoreButton;
