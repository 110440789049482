import React, { useEffect } from 'react'
import Landscaping from '../../components/Landscaping/Landscaping'
import MainImageHeader from '../../components/MainImageHeader'
import NewHeader from '../../components/NewHeader'
import RequestForCalculating from '../../components/RequestForCalculating'
import PortfolioMain from '../PortfolioMain/PortfolioMain'
import SeasonCatalog from '../SeasonsCatalog/SeasonCatalog'
import BlogComponent from '../Blog/BlogComponent';
import CategoryListComponent from './../../components/Category/CategoryListComponent';
import DarkLineComponent from './../../components/DarkLineComponent';
import BlackLine from './../../components/BlackLine';

const HomePage = () => {

    return (
        <>
            <NewHeader noneFooter />
            <MainImageHeader />
            <div>
                <CategoryListComponent />
                <Landscaping />
                <BlackLine />
                <SeasonCatalog />
                <DarkLineComponent
                    buttonBlock
                    titleTop="НАШИ ИЗДЕЛИЯ"
                    titleCenter="В ОНЛАЙН КАТАЛОГЕ"
                    titleBottom="«БЛАГОУТРОЙСТВО ГОРОДСКОЙ СРЕДЫ»"
                />
                <PortfolioMain />
                <DarkLineComponent
                    titleTop="МЫ"
                    titleBottom="ПРЕДЛАГАЕМ:"
                    descriptionTop="ШИРОКУЮ ЛИНЕЙКУ ГОТОВЫХ МАФОВ"
                    descriptionCenter="РАЗРАБОТКУ ИЗДЕЛИЙ ПОД КОНКРЕТНЫЙ"
                    descriptionBottom="ПРОЕКТ"
                />
                <BlogComponent />
                <RequestForCalculating />
            </div>
        </>

    )
}

export default HomePage
