import React from 'react'
import metalImg from '../../../assets/metalImg.png'
import woodImg from '../../../assets/woodImg.png'
import concreteImg from '../../../assets/concreteImg.png'


const RenderProductMaterial = ({
    productMaterials,

}) => {
    const imgFunc = (materialType) => {

        let imgBlock
        switch (materialType) {
            case "wood":
                imgBlock = woodImg
                break;
            case "concrete":
                imgBlock = concreteImg
                break;
            default:
                imgBlock = metalImg
        }
        return imgBlock
    }
    const materialFilter = productMaterials.filter(obj => obj.isCompleted)
    return (
        <div style={{ width: materialFilter.length < 3 ? "250px" : "400px" }} className="renderProductMaterial">
            <h5>Используемые материалы</h5>
            <div className="renderProductMaterial__row">
                {materialFilter.map((obj, index) => (
                    <div className="renderProductMaterial__contentBox"
                        key={index}
                    >
                        <img src={imgFunc(obj.type)} alt={obj.text} />
                        <div className="renderProductMaterial__textBox">
                            {obj.text}

                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RenderProductMaterial