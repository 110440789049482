import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { goToTop } from './GoToTop';

const PaginationComponent = ({
    goToTopRef,
    setPageNumber,
    pageNumber,
    pageSize
}) => {
    const product = useSelector((state) => state.product)
    const { totalPages, totalAll } = product

    const buttonPages = new Array(totalPages).fill(null).map((v, i) => i);
    const [countPa, setCountPa] = useState(0)
    const [countPaEnd, setCountPaEnd] = useState(5)

    const handlePageNumber = (pageIndex) => {
        setPageNumber(pageIndex)
        goToTop("", "", goToTopRef)

    }
    const gotoPrevious = () => {
        setPageNumber(Math.max(0, pageNumber - 1));
        goToTop("", "", goToTopRef)
        if (pageNumber === countPa) {
            paginationCountMinus()
        }
    };

    const gotoNext = () => {
        setPageNumber(Math.min(totalPages - 1, pageNumber + 1));
        goToTop("", "", goToTopRef)
        if (pageNumber + 1 === countPaEnd) {
            paginationCount()
        }
    }
    const pageItemEnd = (one, two) => {
        let page = one * two
        return page

    }
    const pageItemStart = (one, two) => {
        let page = one * two - two + 1
        return page

    }

    const test = buttonPages.slice(countPa, countPaEnd)

    const paginationCount = () => {
        setCountPa(countPa + 4)
        setCountPaEnd(countPaEnd + 4)
    }
    const paginationCountMinus = () => {
        setCountPa(countPa - 4)
        setCountPaEnd(countPaEnd - 4)
    }


    return (
        <div className="paginationComponent">
            <div className="paginationComponent__mainContainer">


                <div className="paginationComponent__leftTextContainer">
                    Показано с {pageItemStart(pageNumber + 1, pageSize)} по {pageItemEnd(pageNumber + 1, pageSize < totalAll ? pageSize : totalAll)} из {totalAll} (всего {totalPages} страниц(-ы))
                </div>
                <div className="paginationComponent__container">
                    {countPa === 0 ? null : <button
                        className="paginationComponent__buttonBack"
                        onClick={paginationCountMinus}><FirstPageIcon /></button>
                    }

                    <button
                        className="paginationComponent__buttonBack"
                        onClick={gotoPrevious}>Назад</button>
                    {test.map((pageIndex) => (
                        <button
                            key={pageIndex} onClick={() => handlePageNumber(pageIndex)}
                            className={pageIndex === pageNumber
                                ? "paginationComponent__buttonNumber active"
                                : "paginationComponent__buttonNumber"}
                        >
                            {pageIndex + 1}
                        </button>
                    ))}
                    <button
                        className="paginationComponent__buttonNext"
                        onClick={gotoNext}>Вперед</button>
                    {countPaEnd >= totalPages ? null : <button
                        className="paginationComponent__buttonNext"
                        onClick={paginationCount}>
                        <LastPageIcon />
                    </button>}
                </div>
            </div>
        </div >
    )
}

export default PaginationComponent