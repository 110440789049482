import React from 'react'
import GrayButton from './../button/GrayButton';

const MiniModalQuestion = ({
    visible,
    text,
    textDelete,
    description,
    handleClose,
    handleExecute,

}) => {

    if (!visible) return null

    return (
        <div className="miniModalQuestion"
            onClick={(e) => e.stopPropagation()}
        >
            <div className="miniModalQuestion__content">
                <h3>{textDelete
                    ? "Действительно ли вы хотите удалить товар"
                    : description}
                </h3>
                <div className="miniModalQuestion__buttonContainer">
                    <GrayButton
                        onClick={handleClose}
                        type="miniGrayButton"
                        text="Назад"
                    />
                    {handleExecute ? <GrayButton
                        type="medium"
                        onClick={handleExecute}
                        text={textDelete ? textDelete : "Подтвердить"}
                    /> : <GrayButton
                        onClick={handleExecute}
                        type="greenButtonHref"
                        href="/checkoutPage"
                        text={text ? text : "Подтвердить"}
                    />}
                </div>
            </div>
        </div>
    )
}

export default MiniModalQuestion