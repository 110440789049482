import React from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeCartItem } from '../../../actions';
import { setShowModalCart } from './../../../reducers/cart.reducer';
import TextView from './../../../components/TextView';
import activeAddButton from '../../Products/ActiveAddButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip } from '@mui/material';

const DescContainer = ({
    obj,
}) => {
    const { cartItems } = useSelector((state) => state.cart)

    const dispatch = useDispatch()

    const handleCartButton = (e, _id) => {
        e.stopPropagation();
        if (activeAddButton(obj._id, cartItems) === obj._id) {
            dispatch(removeCartItem({ productId: _id }));
        } else {
            const payload = {
                _id: obj._id,
                fullName: obj.fullName,
                img: obj.reducedImage
            }
            dispatch(addToCart(payload))
            dispatch(setShowModalCart());
        }
    };
    return (
        <div className="descContainer">
            <div className="descContainer__nameBox">
                <div className="descContainer__name">
                    {obj.fullName}
                </div>
                <TextView
                    itemViews={obj.viewsLength}
                />
            </div>
            <div className="descContainer__rowPrice">
                <div>
                    <div className="descContainer__priceFalse">
                        Уточнить цену
                    </div>
                    <div className="descContainer__inStock">
                        {obj.quantity > 0 ? (
                            <div className="descContainer__yesN">
                                В наличии {obj.quantity} шт.
                            </div>
                        ) : (
                            <div className="descContainer__noN">
                                Под заказ
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`descContainer__cartIconContainer ${activeAddButton(obj._id, cartItems) === obj._id
                        ? "gray"
                        : "В"
                        }`}
                    onClick={(e) => handleCartButton(e, obj._id)}
                >
                    <Tooltip title={activeAddButton(obj._id, cartItems) === obj._id
                        ? "Уже добавлено"
                        : "В корзину"
                    }>

                        {activeAddButton(obj._id, cartItems) === obj._id
                            ? <CheckCircleIcon />
                            : <ShoppingCartIcon
                            />}
                    </Tooltip>
                </div>
            </div>


        </div>
    )
}

export default DescContainer