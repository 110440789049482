import { userConstants } from "../actions/constants";

const initState = {
  address: [],
  addressObj: {},
  error: null,
  loadingAddress: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case userConstants.GET_USER_ADDRESS_REQUEST:
      state = {
        ...state,
        loadingAddress: true,
      };
      break;
    case userConstants.GET_USER_ADDRESS_SUCCESS:
      state = {
        ...state,
        address: action.payload.address,
        loadingAddress: false,
      };
      break;
    case userConstants.GET_USER_ADDRESS_FAILURE:
      state = {
        ...state,
        loadingAddress: false,
        error: "Нет созданных адресов",
      };
      break;
    case userConstants.ADD_USER_ADDRESS_REQUEST:
      state = {
        ...state,
        loadingAddress: true,
      };
      break;
    case userConstants.ADD_USER_ADDRESS_SUCCESS:
      state = {
        ...state,
        address: action.payload.address,
        addressObj: action.payload.addressObj,
        loadingAddress: false,
      };
      break;
    case userConstants.ADD_USER_ADDRESS_FAILURE:
      state = {
        ...state,
        loadingAddress: false,
        error: action.payload.error,
      };
      break;
    default:
      return state
  }

  return state;
};


