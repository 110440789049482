import React from 'react'
import SocialMediaIcons from './miniButtons/SocialMediaIcons';

const Sidebar = (props) => {

    const sidebarItems = [
        {
            href: "/maf",
            name: "Главная"
        },
        {
            href: "/aboutUs",
            name: "О нас"
        },
        // {
        //     href: "/portfolioPage",
        //     name: "Портфолио"
        // },
        // {
        //     href: "/videoPortfolioPage",
        //     name: "Видео портфолио"
        // },
        {
            href: "/productsCategory/all",
            name: "Продукция"
        },
        {
            href: "/architect",
            name: "Архитекторам"
        },
        {
            href: "/blog",
            name: "Блог"
        },
        // {
        //     href: "/contactM",
        //     name: "Наши соц-сети"
        // },
        {
            href: "/contacts",
            name: "Контакты"
        },
    ]
    return (
        <ul className='sidebar'>
            {sidebarItems.map((item, index) => (
                <li
                    className={props.blackColor ? "sidebar__block-black" : "sidebar__block"}
                    key={index}>
                    <a href={item.href}>
                        {item.name}
                    </a>
                </li>
            ))}
            {props.socialMediaSites ? <SocialMediaIcons backgroundNone /> : null}
        </ul>
    )
}

export default Sidebar
