import React, { useEffect, useState } from 'react'
import TitleComponent from '../../components/TitleComponent'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Slider from '../../components/Slider/Slider';
import { getPortfolio } from './../../actions/components/portfolio';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion'
import { rightAnimation } from '../../components/Animation/Animation';
import TitleHeaderComponent from './../../components/TitleHeaderComponent';

const PortfolioMain = () => {
    const portfolio = useSelector((state) => state.portfolio.portfolio)

    const [portfolioIndex, setPortfolioIndex] = useState(1)
    const [namePort, setNamePort] = useState("")

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPortfolio())
    }, [])
    const renderNamePort = () => {
        return (
            <div className="col-12 portfolioMain__text">
                {portfolio.map((obj, index) => (
                    <div
                        key={index}
                        className={index + 1 === portfolioIndex ? "portfolioMain__slideText active" : "portfolioMain__slideText"}
                    >
                        {obj.title}
                    </div>
                ))}
            </div>
        )
    }
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            variants={rightAnimation}
            className="portfolioMain"
        >
            <TitleHeaderComponent
                description={renderNamePort()}
                greyColorTitle
                title={"Реализованые проекты"}
                text={"Наши проекты"}
                aHref='/portfolioPage'
            />
            <div className="row">
                <div className="wow col-12 col-sm-12 col-md-12 col-lg-6 portfolioMain__leftBlock ">
                    <div className="col-12 portfolioMain__text">
                        {portfolio.map((obj, index) => (
                            <div
                                key={index}
                                className={index + 1 === portfolioIndex ? "portfolioMain__slideText active" : "portfolioMain__slideText"}
                            >
                                {obj.description}
                            </div>
                        ))}
                    </div>
                    <div className="portfolioMain__arrowRightBlock">
                        <a href="/portfolioPage">
                            <span>Все проекты</span>
                            <ArrowRightAltIcon />
                        </a>
                    </div>
                    <div className="portfolioMain__videoButtonBlock">
                        <a href="/videoPortfolioPage">
                            <div className="portfolioMain__videoButtonBlock__iconBlock">
                                <PlayArrowIcon />
                            </div>
                            <span>Смотреть видео проектов</span>
                        </a>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 portfolioMain__rightBlock">
                    <Slider
                        setPortfolioIndex={setPortfolioIndex}
                        titleNone
                        item={portfolio}
                    />
                </div>
            </div>
        </motion.div >

    )
}

export default PortfolioMain
