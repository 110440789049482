import React, { useState } from 'react';

const ButtonBoxFullWidth = ({
    onClick,
    checked,
    text,
}) => {
    const [error, setError] = useState(false);

    const handleClick = () => {
        if (!checked) {
            setError("Согласитесь с условиями");
            setTimeout(() => {
                setError("");
            }, 3000);
            return;
        } else {
            onClick()
        }

    };

    return (
        <div className="buttonBoxFullWidth">
            {error && <p>Подтвердите свое согласие и заполните все поля</p>}
            <div className="buttonBoxFullWidth__content"
                onClick={handleClick}
            >
                <div
                    className={`buttonBoxFullWidth__button ${!checked && 'disabled'}`}
                    disabled={!checked}
                >
                    {text ? text : 'Оформить заказ'}
                </div>
            </div>
        </div>
    );
};

export default ButtonBoxFullWidth;
