import React from 'react'
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const RadioInputs = ({
    title,
    value,
    setAddressType,
    arrayRadio
}) => {

    const style = {
        margin: "10px"
    }

    const handleChange = (event) => {
        setAddressType(event.target.value);
    };

    return (
        <div style={style}>
            <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">{title}</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                // style={{
                //     display: "flex",
                //     alignItems: "center",
                //     justifyContent: "center",
                // }}
                >
                    {!arrayRadio ? null : arrayRadio.map((obj, index) => (
                        <FormControlLabel
                            key={index}
                            value={obj.inputValue}
                            control={<Radio />}
                            label={obj.inputText} />

                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    )
}

export default RadioInputs