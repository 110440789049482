import React, { useState, useEffect } from 'react'
import aLotBLogo from '../assets/TestW.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PersonIcon from '@mui/icons-material/Person';
import MiniUserModal from '../containers/Header/MiniUserModal';
import { signout } from '../actions';
import { setShowModalUser } from '../reducers/auth.reducer';
import CreateUser from '../components/modal/CreateUserModal';


const HeaderMainPage = ({
    background
}) => {
    const auth = useSelector((state) => state.auth)
    const contactNumber = useSelector((state => state.contact.contact[0]?.number))
    const { loading } = auth
    const [showMiniUserModal, setShowMiniUserModal] = useState(false)
    const [signup, setSignup] = useState(false);
    const [buttonIndex, setButtonIndex] = useState(1)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (showMiniUserModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showMiniUserModal]);

    const hrefNavigate = () => {
        navigate('/')
    }
    const logout = () => {
        dispatch(signout());
        setSignup(false)
    }

    const closeShowMiniModal = () => {
        setShowMiniUserModal(!showMiniUserModal)
    }
    const handleShowUserModal = (item, index) => {
        if (item.text === "Войти") {
            setSignup(false)
        } else {
            setSignup(true)
        }
        setButtonIndex(index + 1)
        dispatch(setShowModalUser())
        showMiniUserModal(false)
    }

    return (
        <div className={`headerMainPage ${background ? "background" : ""}`}>
            <div className="headerMainPage__rowContent">
                <div className="headerMainPage__title">
                    <img
                        src={aLotBLogo}
                        alt="aLotBLogo"
                        onClick={hrefNavigate}
                    />
                </div>
                <div className="headerMainPage__signInBlock">
                    <div
                        onClick={() => setShowMiniUserModal(!showMiniUserModal)}
                        className={showMiniUserModal ? "headerNav__personContainer activePersonName" : "headerNav__personContainer"}>
                        <PersonIcon />
                        <span>
                            {auth.authenticate ? auth.user.firstName : " Мой профиль"}
                        </span>
                    </div>
                </div>
            </div>
            <MiniUserModal
                contactNumber={contactNumber}
                auth={auth.authenticate}
                fullName={auth.user.fullName}
                buttonIndex={buttonIndex}
                handleShowUserModal={handleShowUserModal}
                signup={signup}
                setShowMiniUserModal={setShowMiniUserModal}
                closeShowMiniModal={closeShowMiniModal}
                visible={showMiniUserModal}
                logout={logout}
                typeModal="rightModal"
            />
            <CreateUser
                loading={loading}
                signup={signup}
                setSignup={setSignup}
                setShowMiniUserModal={setShowMiniUserModal}
            />
        </div>
    )
}

export default HeaderMainPage