import { applicationConstants } from './constants'
import axios from '../helpers/axios';
import swal from 'sweetalert';


export const createApplication = (form) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationConstants.CREATE_APPLICATION_REQUEST });
      const res = await axios.post("application/create", form);
      if (res.status === 201) {
        dispatch({ type: applicationConstants.CREATE_APPLICATION_SUCCESS });
        dispatch(getApplicationsByUser());

        swal({
          title: "Успешно отправено",
          text: "Оператор свяжется с вами в ближайшее время",
          icon: "success",
        });
      } else {
        dispatch({ type: applicationConstants.CREATE_APPLICATION_FAILURE });
        swal({
          title: "Ошибка отправки",
          text: "Попробуйте снова",
          icon: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      swal({
        title: "Ошибка отправки",
        text: "Попробуйте снова",
        icon: "warning",
      });
    }
  };
};

export const getApplicationsByUser = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/application/getApplicationsByUser");
      dispatch({ type: applicationConstants.GET_APPLICATION_BY_USER_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: applicationConstants.GET_APPLICATION_BY_USER_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: applicationConstants.GET_APPLICATION_BY_USER_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addSamplePhotoApplication = (_id, files) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      for (let pic of files) {
        formData.append("samplePhoto", pic);
      }
      const res = await axios.post(`/application/${_id}/addSamplePhoto`, formData);
      console.log(res.data)
      dispatch({ type: applicationConstants.ADD_SAMPLE_PHOTO_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: applicationConstants.ADD_SAMPLE_PHOTO_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: applicationConstants.ADD_SAMPLE_PHOTO_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
