import '../src/scss/app.scss'
import HomePage from './containers/HomePage/HomePage';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Catalog from './containers/Catalog';
import FooterComponent from './components/Footer/FooterComponent';
import CallMeComponent from './components/button/CallMeComponent';
import Contact from './containers/Contact/Contact';
import AboutUs from './containers/AboutUs/AboutUs';
import Products from './containers/Products/Products';
import PortfolioPage from './containers/PortfolioPage/PortfolioPage';
import Architect from './containers/Architect/Architect';
import VideoPortfolioPage from './containers/VideoPortfolioPage/VideoPortfolioPage';
import BlogPage from './containers/Blog/BlogPage';
import BlogDetailsPage from './containers/Blog/BlogDetailsPage';
import ProductDetails from './containers/Products/ProductDetails/ProductDetails';
import PortfolioPageDetails from './containers/PortfolioPage/PortfolioPageDetails/PortfolioPageDetails';
import EmailNewsletterPage from './containers/EmailNewsletterPage';
import CheckoutPage from './containers/Chekout/CheckoutPage';
import OrderPage from './containers/CabinetPage/OrderPage/OrderPage';
import PersonalInformation from './containers/CabinetPage/PersonalInfo/PersonalInformation';
import HistoryProductPage from './containers/CabinetPage/HistoryPage/HistoryProductPage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isUserLoggedIn } from './actions/auth.action';
import { updateCart, updateDesires } from './actions';
import DesiresPage from './containers/CabinetPage/DesiresPage/DesiresPage';
import MailingList from './containers/CabinetPage/MailingListPage/MailingList';
import ApplicationPage from './containers/CabinetPage/ApplicationPage/ApplicationPage';
import PartnerPage from './containers/CabinetPage/PartnerPage/PartnerPage';
import GalleryPage from './containers/Gallery/GalleryPage';
import ElementPaymentPage from './containers/ElementPaymentPage/ElementPaymentPage';
import ContactM from './containers/ContactM/ContactM';
import MainPage from './mainPage/MainPage';
import ContainerPage from './sites/ContainerPage';

import OfflinePage from './OfflinePage';
import ScrollBlock from './components/ScrollBlock';
import SettingPage from './containers/CabinetPage/SettingPage/SettingPage';
import { updateGalleryImgUserItem } from './actions/galleryImgUser';

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isOnline, setIsOnline] = useState(true);
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    function handleOnlineStatus() {
      setIsOnline(true);
    }
    function handleOfflineStatus() {
      setIsOnline(false);
    }
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOfflineStatus);
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOfflineStatus);
    }
  }, []);

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
    dispatch(updateCart());
    dispatch(updateDesires());
    dispatch(updateGalleryImgUserItem());
  }, [auth.authenticate]);

  const renderRoute = () => {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/maf" element={<HomePage />} />
            <Route path="/productDetails/:productId" element={<ProductDetails />} />
            <Route path="/blogDetails/:blogId" element={<BlogDetailsPage />} />
            <Route path="/portfolioPage" element={<PortfolioPage />} />
            <Route path="/videoPortfolioPage" element={<VideoPortfolioPage />} />
            <Route path="/productsCategory/:slug" element={<Products />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/contacts" element={<Contact />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/architect" element={<Architect />} />
            <Route path="/portfolioDetails/:portfolioId" element={<PortfolioPageDetails />} />
            <Route path="/emailNewsletter" element={<EmailNewsletterPage />} />
            <Route path="/checkoutPage" element={<CheckoutPage setShowFooter={setShowFooter} />} />
            <Route path="/historyProductPage" element={<HistoryProductPage />} />
            <Route path="/orderPage" element={<OrderPage />} />
            <Route path="/desiresPage" element={<DesiresPage />} />
            <Route path="/personalInformation" element={<PersonalInformation />} />
            <Route path="/partnerPage" element={<PartnerPage />} />
            <Route path="/mailingList" element={<MailingList />} />
            <Route path="/user/settingPage" element={<SettingPage />} />
            <Route path="/applicationPage" element={<ApplicationPage />} />
            <Route path="/applicationCallMePage" element={<ApplicationPage callMe />} />
            <Route path="/galleryPage" element={<GalleryPage />} />
            <Route path="/elementPaymentPage" element={<ElementPaymentPage />} />
            <Route path="/contactM" element={<ContactM />} />
            <Route path="/" element={<MainPage setShowFooter={setShowFooter} />} />
            <Route path="/mainPage/:mainPageId" element={<ContainerPage setShowFooter={setShowFooter} />} />
          </Routes>
          {showFooter && <FooterComponent auth={auth} />}
        </BrowserRouter>
        <ScrollBlock />
        <CallMeComponent />
      </>
    )
  }

  return (
    <>
      {isOnline ? renderRoute() : <OfflinePage />}
    </>
  );
}

export default App;

