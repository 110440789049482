import React from 'react'
import { ButtonMaterialUi } from '../../components/MaterialUi';
import AddressForm from './CheckoutPage/AddressForm';
import MainButtonSite from './../../components/button/MainButtonSite';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';

const Address = ({
    disableAddressEditForm,
    confirmDeliveryAddress,
    enableAddressEditForm,
    onAddressSubmit,
    setNewAddress,
    selectAddress,
    auth,
    adr,
}) => {

    return (
        <div className="flexRow addressContainer">
            <div className="addressContainer__addressInfoContent">
                <div className="addressContainer__addressInputBox">
                    <div className="addressContainer__addressType">{adr.addressType}</div>
                    <input
                        className="addressContainer__addressInput"
                        name="address"
                        onClick={() => selectAddress(adr)}
                        checked={adr.selected}
                        type="radio"
                    />
                </div>
                {!adr.edit ? (
                    <div className="addressContainer__textBlock">
                        <div className="addressContainer__addressDetail">
                            {adr.mobileNumber && (
                                <div>
                                    <span className="addressMobileNumber">{adr.mobileNumber}</span>
                                </div>
                            )}
                            {adr.locality && (
                                <div>
                                    <span>{adr.locality}</span>
                                </div>
                            )}
                        </div>
                        <div className="fullAddress">
                            {adr.address} <br /> {`${adr.cityDistrictTown} - ${adr.pinCode}`}
                        </div>
                        <div className="addressContainer__formButtonContainer">
                            {adr.selected && (
                                <>
                                    {!confirmDeliveryAddress
                                        ? null
                                        : <Button
                                            style={{
                                                outline: "none",
                                                margin: "10px 0",
                                            }}
                                            variant={"contained"}
                                            onClick={() => confirmDeliveryAddress(adr)}
                                        >
                                            ВЫБРАТЬ АДРЕСС
                                        </Button>}

                                    <Button
                                        style={{
                                            outline: "none"
                                        }}
                                        variant={"outlined"}
                                        onClick={() => enableAddressEditForm(adr)}
                                    >
                                        ИЗМЕНИТЬ
                                    </Button>
                                </>
                            )}

                        </div>
                    </div>
                ) : (
                    <AddressForm
                        auth={auth}
                        disableAddressEditForm={disableAddressEditForm}
                        withoutLayout={true}
                        onSubmitForm={onAddressSubmit}
                        initialData={adr}
                        setNewAddress={setNewAddress}
                    />
                )}
            </div>
        </div>
    );
};


export default Address