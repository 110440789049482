import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import ButtonProduct from './../../components/button/ButtonProduct';
import activeAddButton from './ActiveAddButton';

const ButtonAddCart = ({
    obj,
    handleCartButton
}) => {
    const cart = useSelector((state) => state.cart);

    const handleAddCart = (obj) => {
        handleCartButton(obj)
    }
    return (
        <>
            <ButtonProduct
                onClick={() => handleAddCart(obj)}
                _className={activeAddButton(obj._id, cart.cartItems) === obj._id ? "active" : ""}
                text={activeAddButton(obj._id, cart.cartItems) === obj._id ? "Уже в корзине" : "Добавить в корзину"}

            />
        </>

    )
}

export default ButtonAddCart