import React, { useState } from 'react'
import { FormControlLabel, Checkbox } from '@mui/material';

const CheckboxText = ({
    data,
    setData,
    label,
    blackText,
    fullWidth,
    plus10W,
    style,
    visible
}) => {

    const colorText = () => blackText ? "#000" : "#fff"
    const fullWidthFunc = () => fullWidth ? "100%" : plus10W ? "110%" : "85%"

    const [checked, setChecked] = useState(data)


    const handleChange = (e) => {
        setData(e.target.checked)
        setChecked(e.target.checked)
    }
    if (visible) return null
    return (
        <>
            <div
                className="checkboxText"
                style={{
                    color: colorText(),
                    width: fullWidthFunc(),
                    margin: "0 auto",
                    ...style,
                }}>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleChange} />}
                    label={label}
                />
            </div>

        </>

    )
}

export default CheckboxText