import React from 'react'
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { goToTop } from '../GoToTop';

const ComeBackButton = ({
    handleClick,
    text,
    href,
    type
}) => {
    const navigate = useNavigate();

    const handleHref = () => {
        if (type === "otherLink") {
            navigate(href);
            handleClick()
            goToTop("onlyTop", 0)
        } else {
            navigate(-1);
        }
    };

    return (
        <div className="comeBackButton">
            <div className="comeBackButton__linkBox" onClick={handleHref}>
                <div className="comeBackButton__row">
                    <BsArrowLeft />
                    <div>{text ? text : "Вернуться назад"}</div>
                </div>
            </div>
        </div>
    );
};

export default ComeBackButton;
