import React from 'react'
import PurpleButton from './button/PurpleButton';
import BlackButton from './button/BlackButton';
import { NavLink } from 'react-router-dom';

const TwoButtonsComponent = () => {
    return (
        <div className="twoButtonsComponent">
            <div className="twoButtonsComponent__buttonsBlock">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <NavLink className="twoButtonsComponent__leftButton" to="/portfolioPage">
                            <PurpleButton onClick={() => { }} whiteBackground text="Вернуться в портфолио" />
                        </NavLink>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <a className="twoButtonsComponent__rightButton" href=''>
                            <BlackButton text="Перейти в каталог" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TwoButtonsComponent