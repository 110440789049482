import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getApplicationsByUser, getApplicationsCallMeByUser, getItemById } from '../../../actions';
import ContainerCabinetComponent from '../../ContainerCabinetComponent';
import { useDispatch } from 'react-redux';
import ApplicationPageItem from './ApplicationPageItem';
import RenderDetailsApplication from './RenderDetailsApplication';
import AlertComponent from '../../../components/Alert';
import { setCloseAlertApplication } from '../../../reducers/application.reducer';

const ApplicationPage = ({
    callMe
}) => {
    const [showPhoto, setShowPhoto] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState("");
    const [valueTabsSort, setValueTabsSort] = useState(1);
    const {
        applications,
        applicationObj,
        colorAlert,
        showAlert,
        loading,
        message
    } = useSelector((state) => callMe ? state.applicationCallMe : state.application);

    const dispatch = useDispatch();

    useEffect(() => {
        if (callMe) {
            dispatch(getApplicationsCallMeByUser());
        } else {
            dispatch(getApplicationsByUser());
        }
    }, [callMe]);

    const toggle = (id) => {
        if (selectedItemId === id) {
            setSelectedItemId(null);
        } else {
            setSelectedItemId(id);
            if (callMe) {
                dispatch(getItemById("applicationCallMe", id));
            } else {
                dispatch(getItemById("application", id));
            }
        }
    };

    return (
        <>
            <ContainerCabinetComponent
                title={"Заявки по вашим вопросам"}
                setValueTabsSort={setValueTabsSort}
                tabs
            >
                <div className="applicationPage">
                    <div className="applicationPage__mainContainer">
                        {applications.length > 0 ? (
                            applications.map((item) => (
                                <ApplicationPageItem
                                    key={item._id}
                                    item={item}
                                    selectedItemId={selectedItemId}
                                    toggle={toggle}
                                >
                                    {selectedItemId === item._id && (
                                        <RenderDetailsApplication
                                            callMe={callMe}
                                            selectedItemId={selectedItemId}
                                            loading={loading}
                                            applicationObj={applicationObj}
                                            setShowPhoto={setShowPhoto}
                                            showPhoto={showPhoto}
                                            samplePhoto={applicationObj?.samplePhoto}
                                        />
                                    )}
                                </ApplicationPageItem>
                            ))
                        ) : (
                            <p> Нет созданых заявок</p>
                        )}
                    </div>
                </div>
            </ContainerCabinetComponent>
            <AlertComponent
                dispatchCloseAlert={setCloseAlertApplication}
                color={colorAlert}
                showObj={showAlert}
                text={message}
            />
        </>
    );
};
export default ApplicationPage;

