import { allQuestionConstants } from "../../actions/constants"


const initialState = {
    allQuestion: [],
    allQuestionDetails: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case allQuestionConstants.GET_ALL_QUESTION_SUCCESS:
            state = {
                ...state,
                allQuestion: action.payload.allQuestion,
            }
            break
    }
    return state
}