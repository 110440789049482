import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import { useDispatch } from 'react-redux';

const OrderMenuSearchSort = ({
    dispatchSearch,
    get,
    sort,
    setSort,
    search,
    setSearch,
}) => {
    const dispatch = useDispatch();
    const [searchTimeout, setSearchTimeout] = useState(false);
    const [showSearchIcon, setShowSearchIcon] = useState(true);

    const handleChange = (event) => {
        setSort(event.target.value);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        if (searchTimeout !== false) {
            clearTimeout(searchTimeout);
        }
        if (e.target.value !== '') {
            setSearchTimeout(
                setTimeout((value) => {
                    dispatch(dispatchSearch(value));
                }, 1000, e.target.value)
            );
        } else {
            dispatch(get());
        }
    };

    const handleInputFocus = () => {
        setShowSearchIcon(false);
    };

    const handleInputBlur = () => {
        setShowSearchIcon(true);
    };

    const selectItem = [
        {
            value: 'AllTime',
            text: 'За все время',
        },
        {
            value: 'day1',
            text: 'Последние сутки',
        },
        {
            value: 'day7',
            text: 'Последняя неделя',
        },
        {
            value: 'day30',
            text: 'Последние 30 дней',
        },
        {
            value: 'day180',
            text: 'последние 6 месяц',
        },
        {
            value: 'lastYear',
            text: 'За год',
        },
    ];

    return (
        <div className="orderMenuSearchSort">
            <div className="orderMenuSearchSort__inputContainer">
                {showSearchIcon && (
                    <div className="orderMenuSearchSort__searchIcon">
                        <SearchIcon />
                    </div>
                )}
                <input
                    className="orderMenuSearchSort__search"
                    type="text"
                    placeholder="Поиск по заказам"
                    value={search}
                    onChange={handleSearchChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                />
            </div>
            <div className="orderMenuSearchSort__selectContainer">
                <FormControl sx={{ m: 1, minWidth: 250 }}>
                    <InputLabel id="demo-simple-select-helper-label">сортировка</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={sort}
                        label="Сортировка"
                        onChange={handleChange}
                    >
                        {selectItem.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                                {item.text}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}
export default OrderMenuSearchSort