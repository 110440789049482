import React, { useEffect, useState } from 'react'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ContainerMainComponent from './ContainerMainComponent';
import CustomLink from '../components/CustomLink';
import ContainerContentCabinet from './CabinetPage/ContainerContentCabinet';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { itemsCabinetList } from './../store/localItems';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '../mainPage/BackDrop';
import useBodyOverflow from '../components/useBodyOverflow';
import IconButtonClose from '../components/IconButtons/IconButtonClose';
// import { Tabs } from 'react-bootstrap';

const ContainerCabinetComponent = ({
    title,
    children,
    // tabs,
    // setValueTabsSort
}) => {
    const auth = useSelector((state) => state.auth);
    const { user, authenticate } = auth;
    const [showLeftMenu, setShowLeftMenu] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!authenticate) {
            navigate("/maf");
        } else {
            navigate(location.pathname);
        }
    }, [navigate, authenticate]);

    const handleMenuIconClick = () => {
        setShowLeftMenu(!showLeftMenu);
    };
    useBodyOverflow(showLeftMenu)
    return (
        <ContainerMainComponent>
            <>
                <Backdrop visible={showLeftMenu} closeShowMiniModal={() => setShowLeftMenu(false)} />
                <div className={`containerCabinetComponent ${showLeftMenu ? "open" : ""
                    }`}>
                    <div className="containerCabinetComponent__row">
                        <div
                            className="containerCabinetComponent__menuIcon"
                            onClick={handleMenuIconClick}
                        >
                            <MenuIcon />
                        </div>
                        <div
                            className={`containerCabinetComponent__cabinetSidebar ${showLeftMenu ? "open" : ""
                                }`}
                        >
                            <div className="containerCabinetComponent__closeIconBlock">
                                <IconButtonClose
                                    onClick={handleMenuIconClick}
                                    top="10px"
                                />
                            </div>
                            <div className={`containerCabinetComponent__fix`}>
                                <CustomLink
                                    to={"/personalInformation"}
                                    className="containerCabinetComponent__content"
                                >
                                    <div className="containerCabinetComponent__iconContainer">
                                        <PersonOutlineIcon />
                                    </div>
                                    <div className="containerCabinetComponent__personContainer">
                                        <h5>
                                            {user.firstName} {user.lastName}
                                        </h5>
                                        <span>{user.email}</span>
                                    </div>
                                </CustomLink>
                                <div className="containerCabinetComponent__container">
                                    <div>
                                        {itemsCabinetList.map((item, index) => (
                                            <CustomLink key={index} to={item.href}>
                                                <div className="containerCabinetComponent__customLinkContainer">
                                                    <div className="containerCabinetComponent__iconContainer">
                                                        {item.icon}
                                                    </div>
                                                    <div className="containerCabinetComponent__LinkText">
                                                        {item.text}
                                                    </div>
                                                </div>
                                            </CustomLink>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="containerCabinetComponent__contentBlock">
                            <ContainerContentCabinet title={title}>
                                {/* {tabs && <Tabs
                                setValueTabsSort={setValueTabsSort}
                            />} */}
                                {children}
                            </ContainerContentCabinet>
                        </div>
                    </div>
                </div>
            </>
        </ContainerMainComponent>
    );
};

export default ContainerCabinetComponent;