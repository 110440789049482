import React, { useState, useEffect } from 'react'
import { setCurrentCategory } from './../../reducers/product.reducer';
import { getCategoryDetailsById } from '../../actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setShowCategoryBlock } from '../../reducers/category.reducer';
import RenderAllCategories from './RenderAllCategories';


const ProductList = ({
    showCategoryBlock,
    setCategoryObj,
    setPageNumber,
    setSlideIndex,
    buttonHandler,
    categoryObj,
    refCategory,
    slideIndex,
    category,
}) => {

    const [mainCategoryIndex, setMainCategoryIndex] = useState(1)

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handlerCategoryList = (index, childrenCategory) => {
        setSlideIndex(index + 1)
        setPageNumber(0)
        buttonHandler(refCategory)
        setCategoryObj(childrenCategory)
        dispatch(getCategoryDetailsById(childrenCategory._id))
    }

    const currentCategory = (obj, index) => {
        if (obj.name === "Галерея всей продукции") {
            navigate("/galleryPage")
        }
        setCurrentCategory(obj._id)
        setMainCategoryIndex(index + 1)
        setPageNumber(0)

    }
    useEffect(() => {
        const storedData = window.localStorage.getItem('TEST');
        let data = null;

        try {
            data = JSON.parse(storedData);
        } catch (error) {
            console.error('Ошибка при парсинге JSON:', error);
        }
        setCategoryObj(data)
    }, [])
    useEffect(() => {
        window.localStorage.setItem('TEST', JSON.stringify(categoryObj))
    }, [categoryObj])


    return (
        <div className="productList">
            {/* <button onClick={() => dispatch(setShowCategoryBlock())}>crhsnm njdfhs</button> */}
            {
                showCategoryBlock
                    ? <RenderAllCategories
                        category={category}
                        mainCategoryIndex={mainCategoryIndex}
                        currentCategory={currentCategory}
                        handlerCategoryList={handlerCategoryList}
                        slideIndex={slideIndex}
                    />
                    : null
            }

        </div>
    )


}

export default ProductList
