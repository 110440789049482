import React, { useEffect, useState, useMemo } from 'react'
import CompanyTitle from './../../components/CompanyTitle';
import CheckoutDeliveryBlock from './CheckoutPage1/CheckoutDeliveryBlock';
import CheckoutPaymentMethod from './CheckoutPage1/CheckoutPaymentMethod';
import CheckoutUserForm from './CheckoutPage1/CheckoutUserForm';
import CheckoutItemsOrder from './CheckoutPage1/CheckoutItemsOrder';
import Breadcrumb from './../../components/Breadcrumb';
import CheckoutOrderInfo from './CheckoutPage1/CheckoutOrderInfo';
import { Col, Row } from 'react-bootstrap';
import CheckoutAddressForm from './CheckoutPage1/CheckoutAddressForm';
import { PersonalDataCheckbox } from '../../components/MaterialUi';
import ButtonBoxFullWidth from './../../components/button/ButtonBoxFullWidth';
import { useDispatch, useSelector } from 'react-redux';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ItemsPickupPoints from './CheckoutPage1/PickupPoints/ItemsPickupPoints';
import ContainerCheckoutList from './CheckoutPage1/ContainerCheckoutList';
import { Navigate, useNavigate } from 'react-router-dom';
import { addOrder, getAddress } from '../../actions';
import RegularOrNewClient from './RegularOrNewClient';
import { setCloseAlert } from '../../reducers/cart.reducer';
import AlertComponent from '../../components/Alert';
import { goToTop } from '../../components/GoToTop';

const CheckoutPage = (props) => {
    const cart = useSelector((state) => state.cart);
    const { variantAlert, showAlert, textAlert, loading } = cart
    const auth = useSelector((state) => state.auth)
    const user = useSelector((state) => state.user);

    const [readyToOrder, setReadyToOrder] = useState(false)
    const [typeClient, setTypeClient] = useState("regular");

    const [successRegularOrNewClient, setSuccessRegularOrNewClient] = useState(false)
    const [successDelivery, setSuccessDelivery] = useState(false)
    const [successProducts, setSuccessProducts] = useState(false)
    const [successPayment, setSuccessPayment] = useState(false)
    const [successUserData, setSuccessUserData] = useState(false)
    const [successPickup, setSuccessPickup] = useState(false)
    const [presenceAddress, setPresenceAddress] = useState(false)

    const [disabledButtonDelivery, setDisabledButtonDelivery] = useState(false)
    const [disabledButtonPayment, setDisabledButtonPayment] = useState(true)
    const [disabledButtonUserData, setDisabledButtonUserData] = useState(true)
    const [disabledButtonPickup, setDisabledButtonPickup] = useState(false)
    const [disabledButtonCartItems, setDisabledButtonCartItems] = useState(false)

    const [dataProcessingConsent, setDataProcessingConsent] = useState(false)
    const [pickupFromWarehouse, setPickupFromWarehouse] = useState(false)
    const [orderOnOperator, setOrderOnOperator] = useState(false)

    const [paymentOrderOnOperator, setPaymentOrderOnOperator] = useState(true)

    const [pickupPointsId, setPickupPointsId] = useState(null)
    const [deliveryId, setDeliveryId] = useState("")
    const [paymentId, setPaymentId] = useState("")
    const [userData, setUserData] = useState(null)

    const [confirmOrder, setConfirmOrder] = useState(false);
    const [userType, setUserType] = useState("individual");
    const [fixedModal, setFixedModal] = useState(false)
    const [newAddress, setNewAddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [confirmAddress, setConfirmAddress] = useState(false);
    const [address, setAddress] = useState([]);
    const [addressObj, setAddressObj] = useState(null);
    const [formAddress, setFormAddress] = useState({
        address: "",
        landmark: "",
        pinCode: "",
        cityDistrictTown: "",
        userType: "home"

    });
    const navigate = useNavigate();

    const dispatch = useDispatch()

    useEffect(() => {
        if (successUserData
            && successPayment
            && successRegularOrNewClient
            && dataProcessingConsent
            && successProducts
            && (pickupFromWarehouse ? successPickup : presenceAddress)
        ) {
            setReadyToOrder(true)
        } else {
            setReadyToOrder(false)
        }
    }, [dataProcessingConsent,
        successUserData,
        successPayment,
        successRegularOrNewClient,
        pickupFromWarehouse,
        successPickup,
        presenceAddress,
        successProducts
    ])

    useEffect(() => {
        if (cart.cartItems && cart.cartItems.length > 0) {
            setDisabledButtonCartItems(false)
        } else {
            setDisabledButtonCartItems(true)
        }
    }, [cart.cartItems])

    useEffect(() => {
        props.setShowFooter(false)
    }, [])

    useEffect(() => {
        document.addEventListener("scroll", scrollHandler)

        return function () {
            document.removeEventListener("scroll", scrollHandler)
        }
    }, [])
    useEffect(() => {
        auth.authenticate && dispatch(getAddress());
    }, [auth.authenticate]);

    useEffect(() => {
        const address = user.address.map((adr) => ({
            ...adr,
            selected: false,
            edit: false,
        }));
        setAddress(address);
        user.address.length === 0 ? setNewAddress(true) : setNewAddress(false);
        setAddressObj(user.address && user.addressObj)
    }, [user.address]);

    const scrollHandler = (e) => {
        if (e.target.documentElement.scrollTop > 190) {
            setFixedModal(true)
        }
        if (e.target.documentElement.scrollTop < 190) {
            setFixedModal(false)
        }
    }

    const onAddressSubmit = (addr) => {
        setSelectedAddress(addr);
        setConfirmAddress(true);
    };

    const confirmDeliveryAddress = (addr) => {
        setSelectedAddress(addr);
        setConfirmAddress(true);
    };

    const onConfirmOrder = () => {
        const items = Object.keys(cart.cartItems).map((key) => {
            return {
                productId: cart.cartItems[key]._id,
                payablePrice: 0,
                purchasedQty: cart.cartItems[key].qty,
            };
        });

        let payload = {
            buyerType: userType,
            pickupFromWarehouse,
            totalAmount: 0,
            items,
            paymentStatus: "pending",
            paymentType: "cod",
            userData
        };
        if (selectedAddress !== null) {
            payload = {
                ...payload,
                addressId: selectedAddress._id
            };
        }

        if (pickupFromWarehouse) {
            payload.pickupPointsId = pickupPointsId;
        } else {
            payload.deliveryId = deliveryId;
        }

        if (!paymentOrderOnOperator) {
            payload.paymentId = paymentId;
        } else {
            payload.paymentOrderOnOperator = true;
        }

        if (orderOnOperator) {
            payload.orderOnOperator = true;
            payload.deliveryId = null;
        }

        dispatch(addOrder(payload))
            .then(() => {
                navigate('/maf')
                goToTop("onlyTop", 0)
            });
    };
    return (
        <>
            <div className="checkoutPage">
                <div className="checkoutPage__header">
                    <CompanyTitle />

                </div>
                <div className="checkoutPage__checkoutContainer">
                    <div className="checkoutPage__warningsBlock">
                        <span>
                            Внимание! Товары, находящиеся на разных складах или разных продавцов, будут доставлены отдельными заказами
                        </span>
                    </div>
                    <Breadcrumb
                        titleItem="Оформление заказа"
                    />
                    <Row>
                        <Col md="8">
                            <div className="checkoutPage__contentBox">
                                <h3>Заполнить формы</h3>
                                <RegularOrNewClient
                                    setSuccessData={setSuccessRegularOrNewClient}
                                    successData={successRegularOrNewClient}
                                    setTypeClient={setTypeClient}
                                    showSuccessButtons
                                    typeClient={typeClient}
                                />
                                <CheckoutUserForm
                                    setDisabledButton={setDisabledButtonUserData}
                                    disabledButton={disabledButtonUserData}
                                    setSuccessData={setSuccessUserData}
                                    successData={successUserData}
                                    setDisabledButtonPayment={setDisabledButtonPayment}
                                    showSuccessButtons={true}
                                    userType={userType}
                                    setUserType={setUserType}
                                    setUserData={setUserData}
                                />
                                <Row>
                                    <Col xs="12" md="12" sm="12" lg="5">
                                        <CheckoutPaymentMethod
                                            disabledButton={disabledButtonPayment}
                                            successUserData={successUserData}
                                            showSuccessButtons={true}
                                            setSuccessData={setSuccessPayment}
                                            successData={successPayment}
                                            userType={userType}
                                            setPaymentId={setPaymentId}
                                        />
                                    </Col>
                                    <Col xs="12" md="12" sm="12" lg="7">
                                        <CheckoutDeliveryBlock
                                            disabledButton={disabledButtonDelivery}
                                            setDisabledButton={setDisabledButtonDelivery}
                                            setSuccessData={setSuccessDelivery}
                                            successData={successDelivery}
                                            showSuccessButtons={true}
                                            setDeliveryId={setDeliveryId}
                                            setPickupFromWarehouse={setPickupFromWarehouse}
                                            setOrderOnOperator={setOrderOnOperator}
                                        />
                                    </Col>
                                </Row>
                                {successDelivery ? pickupFromWarehouse
                                    ? <ContainerCheckoutList
                                        disabledButton={disabledButtonPickup}
                                        currentId={pickupPointsId}
                                        showSuccessButtons={true}
                                        setSuccessData={setSuccessPickup}
                                        successData={successPickup}
                                        title="Самовывоз со склада"
                                        icon={<Inventory2Icon />}
                                        heightType={successPickup && "150px"}
                                        children={<ItemsPickupPoints
                                            setDisabledButton={setDisabledButtonPickup}
                                            setPickupPointsId={setPickupPointsId}
                                            pickupPointsId={pickupPointsId}
                                            successData={successPickup}
                                        />}
                                    />
                                    : <CheckoutAddressForm
                                        errorBlock={presenceAddress}
                                        setPresenceAddress={setPresenceAddress}
                                        setFormAddress={setFormAddress}
                                        formAddress={formAddress}
                                        selectedAddress={selectedAddress}
                                        setConfirmAddress={setConfirmAddress}
                                        setSelectedAddress={setSelectedAddress}
                                        setAddress={setAddress}
                                        setNewAddress={setNewAddress}
                                        confirmAddress={confirmAddress}
                                        confirmDeliveryAddress={confirmDeliveryAddress}
                                        onAddressSubmit={onAddressSubmit}
                                        address={address}
                                        addressObj={addressObj}
                                        auth={auth}
                                    />
                                    : null
                                }
                                <CheckoutItemsOrder
                                    cartItems={cart.cartItems}
                                    disabledButtonCartItems={disabledButtonCartItems}
                                    setSuccessData={setSuccessProducts}
                                    successData={successProducts}
                                    showSuccessButtons={true}

                                />
                            </div>
                        </Col>
                        <Col
                            md="4"
                            className={`checkoutPage__orderInfoBlock ${fixedModal ? "fixedModal" : ""}`}
                        >
                            <CheckoutOrderInfo
                                checked={readyToOrder}
                                onClick={onConfirmOrder}
                            />
                        </Col>
                    </Row>
                    <div className="checkoutPage__addOrderBlock">
                        <PersonalDataCheckbox
                            blackText
                            data={dataProcessingConsent}
                            setData={setDataProcessingConsent}
                            checked1="dscdscdsc"
                        />
                        <div className="checkoutPage__addOrderBlock__button">
                            <ButtonBoxFullWidth
                                checked={readyToOrder}
                                onClick={onConfirmOrder}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <AlertComponent
                dispatchCloseAlert={setCloseAlert}
                color={variantAlert}
                showObj={showAlert}
                text={textAlert}
            />
        </>

    )
}

export default CheckoutPage