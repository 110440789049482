import { applicationConstants, mainActionsConstants } from './../actions/constants';

const initState = {
  applications: [],
  applicationObj: {},
  visibleRegModal: false,
  // passwordsMatch: false,
  loading: false,
  modalType: "",
  message: "",
  showAlert: false,
  variantAlert: "success",
};
export default (state = initState, action) => {
  switch (action.type) {
    case applicationConstants.ADD_SAMPLE_PHOTO_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationConstants.ADD_SAMPLE_PHOTO_SUCCESS:
      state = {
        ...state,
        applicationObj: action.payload?.application,
        loading: false,
        showAlert: true,
        variantAlert: "success",
        message: action.payload?.message,
      };
      break;
    case applicationConstants.ADD_SAMPLE_PHOTO_FAILURE:
      state = {
        ...state,
        loading: false,
        showAlert: true,
        variantAlert: "warning",
        message: action.payload?.message,
      };
      break;
    case mainActionsConstants.GET_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case mainActionsConstants.GET_BY_ID_SUCCESS:
      state = {
        ...state,
        applicationObj: action.payload?.item,
        loading: false,
      };
      break;
    case mainActionsConstants.GET_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationConstants.GET_APPLICATION_BY_USER_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationConstants.GET_APPLICATION_BY_USER_SUCCESS:
      state = {
        ...state,
        applications: action.payload?.applications.reverse(),
        loading: false,
      };
      break;
    case applicationConstants.GET_APPLICATION_BY_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationConstants.CREATE_APPLICATION_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationConstants.CREATE_APPLICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationConstants.CREATE_APPLICATION_FAILURE:
      state = {
        ...state,
        loading: false
      };
      break;
    case applicationConstants.VISIBLE_REGISTER_MODAL_APPLICATION:
      state = {
        ...state,
        visibleRegModal: !state.visibleRegModal
      };
      break;
    // case applicationConstants.PASSWORDS_MATCH_APPLICATION_SUCCESS:
    //   state = {
    //     ...state,
    //     passwordsMatch: true
    //   };
    //   break;
    case applicationConstants.SET_MODAL_TYPE_APPLICATION:
      state = {
        ...state,
        modalType: action.payload
      }
      break;
    case applicationConstants.CLOSE_ALERT_TO_APPLICATION:
      state = {
        ...state,
        showAlert: false
      }
      break;
    default:
      return state
  }

  return state;
};
export const setModalType = (payload) => ({
  type: applicationConstants.SET_MODAL_TYPE_APPLICATION,
  payload
})
export const setVisibleRegModal = (payload) => ({
  type: applicationConstants.VISIBLE_REGISTER_MODAL_APPLICATION,
  payload
})
export const setCloseAlertApplication = (payload) => ({
  type: applicationConstants.CLOSE_ALERT_TO_APPLICATION,
  payload
})

// export const setPasswordMatchSuccess = (payload) => ({
//   type: applicationConstants.PASSWORDS_MATCH_APPLICATION_SUCCESS,
//   payload
// })

