import React, { useState, useRef, useEffect } from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import RenderMiniImg from './../Slider/RenderMiniImg';
import GrayButton from './GrayButton';

const BRenderAddPictures = ({
    setPictures,
    pictures
}) => {

    const ref = useRef()
    const [image, setImage] = useState("")
    const [preview, setPreview] = useState("")
    const [previewArray, setPreviewArray] = useState([])
    const [_previewArray, _setPreviewArray] = useState([])
    const [slideIndex, setSlideIndex] = useState(null)

    useEffect(() => {
        if (previewArray.length) {
            for (let i = 0; i < previewArray.length; i++) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    previewArray.push(reader.result)
                }
                reader.readAsDataURL(previewArray[i])
                previewArray.map((obj) => obj.slice(0, 4) === "data" && _previewArray.push(obj))
            }
        } else {
            setPreview(null)
        }
    }, [image])

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result)
            }
            reader.readAsDataURL(image)
        } else {
            setPreview(null)
        }
    }, [image])



    const handleImageChange = (event) => {
        setPictures(event.target.files);
        const fileArray = event.target.files;

        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === "image") {
            for (let i = 0; i < fileArray.length; i++) {
                previewArray.push(fileArray[i])
            }
            setImage(file)
        } else {
            setImage(null)
        }
    }
    const ItemPreview = previewArray.filter(item => item.slice(0, 4) === "data")
    const clearAllPhoto = () => {
        setPictures([])
        setPreviewArray([])
    }
    return (
        <div className="renderAddPictures">
            <RenderMiniImg
                itemObj={ItemPreview}
                slideIndex={slideIndex}
                directlyImg
            />
            <div className="renderAddPictures__buttonBox">
                {!ItemPreview.length ? null : <GrayButton
                    type="miniGrayButton"
                    text="Очистить все"
                    onClick={clearAllPhoto}
                />}
                <div
                    className="renderAddPictures__button"
                    title="Добавить"
                    onClick={(event) => {
                        event.preventDefault()
                        setPreviewArray([])
                        ref.current.click();
                    }} >
                    <AttachFileIcon />
                </div>
                <input
                    type="file"
                    name="productPictures"
                    onChange={(event) => handleImageChange(event)}
                    // required
                    multiple
                    accept="image/*"
                    ref={ref}
                    style={{ display: "none" }}
                />
            </div>
        </div>
    )
}

export default BRenderAddPictures