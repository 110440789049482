import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getBlog } from '../../actions';
import ContainerMainComponent from './../ContainerMainComponent';
import CategoryContainer from './CategoryContainer';
import RenderBlogItems from './RenderBlogItems';
import ComeBackButton from './../../components/button/ComeBackButton';



const BlogPage = () => {
    const blog = useSelector((state) => state.blog.blog)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getBlog())
    }, [])
    return (
        <ContainerMainComponent
            title="БЛОГ"
            description="ЧИТАЙТЕ ИНТЕРЕСНЫЕ СТАТЬИ, СМОТРИТЕ ВДОХНОВЛЯЮЩИЕ ПОДБОРКИ"
        >
            <ComeBackButton
                href="/maf"
                text="На главную"
            />

            <CategoryContainer />

            <RenderBlogItems blog={blog} />
        </ContainerMainComponent>
    )
}

export default BlogPage