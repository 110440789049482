import React, { useState } from 'react'
import { generatePublicUrl } from '../urlConfig'
import { useNavigate } from 'react-router-dom'
import { goToTop } from '../components/GoToTop'
import { useDispatch, useSelector } from 'react-redux';
import { updateGalleryView } from '../actions';

const Item = ({ item }) => {
    const { user } = useSelector((state) => state.auth)
    const [showButtonBlock, setShowButtonBlock] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleHref = (mainPageId, title) => {
        const payload = {
            galleryId: item.galleryId,
            userId: user?._id
        }
        dispatch(updateGalleryView(payload))
        if (title === "maf") {
            navigate("/maf")
        } else {
            navigate(`/mainPage/${mainPageId}`)
            goToTop("onlyTop", "0")
        }
    }
    const handleClick = () => {
        setShowButtonBlock(!showButtonBlock)
    }
    const titleWithBreaks = item.title.split(' ').join('<br />');
    const titleMarkup = { __html: titleWithBreaks }; // создаем объект, содержащий HTML разметку для использования в dangerouslySetInnerHTML

    return (
        <div className="mainPage__item">
            <div className="mainPage__contentBox"
                onClick={handleClick}
                onMouseLeave={() => setShowButtonBlock(false)}
                onMouseEnter={() => setShowButtonBlock(true)}
            >
                <div className="mainPage__imgContainer">
                    <img src={generatePublicUrl(item.itemPictures[0].img)} alt={item.itemPictures[0]._id} />
                    {!showButtonBlock
                        ? <div className="mainPage__title" dangerouslySetInnerHTML={titleMarkup} />
                        : <div className="mainPage__buttonBlock"
                            onClick={() => handleHref(item._id, item.title)}
                        >
                            Перейти
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default Item