import React from 'react'
import Item from './Item'

const RenderItems = ({
    mainPages,

}) => {
    return (
        <div className="mainPage__row">
            {mainPages.map((item) =>
                <Item
                    key={item._id}
                    item={item}
                />
            )}
        </div>
    )
}

export default RenderItems