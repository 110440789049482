import React from 'react'
import { Button } from '@mui/material'

const ButtonBlock = ({
    onClick,
    type
}) => {

    const renderContent = () => {
        let title, textButton;
        switch (type) {
            case "status":
                title = "Вы уже отправили заявку на изменение данных"
                textButton = "Статус заявки"
                break;
            case "allApplication":
                title = "Все ваши заявки"
                textButton = "Посмотреть"
                break;
            default:
                title = "Вы не можете создать больше одной активной заявки!"
                textButton = "Что в заявке"
        }
        return {
            title,
            textButton
        }
    }

    return (
        <div className="personalInformation__alreadySendBlock">
            <div className="personalInformation__text">
                {renderContent().title}
            </div>
            <Button
                variant="outlined"
                onClick={onClick}
            >
                {renderContent().textButton}
            </Button>
        </div>
    )
}

export default ButtonBlock