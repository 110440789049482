import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

const IconButtonClose = ({
    onClick,
    icon,
    fontSize,
    top
}) => {

    let iconObj = icon ? icon : <CloseIcon fontSize={fontSize} />
    return (
        <div className="iconButtonClose"
            style={{
                top: top ? top : ""
            }}
            onClick={onClick}
        >
            <div className="iconButtonClose__iconContainer">
                {iconObj}
            </div>
        </div>
    )
}

export default IconButtonClose