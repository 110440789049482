import React, { useEffect, useState, useRef } from 'react'
import Backdrop from '../../mainPage/BackDrop';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Button } from '@mui/material';
import RenderFormControl from './RenderFormControl';

const SortProductModal = ({
    visible,
    closeShowMiniModal,
    modalListArray,
    showFilterModalChildren,
    setShowFilterModalChildren,
    sort,
    setSortMaterial,
    sortMaterial,
    setSort,
    pageSize,
    setPageSize
}) => {
    const [titleText, setTitleText] = useState("Фильтры")
    const [sortArray, setSortArray] = useState(null)
    const [showClearButton, setShowClearButton] = useState(false);
    const [changedPropsCount, setChangedPropsCount] = useState(0); // Счетчик измененных свойств

    const initialSortRef = useRef(sort);
    const initialPageSizeRef = useRef(pageSize);

    useEffect(() => {
        let count = 0;
        if (sort !== initialSortRef.current) {
            count++;
        }
        if (pageSize !== initialPageSizeRef.current) {
            count++;
        }
        if (sortMaterial.length > 0) {
            for (let i = 0; i < sortMaterial.length; i++) {
                count++
            }
        }
        setChangedPropsCount(count);
    }, [sort, pageSize, sortMaterial, initialSortRef, initialPageSizeRef]);

    useEffect(() => {
        if (!showFilterModalChildren) {
            setTitleText("Фильтры")
        }
    }, [showFilterModalChildren])

    const handleShowChildren = (item) => {
        setShowFilterModalChildren(!showFilterModalChildren)
        setTitleText(item.text)
        setSortArray(item.itemsArray)
    }
    const handleCloseAllModal = () => {
        setShowFilterModalChildren(false)
        closeShowMiniModal()
    }
    const clearAllSort = () => {
        setPageSize(25)
        setSort("updatedAtMinus")
        setSortMaterial([])
        setChangedPropsCount(0)
    }
    const renderClearAllSortButton = () => {
        return changedPropsCount > 0
            ? <Button
                variant='contained'
                color='error'
                onClick={clearAllSort}
            >
                Очистить все {changedPropsCount}
            </Button>
            : null
    }




    const modalListBlock = (item, index) => {
        return (
            <>
                <div className="sortProduct__modal__list"
                    key={item.id}
                    onClick={() => handleShowChildren(item, index)}
                >
                    <span>
                        {item.text}
                    </span>
                    {
                        titleText === item.text
                            ? <NavigateBeforeIcon />
                            : <KeyboardArrowRightIcon />
                    }
                </div>
                {showContentButton("")}
            </>
        )
    }

    const headerContent = () => {
        return (
            <div className="sortProduct__headerBox">
                <div className="sortProduct__headerBox__content">

                    <div className="sortProduct__headerBox__title">
                        {titleText}
                    </div>
                    {renderClearAllSortButton()}
                </div>
            </div>
        )
    }
    const showContentButton = (sizeButton) => {
        return (
            <div className="sortProduct__modalChildren__showContentButtonBlock">
                {sizeButton === "all"
                    ? <Button
                        variant="outlined"
                        onClick={() => setShowFilterModalChildren(false)}
                    >
                        Назад
                    </Button>
                    : null
                }
                <Button
                    variant="contained"
                    onClick={handleCloseAllModal}
                >
                    Показать
                </Button>
            </div>
        )
    }
    const leftModalChildren = () => {
        return (
            <>
                <Backdrop
                    visible={showFilterModalChildren}
                    closeShowMiniModal={() => setShowFilterModalChildren(false)}
                />

                <div className={`sortProduct__modalChildren ${showFilterModalChildren ? "visible" : ""}`}>
                    {headerContent()}
                    <div className="sortProduct__modalChildren__content">
                        <RenderFormControl
                            sortArray={sortArray}
                            setSort={setSort}
                            sort={sort}
                            titleText={titleText}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            setSortMaterial={setSortMaterial}
                            sortMaterial={sortMaterial}

                        />
                    </div>
                    {showFilterModalChildren && sortArray && (
                        showContentButton("all")
                    )}
                </div>
            </>
        )
    }
    return (
        <div className="sortProduct__modalContainer">
            <Backdrop visible={visible} closeShowMiniModal={closeShowMiniModal} />
            <div className={`sortProduct__modal ${visible ? "visible" : ""}`}>
                {headerContent()}
                <div className="sortProduct__listBox">
                    {modalListArray.map((item, index) =>
                        modalListBlock(item, index)
                    )}
                </div>
            </div>
            {leftModalChildren()}
        </div>
    )
}

export default SortProductModal