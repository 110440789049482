import React, { useRef, useState, useEffect } from 'react'
import PersonalData from './PersonalData';
import ContactData from './ContactData';
import LoginData from './LoginData';
import ContainerAddress from '../../Address/ContainerAddress';
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import RenderDropdownDetails from '../RenderDropdownDetails';
import { useDispatch } from 'react-redux';
import { createApplicationChangeProfile, getApplicationsChangeProfileById } from '../../../actions';
import RenderEditButtonBlock from './RenderEditButtonBlock';
import RenderEditPasswordButton from './RenderEditPasswordButton';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

const ItemsBlock = ({
    handleUpdateRegion,
    applicationObj,
    setShowModalCabinet,
    applications,
    setNumberForm,
    numberForm,
    setLoginForm,
    loginForm,
    passwordForm,
    setPasswordForm,
    nameForm,
    setNameForm,
    setShowEdit,
    handleShow,
    setRegion,
    showEdit,
    region,
    user,
}) => {
    const [variantForm, setVariantForm] = useState("");
    const [applicationId, setApplicationId] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [applicationItem, setApplicationItem] = useState({});

    const [regionSrt, setRegionStr] = useState("");
    const [clicked, setClicked] = useState(null);

    const [userNameForm, setUserNameForm] = useState({
        firstName: "",
        lastName: "",
        birthData: "",
        gender: "",
    });
    const [userNumberForm, setUserNumberForm] = useState({
        contactNumber: "",
        backupNumber: "",
    });

    const [userLoginForm, setUserLoginForm] = useState({
        email: "",
    });

    const dispatch = useDispatch();
    useEffect(() => {
        setUserNameForm({
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            birthData: user.birthData || "",
            gender: user.gender || "",
        });
        setRegionStr(user.region || "");
        setUserNumberForm({
            contactNumber: user.contactNumber || "",
            backupNumber: user.backupNumber || "",
        });
    }, [showEdit]);

    useEffect(() => {
        const { newPassword, passwordCheck } = passwordForm;
        if (newPassword === "" && passwordCheck === "") {
            setPasswordMatch(false);
        }
    }, [passwordForm.newPassword, passwordForm.passwordCheck]);

    useEffect(() => {
        const { newPassword, passwordCheck } = passwordForm;
        if (newPassword !== "" && passwordCheck !== "") {
            setPasswordMatch(newPassword === passwordCheck);
        }
    }, [passwordForm.newPassword, passwordForm.passwordCheck]);

    const filterApplication = (type) => applications.filter((item) => item.variant === type)

    useEffect(() => {
        if (clicked === 0) {
            setApplicationId(filterApplication("personal")?.length > 0
                ? filterApplication("personal")[0]._id
                : null);
        }
        if (clicked === 1) {
            setApplicationId(filterApplication("contact")?.length > 0
                ? filterApplication("contact")[0]._id
                : null);
        }
        // if (clicked === 2) {
        //     setApplicationId(filterApplication("login")?.length > 0
        //         ? filterApplication("login")[0]._id
        //         : null);
        // }
    }, [clicked]);

    useEffect(() => {
        if (applicationId) {
            dispatch(getApplicationsChangeProfileById(applicationId))
        }
    }, [applicationId])

    const handleCloseEditForm = () => {
        setNameForm({
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            birthData: user.birthData || "",
            gender: user.gender || "",
        })
        setNumberForm({
            contactNumber: user.contactNumber || "",
            backupNumber: user.backupNumber || "",
        })
        setLoginForm({
            email: user.email || "",
        })
        setRegionStr("")
        setShowEdit(false)
    }

    const handleSubmit = () => {
        let payload;
        switch (variantForm) {
            case "personal":
                payload = {
                    nameForm,
                    region,
                }
                break;
            case "contact":
                payload = { numberForm }
                break;
            case "password":
                payload = { passwordForm }
                break;
            default:
                payload = { loginForm }
        }
        payload.variant = variantForm
        dispatch(createApplicationChangeProfile(payload))
            .then(() => setClicked(null))
    }

    const items = [
        {
            icon: <PersonIcon size="25px" />,
            text: "Личные данные",
            button: <RenderEditButtonBlock
                filterApplication={filterApplication("personal")}
                handleCloseEditForm={handleCloseEditForm}
                setApplicationItem={setApplicationItem}
                handleUpdateRegion={handleUpdateRegion}
                applicationItem={applicationItem}
                applicationObj={applicationObj}
                handleSubmit={handleSubmit}
                userNameForm={userNameForm}
                buttonText="личные данные"
                variantForm={variantForm}
                regionSrt={regionSrt}
                nameForm={nameForm}
                showEdit={showEdit}
                region={region}
            />,
            content: <PersonalData
                setShowModalCabinet={setShowModalCabinet}
                setVariantForm={setVariantForm}
                setRegion={setRegion}
                setForm={setNameForm}
                showEdit={showEdit}
                form={nameForm}
                region={region}
                user={user}
            />
        },
        {
            icon: <MailOutlineIcon size="25px" />,
            text: "Контакты",
            button: <RenderEditButtonBlock
                filterApplication={filterApplication("contact")}
                handleCloseEditForm={handleCloseEditForm}
                handleUpdateRegion={handleUpdateRegion}
                setApplicationItem={setApplicationItem}
                applicationItem={applicationItem}
                applicationObj={applicationObj}
                userNumberForm={userNumberForm}
                handleSubmit={handleSubmit}
                variantForm={variantForm}
                numberForm={numberForm}
                regionSrt={regionSrt}
                buttonText="контакты"
                showEdit={showEdit}
                clicked={clicked}
                region={region}
            />,
            content: <ContactData
                setVariantForm={setVariantForm}
                setForm={setNumberForm}
                showEdit={showEdit}
                form={numberForm}
            />
        },
        {
            icon: <AlternateEmailIcon size="25px" />,
            text: "Логин и пароль",
            content: <LoginData
                filterApplicationPassword={filterApplication("password")}
                filterApplicationLogin={filterApplication("login")}
                setApplicationItem={setApplicationItem}
                setUserLoginForm={setUserLoginForm}
                setPasswordMatch={setPasswordMatch}
                applicationItem={applicationItem}
                setPasswordForm={setPasswordForm}
                setVariantForm={setVariantForm}
                applicationObj={applicationObj}
                passwordMatch={passwordMatch}
                userLoginForm={userLoginForm}
                handleSubmit={handleSubmit}
                passwordForm={passwordForm}
                setForm={setLoginForm}
                showEdit={showEdit}
                form={loginForm}
                user={user}
            />,
        },
        {
            icon: <MailOutlineIcon size="25px" />,
            text: "Адреса доставки",
            button: <button
                className="dropdownDetails__button"
                onClick={handleShow}
            >
                Редактировать
            </button>,
            content: <div onClick={e => e.stopPropagation()}>
                <ContainerAddress />
            </div>
        },
    ]
    return <RenderDropdownDetails
        handleCloseEditForm={handleCloseEditForm}
        setShowEdit={setShowEdit}
        items={items}
        handleShow={handleShow}
        setClicked={setClicked}
        clicked={clicked}
    />

}

export default ItemsBlock