import React from 'react'

const ProductFeatureDefault = (props) => {
    const { size, height, width, length, weight, description } = props.items


    const featureList = [
        {
            title: 'Размеры Д/В/Ш',
            desc: `${size ? size : "Уточнить"} ${size ? "мм." : ""}`
        },
        {
            title: 'Высота',
            desc: `${height ? height : "Уточнить"} ${height ? "мм." : ""}`
        },
        {
            title: 'Ширина',
            desc: `${width ? width : "Уточнить"} ${width ? "мм." : ""}`
        },
        {
            title: 'Длина',
            desc: `${length ? "от" : ""} ${length ? length : "Уточнить"} ${length ? "мм." : ""}`
        },
        {
            title: 'Вес',
            desc: `${weight ? weight : "Уточнить"} ${weight ? "кг." : ""}`
        },
        {
            title: 'Компания производитель',
            desc: props?.nameCompany
        },
        {
            title: 'Материалы',
            desc: props?.nameCompany
        },
        {
            title: 'Особенности',
            desc: `${description ? description : "Уточнить"} `,
            full: true

        },
    ]
    return (
        <div className="productFeatureDefault">
            <div className="productFeatureDefault__row">
                {featureList.map((item, index) => (
                    <div
                        key={index}
                        className={item.full
                            ? "productFeatureDefault__containerContent full"
                            : "productFeatureDefault__containerContent"}
                    >
                        <div className="productFeatureDefault__title">
                            <span>
                                {item.title}

                            </span>
                        </div>
                        <div className="productFeatureDefault__desc">
                            <span>
                                {item.desc}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="productFeatureDefault__descriptionContainer">
                <h4>Особенности</h4>
                <span>
                    {description ? description : null}
                </span>
            </div>
        </div>
    )
}

export default ProductFeatureDefault