import React from 'react'

const EmptyButton = (props) => {

    return (
        <div className="emptyButton" onClick={props.onClick}>
            {props.icon ? props.icon : null}
            <span>{props.text}</span>
        </div>
    )
}

export default EmptyButton
