import React, { useState, useEffect } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import BtnSlider from '../Slider/BtnSlider';


const FixedButtonsFullScreenImg = ({
    prevSlide,
    nextSlide,
    closeModal,
    items
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderButtonSlider = () => {
        const fixedItemsButtons = [
            {
                handleClick: prevSlide,
                icon: <ArrowBackIosIcon />
            },
            {
                handleClick: nextSlide,
                icon: <ArrowForwardIosIcon />
            },
            {
                handleClick: closeModal,
                icon: <ZoomInMapIcon />
            },
        ]

        if (items.length > 1) {
            if (windowWidth < 500) {
                return (
                    <div className="fixedButtons">
                        <div className="fixedButtons__items">
                            {fixedItemsButtons.map((items, index) =>
                                <div className="fixedButtons__item"
                                    key={index}
                                    onClick={items.handleClick}
                                >
                                    {items.icon}
                                </div>
                            )}
                        </div>
                    </div>
                );
            } else {
                return <>
                    <BtnSlider
                        moveSlide={nextSlide}
                        direction={"next"}
                        fullHeight
                    />
                    <BtnSlider
                        moveSlide={prevSlide}
                        direction={"prev"}
                        fullHeight
                    />
                </>
            }
        } else {
            return null;
        }
    };

    return (
        <div>{renderButtonSlider()}</div>
    )
}

export default FixedButtonsFullScreenImg