import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContacts } from './../actions/components/contact';
import aLotBLogo from '../assets/TestW.png'
import { useNavigate } from 'react-router-dom';
import { goToTop } from '../components/GoToTop';

const FooterMainPage = ({
    background
}) => {
    const { contact } = useSelector((state) => state.contact)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getContacts())
    }, [])
    const navigate = useNavigate()
    const contactItem = contact[0]

    const hrefNavigate = () => {
        goToTop("onlyTop", '0')
        navigate('/')
    }
    const footerMainPageArray = [
        {
            text: "О компании",
            href: "/aboutUs"
        },
        {
            text: "Партнеры",
            href: "/partnerPage"
        },
        {
            text: "Наши точки",
            href: "/"
        },
        {
            text: "Сотрудничество",
            href: "/partnerPage"
        },
        {
            text: "Контакты",
            href: "/contacts"
        },
    ]
    const leftItem = footerMainPageArray.slice(0, 2)
    const rightItem = footerMainPageArray.slice(2, 6)
    return (
        <div className={`footerMainPage ${background ? "background" : ""}`}>
            <div className="footerMainPage__rowContent">
                <div className="footerMainPage__iconSiteBlock top">
                    <img
                        src={aLotBLogo}
                        alt="aLotBLogo"
                        onClick={hrefNavigate}
                    />
                </div>
                <div className="footerMainPage__listBlock">
                    {leftItem.map((item, index) =>
                        <div className="footerMainPage__list"
                            key={index}>
                            <a href={item.href}>
                                {item.text}
                            </a>
                        </div>
                    )}
                </div>
                <div className="footerMainPage__listBlock">
                    {rightItem.map((item, index) =>
                        <div className="footerMainPage__list"
                            key={index}>
                            <a href={item.href}>
                                {item.text}
                            </a>
                        </div>
                    )}
                </div>
                <div className="footerMainPage__listBlock">
                    <div className="footerMainPage__email">
                        <a href={`tel:${contactItem?.number}`}>
                            {contactItem?.number}
                        </a>
                    </div>
                    <div className="footerMainPage__email">
                        <a href={`mailto:${contactItem?.email}`}>
                            {contactItem?.email}
                        </a>
                    </div>
                </div>
                <div className="footerMainPage__iconSiteBlock bottom">
                    <img
                        src={aLotBLogo}
                        alt="aLotBLogo"
                        onClick={hrefNavigate}
                    />
                </div>
            </div>
            <div className="footerMainPage__year">
                © 2023 The site, Inc.
            </div>
        </div>
    )
}

export default FooterMainPage