import React, { useEffect, useState } from 'react';

const Breadcrumb = ({
    categoryName,
    categorySlug,
    namePage,
    titleItem,
    productId,
    namePageHref,
    type,
}) => {
    const [breadcrumbData, setBreadcrumbData] = useState({});

    useEffect(() => {
        if (type === 'page') {
            const existingData = JSON.parse(localStorage.getItem('breadcrumbData')) || {};
            const updatedData = {
                categoryName: categoryName || existingData.categoryName,
                categorySlug: categorySlug || existingData.categorySlug,
                namePage: namePage || existingData.namePage,
                titleItem: titleItem || existingData.titleItem,
                productId: productId || existingData.productId,
                namePageHref: namePageHref || existingData.namePageHref,
            };
            localStorage.setItem('breadcrumbData', JSON.stringify(updatedData));
            setBreadcrumbData(updatedData);
        } else {
            setBreadcrumbData({});
        }
    }, [categoryName, categorySlug, namePage, titleItem, productId, type, namePageHref]);

    return (
        <>
            <div className="breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/maf">Главная страница</a>
                        </li>
                        {namePage && (
                            <li className="breadcrumb-item">
                                <a href={namePageHref ? namePageHref : "/productsCategory/all"}>
                                    {namePage ? namePage : 'Все товары'}
                                </a>
                            </li>
                        )}
                        {
                            !productId ? null : <li className="breadcrumb-item">
                                {!productId ? (
                                    breadcrumbData.categoryName || categoryName
                                ) : (
                                    <a href={`/productsCategory/${categorySlug}`}>
                                        {breadcrumbData.categoryName || categoryName}
                                    </a>
                                )}
                            </li>
                        }
                        <li className="breadcrumb-item active" aria-current="page">
                            {!productId ? (
                                breadcrumbData.titleItem || titleItem
                            ) : (
                                <a href={`/productDetails/${productId}`}>
                                    {breadcrumbData.titleItem || titleItem}
                                </a>
                            )}
                        </li>
                    </ol>
                </nav>
            </div >
        </>
    );
};

export default Breadcrumb;
