import React, { useState } from 'react'
import { generatePublicUrl } from '../../urlConfig';
import MinusPlusComponent from './MinusPlusComponent';
import { useDispatch } from 'react-redux';
import { updateCartItemQuantity } from './../../actions/cart.action';
import MoreIconFunctionalityProduct from '../../components/Search/MoreIconFunctionalityProduct';
import { setShowModalCart } from '../../reducers/cart.reducer';

const CartItem = ({
    checkoutPage,
    cartItem,
    setCurrentId,
    currentId,
}) => {
    const dispatch = useDispatch()
    const [qty, setQty] = useState(cartItem.qty);
    const { _id, name, img, fullName } = cartItem;

    const onQuantityQtyFunc = (e) => {
        dispatch(updateCartItemQuantity({
            quantity: qty,
            productId: _id,
        }))
    };

    return (
        <div className={`renderCartModal__cartRow ${checkoutPage ? "checkoutP" : ""}`}>
            <div className="renderCartModal__mainContentRow">
                <div className="renderCartModal__cartImgContainer">
                    <img src={generatePublicUrl(img)} alt={name} />
                </div>

                <div className="renderCartModal__contentBox">
                    <a
                        href={`/productDetails/${_id}`}
                        className={`renderCartModal__cartName ${checkoutPage ? "checkoutP" : ""}`}>
                        {fullName}
                    </a>
                    {checkoutPage
                        ? <div className="renderCartModal__qty">
                            <input
                                type="text"
                                value={qty}
                            />
                        </div>
                        : <MinusPlusComponent
                            setCurrentId={setCurrentId}
                            currentId={currentId}
                            cartItem={cartItem}
                            onQuantityQtyFunc={onQuantityQtyFunc}
                            setQty={setQty}
                            qty={qty}
                        />}
                </div>
            </div>
            {!checkoutPage
                ? <div className="renderCartModal__settingIconBox">
                    <MoreIconFunctionalityProduct
                        obj={cartItem}
                    />
                </div> : null}
        </div>
    )
}

export default CartItem 