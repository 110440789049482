import axios from "../../helpers/axios"
import { galleryConstants } from '../constants';


export const updateGalleryView = (payload) => {
    return async dispatch => {
        dispatch({ type: galleryConstants.UPDATE_GALLERY_VIEW_REQUEST });
        let res;
        try {
            res = await axios.post("/gallery/galleryView", payload);
            dispatch({
                type: galleryConstants.UPDATE_GALLERY_VIEW_SUCCESS,
                payload: res.data
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: galleryConstants.UPDATE_GALLERY_VIEW_FAILURE,
                payload: { error: res.data.error }
            });
        }
    }
}
export const updateGalleryPictureView = (payload) => {
    return async dispatch => {
        dispatch({ type: galleryConstants.UPDATE_GALLERY_PICTURE_VIEW_REQUEST });
        let res;
        try {
            res = await axios.post("/gallery/galleryPicturesView", payload);
            dispatch({
                type: galleryConstants.UPDATE_GALLERY_PICTURE_VIEW_SUCCESS,
                payload: res.data
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: galleryConstants.UPDATE_GALLERY_PICTURE_VIEW_FAILURE,
                payload: { error: res.data.error }
            });
        }
    }
}

export const getGallery = (fields = "", limit = false) => {
    return async (dispatch) => {
        try {
            dispatch({ type: galleryConstants.GET_GALLERY_REQUEST })
            let url = `gallery/get/${fields}/${limit}`
            // if (sort) {
            //     url = `gallery/get/${fields}/${limit}/${sort}`
            // }
            const res = await axios.get(url);
            if (res.status === 200) {
                const { galleries } = res.data
                dispatch({
                    type: galleryConstants.GET_GALLERY_SUCCESS,
                    payload: {
                        galleries
                    }
                })
            } else {
                dispatch({ type: galleryConstants.GET_GALLERY_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}
export const getGalleryById = (galleryId, limit = false, random = false) => {
    return async dispatch => {
        dispatch({ type: galleryConstants.GET_GALLERY_BY_ID_REQUEST });
        let res;
        try {
            res = await axios.get(`/gallery/getById/${galleryId}/${limit}/${random}`);
            dispatch({
                type: galleryConstants.GET_GALLERY_BY_ID_SUCCESS,
                payload: { gallery: res.data.gallery }
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: galleryConstants.GET_GALLERY_BY_ID_FAILURE,
                payload: { error: res.data.error }
            });
        }
    }
}
// /gallery/getById/:id/:limit?