import React, { useState } from 'react'

import { generatePublicUrl } from '../../../urlConfig';
import RenderDropdownDesc from './RenderDropdownDesc';
import Loader from './../../../components/Loader';
import IconDownUpContainer from './../../../components/Icon/IconDownUpContainer';
import StatusColorText from './../StatusColorText';

const RenderItemOrder = ({
    setShowDetailsProductModal,
    setProdDetailsObj,
    showOrderModal,
    currentId,
    loading,
    setType,
    toggle,
    type,
    user,
    obj,
}) => {

    const objTest = obj.items.length && obj.items.map((item) => item?.productId?.productPictures?.[0]?.img);

    const addressObj = user.address.find((add) => add._id === obj.addressId);


    if (loading) return <Loader />

    return (
        <div className='renderItemOrder'
            onClick={() => toggle(obj._id)}
        >
            <div className="renderItemOrder__mainRow">
                <div className="renderItemOrder__leftBlock">
                    <div className="renderItemOrder__numberDataRow">
                        {obj.orderNumber}
                        <span className="renderItemOrder__numberOrderAdditional">
                            {obj.orderNumberAdditional ? `_${obj.orderNumberAdditional}` : ""}
                        </span>
                    </div>
                    <StatusColorText
                        status={obj.orderStatus}
                    />
                </div>
                <div>
                    <div className="renderItemOrder__imgSvgContainer">
                        {currentId !== obj._id ? <div className="renderItemOrder__imgContainer">
                            {objTest?.map((item) => (
                                <div className="renderItemOrder__imgBox">
                                    <img
                                        src={generatePublicUrl(item)}
                                        alt={obj.numberOrder}
                                    />
                                </div>
                            ))}
                        </div> : null}
                        <IconDownUpContainer up={currentId === obj._id} />
                    </div>
                </div>
            </div>
            {currentId === obj._id && <RenderDropdownDesc
                setShowDetailsProductModal={setShowDetailsProductModal}
                setProdDetailsObj={setProdDetailsObj}
                showOrderModal={showOrderModal}
                addressObj={addressObj}
                currenId={currentId}
                setType={setType}
                type={type}
                obj={obj}
            />
            }
        </div>
    )
}

export default RenderItemOrder