// export const goToTop = (type, top = 130, ref, behavior = "smooth") => {
//     if (type === "onlyTop") {
//         window.scrollTo({
//             top: top,
//             behavior: behavior ? behavior : "smooth",
//         });
//     } else {
//         if (!ref || !ref.current) return;
//         const scrollPosition = ref.current.getBoundingClientRect().top + window.scrollY;
//         if (scrollPosition > top) {
//             window.scrollTo({
//                 top: scrollPosition - 100,
//                 behavior: behavior,
//             });
//         }
//     }
// };

export const goToTop = (type, top = 130, ref, behavior = "smooth") => {
    if (type === "onlyTop") {
        window.scrollTo({
            top: top,
            behavior: behavior ? behavior : "smooth",
        });
    } else {
        if (!ref || !ref.current) return;
        const elementTopPosition = ref.current.getBoundingClientRect().top;
        const scrollPosition = window.pageYOffset + elementTopPosition;
        if (scrollPosition > top) {
            window.scrollTo({
                top: scrollPosition - 50,
                behavior: behavior,
            });
        }
    }
};
