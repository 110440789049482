import React, { useEffect } from 'react'

const PasswordError = ({
    password,
    passwordCheck,
    passwordMatch,
    setPasswordMatch
}) => {

    const validatePasswords = () => {
        setPasswordMatch(password === passwordCheck);
    };

    useEffect(() => {
        validatePasswords(); // проверяем пароли при каждом изменении
    }, [password, passwordCheck]);

    if (password !== "" && passwordCheck !== "") {
        return <div style={{
            position: "absolute",
            bottom: "-35px"
        }}>
            {passwordMatch
                ? <p style={{
                    color: "green",
                }}>Пароли совпали</p>
                : <p style={{
                    color: "red",
                }}>Пароли должны совпадать</p>}
        </div>
    }
}

export default PasswordError