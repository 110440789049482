import React, { useState } from 'react'
import { generatePublicUrl } from '../../urlConfig';
import BtnSlider from './BtnSlider';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import RenderMiniImg from './RenderMiniImg';
import ButtonProduct from './../button/ButtonProduct';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentProductImgSlide } from '../../reducers/product.reducer';

const SliderMiniImg = ({
    itemObj,
    setShowImgButtonBlock,
    setCurrentIdPicture,
    showImgButtonBlock,
    miniImg,
    setShowModalImg
}) => {
    const { currentProductImgSlider } = useSelector((state) => state.product)

    const dispatch = useDispatch()

    const nextSlide = () => {
        if (currentProductImgSlider !== itemObj.length) {
            dispatch(setCurrentProductImgSlide(currentProductImgSlider + 1))
        }
        else if (currentProductImgSlider === itemObj.length) {
            dispatch(setCurrentProductImgSlide(1)
            )
        }
    }

    const prevSlide = () => {
        if (currentProductImgSlider !== 1) {
            dispatch(setCurrentProductImgSlide(currentProductImgSlider - 1))
        }
        else if (currentProductImgSlider === 1) {
            dispatch(setCurrentProductImgSlide(itemObj.length))
        }
    }

    const moveDot = index => {
        dispatch(setCurrentProductImgSlide(index))
    }


    const handleClick = () => {
        setShowModalImg(true)
    }


    return (

        <div>
            <div className="sliderMiniImg">
                {
                    itemObj ? itemObj.map((obj, index) =>
                        <div
                            onMouseEnter={() => setShowImgButtonBlock(true)}
                            onMouseLeave={() => setShowImgButtonBlock(false)}
                            onClick={() => setShowImgButtonBlock(true)}
                            key={index}
                            className={currentProductImgSlider === index + 1
                                ? "sliderMiniImg__slide1 active-anim"
                                : "sliderMiniImg__slide1"}
                            style={{
                                cursor: showImgButtonBlock ? "" : "pointer"
                            }}
                        >
                            <div className="sliderMiniImg__mainContent"
                            >
                                <img
                                    src={generatePublicUrl(obj.img)}
                                />
                                {/* <ZoomIcon
                                    handleClick={handleClick}
                                    type="show"
                                /> */}
                                <div className={`sliderMiniImg__fullZoomButton ${showImgButtonBlock ? "active" : ""}`}>
                                    <div className="sliderMiniImg__zoomButton">
                                        <ZoomOutMapIcon onClick={() => handleClick()} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    ) : " Продукты подгружаются"}
                <BtnSlider moveSlide={nextSlide} direction={"next"} />
                <BtnSlider moveSlide={prevSlide} direction={"prev"} />


                <div className="sliderMiniImg__container-dots">
                    {Array.from({ length: itemObj.length }).map((item, index) => (
                        <div
                            key={index}
                            onClick={() => moveDot(index + 1)}
                            className={currentProductImgSlider === index + 1
                                ? "sliderMiniImg__dot active"
                                : "sliderMiniImg__dot"}
                        ></div>
                    ))}
                </div>

            </div>
            <div className="sliderMiniImg__renderMiniImgBox">
                {
                    miniImg ? <RenderMiniImg
                        itemObj={itemObj}
                        setShowModalImg={setShowModalImg}
                        moveDot={moveDot}
                        slideIndex={currentProductImgSlider}
                        setCurrentIdPicture={setCurrentIdPicture}
                    /> : "Загрузка фото...."
                }
            </div>
        </div>

    )
}

export default SliderMiniImg