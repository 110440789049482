import { elementPaymentPageConstants } from "../../actions/constants";

const initialState = {
  elementPaymentPages: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_SUCCESS:
      state = {
        ...state,
        elementPaymentPages: action.payload.elementPaymentPages,
        loading: false,
      };
      break;
    case elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      return state;
  }
  return state;
};
