import axios from "../../helpers/axios"
import { benefitsConstants } from '../constants';

export const getBenefits = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: benefitsConstants.GET_BENEFITS_REQUEST })
            const res = await axios.get("benefits/get")
            if (res.status === 200) {
                const { benefits } = res.data
                dispatch({
                    type: benefitsConstants.GET_BENEFITS_SUCCESS,
                    payload: {
                        benefits
                    }
                })
            } else {
                dispatch({ type: benefitsConstants.GET_BENEFITS_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}