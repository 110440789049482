import React, { useState } from 'react'
import { generatePublicUrl } from '../../../urlConfig'
import { Button } from '@mui/material'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { toggleSubscriptionCompletion } from '../../../actions'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'

const SubscriptionsContent = ({
    items,
    loading
}) => {

    const [showConfirm, setShowConfirm] = useState(false)
    const [statusModal, setStatusModal] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    // console.log('userSubscriptions', userSubscriptions)
    const dispatch = useDispatch()

    const toggleSubscriberCompletionById = () => {
        dispatch(toggleSubscriptionCompletion(currentId))
            .then(() => {
                setShowConfirm(false);
                const updatedSubscriptions = items.map((subscription) => {
                    if (subscription._id === currentId) {
                        return {
                            ...subscription,
                            isCompleted: !subscription.isCompleted
                        };
                    }
                    return subscription;
                });
                const updatedUser = JSON.parse(localStorage.getItem('user'));
                updatedUser.subscriptions = updatedSubscriptions;
                localStorage.setItem('user', JSON.stringify(updatedUser));
            });
    }

    const handleShowConfirmModal = (item) => {
        setCurrentId(item._id)
        setStatusModal(item.isCompleted)
        setShowConfirm(true)
    }

    const confirmTitle = () => {
        let title, description;
        title = statusModal
            ? "Отписаться от рассылки"
            : "Подписаться на еженедельную рассылку"

        description = statusModal
            ? "Нажмите подтвержить если вы действительно желаете отменить рассылку"
            : "Нажмите подтвержить если вы желаете подключить рассылку"

        return { title, description }
    }
    if (loading) return <Loader />
    return (
        <>
            <div className="mailingLists__subscriptions">
                {items?.map((item) =>
                    <div key={item._id}
                        className="mailingLists__subscriptions__item"
                    >
                        <div className="mailingLists__subscriptions__imgBox">
                            <img
                                src={generatePublicUrl(item?.mainPage?.itemPictures[0]?.img)}
                                alt={item.title}
                            />
                        </div>
                        <div className="mailingLists__subscriptions__contentBox">
                            <div
                                className="mailingLists__subscriptions__title"
                            >
                                {item?.mainPage?.title}
                            </div>
                            <Button
                                style={{
                                    marginTop: "20px",
                                    position: "absolute",
                                    right: "0",
                                    bottom: "0"
                                }}
                                color={item.isCompleted ? "error" : "success"}
                                variant="outlined"
                                // startIcon={<DeleteIcon />}
                                onClick={() => handleShowConfirmModal(item)}
                            >
                                {item.isCompleted ? "Отписаться" : "Подписаться"}

                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <ConfirmDialog
                title={confirmTitle().title}
                description={confirmTitle().description}
                open={showConfirm}
                handleClose={() => setShowConfirm(false)}
                handleSubmit={toggleSubscriberCompletionById}
            />
        </>
    )
}

export default SubscriptionsContent