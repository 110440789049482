import React, { useState, useRef, useEffect } from 'react'

const BlackButton = (props) => {

    return (
        <div className="blackButton" onClick={props.onClick}>
            <span>{props.text}</span>
        </div>
    )
}

export default BlackButton
