import { paymentListConstants, mainActionsConstants } from '../../actions/constants';



const initialState = {
    paymentList: [],
    paymentListObj: {},
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case paymentListConstants.GET_PAYMENT_LIST_REQUEST:
            state = {
                ...state,
                loading: true

            }
            break
        case paymentListConstants.GET_PAYMENT_LIST_SUCCESS:
            state = {
                ...state,
                paymentList: action.payload.paymentList,
                loading: false

            }
            break
        case paymentListConstants.GET_PAYMENT_LIST_FAILURE:
            state = {
                ...state,
                loading: false

            }
            break
        case mainActionsConstants.GET_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                paymentListObj: action.payload?.item,
                loading: false,
            };
            break;
        case mainActionsConstants.GET_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        default:
            return state
    }
    return state
}