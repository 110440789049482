import React from 'react';
import DescriptionItemLine from '../../../components/DescriptionItemLine';
import { characteristicList } from '../../../store/localItems';

const RenderCharacteristic = ({
    productObj,
    title,
    type
}) => {

    return (
        <div className="renderCharacteristic">
            <div className="renderCharacteristic__container">
                <div className="renderCharacteristic__title">
                    {title}
                </div>
                {characteristicList(productObj, type).map((characteristic, index) =>
                    <DescriptionItemLine
                        key={index}
                        item={characteristic}
                    />)}
            </div>
        </div>
    );
};

export default RenderCharacteristic;
