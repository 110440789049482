import React, { useState } from 'react'
import CallMeButton from './CallMeButton';
import RenderCallMeModal from './../renderModal/renderCallMeModal';
import { useSelector } from 'react-redux';

const CallMeComponent = () => {
    const { user, authenticate } = useSelector((state) => state.auth)
    const [showBlock, setShowBlock] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const handleShowBlock = () => {
        setShowBlock(true)
    }
    const handleCloseBlock = () => setShowBlock(false)

    const handleShowModal = () => {
        setShowModal(true)
        setTimeout(() => setShowBlock(false), 100);
    }
    const handleSubmit = () => {
        setShowModal(false)
    }




    return (
        <>
            <CallMeButton
                handleShowModal={handleShowModal}
                setShowModal={setShowModal}
                showBlock={showBlock}
                handleShowBlock={handleShowBlock}
                setShowBlock={setShowBlock}
                handleCloseBlock={handleCloseBlock}
            />
            <RenderCallMeModal
                setShowModal={setShowModal}
                showModal={showModal}
                handleSubmit={handleSubmit}
            />
        </>



    )
}

export default CallMeComponent
