import React, { useState, useRef, useEffect } from 'react'
import BlackButton from './button/BlackButton.jsx';
import RenderModalApplication from './button/RenderModalApplication';
import { motion } from 'framer-motion'
import { upAnimation, downAnimation } from './Animation/Animation';
import { useNavigate } from 'react-router-dom';
import { goToTop } from './GoToTop.js';

const DarkLineComponent = (props) => {
    const {
        titleTop,
        titleCenter,
        titleBottom,
        categorySlug,
        descriptionTop,
        descriptionCenter,
        descriptionBottom
    } = props
    const [showModal, setShowModal] = useState(false)

    const rootEl = useRef(null);
    const navigate = useNavigate()
    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setShowModal(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);

    const handleHrefCategorySlug = () => {
        navigate(categorySlug ? `/productsCategory/${categorySlug}` : "productsCategory/all")
        goToTop("onlyTop",)
    }
    const RenderText = () => {
        return (
            <>

                {!props.buttonBlock ? <>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="darkLineComponent__leftBlock">
                            <h1>
                                {titleTop}
                                <br />
                                {titleBottom}
                            </h1>
                        </div>

                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="darkLineComponent__rightBlock">
                            <p>
                                {descriptionTop}
                                <br />
                                {descriptionCenter}
                                <br />
                                {descriptionBottom}
                            </p>
                        </div>
                    </div>
                </>
                    : <>
                        <div className="col-12">
                            <div className="darkLineComponent__bottomBlock" >
                                <div className="darkLineComponent__bottomBlock__textBlock">
                                    <h4>
                                        {titleTop}
                                    </h4>
                                    <h4>
                                        {titleCenter}
                                    </h4>
                                    <h4 className="darkLineComponent__bottomBlock__categoryHref"
                                        onClick={handleHrefCategorySlug}
                                    >
                                        {titleBottom}
                                    </h4>
                                </div>

                            </div>

                        </div>
                    </>}
            </>
        )
    }
    return (
        <>
            <motion.div
                initial="hidden"
                whileInView="visible"
                className="darkLineComponent"
                ref={rootEl}
                style={{ overflow: "hidden" }}

            >
                <motion.div
                    transition={{ duration: 2 }}
                    variants={downAnimation}
                    className="row">
                    <RenderText />
                </motion.div>
                <motion.div
                    variants={upAnimation}
                    className="darkLineComponent__bottomBlock__button">
                    <div className="blackButton" onClick={() => setShowModal(true)} >
                        <span>Возникли вопросы?</span>
                    </div>
                </motion.div>
            </motion.div>
            <RenderModalApplication
                setShowModal={setShowModal}
                showModal={showModal}
            />
        </>
    )
}

export default DarkLineComponent