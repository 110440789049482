import React, { useState, useEffect } from 'react';
import { hoursItems, minutesItems } from '../../store/localItems';
import FutureHours from '../renderModal/FutureHours';
import RenderNormalTimeContent from '../renderModal/RenderNormalTimeContent';

export const SelectOtherSelect = ({
    setIsCallTomorrow,
    isCallTomorrow,
    setAnyTime,
    setMinutes,
    anyTime,
    minutes,
    setHour,
    setTime,
    hour,
    time,
}) => {

    const [currentTime, setCurrentTime] = useState(new Date());


    useEffect(() => {
        const timerId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000 * 60);
        return () => clearInterval(timerId);
    }, []);

    const handleChange = (event) => {
        setHour(event.target.value);
    };

    const handleChangeTwo = (event) => {
        setMinutes(event.target.value);
    };

    const handleClick = (event) => {
        event.stopPropagation();
    };
    const handleAnyTime = () => {
        setAnyTime(true)
        setHour("Часы")
        setMinutes("Минуты")
    }
    const handleTime = () => {
        setTime(true)
    }
    let futureHours = hoursItems.filter(hour => parseInt(hour) >= currentTime.getHours()
        || (parseInt(hour) === currentTime.getHours() && parseInt(minutesItems[0]) > currentTime.getMinutes()));

    let futureMinutes = minutesItems;

    if (parseInt(hour) === currentTime.getHours()) {
        futureMinutes = minutesItems.filter(minute => parseInt(minute) >= currentTime.getMinutes());
    }

    if (futureHours.length === 0) {
        return <FutureHours
            setIsCallTomorrow={setIsCallTomorrow}
            handleChangeTwo={handleChangeTwo}
            isCallTomorrow={isCallTomorrow}
            handleChange={handleChange}
            minutesItems={minutesItems}
            handleClick={handleClick}
            hoursItems={hoursItems}
            setAnyTime={setAnyTime}
            minutes={minutes}
            anyTime={anyTime}
            setTime={setTime}
            time={time}
            hour={hour}
            handleAnyTime={handleAnyTime}
            handleTime={handleTime}
        />
    }

    if (isCallTomorrow) {
        futureHours = hoursItems;
        futureMinutes = minutesItems;
    }
    return (
        <div className="selectContainer"
            style={{
                display: "block",
                background: "#ededed",
                padding: "10px"
            }}
        >

            <RenderNormalTimeContent
                handleChangeTwo={handleChangeTwo}
                handleAnyTime={handleAnyTime}
                futureMinutes={futureMinutes}
                handleChange={handleChange}
                futureHours={futureHours}
                handleTime={handleTime}
                setAnyTime={setAnyTime}
                anyTime={anyTime}
                minutes={minutes}
                setTime={setTime}
                time={time}
                hour={hour}
            />
        </div >
    );
}
