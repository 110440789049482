import React, { useState, useEffect } from 'react'
import { MaterialInput, ButtonMaterialUi, PersonalDataCheckbox } from './../MaterialUi/index';
import { SelectOtherSelect } from './../MaterialUi/select';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { stylesBox } from '../../scss/styles';
import { createApplicationCallMe } from './../../actions/applicationCallMe';

const RenderCallMeModal = ({
    showModal,
    setShowModal
}) => {
    const { user, authenticate } = useSelector((state) => state.auth)

    const [fullName, setFullName] = useState(user ? user.fullName : "")
    const [number, setNumber] = useState(user ? user.contactNumber : "")
    const [email, setEmail] = useState(user ? user.email : "")
    const [hour, setHour] = useState('Часы');
    const [minutes, setMinutes] = useState('Минуты');
    const [isCallTomorrow, setIsCallTomorrow] = useState(false);

    const [anyTime, setAnyTime] = useState(false);
    const [time, setTime] = useState(false);
    const [personalData, setPersonalData] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        setFullName(user.fullName)
        setNumber(user.contactNumber)
        setEmail(user.email)
    }, [authenticate])

    const handleBoxClick = (e) => {
        e.stopPropagation();
    }

    const clear = () => {
        setHour("Часы")
        setMinutes("Минуты")
        setTime(false)
        setAnyTime(false)
        setPersonalData(false)
        setIsCallTomorrow(false)
        if (authenticate) {
            setFullName(user.fullName)
            setNumber(user.contactNumber)
            setEmail(user.email)
        } else {
            setFullName("")
            setNumber("")
            setEmail("")
        }
    }

    const handleClose = () => {
        setShowModal(false)
        clear()
    }
    const submitForm = () => {
        let payload;
        if (!anyTime && !time) {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 5000);
            return;
        }
        payload = {
            number,
            fullName,
            email,
            hour,
            minutes,
            dataProcessingConsent: personalData
        };
        if (user) {
            payload.userId = user._id
        }
        if (isCallTomorrow) {
            payload.isCallTomorrow = true
        }
        if (anyTime) {
            payload.timeMethod = "anyTime";
            payload.hour = null;
            payload.minutes = null;
        }
        if (time) {
            payload.timeMethod = "exactTime";
        }
        setError(false)
        dispatch(createApplicationCallMe(payload))
            .then(handleClose)
    };

    return (
        <Modal open={showModal} style={{
            zIndex: "99999999999999999999999999999999"
        }}>
            <Box
                sx={stylesBox}
                onClick={handleBoxClick}
                style={{
                    maxHeight: "90%",
                    overflowY: "auto",
                    marginTop: "20px"
                }}
            >
                <h2 className="text-center m-1">Давайте мы вам позвоним?</h2>
                <div style={{
                    margin: "10px 0 30px 0",
                    textAlign: "center",
                    fontSize: "14px",
                    color: "gray",

                }}>Оставьте свой номер, и подберите удобное для вас время</div>
                <div>

                    <MaterialInput
                        requiredColorRed
                        widthProps="90%"
                        blackText
                        type="text"
                        label="Имя и Фамилию"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                    <MaterialInput
                        requiredColorRed
                        widthProps="90%"
                        blackText
                        type="text"
                        label="номер телефона"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                    <MaterialInput
                        requiredColorRed
                        widthProps="90%"
                        blackText
                        type="text"
                        label="свой email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <SelectOtherSelect
                    setIsCallTomorrow={setIsCallTomorrow}
                    isCallTomorrow={isCallTomorrow}
                    handleBoxClick={handleBoxClick}
                    setMinutes={setMinutes}
                    setAnyTime={setAnyTime}
                    setHour={setHour}
                    setTime={setTime}
                    minutes={minutes}
                    anyTime={anyTime}
                    time={time}
                    hour={hour}
                />
                <PersonalDataCheckbox
                    blackText
                    fullWidth
                    data={personalData}
                    setData={setPersonalData}
                />

                {error && <p style={{
                    color: "red"
                }}>Нужно выбрать время</p>}
                <ButtonMaterialUi
                    title="Заказать"
                    BDisabled={personalData ? false : true}
                    onClick={submitForm}
                />

            </Box>
        </Modal>
    )
}

export default RenderCallMeModal
