import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGalleryProducts } from './../../actions/product.action';
import ContainerMainComponent from './../ContainerMainComponent';
import Loader from '../../components/Loader';
import MasonryGallery from '../../components/Gallery/MasonryGallery';
import { galleryItem } from '../../store/localItems';
import useTrafficState from '../../hooks/useTrafficState';

const GalleryPage = () => {
    const dispatch = useDispatch();
    const { galleries, loading, totalPagesGallery } = useSelector((state) => state.product);
    const { user, authenticate } = useSelector((state) => state.auth);
    const [currentPage, setCurrentPage] = useState(1);
    const [allGalleries, setAllGalleries] = useState([]);
    const loadedIdsRef = useRef([]);
    const [trafficState, toggleTrafficState] = useTrafficState();

    const loadGalleryProducts = useCallback((page) => {
        const loadedIds = loadedIdsRef.current;
        dispatch(getGalleryProducts(page, loadedIds, trafficState));
    }, [dispatch, trafficState]);

    useEffect(() => {
        loadGalleryProducts(currentPage);
    }, [currentPage, loadGalleryProducts]);

    useEffect(() => {
        if (galleries?.gallery) {
            setAllGalleries(prevGalleries => [...prevGalleries, ...galleries.gallery]);
            const newLoadedIds = galleries.gallery.map(item => item.picture._id);
            loadedIdsRef.current = [...loadedIdsRef.current, ...newLoadedIds];
        }
    }, [galleries.gallery]);

    const handleLoadMore = useCallback(() => {
        if (currentPage < totalPagesGallery) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    }, [currentPage, totalPagesGallery]);

    const memoizedItems = useMemo(() => allGalleries, [allGalleries]);

    return (
        <ContainerMainComponent
            title={galleryItem.title}
            description={galleryItem.description}
            titleItem={galleryItem.title}
            namePage={galleryItem.namePage}
            bread
        >
            {loading && currentPage === 1 ? (
                <Loader />
            ) : (
                <div className="gallery-container">
                    <MasonryGallery
                        itemsPicture={memoizedItems}
                        user={user}
                        handleLoadMore={handleLoadMore}
                        currentPage={currentPage}
                        totalPagesGallery={totalPagesGallery}
                        loading={loading}
                    />
                    {loading && currentPage > 1 && <Loader />}
                </div>
            )}
        </ContainerMainComponent>
    );
};

export default GalleryPage;
