import { productListLinkConstants } from "../actions/constants";



const initialState = {
    productListLinkObj: {},
    loading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_SUCCESS:
            state = {
                ...state,
                loading: false,
                productListLinkObj: action.payload.productListLink
            };
            break;
        case productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        default:
            return state
    }
    return state
}

// export const setCloseAlertProductListLink = (payload) => ({ type: productListLinkConstants.SET_CLOSE_ALERT, payload })
