import React from 'react'
import ContainerMainComponent from './../ContainerMainComponent';
import RenderVideoYoutube from './RenderVideoYoutube';
import BlackButton from './../../components/button/BlackButton';
// import DarkLineComponent from '../../components/DarkLineComponent';
// import { NavLink } from 'react-router-dom';
import RequestForCalculating from '../../components/RequestForCalculating';

const VideoPortfolioPage = () => {
  const contentVideo = [
    {
      id: '1',
      name: "КАЛУГА 2021",
      description: "Волшебное оформление центральных городских локаций города Калуги! Мы эффектно и ярко украсили город к новогодним праздникам. Разместили масштабные надписи на съезде в город: «Добро пожаловать в новогоднюю столицу!» и «С Новым годом и Рождеством!», вокруг расположились световые фигуры в виде спутников. Похожие «спутники» украсили улицу Кирова и улицу Ленина. Космическая тема продолжилась на улице Гагарина, где расположилась композиция «Парад планет». На бульваре Кирова появился сказочный тоннель, окутанный сияющим светом. Также на улицах и скверах города установили множество объемных фигур. Жителей города порадовали сразу четыре новогодние ели, выполненные в различном стиле.",
      videoHref: "DWQr8_47MC4"
    },
    {
      id: '2',
      name: "КРАСНОГОРСК 2021",
      description: "Волшебное оформление центральных городских локаций города Калуги! Мы эффектно и ярко украсили город к новогодним праздникам. Разместили масштабные надписи на съезде в город: «Добро пожаловать в новогоднюю столицу!» и «С Новым годом и Рождеством!», вокруг расположились световые фигуры в виде спутников. Похожие «спутники» украсили улицу Кирова и улицу Ленина. Космическая тема продолжилась на улице Гагарина, где расположилась композиция «Парад планет». На бульваре Кирова появился сказочный тоннель, окутанный сияющим светом. Также на улицах и скверах города установили множество объемных фигур. Жителей города порадовали сразу четыре новогодние ели, выполненные в различном стиле.",
      videoHref: "DWQr8_47MC4"
    },
    {
      id: '1',
      name: "САНКТ-ПЕТЕРБУРГ 2021",
      description: "Волшебное оформление центральных городских локаций города Калуги! Мы эффектно и ярко украсили город к новогодним праздникам. Разместили масштабные надписи на съезде в город: «Добро пожаловать в новогоднюю столицу!» и «С Новым годом и Рождеством!», вокруг расположились световые фигуры в виде спутников. Похожие «спутники» украсили улицу Кирова и улицу Ленина. Космическая тема продолжилась на улице Гагарина, где расположилась композиция «Парад планет». На бульваре Кирова появился сказочный тоннель, окутанный сияющим светом. Также на улицах и скверах города установили множество объемных фигур. Жителей города порадовали сразу четыре новогодние ели, выполненные в различном стиле.",
      videoHref: "DWQr8_47MC4"
    },
    {
      id: '1',
      name: "МУРМАНСК 2021",
      description: "Волшебное оформление центральных городских локаций города Калуги! Мы эффектно и ярко украсили город к новогодним праздникам. Разместили масштабные надписи на съезде в город: «Добро пожаловать в новогоднюю столицу!» и «С Новым годом и Рождеством!», вокруг расположились световые фигуры в виде спутников. Похожие «спутники» украсили улицу Кирова и улицу Ленина. Космическая тема продолжилась на улице Гагарина, где расположилась композиция «Парад планет». На бульваре Кирова появился сказочный тоннель, окутанный сияющим светом. Также на улицах и скверах города установили множество объемных фигур. Жителей города порадовали сразу четыре новогодние ели, выполненные в различном стиле.",
      videoHref: "DWQr8_47MC4"
    },
  ]
  const items = {
    title: "ВИДЕОПОРТФОЛИО",
    description: "Наша компания реализует комплексные проекты по праздничному оформлению и благоустройству городских пространств. Мы воплотили несколько сотен проектов в крупных мегаполисах и небольших городах. Наш опыт работы позволяет предлагать лучшие решения в сфере праздничного оформления с учетом архитектурных и исторических особенностей городов. Мы работаем на рынке 2 года, и за это время была сформирована внушительная материально-техническая база, которая помогает нам эффективно воплощать каждый проект «под ключ». Предлагаем вам ознакомиться с видеопрезентациями наших комплексных проектов по праздничному оформлению городов.",
    buttonText: "посмотреть видео проектов",
    titleBlockText: "Наша основная задача — создание единой свето-цветовой среды города и комфортных условий для его жителей. Каждый проект оформления разрабатывается индивидуально с учетом всех требований локации. Производственная и материально-техническая база позволяют нам реализовывать все этапы работ от создания дизайн-концепции до выполнения монтажа и последующего технического обслуживания конструкций."
  }






  return (
    <ContainerMainComponent
      title={items.title}
    // description={items.description}
    >
      <div className="videoPortfolioPage">
        <div className="videoPortfolioPage__headerDescription">
          <p>{items.description} </p>
        </div>
        {contentVideo.map((item) => (
          <div className="videoPortfolioPage__container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                <RenderVideoYoutube videoHref={item.videoHref} />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                <div className="videoPortfolioPage__contentBlock">
                  <h2>
                    {item.name}
                  </h2>
                  <p>
                    {item.description}
                  </p>
                  <a href="/portfolioPage">
                    <BlackButton text="смотреть проект" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <RequestForCalculating />
    </ContainerMainComponent>
  )
}

export default VideoPortfolioPage