import { desiresConstants } from "../actions/constants";

const initState = {
    desiresItems: [],
    updatingDesires: false,
    showModal: false,
    showAlert: false,
    textAlert: "",
    variantAlert: "success",
    error: null
};

export default (state = initState, action) => {
    switch (action.type) {
        case desiresConstants.VISIBLE_DESIRES_LIKE_ICON:
            state = {
                ...state,
                desiresItems: state.desiresItems?.filter((desires) => desires._id === action.payload._id ? action.payload : desires),
                updatingDesires: false,
            }
            break;
        case desiresConstants.CLOSE_ALERT_TO_DESIRES_SUCCESS:
            state = {
                ...state,
                showAlert: !state.showAlert
            }
            break;
        case desiresConstants.ADD_TO_DESIRES_SUCCESS:
            state = {
                ...state,
                textAlert: "Добавлен в список желаний!",
                showAlert: true,
                variantAlert: "success",
            }
            break;
        case desiresConstants.ADD_TO_DESIRES_FAILURE:
            state = {
                ...state,
                variantAlert: "warning",
                textAlert: "Уже добавлен в список!",
                showAlert: true,
            }
            break;
        case desiresConstants.GET_TO_DESIRES_REQUEST:
            state = {
                ...state,
                updatingDesires: true,
            }
            break;
        case desiresConstants.GET_TO_DESIRES_SUCCESS:
            state = {
                ...state,
                desiresItems: action.payload.desiresItems,
                updatingDesires: false,
            }
            break;
        case desiresConstants.GET_TO_DESIRES_FAILURE:
            state = {
                ...state,
                updatingDesires: false,
                error: action.payload.error
            }
            break;
        case desiresConstants.REMOVE_DESIRES_ITEM_REQUEST:
            state = {
                ...state,
                updatingDesires: true,

            }
            break;
        case desiresConstants.REMOVE_DESIRES_ITEM_SUCCESS:
            state = {
                ...state,
                updatingDesires: false,
                textAlert: "Успешно удален!",
                showAlert: true,
            }
            break;
        case desiresConstants.REMOVE_DESIRES_ITEM_FAILURE:
            state = {
                ...state,
                updatingDesires: false,
                variantAlert: "warning",
                textAlert: "Проблемы с удалением!",
                showAlert: true,
            }
            break;

        case desiresConstants.SHOW_MODAL_DESIRES:
            state = {
                ...state,
                showModal: !state.showModal
            }
            break;
        case desiresConstants.RESET_DESIRES:
            state = {
                ...initState
            }
    }
    return state;
}

export const setShowModalDesires = (payload) => ({ type: desiresConstants.SHOW_MODAL_DESIRES, payload: payload })
export const setShowAlertDesires = (payload) => ({ type: desiresConstants.CLOSE_ALERT_TO_DESIRES_SUCCESS, payload: payload })