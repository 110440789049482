import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmDialog = ({
    open,
    handleClose,
    title,
    description,
    handleSubmit
}) => {
    return (
        <Dialog
            style={{
                zIndex: "9999999999999999999"
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description ? description : "Если вы подтвердите это действие то вернуть его сможет только администратор!"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Закрыть
                </Button>
                <Button onClick={handleSubmit}>
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog