import { mainActionsConstants } from "../actions/constants";



const initialState = {
    mainPages: [],
    mainPageObj: {},
    loading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case mainActionsConstants.GET_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                mainPageObj: action.payload.item,
                loading: false
            };
            break;
        case mainActionsConstants.GET_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case mainActionsConstants.GET_ALL_ITEMS_REQUEST:
            state = {
                ...state,
                loading: true
            };
        case mainActionsConstants.GET_ALL_ITEMS_SUCCESS:
            state = {
                ...state,
                mainPages: action.payload?.items,
                loading: false,
            };
            break;
        case mainActionsConstants.GET_ALL_ITEMS_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        default:
            return state
    }
    return state
}