import { architectSkillsConstants } from '../../actions/constants';



const initialState = {
    architectSkills: [],
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case architectSkillsConstants.GET_ARCHITECT_SKILLS_REQUEST:
            state = {
                ...state,
                loading: true

            }
            break
        case architectSkillsConstants.GET_ARCHITECT_SKILLS_SUCCESS:
            state = {
                ...state,
                architectSkills: action.payload.architectSkills,
                loading: false

            }
            break
        case architectSkillsConstants.GET_ARCHITECT_SKILLS_FAILURE:
            state = { 
                ...state,
                loading: false

            }
            break
    }
    return state
}