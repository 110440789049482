import React from 'react'
import ContainerCabinetComponent from '../../ContainerCabinetComponent'
import { useDispatch, useSelector } from 'react-redux'
import TitleHeaderComponent from '../../../components/TitleHeaderComponent'
import { updateTrafficSavings } from '../../../actions'
import AlertComponent from '../../../components/Alert'
import Loader from '../../../components/Loader'
import { setCloseAlertAuth } from '../../../reducers/auth.reducer'
import useTrafficState from '../../../hooks/useTrafficState'
import RenderSettingLists from './RenderSettingLists'

const SettingPage = () => {
    const {
        loading,
        variantAlert,
        showAlert,
        message,
    } = useSelector((state) => state.auth)

    const [trafficState, toggleTrafficState] = useTrafficState();

    return (
        <ContainerCabinetComponent
            title="Настройки сайта"
        >
            <RenderSettingLists />
            <AlertComponent
                dispatchCloseAlert={setCloseAlertAuth}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
        </ContainerCabinetComponent>
    )
}

export default SettingPage