import axios from "../../helpers/axios"
import { portfolioConstants } from '../constants';


export const getPortfolio = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: portfolioConstants.GET_ALL_PORTFOLIO_REQUEST })
            const res = await axios.get("portfolio/get")
            if (res.status === 200) {
                const { portfolio } = res.data
                dispatch({
                    type: portfolioConstants.GET_ALL_PORTFOLIO_SUCCESS,
                    payload: {
                        portfolio
                    }
                })
            } else {
                dispatch({ type: portfolioConstants.GET_ALL_PORTFOLIO_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const getPortfolioDetailsById = (payload) => {
    return async dispatch => {
        dispatch({ type: portfolioConstants.GET_PORTFOLIO_DETAILS_BY_ID_REQUEST });
        let res;
        try {
            const { portfolioId } = payload.params;
            res = await axios.get(`/portfolio/portfolioDetails/${portfolioId}`);
            console.log(res);
            dispatch({
                type: portfolioConstants.GET_PORTFOLIO_DETAILS_BY_ID_SUCCESS,
                payload: { portfolioDetails: res.data.portfolio }
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: portfolioConstants.GET_PORTFOLIO_DETAILS_BY_ID_FAILURE,
                payload: { error: res.data.error }
            });
        }
    }
}