import React, { useState, useEffect } from 'react'
import CompanyNumber from '../CompanyNumber';
import CompanyTitle from '../CompanyTitle';
import { MaterialInput } from '../MaterialUi';
import SocialMediaIcons from './../miniButtons/SocialMediaIcons';
import SendIcon from '@mui/icons-material/Send';
import Sidebar from './../Sidebar';
import SeasonsCatalog from './../SeasonsCatalog';
import OrderCatalogButton from './../button/OrderCatalogButton';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCategory } from './../../actions/category.action';
import { getContacts } from '../../actions';
import Loader from '../Loader';

const FooterComponent = () => {

    const [email, setEmail] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const [sliceCategory, setSliceCategory] = useState("")

    const { categories, loading } = useSelector((state) => state.category)
    const contact = useSelector((state) => state.contact.contact)
    const { authenticate, user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    useEffect(() => {
        setEmail(user.email)
    }, [authenticate])

    useEffect(() => {
        dispatch(getAllCategory())
        dispatch(getContacts())

    }, [])
    useEffect(() => {
        if (categories.length > 0) {
            setCategoryName(categories[0].name)
            setSliceCategory(categories.slice(0, 1))
        }
    }, [loading, categories])

    return (
        <div className="footer">
            <div className="footer__header">
                <div className="footer__headerTitle">
                    <h2>
                        БУДЬТЕ В КУРСЕ НОВИНОК,
                        <br />
                        ВДОХНОВЕНИЯ И СОБЫТИЙ!
                    </h2>
                </div>

                <div className="footer__headerInput">
                    <div className="footer__headerInputContainer">
                        <MaterialInput
                            type="text"
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <a className="footer__headerSendButton"
                            href="/emailNewsletter"
                        >
                            <SendIcon fontSize='medium' />
                        </a>
                    </div>
                </div>
            </div>
            <div className="row  footer__row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                </div>
                <div className="col-10 footer__whiteBlock">
                    <div className="footer__whiteBlock-content">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 footer__companyName">
                                <CompanyTitle />
                            </div>
                            <div className="col-6 footer__categoryTitle">
                                {categoryName}
                            </div>
                        </div>
                        <div className="row footer__row">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 footer__sidebar">
                                <Sidebar blackColor />
                            </div>
                            {sliceCategory ? sliceCategory.map((item, index) => (
                                <div div className="col-6 footer__displayNone" key={index} >
                                    <SeasonsCatalog
                                        blackColor
                                        item={item}
                                        title={""}
                                        obj={item.children}
                                        noneTitle
                                    />
                                </div>
                            )) : "Loading...."}
                        </div>

                        {!contact.length > 0 ? <Loader /> : contact.map((obj) => (
                            <div className="footer__addressPhoneBox"
                                key={obj._id}>
                                <div className="footer__infoLeft ">
                                    <h5>
                                        {obj.cityIndex}  {obj.city} {obj.country}
                                    </h5>
                                </div>
                                <div className="footer__infoRight">
                                    <span>
                                        {obj.number}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="footer__socialMediaIcons">
                        <SocialMediaIcons />
                    </div>
                </div>
            </div>
            <div className="footer__allRightsReserved">
                <p>© 2024 ALOTB. Все права защищены.</p>
            </div>
        </div>
    )
}

export default FooterComponent
