import React, { useState, useEffect } from 'react'
import { PersonalDataCheckbox, ButtonMaterialUi } from '../MaterialUi/index';
import UserDataInput from './../button/UserDataInput';
import { createApplication } from './../../actions/application.action';
import { useDispatch } from 'react-redux';
import BRenderAddPictures from './../button/BRenderAddPictures';


const RenderAskQModal = ({
    setProductDetailsList,
    productId,
    colorModal,
    title,
    auth
}) => {
    // const [showModal, setShowModal] = useState(false)

    const dispatch = useDispatch()

    const [applicationForm, setApplicationForm] = useState({
        fullName: auth.authenticate ? auth.user.fullName : "",
        descriptionProblem: "",
        nameCompany: "",
        email: auth.authenticate ? auth.user.email : "",
        contactNumber: auth.authenticate ? auth.user.contactNumber : "",
        userId: auth.authenticate ? auth.user._id : null,
        productId: productId !== undefined ? productId : null,
    })
    const [dataProcessingConsent, setDataProcessingConsent] = useState(false)
    const [samplePhoto, setSamplePhoto] = useState([])

    const clear = () => {
        setSamplePhoto([])
        setDataProcessingConsent(false)
        setApplicationForm({
            descriptionProblem: "",
            nameCompany: ""
        })
        dispatch(setProductDetailsList("Все о товаре"))

    }
    const submitApplicationForm = () => {
        const form = new FormData();
        form.append("fullName", applicationForm.fullName);
        form.append("descriptionProblem", applicationForm.descriptionProblem);
        form.append("nameCompany", applicationForm.nameCompany);
        form.append("email", applicationForm.email);
        form.append("contactNumber", applicationForm.contactNumber);
        form.append("dataProcessingConsent", dataProcessingConsent);
        form.append("productId", productId ? productId : null);
        form.append("userId", applicationForm.userId);
        for (let pic of samplePhoto) {
            form.append("samplePhoto", pic);
        }
        dispatch(createApplication(form))
            .then(() => clear())
    };
    const setUserObj = () => {
        setApplicationForm({
            fullName: auth.user.fullName,
            descriptionProblem: "",
            nameCompany: "",
            contactNumber: auth.user.contactNumber,
            email: auth.user.email,
            userId: auth.authenticate ? auth.user._id : null,
            productId: productId !== undefined ? productId : null,
        })
    }
    useEffect(() => {
        setUserObj()
    }, [auth.authenticate])

    return (
        <>
            <div className={`renderAskQModal ${colorModal === "black" ? "black" : ""}`}>
                <h1
                    style={{
                        color: colorModal === "black" ? "white" : "black"
                    }}
                    className="text-center pb-4">{title ? title : "Задать вопрос"}</h1>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="renderAskQModal__inputContainer">
                            <UserDataInput
                                setForm={setApplicationForm}
                                form={applicationForm}
                                blackText={colorModal ? false : true}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div>
                            <textarea
                                id="One"
                                placeholder='Коментарии'
                                name={applicationForm.descriptionProblem}
                                value={applicationForm.descriptionProblem}
                                onChange={(e) => setApplicationForm({ ...applicationForm, descriptionProblem: e.target.value })}
                            />
                        </div>
                        <BRenderAddPictures
                            setPictures={setSamplePhoto}
                            pictures={samplePhoto}
                        />
                        <div className="renderAskQModal__checkboxBlock">
                            <PersonalDataCheckbox
                                data={dataProcessingConsent}
                                setData={setDataProcessingConsent}
                                fullWidth />
                        </div>
                        <ButtonMaterialUi
                            BDisabled={dataProcessingConsent ? false : true}
                            title="Задать вопрос"
                            onClick={submitApplicationForm}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export default RenderAskQModal