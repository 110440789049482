import { architectLiConstants } from '../../actions/constants';



const initialState = {
    architectLi: [],
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case architectLiConstants.GET_ARCHITECT_LI_REQUEST:
            state = {
                ...state,
                loading: true

            }
            break
        case architectLiConstants.GET_ARCHITECT_LI_SUCCESS:
            state = {
                ...state,
                architectLi: action.payload.architectLi,
                loading: false

            }
            break
        case architectLiConstants.GET_ARCHITECT_LI_FAILURE:
            state = {
                ...state,
                loading: false

            }
            break
        default:
            return state
    }
    return state
}