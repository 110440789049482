import React from 'react'
import { FullModal } from './../../../components/MaterialUi/FullModal';
import { Row, Col } from 'react-bootstrap';
import ContentLabelP from './../../../components/ContentLabelP';

const RenderDetailsOrderProductModal = ({
    visible,
    setShow,
    onClose,
    item,
}) => {


    if (!visible) return null
    const additionalArrayUser = item.additionalFieldItems.filter((obj) => obj.afViewType === "publicly")
    return (
        <FullModal
            type="miniModal"
            minH
            typeCloseIcon={"insideCloseIcon"}
            visible={visible}
            onClose={onClose}
            setShowModal={setShow}
        >
            <div className="renderDetailsOrderProductModal">
                <div className="renderDetailsOrderProductModal__mainContent">
                    <h4>Основные параметры</h4>
                    <Row>
                        <ContentLabelP labelText="Длина" valueText={item.length} valueEndText="мм" />
                        <ContentLabelP labelText="Высота" valueText={item.height} valueEndText="мм" />
                    </Row>
                    <Row>
                        <ContentLabelP labelText="Ширина" valueText={item.width} valueEndText="мм" />
                        <ContentLabelP labelText="Цена" valueText={item.bayerPrice} valueEndText="Валюта" />
                    </Row>
                    <Row>
                        <ContentLabelP labelText="Цвет" valueText={item.width} />
                    </Row>
                </div>
                <div className="renderDetailsOrderProductModal__additionalItems">
                    <h4>Дополнительные параметры</h4>
                    {additionalArrayUser.length > 0 ? <>
                        <div className="renderDetailsOrderProductModal__additionalItemHeader">
                            <div className="renderDetailsOrderProductModal__additionalTitleHeader">
                                Название
                            </div>
                            <div className="renderDetailsOrderProductModal__additionalDescriptionHeader">
                                Описание
                            </div>
                        </div>
                        {additionalArrayUser.map((item) => (
                            <div className="renderDetailsOrderProductModal__additionalItem"
                                key={item._id}
                            >
                                <div className="renderDetailsOrderProductModal__additionalTitle">
                                    {item.afTitle}
                                </div>
                                <div className="renderDetailsOrderProductModal__additionalDescription">
                                    {item.afDescription}
                                </div>
                            </div>
                        ))}
                    </>
                        : <h5>Отсутствуют</h5>
                    }

                </div>
            </div>

        </FullModal>
    )
}

export default RenderDetailsOrderProductModal