import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import FileUploadModal from '../ApplicationPage/FileUploadModal';
import { filterApplicationArray } from './filterApplicationArray';

const RenderEditButtonBlock = ({
    handleCloseEditForm,
    applicationItem,
    setApplicationItem,
    handleUpdateRegion,
    filterApplication,
    applicationObj,
    userNumberForm,
    userLoginForm,
    userNameForm,
    handleSubmit,
    variantForm,
    numberForm,
    buttonText,
    regionSrt,
    loginForm,
    nameForm,
    showEdit,
    clicked,
    // locked,
    region,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [typeModal, setTypeModal] = useState("");
    const [plusItems, setPlusItems] = useState(false);

    const nameFormStr = JSON.stringify(nameForm);
    const userNameFormStr = JSON.stringify(userNameForm);
    const numberFormStr = JSON.stringify(numberForm);
    const userNumberFormStr = JSON.stringify(userNumberForm);

    const applicationSuccess = filterApplicationArray("success", filterApplication)
    const applicationReject = filterApplicationArray("reject", filterApplication)

    useEffect(() => {
        const allItemsCount = applicationSuccess.length + applicationReject.length === filterApplication.length
        if (allItemsCount) {
            setPlusItems(true)
        }
    }, [applicationSuccess, applicationReject])

    const handleShowStatusModal = (type, type1, item) => {
        if (item) {
            setApplicationItem(item)
        }
        setIsModalOpen(true)
        setTypeModal(type)
    }
    const disabledVariant = () => {
        let variant;
        switch (variantForm) {
            case "personal":
                if (regionSrt !== region.value) {
                    variant = true;
                } else {
                    variant = nameFormStr !== userNameFormStr;
                }
                break;
            default:
                variant = numberFormStr !== userNumberFormStr;
        }
        return variant;
    }
    return (
        <>
            {
                plusItems
                    ? <>
                        <Button
                            style={{
                                margin: "5px"
                            }}
                            disabled={!showEdit ? false : disabledVariant() ? false : true}
                            variant="outlined"
                            onClick={!showEdit ? handleUpdateRegion : handleSubmit}
                        >
                            {!showEdit ? `Изменить ${buttonText}` : "Сохранить правки"}
                        </Button>
                        {
                            showEdit ? (
                                <Button
                                    style={{
                                        margin: "5px"
                                    }}
                                    variant="outlined"
                                    onClick={handleCloseEditForm}
                                >
                                    Назад
                                </Button>
                            ) : null
                        }
                    </>
                    : null
            }
            {!plusItems
                ? <div className="personalInformation__alreadySendBlock">
                    <div className="personalInformation__text">
                        Вы уже отправили заявку на изменение данных
                    </div>
                    <Button onClick={() => handleShowStatusModal("statusModal")}>Статус заявки</Button>
                </div>
                : null
            }
            {!plusItems
                ? <div className="personalInformation__alreadySendBlock">
                    <div className="personalInformation__text">
                        Вы не можете создать больше одной активной заявки!
                    </div>
                    <Button onClick={() => handleShowStatusModal(clicked === 1
                        ? "applicationContact"
                        : "applicationPersonal")}
                    >
                        Что в заявке
                    </Button>
                </div>
                : null
            }
            {filterApplication.length > 0
                ? <div className="personalInformation__alreadySendBlock">
                    <div className="personalInformation__text">
                        Все ваши заявки
                    </div>
                    <Button onClick={() => handleShowStatusModal("allApplication")}
                    >
                        Посмотреть
                    </Button>
                </div>

                : null
            }

            <FileUploadModal
                applicationItem={applicationItem}
                itemObjStatus={applicationObj?.applicationStatus}
                handleShowStatusModal={handleShowStatusModal}
                filterApplication={filterApplication}
                onClose={() => setIsModalOpen(false)}
                itemObj={applicationObj}
                typeModal={typeModal}
                isOpen={isModalOpen}
            />
        </>
    )
};

export default RenderEditButtonBlock