import { userConstants } from "./constants";
import axios from "../helpers/axios";
import { setUser } from "../reducers/auth.reducer";


export const updateSiteThemeType = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: userConstants.USER_UPDATE_SITE_THEME_TYPE_REQUEST });
      const res = await axios.post("/user/updateSiteThemeType", payload);
      if (res.status === 200) {
        dispatch({
          type: userConstants.USER_UPDATE_SITE_THEME_TYPE_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: userConstants.USER_UPDATE_SITE_THEME_TYPE_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: userConstants.USER_UPDATE_SITE_THEME_TYPE_FAILURE,
        payload: { error: error.message }
      });
    }
  };
};
export const updateTrafficSavings = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: userConstants.USER_UPDATE_TRAFFIC_SAVING_REQUEST });
      const res = await axios.post("/user/updateTrafficSavings", payload);
      if (res.status === 200) {
        dispatch({
          type: userConstants.USER_UPDATE_TRAFFIC_SAVING_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: userConstants.USER_UPDATE_TRAFFIC_SAVING_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const toggleSubscriptionCompletion = (subscriptionId) => {
  return async (dispatch) => {
    try {
      const res = await axios.put(`/user/subscriptions/${subscriptionId}`);
      dispatch({ type: userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateRegion = (regionId) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch(`/user/${regionId}`);
      dispatch({ type: userConstants.UPDATE_USER_REGION_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: userConstants.UPDATE_USER_REGION_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: userConstants.UPDATE_USER_REGION_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAddress = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/user/getAddress");
      dispatch({ type: userConstants.GET_USER_ADDRESS_REQUEST });
      if (res && res.status === 200) {
        const {
          userAddress: { address },
        } = res.data;
        dispatch({
          type: userConstants.GET_USER_ADDRESS_SUCCESS,
          payload: { address },
        });
      } else {
        dispatch({
          type: userConstants.GET_USER_ADDRESS_FAILURE,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addAddress = (payload) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/user/address/create", { payload });
      dispatch({ type: userConstants.ADD_USER_ADDRESS_REQUEST });
      if (res.status === 201) {
        const {
          address: { address },
          addressObj,
        } = res.data;
        dispatch({
          type: userConstants.ADD_USER_ADDRESS_SUCCESS,
          payload: { address, addressObj },
        });
      } else {
        const { error } = res.data;
        dispatch({
          type: userConstants.ADD_USER_ADDRESS_FAILURE,
          payload: { error },
        });
      }
      dispatch(getAddress())
    } catch (error) {
      console.log(error);
    }
  };
};


export const subscribeEAUser = (id) => async (dispatch) => {
  try {
    const data = await axios.get(`/subscribeEAUser/${id}`)
    dispatch({
      type: userConstants.UPDATE_SUBSCRIBE_USER_BY_ID, payload: data
    })
    dispatch(setUser(data))
  } catch (error) {
    console.log(error);
  }
}