import React, { useState } from 'react'
import { addToCart, addToDesires, removeCartItem, removeDesiresItem, updateProductView } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import MoreIconButtonCurrentId from '../button/MoreIconButtonCurrentId';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { setShowModalCart } from '../../reducers/cart.reducer';

const MoreIconFunctionalityProduct = ({
    obj,
}) => {
    const [closeMenu, setCloseMenu] = useState(false);
    const { cartItems } = useSelector((state) => state.cart)
    const { desiresItems } = useSelector((state) => state.desires)
    const dispatch = useDispatch()

    const handleAddToCart = (item) => {
        dispatch(setShowModalCart())
        const payload = {
            _id: item._id,
            fullName: item.fullName,
            img: item.reducedImage
        }
        dispatch(addToCart(payload))
    }

    const handleRemoveCartItem = (productId) => {
        dispatch(removeCartItem({ productId }))
        setCloseMenu(false);
    }
    const handleDisableLike = (productId) => {

        dispatch(removeDesiresItem({ productId }))
    }
    const handleAddToDesires = (product) => {
        dispatch(addToDesires({ _id: product._id }))
    }
    const itemSettingArray = [
        {
            title: "Добавить в желаемое",
            icon: <FavoriteBorderIcon />,
            onClick: handleAddToDesires,
            onClickDisable: handleDisableLike,
            activeAddButtonItems: desiresItems,
            titleAdd: "Уже добавлено"
        },
        {
            title: "Добавить в корзину",
            icon: <ShoppingCartIcon />,
            activeAddButtonItems: cartItems,
            onClick: handleAddToCart,
            onClickDisable: handleRemoveCartItem,
            titleAdd: "Удалить с корзины"
        }
    ]

    return (
        <div>
            <MoreIconButtonCurrentId
                objId={obj._id}
                item={obj}
                itemArray={itemSettingArray}
                closeMenu={closeMenu}
                setCloseMenu={setCloseMenu}
            />
        </div>
    )
}

export default MoreIconFunctionalityProduct