import { authConstants, userConstants } from "../actions/constants";

const initState = {
    token: null,
    searchShow: false,
    user: {
        firstName: '',
        lastName: '',
        email: '',
        picture: '',
        trafficSavings: true,
        siteThemeType: "White"
    },
    authenticate: false,
    authenticating: false,
    loading: false,
    error: null,
    showAlertAuth: false,
    variantAlertAuth: "",
    textAlertAuth: "",
    showAlert: false,
    variantAlert: "",
    visibleButtonBuy: false,
    showModalUser: false,
    showSettingModal: false
};

export default (state = initState, action) => {
    switch (action.type) {
        case userConstants.USER_UPDATE_SITE_THEME_TYPE_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case userConstants.USER_UPDATE_SITE_THEME_TYPE_SUCCESS:
            const updatedSiteThemeType = action.payload.user.siteThemeType;
            localStorage.setItem('user', JSON.stringify({
                ...state.user,
                siteThemeType: updatedSiteThemeType
            }));
            return {
                ...state,
                user: {
                    ...state.user,
                    siteThemeType: updatedSiteThemeType
                },
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
            };
            break;
        case userConstants.USER_UPDATE_SITE_THEME_TYPE_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false,
            };
            break;

        case userConstants.USER_UPDATE_TRAFFIC_SAVING_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case userConstants.USER_UPDATE_TRAFFIC_SAVING_SUCCESS:
            const updatedTrafficSavings = action.payload.user.trafficSavings;
            localStorage.setItem('user', JSON.stringify({
                ...state.user,
                trafficSavings: updatedTrafficSavings
            }));
            return {
                ...state,
                user: {
                    ...state.user,
                    trafficSavings: updatedTrafficSavings
                },
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
            };
            break;
        case userConstants.USER_UPDATE_TRAFFIC_SAVING_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false,
            };
            break;
        case userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
                user: action.payload.user
            };
            break;
        case userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false,
            };
            break;
        case userConstants.UPDATE_USER_REGION_REQUEST:
            state = {
                ...state,
                loading: false,
            }
            break;
        case userConstants.UPDATE_USER_REGION_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                showAlert: true,
                variantAlert: "success"
            }
            break;
        case userConstants.UPDATE_USER_REGION_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                showAlert: true,
                variantAlert: "warning"
            }
            break;
        case authConstants.LOGIN_REQUEST:
            state = {
                ...state,
                authenticating: true,
                loading: true
            }
            break;
        case authConstants.LOGIN_SUCCESS:
            state = {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                loading: false,
                authenticate: true,
                authenticating: false
            }
            break;
        case authConstants.LOGIN_FAILURE:
            state = {
                ...state,
                loading: false,
                // textAlertAuth: action.payload
            }
            break;
        case authConstants.LOGOUT_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case authConstants.LOGOUT_SUCCESS:
            state = {
                ...initState,
                loading: false,
                showAlertAuth: true,
                variantAlertAuth: "success",
                textAlertAuth: action.payload.message
            }
            break;
        case authConstants.LOGOUT_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                // showAlertAuth: true,
                // variantAlertAuth: "success",
                // textAlertAuth: action.payload.message,
                loading: false
            }
            break;
        case authConstants.SHOW_MODAL_USER:
            state = {
                ...state,
                showModalUser: !state.showModalUser
            }
            break;
        case authConstants.SHOW_SETTING_MODAL:
            state = {
                ...state,
                showSettingModal: !state.showSettingModal
            }
            break;
        case authConstants.SET_CLOSE_ALERT:
            state = {
                ...state,
                showAlertAuth: false,
                showAlert: false
            }
            break;
        case authConstants.SHOW_SEARCH_SHOW:
            state = {
                ...state,
                searchShow: action.payload,
            }
            break;
    }
    return state;
}


export const setCloseAlertAuth = (payload) => ({
    type: authConstants.SET_CLOSE_ALERT,
    payload: payload
})
export const setSearchShow = (payload) => ({ type: authConstants.SHOW_SEARCH_SHOW, payload })
export const setShowSettingModal = () => ({ type: authConstants.SHOW_SETTING_MODAL })
export const setShowModalUser = () => ({ type: authConstants.SHOW_MODAL_USER })
export const setUser = (users) => ({ type: authConstants.LOGIN_SUCCESS, payload: users })








