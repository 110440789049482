import { height } from '@mui/system';
import React, { useEffect, useState } from 'react'

const Loader = ({
    descriptionEmpty,
    allFunctional,
    description,
    typeLoader,
    loadText,
    title,
    height,
    time,
}) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true)
        }, time ? time : 5000);
        return () => clearTimeout(timer);
    }, []);
    const renderContent = () => {
        let content;
        switch (typeLoader) {
            case "allFunctional":
                content =
                    <>

                    </>
                break;
            default:
                content = null
        }
        return { content }
    }


    return (
        <div className="loader">
            {allFunctional ? !show
                ? <div className="loader__loaderContainer">
                    <div className="loader__lds-dual-ring"></div>
                    <p>{!loadText ? "Загрузка..." : loadText}</p>
                </div>
                : <div className="loader__showContentBox">
                    <div className="loaderContent">
                        <div className="loader__title">
                            {title ? title : null}
                        </div>
                        <div className="loader__description">
                            {descriptionEmpty ? descriptionEmpty : description ? `У вас пока нет ${description}` : "Ничего нет"}
                        </div>
                    </div>
                </div>
                : <div className="loader__loaderContainer">
                    <div className="loader__lds-dual-ring"></div>
                    <p>{!loadText ? "Загрузка..." : loadText}</p>
                </div>

            }
        </div >
    )
}

export default Loader
