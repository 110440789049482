
import React from 'react'
import { NavLink } from 'react-router-dom'
import HeaderNav from '../containers/Header/HeaderNav'
import OrderCatalogButton from './button/OrderCatalogButton'
import logo from '../assets/TestW.png'

const NewHeader = ({
    noneFooter
}) => {

    return (
        <>
            <div className="banner-outer">
                <div className="banner-inner responsive-wrapper">
                    <HeaderNav />
                </div>
            </div>
            {noneFooter ? null : <header className="header-outer">
                <div className="header-inner responsive-wrapper">
                    <div className="header-logo">
                        <img src={logo} alt='scds' />
                    </div>
                    <nav className="header-navigation">
                        <NavLink to="/maf">Главная</NavLink>
                        <NavLink to="/aboutUs">О нас</NavLink>
                        <NavLink to="/productsCategory/all">Продукция</NavLink>
                        <NavLink to="/contacts">Контакты</NavLink>
                        {/* <button>Menu</button> */}
                    </nav>
                </div>
            </header>}

            <OrderCatalogButton text="Возникли вопросы?" />

        </>
    )

}

export default NewHeader
