import React, { useState } from 'react'
import { Button } from '@mui/material';
import FileUploadModal from './../ApplicationPage/FileUploadModal';

const RenderDetailsPartnerPage = ({
    roleChangeRequestObj,
}) => {
    // const [photo, setPhoto] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [typeModal, setTypeModal] = useState("");

    if (!roleChangeRequestObj) return null

    const handleShowStatusModal = (type) => {
        setIsModalOpen(true)
        setTypeModal(type)
    }

    const handleClose = () => {
        setTypeModal("")
        setIsModalOpen(false)
    }
    const itemsDetailsContent = [
        {
            label: "Номер указаный в заявке",
            text: roleChangeRequestObj.contactNumber
        },
        {
            label: "Дата и время оформления",
            text: `${roleChangeRequestObj.createdAt.slice(0, 10)} ${roleChangeRequestObj.createdAt.slice(11, 19)}`
        },
        {
            label: "Статус заявки",
            text: <Button onClick={() => handleShowStatusModal("statusModal")}>Подробнее</Button>
        },
        {
            label: "Описание проблемы",
            text: roleChangeRequestObj.description
        },
    ];

    return (
        <>
            <div className="applicationPage__renderDetails">
                {itemsDetailsContent.map((item, index) => (
                    <div
                        className="applicationPage__detailsContent"
                        key={index}
                    >
                        <div className="applicationPage__detailsLabel">
                            {item.label}
                        </div>
                        <div className="applicationPage__detailsText">
                            {item.text}
                        </div>
                    </div>
                ))}
            </div>
            {
                isModalOpen && (
                    <FileUploadModal
                        typeModal={typeModal}
                        isOpen={isModalOpen}
                        onClose={handleClose}
                        itemObjStatus={roleChangeRequestObj.status}
                        itemObj={roleChangeRequestObj}
                    />
                )
            },
        </>
    );
};

export default RenderDetailsPartnerPage