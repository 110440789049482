import React from 'react'
import { Row, Col } from 'react-bootstrap';
import InputFullBorder from './Input/InputFullBorder';
import IconButtonClose from './IconButtons/IconButtonClose';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';

const ContentLabelP = ({
    onClickIcon,
    setPasswordForm,
    handleChange,
    valueEndText,
    passwordForm,
    labelText,
    valueText,
    fullWidth,
    children,
    showEdit,
    showIcon,
    type,
}) => {
    const textGray = () => {
        return (
            <div className="contentLabelP__textGray">
                Не указан(-a)
            </div>
        )
    }
    if (type === "password" && showEdit) return <>
        <InputFullBorder
            label="Старый пароль"
            value={passwordForm.password}
            onChange={(e) =>
                setPasswordForm({ ...passwordForm, password: e.target.value })
            }
        />
        <InputFullBorder
            label="Новые пароль"
            value={passwordForm.newPassword}
            onChange={(e) =>
                setPasswordForm({ ...passwordForm, newPassword: e.target.value })
            }
        />
        <InputFullBorder
            label="Повторите пароль"
            value={passwordForm.passwordCheck}
            onChange={(e) =>
                setPasswordForm({ ...passwordForm, passwordCheck: e.target.value })
            }
        />
    </>
    return !showEdit
        ? <Col className="contentLabelP" md={fullWidth ? "12" : "6"}>
            <div className="contentLabelP__row">
                <div className={`contentLabelP__label ${showIcon ? "showIcon" : ""}`}>{labelText}
                    {showIcon
                        ? <div className="contentLabelP__settingIcon">
                            <Tooltip title="Сделать запрос на партнерство">
                                <SettingsIcon onClick={onClickIcon} />
                            </Tooltip>
                        </div>
                        : null}
                </div>
                <div className="contentLabelP__text">{valueText
                    ? valueText
                    : textGray()} {valueEndText && valueText && valueEndText}</div>
            </div>
            {children}
        </Col>
        : <InputFullBorder
            label={labelText}
            value={valueText}
            onChange={handleChange}
        />
}

export default ContentLabelP