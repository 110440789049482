import React, { useEffect } from 'react'
import CarouselComponent from '../../../components/CarouselComponent';
import { getProductsBySlug } from './../../../actions/product.action';
import { useDispatch, useSelector } from "react-redux";

const RenderOtherOffers = ({
    productSlug,
}) => {

    const product = useSelector((state) => state.product)

    const { products, loading, productDetails } = product
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProductsBySlug(productSlug))
    }, [])
    return (
        <div>
            <CarouselComponent
                itemsType
                typeCarousel="similar"
                items={products}
                get={getProductsBySlug}
                itemSlug={productSlug}
                mainTitle={`Товары с категории -  ${productDetails.categoryName}`}
                buttonMainText="Перейти"
                aHref={`/productsCategory/${productSlug}`}
                textName="Похожие"
                loading={loading}

            />
        </div>
    )
}

export default RenderOtherOffers