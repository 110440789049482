import { applicationCallMeConstants } from './constants'
import axios from '../helpers/axios';
import swal from 'sweetalert';


export const createApplicationCallMe = (form) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationCallMeConstants.CREATE_APPLICATION_CALL_ME_REQUEST });
      const res = await axios.post("applicationCallMe/create", form);
      if (res.status === 201) {
        dispatch({ type: applicationCallMeConstants.CREATE_APPLICATION_CALL_ME_SUCCESS });
        dispatch(getApplicationsCallMeByUser());

        swal({
          title: "Успешно отправено",
          text: "Оператор свяжется с вами в ближайшее время",
          icon: "success",
        });
      } else {
        dispatch({ type: applicationCallMeConstants.CREATE_APPLICATION_CALL_ME_FAILURE });
        swal({
          title: "Ошибка отправки",
          text: "Попробуйте снова",
          icon: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      swal({
        title: "Ошибка отправки",
        text: "Попробуйте снова",
        icon: "warning",
      });
    }
  };
};

export const getApplicationsCallMeByUser = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/applicationCallMe/getApplicationsCallMeByUser");
      dispatch({ type: applicationCallMeConstants.GET_APPLICATION_CALL_ME_BY_USER_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: applicationCallMeConstants.GET_APPLICATION_CALL_ME_BY_USER_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: applicationCallMeConstants.GET_APPLICATION_CALL_ME_BY_USER_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addSamplePhotoApplicationCallMe = (_id, files) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      for (let pic of files) {
        formData.append("samplePhoto", pic);
      }
      const res = await axios.post(`/applicationCallMe/${_id}/addSamplePhoto`, formData);
      console.log(res.data)
      dispatch({ type: applicationCallMeConstants.ADD_SAMPLE_PHOTO_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: applicationCallMeConstants.ADD_SAMPLE_PHOTO_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: applicationCallMeConstants.ADD_SAMPLE_PHOTO_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
