import { cartConstants, orderConstants } from "./constants";
import swal from 'sweetalert';
import axios from "../helpers/axios";


export const addOrder = (payload) => {
    return async (dispatch) => {
        try {
            const res = await axios.post("/addOrder", payload);
            dispatch({ type: orderConstants.ADD_USER_ORDER_REQUEST });
            if (res.status === 201) {
                dispatch({
                    type: cartConstants.RESET_CART,
                });
                swal({
                    title: "Заказ успешно оформлен",
                    icon: "success",
                    content: {
                        element: "div",
                        attributes: {
                            innerHTML: `
                          <p>Оператор свяжется с вами в ближайшее время.</p>
                          <p><a href="/orderPage">Перейти к заказу</a></p>
                        `,
                        },
                    },
                });
                dispatch({
                    type: orderConstants.ADD_USER_ORDER_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: orderConstants.ADD_USER_ORDER_FAILURE,
                    payload: res.data,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};
export const updateOrderVisibleDiscountedTotal = (payload) => {
    return async (dispatch) => {
        dispatch({ type: orderConstants.UPDATE_ORDER_BY_ID_VISIBLE_REQUEST });
        try {
            const res = await axios.post("/updateOrderVisible", payload);
            if (res.status === 200) {
                dispatch({ type: orderConstants.UPDATE_ORDER_BY_ID_VISIBLE_SUCCESS, payload: res.data, });
            } else {
                dispatch({
                    type: orderConstants.UPDATE_ORDER_BY_ID_VISIBLE_FAILURE,
                    payload: res.data,
                });
            }
            dispatch(getOrders());
        } catch (error) {
            console.log(error);
        }
    };
};

export const getOrders = (sort) => {
    return async (dispatch) => {
        try {
            const res = await axios.get(`/getOrders?sort=${sort}`);
            dispatch({ type: orderConstants.GET_USER_ORDER_REQUEST });
            if (res.status === 200) {
                // console.log(res);
                const { orders } = res.data;
                dispatch({
                    type: orderConstants.GET_USER_ORDER_SUCCESS,
                    payload: { orders },
                });
            } else {
                const { error } = res.data;
                dispatch({
                    type: orderConstants.GET_USER_ORDER_FAILURE,
                    payload: { error },
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const getOrder = (payload) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`/getOrder`, payload);
            dispatch({ type: orderConstants.GET_USER_ORDER_DETAILS_REQUEST });
            if (res.status === 200) {
                const { order } = res.data;
                dispatch({
                    type: orderConstants.GET_USER_ORDER_DETAILS_SUCCESS,
                    payload: { order },
                });
            } else {
                const { error } = res.data;
                dispatch({
                    type: orderConstants.GET_USER_ORDER_DETAILS_FAILURE,
                    payload: { error },
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};




export const findAllOrderByOrderNumber = (search) => {
    return async dispatch => {
        try {
            dispatch({ type: orderConstants.SEARCH_ORDERS_BY_ORDER_NUMBER_REQUEST });
            const res = await axios.get(`orders/${search}`)

            if (res.status === 200) {
                dispatch({
                    type: orderConstants.SEARCH_ORDERS_BY_ORDER_NUMBER_SUCCESS,
                    payload: res.data
                })
            }
            else {
                dispatch({
                    type: orderConstants.SEARCH_ORDERS_BY_ORDER_NUMBER_FAILURE,
                })
            }
        } catch (e) {
            console.log(e);
        }
    }
}
