import React from 'react'
import { opacityAnimation } from './Animation/Animation';
import { motion } from 'framer-motion'
import PS from '../assets/PS.mp4'
import OrderCatalogButton from './button/OrderCatalogButton'

const MainImageHeaderItem = ({
    item,
    handleClick,
    type
}) => {
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.3 }}
            className="mainImageHeaderContainer">
            <video src={PS} autoPlay loop muted />
            <div className="mainImageHeader">
                <motion.div custom={1} variants={opacityAnimation} className="mainImageHeader__headerTitle">
                    {item.header}
                </motion.div>
                <div className="mainImageHeader__contentContainer text-center">
                    <motion.h1 custom={2} variants={opacityAnimation}>{item.name}</motion.h1>
                    <div
                        className="mainImageHeader__rowContainer">
                        <div className="mainImageHeader__contentBlock">
                            <div className="mainImageHeader__blockText">
                                <motion.h3
                                    custom={3}
                                    variants={opacityAnimation}>
                                    {item.description}
                                </motion.h3>
                                <motion.p
                                    custom={4}
                                    style={{ overflow: "hidden" }}
                                    variants={opacityAnimation}>
                                    {item.descriptionButton}
                                </motion.p>
                            </div>
                            {type === "other"
                                ? <div className="mainImageHeader__blockButton">
                                    <OrderCatalogButton
                                        whiteBackground
                                        text={item.buttonText}
                                        onClick={handleClick}
                                    />
                                </div>
                                : <a
                                    href='/productsCategory/all'
                                    className="mainImageHeader__blockButton">
                                    <OrderCatalogButton
                                        whiteBackground
                                        text={item.buttonText}
                                        onClick={() => console.log("")}
                                    />
                                </a>}
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default MainImageHeaderItem