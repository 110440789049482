import React from 'react'
import ComeBackButton from '../../components/button/ComeBackButton'
import { useDispatch } from 'react-redux'
import { setHideModalCart } from '../../reducers/cart.reducer'

const EmptyCartContainer = ({
    text,
    description,
    img,
    type
}) => {
    const dispatch = useDispatch()

    let imgUrl = "https://xl-static.rozetka.com.ua/assets/img/design/modal-cart-dummy.svg"
    return (
        <>
            <div className="renderCartModal__emptyCartContainer">
                <div>
                    {type === "full" && <div className="renderCartModal__imgContainer">
                        <img
                            src={img ? img : imgUrl}
                            alt="EmptyCartImg"
                        />
                    </div>}
                    <h3>{text ? text : "Корзина Пуста"}</h3>
                    <p>{description ? description : "Но это никогда не поздно изменить"}</p>
                </div>
            </div>
            <ComeBackButton
                handleClick={() => dispatch(setHideModalCart())}
                type="otherLink"
                href="/productsCategory/all"
                text="Перейти в каталог"
            />
        </>
    )
}

export default EmptyCartContainer