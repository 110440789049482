import React from 'react'
import { generatePublicUrl } from '../../../urlConfig';
import Loader from './../../../components/Loader';

const RenderImgListComponent = (props) => {
    const { productObj } = props
    return (
        <div className="imgListComponent">
            <h2>Все фото товара</h2>
            <div className="imgListComponent__row">

                {!productObj ? <Loader /> : productObj.productPictures.map((obj, index) => (
                    <div
                        className="imgListComponent__contentBlock"
                        key={index}>

                        <div className="imgListComponent__imgContainer">
                            <img src={generatePublicUrl(obj.img)} alt={obj._id} />
                        </div>
                    </div>
                ))
                }
            </div>
        </div>
    )
}

export default RenderImgListComponent