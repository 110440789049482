import React, { useEffect, useState, useRef } from 'react'
import BlackButton from './button/BlackButton'
import RenderModalRequestForCalculating from './modal/RenderModalRequestForCalculating'
import RenderModalApplication from './button/RenderModalApplication';

const RequestForCalculating = () => {
    const [showModal, setShowModal] = useState(false)


    const [formObj, setFormObj] = useState({
        name: "",
        nameCompany: "",
        number: "",
        email: "",
        description: ""
    })


    const rootEl = useRef(null);

    const handleShow = () => {
        setShowModal(true)
    }
    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setShowModal(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);


    return (
        <>

            <div className="requestForCalculating">
                <div className="requestForCalculating__containerButton" ref={rootEl}>
                    <BlackButton
                        onClick={handleShow}
                        text="Запрос на рассчет"

                    />
                </div>
            </div>
            <RenderModalApplication
                setShowModal={setShowModal}
                showModal={showModal}
            />
        </>
    )
}

export default RequestForCalculating
