import React from 'react';
import { loggedInMenuItems } from '../../store/localItems';
import { useNavigate } from 'react-router-dom';
import { goToTop } from '../../components/GoToTop';
import { useDispatch } from 'react-redux';
import { setShowSettingModal } from '../../reducers/auth.reducer';

const LoggedInMenu = ({ typeModal, auth }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = (href) => {
        goToTop("onlyTop", "-100");
        navigate(href);
    };

    const handleClickButton = (text) => {
        if (text === "Настройки") {
            dispatch(setShowSettingModal(true));
        }
    };

    return (
        <div className="loggedInMenu">
            {loggedInMenuItems(auth, handleClickButton).map((item, index) => (
                item && (
                    <div
                        key={index}
                        onClick={() => {
                            if (item.handleClickType && item.handleClickType === "button") {
                                item.handleClick();
                            } else {
                                handleClick(item.href);
                            }
                        }}
                        className={`loggedInMenu__list ${typeModal === "rightModal" ? "rightModal" : ""}`}>
                        <p>
                            {item.text}
                        </p>
                    </div>
                )
            ))}
        </div>
    );
}

export default LoggedInMenu;
