import React, { useRef, useState, useEffect } from 'react'
import ButtonProduct from './../../components/button/ButtonProduct';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Loader from './../../components/Loader';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PaginationComponent from '../../components/PaginationComponent';
import { useNavigate } from 'react-router-dom';
import ButtonAddCart from './ButtonAddCart';
import activeAddButton from './ActiveAddButton';
import Tooltip from '@mui/material/Tooltip';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { goToTop } from './../../components/GoToTop';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import GetVisibleImageUrl from '../../components/GetVisibleImageUrl';
import { generatePublicUrl } from '../../urlConfig';

const RenderProductListItems = ({
    showCategoryBlock,
    handleHistoryProduct,
    setIndexProductImg,
    setItemProductImg,
    handleViewProduct,
    handleDisableLike,
    handleEnableLike,
    handleCartButton,
    indexProductImg,
    setShowModalImg,
    setPageNumber,
    desiresItems,
    categoryObj,
    setPageSize,
    totalAll,
    pageNumber,
    pageSize,
    product,
    loading,
    slug,
}) => {
    const categoryName = () => slug === "all" ? "Все товары" : product[0]?.categoryName
    const mainTitleRef = useRef(null);
    const items = [
        {
            mainTitle: categoryObj === null ? categoryName() : categoryObj.name ? categoryObj.name : "Все товары",
            title: "ЯРКИЕ КОНСТРУКЦИИ ДЛЯ ОФОРМЛЕНИЯ ГОРОДОВ",
            leftDescription: "Модульные и универсальные скамьи позволяют создавать комфортную городскую среду. Ключевая особенность модульных систем — их универсальность. Модули можно менять местами, дополнять деталями, комбинировать между собой по аналогии с конструктором. Модульные скамьи позволяют создавать конструкции любого размера:",
            rightDescription: "Широкий выбор материалов, цветовых решений и дополнительной отделки конструкций позволяет создавать полноценные современные зоны отдыха с различными видами скамеек."
        }
    ]

    const handleClick = (obj) => {
        setShowModalImg(true)
        setItemProductImg(obj)
    }

    const handleShowButton = (index) => {
        setIndexProductImg(index + 1)
    }

    const handleMouseClose = (e) => {
        setIndexProductImg("")
    }

    const navigate = useNavigate()

    const handleProdDetailsPush = (obj) => {
        handleHistoryProduct(obj)
        handleViewProduct(obj._id)
        navigate(`/productDetails/${obj._id}`)
        window.scrollTo(0, 0);
    }
    const handleHrefCategoryName = (obj) => {
        navigate(`/productsCategory/${obj.categorySlug}?${obj._id}`)
    }

    const goToCategory = () => {
        goToTop("onlyTop", "100")
    }
    return (
        <div className="productListItems">
            <PaginationComponent
                loading={loading}
                goToTopRef={mainTitleRef}
                pageSize={pageSize}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
            />
            {loading ? <Loader /> : items.map((item, index) => (
                <div
                    key={index}>
                    <div>
                        <div className="productListItems__mainTitle"
                            ref={mainTitleRef}
                        >
                            <h1 data-title={!totalAll ? 0 : totalAll}
                            >
                                {item.mainTitle}
                            </h1>
                        </div>
                        <p className="productListItems__description">
                            {item.description}
                        </p>
                    </div>
                    <div className="productListItems__imageContainer">
                        <div className="row">
                            {loading ? <Loader /> : product.length === 0
                                ? <div className="productListItems__emptyProducts">
                                    <p>
                                        Мы работаем над наполнением данной категории уникальными товарами,
                                        и скоро мы порадуем вас широким ассортиментом.
                                        Оставайтесь с нами и следите за обновлениями!
                                    </p>
                                    <div className="productListItems__emptyProducts__iconBox">
                                        <VerticalAlignTopIcon
                                            onClick={goToCategory}
                                        />
                                    </div>
                                </div>
                                : product.map((obj, index) => (
                                    <div
                                        key={index}
                                        className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5"
                                    >
                                        <div className="productListItems__mainContainer">
                                            <div className="productListItems__imageBlock"
                                                onMouseEnter={() => handleShowButton(index)}
                                                onClick={() => handleShowButton(index)}
                                                onMouseLeave={() => handleMouseClose()}
                                            >
                                                <img
                                                    src={generatePublicUrl(obj.reducedImage)}
                                                    alt="sdcs"
                                                />

                                                <div className={`productListItems__showButtonBlock ${indexProductImg === index + 1 && "active"}`}>
                                                    {
                                                        activeAddButton(obj._id, desiresItems)
                                                            ? <div className="productListItems__likeButton active">
                                                                <Tooltip title="Уже добавлено">
                                                                    <FavoriteIcon onClick={() => handleDisableLike(obj._id)} />
                                                                </Tooltip>
                                                            </div>
                                                            : <div className="productListItems__likeButton">
                                                                <Tooltip title="Добавить в желаемое">
                                                                    <FavoriteBorderIcon onClick={() => handleEnableLike(obj)} />
                                                                </Tooltip>
                                                            </div>
                                                    }
                                                    <div className="productListItems__zoomButton">
                                                        <ZoomOutMapIcon onClick={() => handleClick(obj)} />
                                                    </div>
                                                </div>
                                                <div className="productListItems__productPrice">
                                                    <span>
                                                        {categoryObj?.visibleAllProductPrice ?
                                                            <>
                                                                {
                                                                    !obj.visiblePrice
                                                                        ? <div className="file__price">
                                                                            {!obj.price ? "Уточнить цену" : obj.price}
                                                                            &nbsp;
                                                                            {!obj.price ? null : "Валюта"}

                                                                        </div>
                                                                        : <div className="file__price">
                                                                            Уточнить цену
                                                                        </div>
                                                                }
                                                            </>
                                                            : <div className="file__price">
                                                                Уточнить цену
                                                            </div>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="productListItems__contentMainContainer">
                                                <div className="productListItems__productTitle">
                                                    <a href={`/productDetails/${obj._id}`}>
                                                        {obj.fullName}
                                                    </a>
                                                </div>

                                                <div className="productListItems__buttonBlock">
                                                    <ButtonProduct
                                                        onClick={() => handleProdDetailsPush(obj)}
                                                        text="Подробнее"
                                                    />
                                                    <ButtonAddCart
                                                        obj={obj}
                                                        handleCartButton={handleCartButton}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div >
            ))}
            <PaginationComponent
                loading={loading}
                goToTopRef={mainTitleRef}
                pageSize={pageSize}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
            />
        </div>
    )
}

export default RenderProductListItems
