import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getAllItemsEmptyUser } from '../actions/mainActions/mainAction';
import HeaderMainPage from './HeaderMainPage';
import RenderItems from './RenderItems';
import Loader from './../components/Loader';
import FooterMainPage from './FooterMainPage';

const MainPage = ({
    setShowFooter
}) => {
    const {
        mainPages,
        loading
    } = useSelector((state) => state.mainPage)

    const dispatch = useDispatch()

    useEffect(() => {
        setShowFooter(false)
    }, [])

    useEffect(() => {
        dispatch(getAllItemsEmptyUser("mainPage"))
    }, [])

    return (
        <div style={{
            position: "relative"
        }}>
            <HeaderMainPage />
            <div className="mainPage">
                <div className="mainPage__mainContent">
                    {!mainPages?.length > 0 || loading
                        ? <Loader />
                        : <RenderItems
                            mainPages={mainPages}
                        />}
                </div>
                <FooterMainPage />
            </div>
        </div>
    )
}

export default MainPage