import React from 'react'
import IconDownUpContainer from './../../../components/Icon/IconDownUpContainer';
import StatusColorText from './../StatusColorText';

const PartnerPageItem = ({
    item,
    selectedItemId,
    toggle,
    children
}) => {
    const isOpen = selectedItemId === item._id;

    if (!item) return null

    const filterOrderStatusColor = (text) => {
        let color;
        switch (text) {
            case "Оформлена":
                color = "gray";
                break;
            case "Подтвержено":
                color = "green";
                break;
            case "Отклонено":
                color = "red";
                break;
            default: color = "gray";
        }
        return color
    }

    return (
        <div
            className={`applicationPage__contentBlock ${isOpen ? " active" : ""}`}

        >
            <div className="applicationPage__headerContent">
                <div className="applicationPage__descriptionProblemHeader"
                    onClick={() => toggle(item._id)}
                >
                    <div className="applicationPage__descSliceText">
                        {item.description.slice(0, 20)}...
                    </div>
                    <StatusColorText
                        filterOrderStatusColor={filterOrderStatusColor}
                        status={item.status}
                    />
                </div>
                <div className="applicationPage__data">
                    {item.createdAt.slice(0, 10)}
                </div>
            </div>
            <div className="applicationPage__iconBox"
                onClick={() => toggle(item._id)}>
                <IconDownUpContainer
                    up={!isOpen}
                />
            </div>

            {isOpen && children}
        </div >
    );
};

export default PartnerPageItem;
