import axios from "../helpers/axios";
import { cartConstants } from "./constants";
import store from "../store";


export const updateCartItemQuantity = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: cartConstants.UPDATE_CART_ITEM_QUANTITY_REQUEST });
      const { cart: { cartItems }, auth: { authenticate } } = store.getState();
      if (authenticate) {
        const res = await axios.post("/user/cart/updateCartItemQuantity", payload);
        if (res.status === 200) {
          const { updatedCart } = res.data;
          if (updatedCart) {
            dispatch({
              type: cartConstants.UPDATE_CART_ITEM_QUANTITY_SUCCESS,
              payload: { updatedCart },
            });
          }
        }
      } else {
        if (cartItems.length > 0) {
          const { productId, quantity } = payload
          const existingCartItem = cartItems.find(item => item._id === productId);
          if (existingCartItem) {
            existingCartItem.qty = quantity;
            localStorage.setItem("cart", JSON.stringify(Object.values(cartItems)));
            dispatch({
              type: cartConstants.UPDATE_CART_ITEM_QUANTITY_SUCCESS,
            });
          }
        }
      }
      dispatch(getCartItems());
    } catch (error) {
      dispatch({
        type: cartConstants.UPDATE_CART_ITEM_QUANTITY_FAILURE,
      });
    }
  };
};

export const addToCart = (product, newQty = 1, type) => {
  return async (dispatch) => {
    try {
      const { cart: { cartItems }, auth } = store.getState();
      let qty;
      qty = type === "newQty" ? parseInt(newQty) : 1;
      if (cartItems.length > 0) {
        const existingCartItem = cartItems.find(item => item._id === product._id);

        if (existingCartItem) {
          qty = parseInt(existingCartItem.qty + newQty);
          existingCartItem.qty = qty;
        } else {
          cartItems.push({ ...product, qty });
        }
      } else {
        cartItems[product._id] = { ...product, qty };
      }

      if (auth.authenticate) {
        dispatch({ type: cartConstants.ADD_TO_CART_REQUEST });

        const payload = {
          cartItems: [{ product: product._id, quantity: qty }],
        };
        const res = await axios.post(`/user/cart/addtocart`, payload);

        if (res.status === 201) {
          dispatch({
            type: cartConstants.ADD_TO_CART_SUCCESS,
            payload: { message: res.data.message },
          });
        }
      } else {
        localStorage.setItem("cart", JSON.stringify(Object.values(cartItems)));
      }
      dispatch({
        type: cartConstants.ADD_TO_CART_SUCCESS,
        payload: { cartItems },
      });
      dispatch(getCartItems());
    } catch (payload) {
      dispatch({
        type: cartConstants.ADD_TO_CART_FAILURE,
        payload: { message: payload.response.data.message },
      });
    }
  };
};

const getCartItems = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: cartConstants.GET_CART_ITEMS_REQUEST });
      const { auth: { authenticate } } = getState();

      if (authenticate) {
        const res = await axios.post("/user/getCartItems");
        if (res.status === 200) {
          const { cartItems } = res.data;
          if (cartItems) {
            dispatch({
              type: cartConstants.GET_CART_ITEMS_SUCCESS,
              payload: { cartItems },
            });
          }
        }
      } else {
        const localCartItems = JSON.parse(localStorage.getItem('cart')) || [];
        dispatch({
          type: cartConstants.GET_CART_ITEMS_SUCCESS,
          payload: { cartItems: localCartItems },
        });
      }
    } catch (error) {
      dispatch({
        type: cartConstants.GET_CART_ITEMS_FAILURE,
      });
    }
  };
};

export const removeCartItem = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: cartConstants.REMOVE_CART_ITEM_REQUEST });
      const { cart: { cartItems }, auth: { authenticate } } = store.getState();
      if (authenticate) {
        const res = await axios.post(`/user/cart/removeItem`, { payload });
        if (res.status === 202) {
          dispatch({ type: cartConstants.REMOVE_CART_ITEM_SUCCESS, message: res.data.message });
          dispatch(getCartItems());
        } else {
          const { error } = res.data;
          dispatch({
            type: cartConstants.REMOVE_CART_ITEM_FAILURE,
            payload: { error },
          });
        }
      }
      else {
        if (cartItems.length > 0) {
          const { productId } = payload;
          const updatedCartItems = cartItems.filter(item => item._id !== productId);
          localStorage.setItem("cart", JSON.stringify(updatedCartItems));
          dispatch({
            type: cartConstants.REMOVE_CART_ITEM_SUCCESS,
          });
          dispatch(getCartItems());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateCart = () => {
  return async (dispatch) => {
    const { auth } = store.getState();
    let cartItems = localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : null;

    if (auth.authenticate) {
      localStorage.removeItem("cart");
      dispatch(getCartItems());
      if (cartItems) {
        const payload = {
          cartItems: Object.keys(cartItems).map((key) => {
            return {
              quantity: cartItems[key].qty,
              product: cartItems[key]._id,
            };
          }),
        };
        if (Object.keys(cartItems).length > 0) {
          const res = await axios.post(`/user/cart/addtocart`, payload);
          if (res.status === 201) {
            dispatch(getCartItems());
          }
          if (res.status === 400) {
            dispatch(getCartItems());
          }
        }
      } else {
        dispatch(getCartItems());
      }
    } else {
      if (cartItems) {
        dispatch({
          type: cartConstants.UPDATE_TO_CART_SUCCESS,
          payload: { cartItems },
        });
        dispatch(getCartItems());
      }
    }
  };
};
export { getCartItems };

