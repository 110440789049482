import React from 'react'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

const ZoomIcon = ({
    item,
    currentId,
    handleClick,

}) => {
    if (!item) return null
    return (
        <div className={`zoomIcon ${item._id === currentId ? "visible" : ""}`}>
            <div className="zoomIcon__iconBlock">
                <ZoomOutMapIcon onClick={() => handleClick(item)} />
            </div>
        </div>
    )
}

export default ZoomIcon