import React from 'react'
import { stylesSelect } from '../../scss/styles';
import ButtonContainer from './ButtonContainer';
import { Button } from '@mui/material';

const RenderNormalTimeContent = ({
    handleChangeTwo,
    handleAnyTime,
    futureMinutes,
    handleChange,
    futureHours,
    setAnyTime,
    handleTime,
    setTime,
    anyTime,
    minutes,
    time,
    hour,
}) => {
    switch (true) {
        case time:
            return (
                <div style={{
                    display: "block"
                }}>
                    <h5>
                        Выбранное время {hour} : {minutes}

                    </h5>
                    <h6>Наши операторы могут позвонить на 10-20 минут раньше или позже в зависимости от загруженности</h6>
                    <Button
                        variant="contained"
                        onClick={() => setTime(false)}
                    >
                        Отменить
                    </Button>
                </div>
            );
        case anyTime:
            return (
                <div style={{
                    display: "block"
                }}>
                    <h5>
                        Вы выбрали звонок в любое время
                    </h5>
                    <h6>Наши операторы свяжутся с вами как только кто-то из них появится на связи</h6>
                    <Button
                        variant="contained"
                        onClick={() => setAnyTime(false)}
                    >
                        Отменить
                    </Button>
                </div>
            );
        default:
            return (
                <>
                    <div>
                        Когда вам позвонить?
                    </div>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                        width: "100%",
                        maxWidth: "250px"
                    }}>
                        <select
                            labelId="hour"
                            id="hour"
                            value={hour}
                            label="Часы"
                            onChange={handleChange}
                            style={stylesSelect}
                        >
                            <option>Часы</option>
                            {futureHours.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                        <select
                            style={stylesSelect}
                            labelId="minutes"
                            id="minutes"
                            value={minutes}
                            label="Минуты"
                            onChange={handleChangeTwo}
                            disabled={hour === "Часы"}
                        >
                            <option>Минуты</option>
                            {futureMinutes.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                        <ButtonContainer
                            hour={hour}
                            minutes={minutes}
                            handleTime={handleTime}
                            handleAnyTime={handleAnyTime}
                        />
                    </div>
                </>
            )
    }
}

export default RenderNormalTimeContent