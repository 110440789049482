import React from 'react'
import { generatePublicUrl } from '../../urlConfig'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Tooltip } from '@mui/material';
const RenderMiniImg = ({
    onClickIcon,
    setCurrentIdPicture,
    setShowModalImg,
    iconFunctional,
    directlyImg,
    slideIndex,
    moveDot,
    itemObj,
}) => {


    const handleClick = (index, pictureId, type) => {
        if (iconFunctional) return null
        setCurrentIdPicture(pictureId)
        if (moveDot) {
            moveDot(index + 1)
        }
        if (type === "click") {
            setShowModalImg(true)
        }
    }
    return (
        <div className='sliderMiniImg__containerMiniImg'>
            {
                !itemObj.length ? null : itemObj.map((obj, index) => (
                    <div
                        key={directlyImg ? index : obj._id}
                        onClick={() => handleClick(index, obj._id, "click")}
                        onMouseEnter={() => handleClick(index, obj._id)}

                        className={slideIndex === index + 1
                            ? "sliderMiniImg__miniImg active-mini"
                            : "sliderMiniImg__miniImg"}
                    >
                        <img
                            src={directlyImg ? obj : generatePublicUrl(obj.img)}
                        />
                        {iconFunctional
                            ? <div className="sliderMiniImg__miniImg__iconFunctional"
                                onClick={() => onClickIcon(obj.galleryItemId)}
                            >
                                <Tooltip title="Удалить">

                                    <DeleteForeverIcon />
                                </Tooltip>
                            </div>
                            : null
                        }
                    </div>
                )
                )}
        </div>
    )
}

export default RenderMiniImg