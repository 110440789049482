import React, { useState, useEffect } from 'react'
import ButtonBoxFullWidth from '../../../components/button/ButtonBoxFullWidth';
import ContainerCheckoutList from './ContainerCheckoutList';
import { useSelector } from 'react-redux';
import DescriptionItemLine from '../../../components/DescriptionItemLine';

const CheckoutOrderInfo = ({
    checked,
    onClick
}) => {
    const { cartItems } = useSelector((state) => state.cart);
    const infoOrderArray = [
        {
            title: "Всего позиций",
            content: `${cartItems.length} шт`
        },
        {
            title: "Экономия:",
            content: "После просчета"
        },
    ]


    const renderOrderInfo = () => {
        return (
            <div className="renderOrderInfo">

                <div className="renderOrderInfo__infoOrderRow">
                    {infoOrderArray.map((obj, index) =>
                        <DescriptionItemLine
                            key={index}
                            item={obj}
                        />)}
                </div>
                <div className="renderOrderInfo__totalInfoBox">
                    <div className="renderOrderInfo__totalInfoBox__text">
                        Итого:
                    </div>
                    <div className="renderOrderInfo__totalInfoBox__desc">
                        После подтверждения
                    </div>
                </div>
                <ButtonBoxFullWidth
                    checked={checked}
                    onClick={onClick}
                />
            </div>
        )
    }
    return (
        <ContainerCheckoutList
            title="Ваш заказ"
            titleChildren={renderOrderInfo()}
        />
    )
}

export default CheckoutOrderInfo