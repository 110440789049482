import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getArchitectLi } from './../../actions';
import Loader from './../../components/Loader';
import ArchitectContentBlock from './../Architect/ArchitectContentBlock';
import DarkLineComponent from './../../components/DarkLineComponent';

const ArchitectLi = () => {
    const architectLi = useSelector((state) => state.architectLi)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getArchitectLi())
    }, [])

    if (architectLi.loading) {
        return <Loader />
    }
    return (
        <div>

            <ArchitectContentBlock
                items={architectLi.architectLi[0]}
            />
            <DarkLineComponent
                titleTop="МЫ"
                titleBottom="ПРЕДЛАГАЕМ:"
                descriptionTop="ШИРОКУЮ ЛИНЕЙКУ ГОТОВЫХ МАФОВ"
                descriptionCenter="РАЗРАБОТКУ ИЗДЕЛИЙ ПОД КОНКРЕТНЫЙ"
                descriptionBottom="ПРОЕКТ"
            />
            <ArchitectContentBlock
                items={architectLi.architectLi[1]}
                rightImage
            />
            <DarkLineComponent
                buttonBlock
                titleTop="НАШИ ИЗДЕЛИЯ"
                titleCenter="В ОНЛАЙН КАТАЛОГЕ"
                titleBottom="«БЛАГОУТРОЙСТВО ГОРОДСКОЙ СРЕДЫ»"
            />
            <ArchitectContentBlock
                items={architectLi.architectLi[2]}
                sameWidth
            />
            <ArchitectContentBlock
                items={architectLi.architectLi[3]}
                rightImage
                sameWidth
            />
        </div>
    )
}

export default ArchitectLi