import { categoryConstants } from "../actions/constants";

const initState = {
    categories: [],
    categoryDetails: {},
    showCategoryBlock: true
};

export default (state = initState, action) => {
    switch (action.type) {
        case categoryConstants.GET_ALL_CATEGORIES_SUCCESS:
            state = {
                ...state,
                categories: action.payload.categories,
            }
            break;
        case categoryConstants.GET_CATEGORY_DETAILS_BY_ID_SUCCESS:
            state = {
                ...state,
                categoryDetails: action.payload.categoryDetails
            }
            break;
        case categoryConstants.SHOW_CATEGORY_BLOCK:
            state = {
                ...state,
                showCategoryBlock: !state.showCategoryBlock
            }
            break;
    }

    return state;
}

export const setShowCategoryBlock = () => ({
    type: categoryConstants.SHOW_CATEGORY_BLOCK
})
