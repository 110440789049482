import React, { useEffect } from 'react'
import TitleHeaderComponent from '../../components/TitleHeaderComponent'
import RenderPortfolio from './RenderPortfolio'
import { useSelector, useDispatch } from 'react-redux';
import { getBlog } from '../../actions/components/blog';



const BlogComponent = () => {
    const blog = useSelector((state) => state.blog.blog)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getBlog())
    }, [])
    return (
        <div className="portfolio p-3">
            
            <TitleHeaderComponent
                description="Пишем о том, что любим"
                title="Читать Блог"
                text="ЧИТАТЬ"
                
            />
            <RenderPortfolio
                items={blog}

            />

        </div>
    )
}

export default BlogComponent
