import React from 'react'
import { MaterialInput } from './../MaterialUi/index';

const UserDataInput = ({
    setForm,
    form,
    blackText
}) => {


    return (
        <>
            <MaterialInput
                blackText={blackText && blackText}
                label="ваше имя и фамилию"
                type="text"
                value={form.fullName}
                onChange={(e) => setForm({ ...form, fullName: e.target.value })}
            />
            <MaterialInput
                blackText={blackText && blackText}
                label="Номер телефона"
                type="text"
                value={form.contactNumber}
                onChange={(e) => setForm({ ...form, contactNumber: e.target.value })}
            />
            <MaterialInput
                blackText={blackText && blackText}
                label="email"
                type="text"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
            <MaterialInput
                blackText={blackText && blackText}
                label="Название компании"
                type="text"
                value={form.nameCompany}
                onChange={(e) => setForm({ ...form, nameCompany: e.target.value })}
            />
        </>
    )
}

export default UserDataInput