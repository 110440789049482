import React, { useState } from 'react'
import { generatePublicUrl } from '../../urlConfig'
import BtnSlider from './BtnSlider'
import TitleHeaderComponent from './../TitleHeaderComponent';
import { motion } from 'framer-motion'
import { opacityAnimation } from './../Animation/Animation';
import RenderHrefContent from './RenderHrefContent';

const Slider = ({
    renderHrefContent,
    setPortfolioIndex,
    setProdObjHref,
    prodObjHref,
    titleNone,
    textName,
    itemTwo,
    item,
    obj,
}) => {
    const [slideIndex, setSlideIndex] = useState(1)
    const [show, setShow] = useState(false)

    const showHrefContent = (objOne) => {
        setProdObjHref(objOne)
        setShow(!show)
    }
    const nextSlide = () => {
        setShow(false)
        if (slideIndex !== (item ? item.length : itemTwo.length)) {
            setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex === item ? item.length : itemTwo.length) {
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        setShow(false)
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)
            setPortfolioIndex(slideIndex)
        }
        else if (slideIndex === 1) {
            setSlideIndex(item ? item.length : itemTwo.length)
            setPortfolioIndex(slideIndex)
        }
    }
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.3 }}

        >
            {textName ? <TitleHeaderComponent
                description={textName}
                greyColorTitle
                title={titleNone ? null : obj?.description}
                text={titleNone ? null : "Каталог товаров"}
            /> : null}
            <motion.div
                variants={opacityAnimation}
                viewport={{ amount: 0.3, once: true }}

                className={show ? "container-slider active" : "container-slider"}>
                {item ?
                    item.map((obj, index) =>
                        <div className="sliderContainer"
                            key={index}
                            style={{ marginTop: "50px" }}
                        >
                            <div
                                key={index}
                                className={slideIndex === index + 1 ? "container-slider__slide1 active-anim" : "container-slider__slide1"}
                            >
                                <div className="container-slide__imgBlock">
                                    <div>
                                        <img
                                            src={obj.img || generatePublicUrl(obj.images[0].img)}
                                            alt="hello"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    :
                    itemTwo?.map((objOne, index) =>
                        <div
                            onClick={() => showHrefContent(objOne.product)}
                            key={index}
                            className={slideIndex === index + 1 ? "container-slider__slide1 active-anim" : "container-slider__slide1"}
                        >
                            <div className="container-slide__imgBlock">
                                <div className="landscaping__imgBlock">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                            <div className="landscaping__imgOneContainer">
                                                <img
                                                    src={generatePublicUrl(objOne.product.productPictures[0].img)}
                                                    alt="ss"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                            <div className="landscaping__contentBlock">
                                                <div className="landscaping__imgTwoContainer-wrapper">
                                                    {objOne.product.productPictures.slice(1, 3).map((obj, index) => (
                                                        <div
                                                            key={index}
                                                            className="landscaping__imgTwoContainer"
                                                        >
                                                            <img src={generatePublicUrl(obj.img)} alt="ss" />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <BtnSlider moveSlide={nextSlide} direction={"next"} />
                <BtnSlider moveSlide={prevSlide} direction={"prev"} />
                {!renderHrefContent ? null : show ? <RenderHrefContent
                    setShow={setShow}
                    itemObj={prodObjHref}
                /> : null}
            </motion.div>
        </motion.div>
    )
}

export default Slider