
import React, { useEffect, useState } from 'react'
import PurpleButton from '../../../components/button/PurpleButton';
import ContainerMainComponent from './../../ContainerMainComponent';
import { getProductDetailsById } from './../../../actions/product.action';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DarkLineComponent from './../../../components/DarkLineComponent';
import RenderFullImgModal from './../../../components/modal/RenderFullImgModal';
import { getWarranty } from './../../../actions/components/warranty';
import RenderNavMenu from './RenderNavMenu';
import RenderViewedProduct from './RenderViewedProduct';
import BlackLine from './../../../components/BlackLine';
import Loader from '../../../components/Loader';
import { goToTop } from '../../../components/GoToTop';

const ProductDetails = () => {

    const nameCompany = useSelector((state) => state.contact.contact[0]?.nameCompany)
    const auth = useSelector((state) => state.auth.authenticate);
    const product = useSelector((state) => state.product)
    const desires = useSelector((state) => state.desires)
    const cart = useSelector((state) => state.cart)

    const dispatch = useDispatch()
    const productObj = product?.productDetails

    const [showImgButtonBlock, setShowImgButtonBlock] = useState(false);
    const [currentIdPicture, setCurrentIdPicture] = useState(null);
    const [showModalImg, setShowModalImg] = useState(false);
    const [categoryObj, setCategoryObj] = useState(null)

    let { productId } = useParams();

    const handleCloseModalImg = () => {
        setShowModalImg(false)
    }

    useEffect(() => {
        const storedData = window.localStorage.getItem('TEST');
        let data = null;

        try {
            data = JSON.parse(storedData);
        } catch (error) {
            console.error('Ошибка при парсинге JSON:', error);
        }
        setCategoryObj(data)
    }, [])
    useEffect(() => {
        dispatch(getWarranty())
    }, [])


    useEffect(() => {
        const payload = {
            params: {
                productId
            }
        }
        dispatch(getProductDetailsById(payload))
        // .then(() => goToTop("onlyTop", 100))
    }, [productId]);

    useEffect(() => {
        document.addEventListener("scroll", scrollHandler)

        return function () {
            document.removeEventListener("scroll", scrollHandler)
        }
    }, [])

    const scrollHandler = (e) => {
        if (e.target.documentElement.scrollTop > 1000) {
        }

    }
    if (!product) {
        return <div>
            <h1>Загрузка...</h1>
        </div>
    }
    if (Object.keys(product.productDetails).length === 0) {
        return <Loader />;
    }
    const handleMouseClose = (e) => {
        setShowImgButtonBlock(false)
    }
    return (
        <ContainerMainComponent
            categoryName={productObj.categoryName}
            href="/productsCategory/all"
            bread
            titleItem={productObj.fullName}
            title={`${productObj.categoryName} '${productObj.categoryOnlyLatterName}${productObj.nameMaterial} ${productObj.numberMaterialName}'`}
            categorySlug={productObj.categorySlug}
            productId={productObj._id}
        >
            <div className="productDetails">
                <PurpleButton text="Не смогли найти то что вам нужно?" />
                <RenderNavMenu
                    auth={auth}
                    categoryObj={categoryObj}
                    setCurrentIdPicture={setCurrentIdPicture}
                    cartItems={cart.cartItems}
                    desiresItems={desires.desiresItems}
                    nameCompany={nameCompany}
                    setShowModalImg={setShowModalImg}
                    showImgButtonBlock={showImgButtonBlock}
                    setShowImgButtonBlock={setShowImgButtonBlock}
                    handleMouseClose={handleMouseClose}
                    productObj={productObj}
                    product={product}
                />
            </div>
            <BlackLine />
            <RenderViewedProduct />
            <DarkLineComponent
                buttonBlock
                titleTop="НАШИ ИЗДЕЛИЯ"
                titleCenter="В КАТЕГОРИИ"
                titleBottom={productObj.categoryName}
                categorySlug={productObj.categorySlug}
            />
            <RenderFullImgModal
                setShowModalImg={setShowModalImg}
                handleCloseModalImg={handleCloseModalImg}
                showModalImg={showModalImg}
                items={productObj.productPictures}
                currentIdPicture={currentIdPicture}
                product={productObj}
            />
        </ContainerMainComponent>
    )
}

export default ProductDetails