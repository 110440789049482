import { orderConstants } from "../actions/constants";

const initState = {
    orders: [],
    orderDetails: {},
    error: null,
    message: "",
    showAlert: false,
    variantAlert: "success",
    textContent: "",
    loading: false,
};

export default (state = initState, action) => {
    switch (action.type) {
        case orderConstants.SEARCH_ORDERS_BY_ORDER_NUMBER_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case orderConstants.SEARCH_ORDERS_BY_ORDER_NUMBER_SUCCESS:
            state = {
                ...state,
                loading: false,
                variantAlert: "success",
                message: action.payload.message,
                orders: action.payload.orders.reverse(),
                showAlert: true,
            };
            break;
        case orderConstants.SEARCH_ORDERS_BY_ORDER_NUMBER_FAILURE:
            state = {
                ...state,
                loading: false,
                message: "Нет заказов с таким номером(названием)",
                variantAlert: "warning",
                orders: [],
                showAlert: true,
            };
            break;

        case orderConstants.UPDATE_ORDER_BY_ID_VISIBLE_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case orderConstants.UPDATE_ORDER_BY_ID_VISIBLE_SUCCESS:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
            };
            break;
        case orderConstants.UPDATE_ORDER_BY_ID_VISIBLE_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                variantAlert: "warning",
                showAlert: true,
            };
            break;

        case orderConstants.GET_USER_ORDER_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case orderConstants.GET_USER_ORDER_SUCCESS:
            state = {
                ...state,
                orders: action.payload.orders,
                loading: false,
            };
            break;
        case orderConstants.GET_USER_ORDER_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case orderConstants.CLOSE_ALERT_TO_ORDER_SUCCESS:
            state = {
                ...state,
                showAlert: false
            };
            break;
        case orderConstants.GET_USER_ORDER_DETAILS_REQUEST:
            break;
        case orderConstants.GET_USER_ORDER_DETAILS_SUCCESS:
            state = {
                ...state,
                orderDetails: action.payload.order,
            };
            break;
        case orderConstants.GET_USER_ORDER_DETAILS_FAILURE:
            break;
        default:
            return state
    }
    return state;
};


export const setCloseAlertOrder = (payload) => ({ type: orderConstants.CLOSE_ALERT_TO_ORDER_SUCCESS, payload })
