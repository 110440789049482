import { roleChangeRequest } from "./constants";
import axios from '../helpers/axios'
import swal from 'sweetalert';

export const createRoleChangeRequest = (payload, userId) => {
    return async dispatch => {
        try {
            dispatch({ type: roleChangeRequest.CREATE_ROLE_CHANGE_REQUEST });
            const res = await axios.post("/roleChangeRequest/create", payload);
            if (res.status === 201) {
                dispatch({
                    type: roleChangeRequest.CREATE_ROLE_CHANGE_SUCCESS,
                    payload: res.data
                })
                dispatch(getRoleChangeRequestProfileByUser(userId));
                swal({
                    title: "Успешно отправлено",
                    text: "Оператор свяжется с вами в ближайшее время",
                    icon: "success",
                });
            } else if (res.status === 200) {
                dispatch({
                    type: roleChangeRequest.CREATE_ROLE_CHANGE_FAILURE,
                    payload: res.data
                });
                swal({
                    title: "Ошибка отправки",
                    text: res.data.message,
                    icon: "warning",
                });

            }
        } catch (error) {
            console.log(error);
        }
    }
}

export const getRoleChangeRequestProfileByUser = (userId) => {
    return async (dispatch) => {
        try {
            const res = await axios.get("/roleChangeRequest/getByUser", userId);
            dispatch({ type: roleChangeRequest.GET_ROLE_CHANGE_BY_USER_REQUEST });
            if (res.status === 200) {
                dispatch({
                    type: roleChangeRequest.GET_ROLE_CHANGE_BY_USER_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: roleChangeRequest.GET_ROLE_CHANGE_BY_USER_FAILURE,
                    payload: res.data,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};