import React, { useEffect, useState } from 'react';
import styles from './MasonryGallery.module.css';
import Masonry from 'react-masonry-css';
import ItemGallery from './ItemGallery';
import RenderFullImgModal from '../modal/RenderFullImgModal';
import ShowMoreButton from '../button/ShowMoreButton';
import { useParams } from 'react-router-dom';

const MasonryGallery = ({
    galleryImagesUser,
    localStorageLiked,
    addGalleryImgUser,
    itemsPicture,
    user,
    handleLoadMore,
    currentPage,
    totalPagesGallery,
    loading,
    authenticate
}) => {
    const [showModalImg, setShowModalImg] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [typeImgBlock, setTypeImgBock] = useState(false)
    const nameSite = useParams()

    useEffect(() => {
        if (nameSite.mainPageId && nameSite.mainPageId !== "") {
            setTypeImgBock("mainPages")
        } else {
            setTypeImgBock("")
        }
    }, [])

    const handleClick = (item) => {
        setCurrentProduct(item)
        setShowModalImg(true);
    };

    const handleShowButton = (pictureId) => {
        setCurrentId(pictureId);
    };

    const handleMouseClose = () => {
        setCurrentId(null);
    };
    return (
        <>
            <Masonry
                breakpointCols={{
                    default: 4,
                    1100: 3,
                    700: 2,
                    500: 1,
                }}
                className={styles.masonryGrid}
                columnClassName={styles.masonryGridColumn}
            >
                {Array.isArray(itemsPicture) && itemsPicture.map((item) => (
                    <ItemGallery
                        galleryImagesUser={galleryImagesUser}
                        localStorageLiked={localStorageLiked}
                        addGalleryImgUser={addGalleryImgUser}
                        key={item._id}
                        item={item}
                        handleClick={handleClick}
                        user={user}
                        handleShowButton={handleShowButton}
                        handleMouseClose={handleMouseClose}
                        currentId={currentId}
                        contentItemType={typeImgBlock}
                        authenticate={authenticate}
                    />
                ))}
            </Masonry>
            {currentPage < totalPagesGallery && (
                <ShowMoreButton
                    loading={loading}
                    currentPage={currentPage}
                    onClick={handleLoadMore}
                    whiteBackground
                    text={"Показать еще"}
                />
            )}
            <RenderFullImgModal
                setCurrentIdPicture={setCurrentId}
                currentIdPicture={currentId}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
                items={itemsPicture}
                typePage="galleryPage"
                product={currentProduct}
                visibleButtonsBottom={true}
            />
        </>
    );
};

export default MasonryGallery;
