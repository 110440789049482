import axios from "../../helpers/axios"
import { regionConstants } from '../constants';

export const getRegions = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: regionConstants.GET_REGION_REQUEST })
            const res = await axios.get("region/get")
            if (res.status === 200) {
                const { regions } = res.data
                dispatch({
                    type: regionConstants.GET_REGION_SUCCESS,
                    payload: {
                        regions
                    }
                })
            } else {
                dispatch({ type: regionConstants.GET_REGION_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const getRegionById = (model, itemId, fields) => {
    return async (dispatch) => {
        try {
            dispatch({ type: regionConstants.GET_REGION_BY_ID_REQUEST });
            const res = await axios.get(`/${model}/${itemId}/${fields || ""}`);
            if (res.status === 200) {
                const { item } = res.data;
                dispatch({
                    type: regionConstants.GET_REGION_BY_ID_SUCCESS,
                    payload: {
                        item,
                    },
                });
            } else {
                dispatch({ type: regionConstants.GET_REGION_BY_ID_FAILURE });
            }
        } catch (e) {
            console.log(e);
        }
    };
};