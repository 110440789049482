import { productConstants } from "../actions/constants";

const initState = {
  products: [],
  galleries: [],
  priceRange: {},
  productsByPrice: {},
  page: {},
  error: null,
  productDetails: {},
  productDetailsList: "Все о товаре",
  loading: false,
  view: 'plate',
  productSearchType: "products",
  currentCategory: "",
  totalAll: 0,
  countPage: "",
  currentPage: 1,
  currentProductImgSlider: 1,
  totalPages: 0,
  pageSize: 25,
  productMoreThreeImg: [],
  message: "",
  showAlert: false,
  variantAlert: "",
  visibleButtonBuy: false,
  totalPagesGallery: 0,
  openSearchModalProduct: false,
  searchName: ""

};
export default (state = initState, action) => {
  switch (action.type) {
    case productConstants.VISIBLE_PRODUCT_BUY_BUTTON:
      state = {
        ...state,
        products: state.products._id === action.payload.id ? state.visibleButtonBuy = true : state.products

      }
      break;
    case productConstants.GET_GALLERY_PRODUCTS_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case productConstants.GET_GALLERY_PRODUCTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        galleries: action.payload,
        totalPagesGallery: action.payload.totalPages
      };
      break;
    case productConstants.GET_GALLERY_PRODUCTS_FAILURE:
      state = {
        ...state,
        loading: false
      };
      break;
    case productConstants.GET_PRODUCTS_BY_SLUG_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case productConstants.GET_ALL_PRODUCTS_REQUEST:
      state = {
        ...state,
        loading: true,
      }
      break;
    case productConstants.GET_ALL_PRODUCTS_SUCCESS:
      state = {
        ...state,
        products: action.payload.products,
        productMoreThreeImg: action.payload.products.filter((product) => product.productPictures.length > 2 ? product : null),
        totalPages: action.payload.totalPages,
        productAddCart: action.payload.products.filter((product) => product._id === action.payload._id ? product : null),
        countPage: action.payload.countPage,
        totalAll: action.payload.totalAll,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage,
        perPage: action.payload.perPage,
        message: state.message === "" ? action.payload.message : state.message,
        loading: false,
      }
      break;
    case productConstants.GET_ALL_PRODUCTS_FAILURE:
      state = {
        ...state,
        loading: false,
      }
      break;
    case productConstants.UPDATE_PRODUCT_VIEW_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.UPDATE_PRODUCT_VIEW_SUCCESS:
      state = {
        ...state,
        productDetails: action.payload.product,
        loading: false,
      };
      break;
    case productConstants.UPDATE_PRODUCT_VIEW_FAILURE:
      state = {
        ...state,
        loading: false
      };
      break;
    case productConstants.GET_PRODUCTS_BY_SLUG_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_PRODUCTS_BY_SLUG_SUCCESS:
      state = {
        ...state,
        products: action.payload.products,
        totalPages: action.payload.totalPages,
        countPage: action.payload.countPage,
        totalAll: action.payload.totalAll,
        perPage: action.payload.perPage,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage,
        message: state.message === "" ? action.payload.message : state.message,
        loading: false,
      };
      break;
    case productConstants.GET_PRODUCTS_BY_SLUG_FAILURE:
      state = {
        ...state,
        loading: false
      };
      break;
    case productConstants.GET_PRODUCT_PAGE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_PRODUCT_PAGE_SUCCESS:
      state = {
        ...state,
        page: action.payload.page,
        loading: false,
      };
      break;
    case productConstants.GET_PRODUCT_PAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case productConstants.GET_PRODUCT_DETAILS_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true
      }
      break;
    case productConstants.GET_PRODUCT_DETAILS_BY_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        productDetails: action.payload.productDetails,
      }
      break;
    case productConstants.GET_PRODUCT_DETAILS_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
        // error: action.payload.error
      }
      break;
    case productConstants.SET_VIEW:
      state = {
        ...state,
        view: action.payload
      }
      break;
    case productConstants.SET_PRODUCT_DETAILS_LIST:
      state = {
        ...state,
        productDetailsList: action.payload
      }
      break;
    case productConstants.SET_PRODUCT_SEARCH_TYPE:
      state = {
        ...state,
        productSearchType: action.payload
      }
      break;
    case productConstants.SET_PAGE_SIZE_SUCCESS:
      state = {
        ...state,
        pageSize: action.payload,
        currentPage: 1
      }
      break;
    case productConstants.SET_CURRENT_CATEGORY:
      state = {
        ...state,
        currentCategory: action.payload
      }
      break;
    case productConstants.SET_CURRENT_PAGE:
      state = {
        ...state,
        currentPage: action.payload
      }
      break;
    case productConstants.SEARCH_PRODUCTS_REQUEST:
      state = {
        ...state,
        loading: true
      }
      break;
    case productConstants.SEARCH_PRODUCTS_SUCCESS:
      state = {
        ...state,
        products: action.payload.products,
        message: action.payload.message,
        loading: false,
        showAlert: true,
        variantAlert: "success"
      }
      break;
    case productConstants.SEARCH_PRODUCTS_FAILURE:
      state = {
        ...state,
        message: action.payload,
        loading: false,
        showAlert: true,
        variantAlert: "warning"
      }
      break;
    case productConstants.CLOSE_ALERT_TO_PRODUCT_SUCCESS:
      state = {
        ...state,
        showAlert: false
      }
      break;
    case productConstants.UPDATE_PRODUCT_LIST:
      state = {
        ...state,
        products: [],
      }
      break;
    case productConstants.SET_CURRENT_IMG_SLIDER_SUCCESS:
      state = {
        ...state,
        currentProductImgSlider: action.payload,
      }
      break;
    case productConstants.SET_OPEN_SEARCH_MODAL_PRODUCT:
      state = {
        ...state,
        openSearchModalProduct: action.payload,
      }
      break;
    case productConstants.SET_SEARCH_NAME:
      state = {
        ...state,
        searchName: action.payload,
      }
      break;
  }
  return state;
};

export const setCloseAlertProduct = (payload) => ({ type: productConstants.CLOSE_ALERT_TO_PRODUCT_SUCCESS, payload })
export const setProductView = (payload) => ({ type: productConstants.SET_VIEW, payload })
export const setProductSearchType = (payload) => ({ type: productConstants.SET_PRODUCT_SEARCH_TYPE, payload })
export const setOpenSearchModalProduct = (payload) => ({ type: productConstants.SET_OPEN_SEARCH_MODAL_PRODUCT, payload })
export const setProduct = (products) => ({ type: productConstants.GET_ALL_PRODUCTS_SUCCESS, payload: products })
export const setCurrentPage = (page) => ({ type: productConstants.SET_CURRENT_PAGE, payload: page })
export const setPerPage = (pageSize) => ({ type: productConstants.SET_PER_PAGE_SUCCESS, payload: pageSize })
export const setCurrentCategory = (payload) => ({ type: productConstants.SET_CURRENT_CATEGORY, payload: payload })

export const setCurrentProductImgSlide = (payload) => ({ type: productConstants.SET_CURRENT_IMG_SLIDER_SUCCESS, payload })
export const updateProductList = (products) => ({ type: productConstants.UPDATE_PRODUCT_LIST, payload: products })
export const setProductDetailsList = (payload) => ({ type: productConstants.SET_PRODUCT_DETAILS_LIST, payload })
export const setSearchName = (payload) => ({ type: productConstants.SET_SEARCH_NAME, payload })