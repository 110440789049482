import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { login, signup as _signup, getAllItemsEmptyUser } from '../../actions';
import { MaterialInput } from '../MaterialUi';
import MainButtonSite from './../button/MainButtonSite';
import EmptyButton from './../button/EmptyButton';
import BasicModal from './../MaterialUi/modal';
import Loader from './../Loader';
import ErrorTitle from './../Title/ErrorTitle';
import InputFullBorder from './../Input/InputFullBorder';
import SearchIcon from '@mui/icons-material/Search';
import PasswordError from '../PasswordError';
import RenderSubscriptionsMainPage from '../Checkbox/RenderSubscriptionsMainPage';

const CreateUser = ({
    signup,
    setSignup,
    loading,
    setShowMiniUserModal
}) => {
    const { mainPages } = useSelector((state) => state.mainPage)

    const [subscriptionsArray, setSubscriptionsArray] = useState([]);
    const [userForm, setUserForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordCheck: "",
        contactNumber: "",
    })
    // const [role, setRole] = useState("");
    const [region, setRegion] = useState("");
    const [typePassword, setTypePassword] = useState(false);
    const [errorShowText, setShowErrorText] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [textError, setTextError] = useState("");
    const [typeContent, setTypeContent] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        if (mainPages?.length === 0) {
            dispatch(getAllItemsEmptyUser("mainPage"))
        }
    }, [mainPages])

    const userSignup = () => {
        const user = {
            ...userForm,
            region: region,
        };

        if (
            userForm.firstName === "" ||
            userForm.lastName === "" ||
            userForm.email === "" ||
            userForm.password === "" ||
            userForm.passwordCheck === "" ||
            userForm.contactNumber === "" ||
            region === "" ||
            !passwordMatch
        ) {
            setShowErrorText(true)
            setTextError("Все поля должны быть заполнены")
        }
        if (subscriptionsArray.length > 0) {
            user.subscriptions = subscriptionsArray
        }
        dispatch(_signup(user))
            .then(() => setShowMiniUserModal(false))
    };

    const userLogin = () => {
        if (signup) {
            userSignup();
        } else {
            const loginObj = {
                email: userForm.email,
                password: userForm.password,
            }
            dispatch(login(loginObj)).then(() => {
                setShowMiniUserModal(false);
            }).finally(() => {
                if (!localStorage.getItem("token")) {
                    setShowErrorText(true);
                    setTextError("Неправильный пароль или логин")
                }
            });
        }
    };
    const handleTypeSubscriptions = () => {
        if (
            userForm.firstName === "" ||
            userForm.lastName === "" ||
            userForm.email === "" ||
            userForm.password === "" ||
            userForm.passwordCheck === "" ||
            userForm.contactNumber === "" ||
            region === "" ||
            !passwordMatch) {
            setShowErrorText(true)
            setTextError("Все поля должны быть заполнены")
        } else {
            setTypeContent("subscriptions")
        }

    }

    const renderContentModal = () => {
        let content, title;
        switch (typeContent) {
            case "subscriptions":
                title = <h2>Рассылка</h2>
                content = <>
                    <RenderSubscriptionsMainPage
                        setTypeContent={setTypeContent}
                        items={mainPages}
                        setSubscriptionsArray={setSubscriptionsArray}
                        subscriptionsArray={subscriptionsArray}
                    />
                    <MainButtonSite
                        text="Регистрация"
                        onClick={userLogin}
                    />
                </>
                break;
            default:
                title = <h2>{!signup ? "Войти" : "Регистрация"}</h2>
                content = <>
                    <div className="createUserModal__rowContent">

                        <div className="createUserModal__loginInputContainer">
                            {signup && (
                                <>

                                    <MaterialInput
                                        blackText
                                        type="text"
                                        label="Имя"
                                        value={userForm.firstName}
                                        onChange={(e) => setUserForm({ ...userForm, firstName: e.target.value })}
                                    />
                                    <MaterialInput
                                        blackText
                                        type="text"
                                        label="Фамилию"
                                        value={userForm.lastName}
                                        onChange={(e) => setUserForm({ ...userForm, lastName: e.target.value })}
                                    />

                                    <MaterialInput
                                        blackText
                                        type="text"
                                        label="Мобильный"
                                        value={userForm.contactNumber}
                                        onChange={(e) => setUserForm({ ...userForm, contactNumber: e.target.value })}
                                    />
                                </>

                            )}

                            <MaterialInput
                                blackText
                                type="text"
                                label="Email"
                                value={userForm.email}
                                onChange={(e) => setUserForm({ ...userForm, email: e.target.value })}
                            />
                            <MaterialInput
                                blackText
                                type={typePassword ? "text" : "password"}
                                label="Пароль"
                                value={userForm.password}
                                onClickIcon={() => setTypePassword(!typePassword)}
                                onChange={(e) => setUserForm({ ...userForm, password: e.target.value })}
                                iconBlockPassword
                            />
                            {signup && (
                                <>
                                    <div style={{
                                        position: "relative"
                                    }}>
                                        <MaterialInput
                                            blackText
                                            type={typePassword ? "text" : "password"}
                                            label="Пароль повторно"
                                            onClickIcon={() => setTypePassword(!typePassword)}
                                            value={userForm.passwordCheck}
                                            onChange={(e) => setUserForm({ ...userForm, passwordCheck: e.target.value })}
                                            iconBlockPassword
                                        />
                                        <PasswordError
                                            password={userForm.password}
                                            passwordCheck={userForm.passwordCheck}
                                            passwordMatch={passwordMatch}
                                            setPasswordMatch={setPasswordMatch}
                                        />
                                    </div>
                                    <InputFullBorder
                                        typeInput="allCity"
                                        icon={<SearchIcon />}
                                        label="Ваша область"
                                        type="text"
                                        value={region}
                                        setValue={setRegion}
                                    />
                                </>
                            )}
                            <ErrorTitle
                                text={textError}
                                setShow={setShowErrorText}
                                show={errorShowText}
                            />
                            {
                                signup
                                    ? <MainButtonSite
                                        text="Дальше"
                                        onClick={handleTypeSubscriptions}
                                    />
                                    : <MainButtonSite
                                        text={!signup ? "Войти" : "Регистрация"}
                                        onClick={userLogin}
                                    />
                            }
                            <div className="createUserModal__emptyButBox">
                                <EmptyButton
                                    onClick={() => setSignup(!signup)}
                                    text={!signup ? "Зарегистрироваться" : "У меня уже есть аккаунт"}
                                />
                            </div>
                        </div>
                    </div>
                </>
        }
        return { content, title }
    }
    return (
        <BasicModal>
            <div
                className="createUserModal">
                {loading ? <Loader /> : <div>
                    <div className="createUserModal__rowContent">
                        {renderContentModal().title}
                    </div>
                    {renderContentModal().content}
                    {/* <div className="createUserModal__rowContent">
                        <div className="createUserModal__loginButtonContainer">
                            {!signup
                                ? <h3>
                                    Войти через
                                </h3>
                                : <h3>
                                    Заполнить все данные и пройти регистрациию
                                </h3>}
                            <div className="createUserModal__buttonBox">
                                <OpacityButtonIcon text="Facebook" icon={<FacebookIcon />} />
                                <OpacityButtonIcon text="Google" icon={<FacebookIcon />} />

                            </div>
                        </div>
                    </div> */}
                </div>
                }
            </div>
        </BasicModal >
    )
}

export default CreateUser
