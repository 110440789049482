import React, { useState } from 'react'
import RenderItemProductDetails from './RenderItemProductDetails';
import RenderImgListComponent from './RenderImgListComponent';
import RenderAskQModal from './../../../components/modal/RenderAskQModal';
import FeaturePage from './FeaturePage';
import RenderOtherOffers from './RenderOtherOffers';
import { setProductDetailsList } from './../../../reducers/product.reducer';
import { useDispatch, useSelector } from 'react-redux';
// import QrScanner from '../../QrRender/QRScanner';

const RenderNavMenu = (props) => {

  const dispatch = useDispatch()
  const { productDetailsList, productDetails } = useSelector((state) => state.product)
  const auth = useSelector((state) => state.auth)
  const items = [
    {
      name: "Все о товаре"
    },
    {
      name: "Характеристики"
    },
    {
      name: "Задать вопрос"
    },
    {
      name: "Фото"
    },
    {
      name: "Другие предложения"
    },
  ]
  const showBlock = (item) => {
    dispatch(setProductDetailsList(item.name))
  }

  const renderProduct = () => {
    let content = null;
    switch (productDetailsList) {
      case "Все о товаре":
        content = <RenderItemProductDetails {...props} />;
        break;
      case "Характеристики":
        content = <FeaturePage
          items={props.productObj}
          nameCompany={props.nameCompany}
        />;
        break;
      case "Задать вопрос":
        content = <RenderAskQModal
          setProductDetailsList={setProductDetailsList}
          productId={productDetails._id}
          auth={auth}
        />;
        break;
      case "Фото":
        content = <RenderImgListComponent productObj={props.productObj} />;
        break;
      case "Другие предложения":
        content = <RenderOtherOffers
          productSlug={props.productObj.categorySlug}
        />
        break;
      // case "Модель":
      //   content = <QrScanner
      //     productSlug={props.productObj.categorySlug}
      //   />
      //   break;
      default:
        content = <RenderItemProductDetails {...props} />;
    }

    return content;
  };
  return (
    <>
      <div className="renderNavMenu">
        <div className="renderNavMenu__row">
          {items.map((item, index) => (
            <div
              key={index}
              className="renderNavMenu__content">
              <div
                onClick={() => showBlock(item)}
                className={productDetailsList === item.name ? "renderNavMenu__link active" : "renderNavMenu__link"}>
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="renderNavMenu__renderProductContainer">
        {renderProduct()}
      </div>
    </>
  )
}

export default RenderNavMenu