import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import RenderStatusArray from '../../containers/CabinetPage/ApplicationPage/RenderStatusArray';

const InfoModal = ({
    isOpen,
    onClose,
    children,
    title
}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: "350px",
        maxWidth: "600px",
        minHeight: "100px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        overflowY: "auto"
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h3 style={{ margin: "10px auto", textAlign: "center" }}>
                    {title}
                </h3>
                <>
                    {children}
                </>
                <div style={{
                    marginTop: "15px",
                    textAlign: "right"
                }}>
                    <Button variant="outlined" color="error" onClick={onClose}>Закрыть</Button>
                </div>
            </Box>

        </Modal>
    );
};

export default InfoModal;
