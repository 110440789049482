
export const elementPaymentPageConstants = {
  GET_ELEMENT_PAYMENT_PAGE_REQUEST: "GET_ELEMENT_PAYMENT_PAGE_REQUEST",
  GET_ELEMENT_PAYMENT_PAGE_SUCCESS: "GET_ELEMENT_PAYMENT_PAGE_SUCCESS",
  GET_ELEMENT_PAYMENT_PAGE_FAILURE: "GET_ELEMENT_PAYMENT_PAGE_FAILURE",
};

export const productListLinkConstants = {
  GET_BY_ID_PRODUCT_LIST_LINK_REQUEST: "GET_BY_ID_PRODUCT_LIST_LINK_REQUEST",
  GET_BY_ID_PRODUCT_LIST_LINK_SUCCESS: "GET_BY_ID_PRODUCT_LIST_LINK_SUCCESS",
  GET_BY_ID_PRODUCT_LIST_LINK_FAILURE: "GET_BY_ID_PRODUCT_LIST_LINK_FAILURE",
};
export const contactMConstants = {
  GET_CONTACT_M_REQUEST: "GET_CONTACT_M_REQUEST",
  GET_CONTACT_M_SUCCESS: "GET_CONTACT_M_SUCCESS",
  GET_CONTACT_M_FAILURE: "GET_CONTACT_M_FAILURE",
};

export const galleryImgUserConstants = {
  ADD_TO_GALLERY_IMG_REQUEST: "ADD_TO_GALLERY_IMG_REQUEST",
  ADD_TO_GALLERY_IMG_SUCCESS: "ADD_TO_GALLERY_IMG_SUCCESS",
  ADD_TO_GALLERY_IMG_FAILURE: "ADD_TO_GALLERY_IMG_FAILURE",

  GET_GALLERY_IMAGES_REQUEST: "GET_GALLERY_IMAGES_REQUEST",
  GET_GALLERY_IMAGES_SUCCESS: "GET_GALLERY_IMAGES_SUCCESS",
  GET_GALLERY_IMAGES_FAILURE: "GET_GALLERY_IMAGES_FAILURE",

  GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",

  REMOVE_ITEM_REQUEST: "REMOVE_ITEM_REQUEST",
  REMOVE_ITEM_SUCCESS: "REMOVE_ITEM_SUCCESS",
  REMOVE_ITEM_FAILURE: "REMOVE_ITEM_FAILURE",

  CLOSE_ALERT_SUCCESS: "CLOSE_ALERT_SUCCESS",
}

export const applicationGalleryConstants = {
  CREATE_APPLICATION_GALLERY_REQUEST: "CREATE_APPLICATION_GALLERY_REQUEST",
  CREATE_APPLICATION_GALLERY_SUCCESS: "CREATE_APPLICATION_GALLERY_SUCCESS",
  CREATE_APPLICATION_GALLERY_FAILURE: "CREATE_APPLICATION_GALLERY_FAILURE",

  GET_BY_USER_REQUEST: "GET_BY_USER_REQUEST",
  GET_BY_USER_SUCCESS: "GET_BY_USER_SUCCESS",
  GET_BY_USER_FAILURE: "GET_BY_USER_FAILURE",

  GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",
}
export const roleChangeRequest = {
  CREATE_ROLE_CHANGE_REQUEST: "CREATE_ROLE_CHANGE_REQUEST",
  CREATE_ROLE_CHANGE_SUCCESS: "CREATE_ROLE_CHANGE_SUCCESS",
  CREATE_ROLE_CHANGE_FAILURE: "CREATE_ROLE_CHANGE_FAILURE",

  GET_ROLE_CHANGE_BY_USER_REQUEST: "GET_ROLE_CHANGE_BY_USER_REQUEST",
  GET_ROLE_CHANGE_BY_USER_SUCCESS: "GET_ROLE_CHANGE_BY_USER_SUCCESS",
  GET_ROLE_CHANGE_BY_USER_FAILURE: "GET_ROLE_CHANGE_BY_USER_FAILURE",
}

export const mainActionsConstants = {
  GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",

  UPDATE_VIEW_BY_ID_REQUEST: "UPDATE_VIEW_BY_ID_REQUEST",
  UPDATE_VIEW_BY_ID_SUCCESS: "UPDATE_VIEW_BY_ID_SUCCESS",
  UPDATE_VIEW_BY_ID_FAILURE: "UPDATE_VIEW_BY_ID_FAILURE",

  GET_ALL_ITEMS_REQUEST: "GET_ALL_ITEMS_REQUEST",
  GET_ALL_ITEMS_SUCCESS: "GET_ALL_ITEMS_SUCCESS",
  GET_ALL_ITEMS_FAILURE: "GET_ALL_ITEMS_FAILURE",
}

export const regionConstants = {
  GET_REGION_REQUEST: "GET_REGION_REQUEST",
  GET_REGION_SUCCESS: "GET_REGION_SUCCESS",
  GET_REGION_FAILURE: "GET_REGION_FAILURE",

  GET_REGION_BY_ID_REQUEST: "GET_REGION_BY_ID_REQUEST",
  GET_REGION_BY_ID_SUCCESS: "GET_REGION_BY_ID_SUCCESS",
  GET_REGION_BY_ID_FAILURE: "GET_REGION_BY_ID_FAILURE",
}

export const pickupPointsConstants = {
  GET_PICKUP_POINTS_REQUEST: "GET_PICKUP_POINTS_REQUEST",
  GET_PICKUP_POINTS_SUCCESS: "GET_PICKUP_POINTS_SUCCESS",
  GET_PICKUP_POINTS_FAILURE: "GET_PICKUP_POINTS_FAILURE",
}

export const allQuestionConstants = {
  GET_ALL_QUESTION_REQUEST: "GET_ALL_QUESTION_REQUEST",
  GET_ALL_QUESTION_SUCCESS: "GET_ALL_QUESTION_SUCCESS",
  GET_ALL_QUESTION_FAILURE: "GET_ALL_QUESTION_FAILURE",
}

export const materialConstants = {
  GET_MATERIAL_REQUEST: "GET_MATERIAL_REQUEST",
  GET_MATERIAL_SUCCESS: "GET_MATERIAL_SUCCESS",
  GET_MATERIAL_FAILURE: "GET_MATERIAL_FAILURE",
}

export const partnerConstants = {
  GET_PARTNER_REQUEST: "GET_PARTNER_REQUEST",
  GET_PARTNER_SUCCESS: "GET_PARTNER_SUCCESS",
  GET_PARTNER_FAILURE: "GET_PARTNER_FAILURE",
  CREATE_PARTNER_REQUEST: "CREATE_PARTNER_REQUEST",
  CREATE_PARTNER_SUCCESS: "CREATE_PARTNER_SUCCESS",
  CREATE_PARTNER_FAILURE: "CREATE_PARTNER_FAILURE",
};

export const architectSkillsConstants = {
  GET_ARCHITECT_SKILLS_REQUEST: "GET_ARCHITECT_SKILLS_REQUEST",
  GET_ARCHITECT_SKILLS_SUCCESS: "GET_ARCHITECT_SKILLS_SUCCESS",
  GET_ARCHITECT_SKILLS_FAILURE: "GET_ARCHITECT_SKILLS_FAILURE",
};

export const architectLiConstants = {
  GET_ARCHITECT_LI_REQUEST: "GET_ARCHITECT_LI_REQUEST",
  GET_ARCHITECT_LI_SUCCESS: "GET_ARCHITECT_LI_SUCCESS",
  GET_ARCHITECT_LI_FAILURE: "GET_ARCHITECT_LI_FAILURE",
};

export const categoryConstants = {
  SHOW_CATEGORY_BLOCK: "SHOW_CATEGORY_BLOCK",
  HIDE_CATEGORY_BLOCK: "HIDE_CATEGORY_BLOCK",

  GET_ALL_CATEGORIES_REQUEST: "GET_ALL_CATEGORIES_REQUEST",
  GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FAILURE: "GET_ALL_CATEGORIES_FAILURE",

  GET_CATEGORY_DETAILS_BY_ID_REQUEST: "GET_CATEGORY_DETAILS_BY_ID_REQUEST",
  GET_CATEGORY_DETAILS_BY_ID_SUCCESS: "GET_CATEGORY_DETAILS_BY_ID_SUCCESS",
  GET_CATEGORY_DETAILS_BY_ID_FAILURE: "GET_CATEGORY_DETAILS_BY_ID_FAILURE",

  GET_CATEGORY_DETAILS_BY_SLUG_REQUEST: "GET_CATEGORY_DETAILS_BY_SLUG_REQUEST",
  GET_CATEGORY_DETAILS_BY_SLUG_SUCCESS: "GET_CATEGORY_DETAILS_BY_SLUG_SUCCESS",
  GET_CATEGORY_DETAILS_BY_SLUG_FAILURE: "GET_CATEGORY_DETAILS_BY_SLUG_FAILURE",
};

export const architectConstants = {
  GET_ARCHITECT_REQUEST: "GET_ARCHITECT_REQUEST",
  GET_ARCHITECT_SUCCESS: "GET_ARCHITECT_SUCCESS",
  GET_ARCHITECT_FAILURE: "GET_ARCHITECT_FAILURE",
};
export const benefitsConstants = {
  GET_BENEFITS_REQUEST: "GET_BENEFITS_REQUEST",
  GET_BENEFITS_SUCCESS: "GET_BENEFITS_SUCCESS",
  GET_BENEFITS_FAILURE: "GET_BENEFITS_FAILURE",
};
export const aboutUsConstants = {
  GET_ABOUT_US_REQUEST: "GET_ABOUT_US_REQUEST",
  GET_ABOUT_US_SUCCESS: "GET_ABOUT_US_SUCCESS",
  GET_ABOUT_US_FAILURE: "GET_ABOUT_US_FAILURE",
};

export const paymentListConstants = {
  GET_PAYMENT_LIST_REQUEST: "GET_PAYMENT_LIST_REQUEST",
  GET_PAYMENT_LIST_SUCCESS: "GET_PAYMENT_LIST_SUCCESS",
  GET_PAYMENT_LIST_FAILURE: "GET_PAYMENT_LIST_FAILURE",
};

export const deliveryInfoConstants = {
  GET_DELIVERY_INFO_REQUEST: "GET_DELIVERY_INFO_REQUEST",
  GET_DELIVERY_INFO_SUCCESS: "GET_DELIVERY_INFO_SUCCESS",
  GET_DELIVERY_INFO_FAILURE: "GET_DELIVERY_INFO_FAILURE",

};

export const blogConstants = {
  GET_ALL_BLOG_REQUEST: "GET_ALL_BLOG_REQUEST",
  GET_ALL_BLOG_SUCCESS: "GET_ALL_BLOG_SUCCESS",
  GET_ALL_BLOG_FAILURE: "GET_ALL_BLOG_FAILURE",

  GET_BLOG_DETAILS_BY_ID_REQUEST: "GET_BLOG_DETAILS_BY_ID_REQUEST",
  GET_BLOG_DETAILS_BY_ID_SUCCESS: "GET_BLOG_DETAILS_BY_ID_SUCCESS",
  GET_BLOG_DETAILS_BY_ID_FAILURE: "GET_BLOG_DETAILS_BY_ID_FAILURE",
};
export const warrantyConstants = {
  GET_ALL_WARRANTY_REQUEST: "GET_ALL_WARRANTY_REQUEST",
  GET_ALL_WARRANTY_SUCCESS: "GET_ALL_WARRANTY_SUCCESS",
  GET_ALL_WARRANTY_FAILURE: "GET_ALL_WARRANTY_FAILURE",

};

export const portfolioConstants = {

  GET_ALL_PORTFOLIO_REQUEST: "GET_ALL_PORTFOLIO_REQUEST",
  GET_ALL_PORTFOLIO_SUCCESS: "GET_ALL_PORTFOLIO_SUCCESS",
  GET_ALL_PORTFOLIO_FAILURE: "GET_ALL_PORTFOLIO_FAILURE",
  GET_PORTFOLIO_BY_SLUG_REQUEST: "GET_PORTFOLIO_BY_SLUG_REQUEST",
  GET_PORTFOLIO_BY_SLUG_SUCCESS: "GET_PORTFOLIO_BY_SLUG_SUCCESS",
  GET_PORTFOLIO_BY_SLUG_FAILURE: "GET_PORTFOLIO_BY_SLUG_FAILURE",
  GET_PORTFOLIO_PAGE_REQUEST: "GET_PORTFOLIO_PAGE_REQUEST",
  GET_PORTFOLIO_PAGE_SUCCESS: "GET_PORTFOLIO_PAGE_SUCCESS",
  GET_PORTFOLIO_PAGE_FAILURE: "GET_PORTFOLIO_PAGE_FAILURE",

  GET_PORTFOLIO_DETAILS_BY_ID_REQUEST: "GET_PORTFOLIO_DETAILS_BY_ID_REQUEST",
  GET_PORTFOLIO_DETAILS_BY_ID_SUCCESS: "GET_PORTFOLIO_DETAILS_BY_ID_SUCCESS",
  GET_PORTFOLIO_DETAILS_BY_ID_FAILURE: "GET_PORTFOLIO_DETAILS_BY_ID_FAILURE",
  SET_VIEW: "SET_VIEW"
};

export const productConstants = {

  SET_SEARCH_NAME: "SET_SEARCH_NAME",
  SET_OPEN_SEARCH_MODAL_PRODUCT: "SET_OPEN_SEARCH_MODAL_PRODUCT",
  SET_CURRENT_IMG_SLIDER_SUCCESS: "SET_CURRENT_IMG_SLIDER_SUCCESS",
  SET_PRODUCT_DETAILS_LIST: "SET_PRODUCT_DETAILS_LIST",
  UPDATE_PRODUCT_LIST: "UPDATE_PRODUCT_LIST",

  GET_GALLERY_PRODUCTS_REQUEST: "GET_GALLERY_PRODUCTS_REQUEST",
  GET_GALLERY_PRODUCTS_SUCCESS: "GET_GALLERY_PRODUCTS_SUCCESS",
  GET_GALLERY_PRODUCTS_FAILURE: "GET_GALLERY_PRODUCTS_FAILURE",

  GET_ALL_PRODUCTS_REQUEST: "GET_ALL_PRODUCTS_REQUEST",
  GET_ALL_PRODUCTS_SUCCESS: "GET_ALL_PRODUCTS_SUCCESS",
  GET_ALL_PRODUCTS_FAILURE: "GET_ALL_PRODUCTS_FAILURE",

  GET_PRODUCTS_BY_SLUG_REQUEST: "GET_PRODUCTS_BY_SLUG_REQUEST",
  GET_PRODUCTS_BY_SLUG_SUCCESS: "GET_PRODUCTS_BY_SLUG_SUCCESS",
  GET_PRODUCTS_BY_SLUG_FAILURE: "GET_PRODUCTS_BY_SLUG_FAILURE",

  GET_PRODUCT_PAGE_REQUEST: "GET_PRODUCT_PAGE_REQUEST",
  GET_PRODUCT_PAGE_SUCCESS: "GET_PRODUCT_PAGE_SUCCESS",
  GET_PRODUCT_PAGE_FAILURE: "GET_PRODUCT_PAGE_FAILURE",

  UPDATE_PRODUCT_VIEW_REQUEST: "UPDATE_PRODUCT_VIEW_REQUEST",
  UPDATE_PRODUCT_VIEW_SUCCESS: "UPDATE_PRODUCT_VIEW_SUCCESS",
  UPDATE_PRODUCT_VIEW_FAILURE: "UPDATE_PRODUCT_VIEW_FAILURE",

  GET_PRODUCT_DETAILS_BY_ID_REQUEST: "GET_PRODUCT_DETAILS_BY_ID_REQUEST",
  GET_PRODUCT_DETAILS_BY_ID_SUCCESS: "GET_PRODUCT_DETAILS_BY_ID_SUCCESS",
  GET_PRODUCT_DETAILS_BY_ID_FAILURE: "GET_PRODUCT_DETAILS_BY_ID_FAILURE",
  SET_PER_PAGE_REQUEST: "SET_PER_PAGE_REQUEST",
  SET_PER_PAGE_SUCCESS: "SET_PER_PAGE_SUCCESS",
  SET_PER_PAGE_FAILURE: "SET_PER_PAGE_FAILURE",

  VISIBLE_PRODUCT_BUY_BUTTON: "VISIBLE_PRODUCT_BUY_BUTTON",
  SET_PRODUCT_SEARCH_TYPE: "SET_PRODUCT_SEARCH_TYPE",
  SET_VIEW: "SET_VIEW",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_CURRENT_CATEGORY: "SET_CURRENT_CATEGORY",
  SET_PAGE_SIZE_SUCCESS: "SET_PAGE_SIZE_SUCCESS",
  SET_ACTIVE_CATEGORY: "SET_ACTIVE_CATEGORY",
  CLOSE_ALERT_TO_PRODUCT_SUCCESS: "CLOSE_ALERT_TO_PRODUCT_SUCCESS",

  SEARCH_PRODUCTS_REQUEST: "SEARCH_PRODUCTS_REQUEST",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAILURE: "SEARCH_PRODUCTS_FAILURE",

};
export const authConstants = {
  SHOW_SETTING_MODAL: "SHOW_SETTING_MODAL",
  SET_CLOSE_ALERT: "SET_CLOSE_ALERT",
  SHOW_SEARCH_SHOW: "SHOW_SEARCH_SHOW",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  SHOW_MODAL_USER: "SHOW_MODAL_USER"
};
export const historyProductConstants = {
  ADD_TO_HISTORY_PRODUCT_REQUEST: "ADD_TO_HISTORY_PRODUCT_REQUEST",
  ADD_TO_HISTORY_PRODUCT_SUCCESS: "ADD_TO_HISTORY_PRODUCT_SUCCESS",
  ADD_TO_HISTORY_PRODUCT_FAILURE: "ADD_TO_HISTORY_PRODUCT_FAILURE",
  GET_TO_HISTORY_PRODUCT_REQUEST: "GET_TO_HISTORY_PRODUCT_REQUEST",
  GET_TO_HISTORY_PRODUCT_SUCCESS: "GET_TO_HISTORY_PRODUCT_SUCCESS",
  GET_TO_HISTORY_PRODUCT_FAILURE: "GET_TO_HISTORY_PRODUCT_FAILURE",
  RESET_HISTORY_PRODUCT: "RESET_HISTORY_PRODUCT",
  SHOW_MODAL_HP: "SHOW_MODAL_HP",
  CLOSE_ALERT_TO_HISTORY_PRODUCT: "CLOSE_ALERT_TO_HISTORY_PRODUCT",

  REMOVE_HISTORY_PRODUCT_REQUEST: "REMOVE_HISTORY_PRODUCT_REQUEST",
  REMOVE_HISTORY_PRODUCT_SUCCESS: "REMOVE_HISTORY_PRODUCT_SUCCESS",
  REMOVE_HISTORY_PRODUCT_FAILURE: "REMOVE_HISTORY_PRODUCT_FAILURE",
};

export const desiresConstants = {
  VISIBLE_DESIRES_LIKE_ICON: "VISIBLE_DESIRES_LIKE_ICON",
  CLOSE_ALERT_TO_DESIRES_SUCCESS: "CLOSE_ALERT_TO_DESIRES_SUCCESS",
  ADD_TO_DESIRES_REQUEST: "ADD_TO_DESIRES_REQUEST",
  ADD_TO_DESIRES_SUCCESS: "ADD_TO_DESIRES_SUCCESS",
  ADD_TO_DESIRES_FAILURE: "ADD_TO_DESIRES_FAILURE",

  GET_TO_DESIRES_REQUEST: "GET_TO_DESIRES_REQUEST",
  GET_TO_DESIRES_SUCCESS: "GET_TO_DESIRES_SUCCESS",
  GET_TO_DESIRES_FAILURE: "GET_TO_DESIRES_FAILURE",

  DELETE_ARRAY_DESIRES_REQUEST: "DELETE_ARRAY_DESIRES_REQUEST",
  DELETE_ARRAY_DESIRES_SUCCESS: "DELETE_ARRAY_DESIRES_SUCCESS",
  DELETE_ARRAY_DESIRES_FAILURE: "DELETE_ARRAY_DESIRES_FAILURE",

  RESET_DESIRES: "RESET_DESIRES",
  SHOW_MODAL_DESIRES: "SHOW_MODAL_DESIRES",

  REMOVE_DESIRES_ITEM_REQUEST: "REMOVE_DESIRES_ITEM_REQUEST",
  REMOVE_DESIRES_ITEM_SUCCESS: "REMOVE_DESIRES_ITEM_SUCCESS",
  REMOVE_DESIRES_ITEM_FAILURE: "REMOVE_DESIRES_ITEM_FAILURE",
};
export const cartConstants = {

  UPDATE_CART_ITEM_QUANTITY_REQUEST: "UPDATE_CART_ITEM_QUANTITY_REQUEST",
  UPDATE_CART_ITEM_QUANTITY_SUCCESS: "UPDATE_CART_ITEM_QUANTITY_SUCCESS",
  UPDATE_CART_ITEM_QUANTITY_FAILURE: "UPDATE_CART_ITEM_QUANTITY_FAILURE",

  ADD_TO_CART_REQUEST: "ADD_TO_CART_REQUEST",
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
  ADD_TO_CART_FAILURE: "ADD_TO_CART_FAILURE",

  GET_CART_ITEMS_REQUEST: "GET_CART_ITEMS_REQUEST",
  GET_CART_ITEMS_SUCCESS: "GET_CART_ITEMS_SUCCESS",
  GET_CART_ITEMS_FAILURE: "GET_CART_ITEMS_FAILURE",

  UPDATE_TO_CART_SUCCESS: "UPDATE_TO_CART_SUCCESS",
  FILTER_CART_ID: "FILTER_CART_ID",
  SET_CLOSE_ALERT: "SET_CLOSE_ALERT",
  RESET_CART: "RESET_CART",
  SHOW_MODAL_CART: "SHOW_MODAL_CART",
  SHOW_MODAL_CART_FALSE: "SHOW_MODAL_CART_FALSE",

  REMOVE_CART_ITEM_REQUEST: "REMOVE_CART_ITEM_REQUEST",
  REMOVE_CART_ITEM_SUCCESS: "REMOVE_CART_ITEM_SUCCESS",
  REMOVE_CART_ITEM_FAILURE: "REMOVE_CART_ITEM_FAILURE",
};
export const orderConstants = {
  CLOSE_ALERT_TO_ORDER_SUCCESS: "CLOSE_ALERT_TO_ORDER_SUCCESS",
  ADD_USER_ORDER_REQUEST: "ADD_USER_ORDER_REQUEST",
  ADD_USER_ORDER_SUCCESS: "ADD_USER_ORDER_SUCCESS",
  ADD_USER_ORDER_FAILURE: "ADD_USER_ORDER_FAILURE",

  SEARCH_ORDERS_BY_ORDER_NUMBER_REQUEST: "SEARCH_ORDERS_BY_ORDER_NUMBER_REQUEST",
  SEARCH_ORDERS_BY_ORDER_NUMBER_SUCCESS: "SEARCH_ORDERS_BY_ORDER_NUMBER_SUCCESS",
  SEARCH_ORDERS_BY_ORDER_NUMBER_FAILURE: "SEARCH_ORDERS_BY_ORDER_NUMBER_FAILURE",

  GET_USER_ORDER_REQUEST: "GET_USER_ORDER_REQUEST",
  GET_USER_ORDER_SUCCESS: "GET_USER_ORDER_SUCCESS",
  GET_USER_ORDER_FAILURE: "GET_USER_ORDER_FAILURE",

  UPDATE_ORDER_BY_ID_VISIBLE_REQUEST: "UPDATE_ORDER_BY_ID_VISIBLE_REQUEST",
  UPDATE_ORDER_BY_ID_VISIBLE_SUCCESS: "UPDATE_ORDER_BY_ID_VISIBLE_SUCCESS",
  UPDATE_ORDER_BY_ID_VISIBLE_FAILURE: "UPDATE_ORDER_BY_ID_VISIBLE_FAILURE",

  GET_USER_ORDER_DETAILS_REQUEST: "GET_USER_ORDER_DETAILS_REQUEST",
  GET_USER_ORDER_DETAILS_SUCCESS: "GET_USER_ORDER_DETAILS_SUCCESS",
  GET_USER_ORDER_DETAILS_FAILURE: "GET_USER_ORDER_DETAILS_FAILURE",
};

export const userConstants = {
  USER_UPDATE_SITE_THEME_TYPE_REQUEST: "USER_UPDATE_SITE_THEME_TYPE_REQUEST",
  USER_UPDATE_SITE_THEME_TYPE_SUCCESS: "USER_UPDATE_SITE_THEME_TYPE_SUCCESS",
  USER_UPDATE_SITE_THEME_TYPE_FAILURE: "USER_UPDATE_SITE_THEME_TYPE_FAILURE",

  USER_UPDATE_TRAFFIC_SAVING_REQUEST: "USER_UPDATE_TRAFFIC_SAVING_REQUEST",
  USER_UPDATE_TRAFFIC_SAVING_SUCCESS: "USER_UPDATE_TRAFFIC_SAVING_SUCCESS",
  USER_UPDATE_TRAFFIC_SAVING_FAILURE: "USER_UPDATE_TRAFFIC_SAVING_FAILURE",

  GET_USER_ADDRESS_REQUEST: "GET_USER_ADDRESS_REQUEST",
  GET_USER_ADDRESS_SUCCESS: "GET_USER_ADDRESS_SUCCESS",
  GET_USER_ADDRESS_FAILURE: "GET_USER_ADDRESS_FAILURE",

  ADD_USER_ADDRESS_REQUEST: "ADD_USER_ADDRESS_REQUEST",
  ADD_USER_ADDRESS_SUCCESS: "ADD_USER_ADDRESS_SUCCESS",
  ADD_USER_ADDRESS_FAILURE: "ADD_USER_ADDRESS_FAILURE",

  UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_REQUEST: "UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_REQUEST",
  UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_SUCCESS: "UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_SUCCESS",
  UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_FAILURE: "UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_FAILURE",

  UPDATE_USER_REGION_REQUEST: "UPDATE_USER_REGION_REQUEST",
  UPDATE_USER_REGION_SUCCESS: "UPDATE_USER_REGION_SUCCESS",
  UPDATE_USER_REGION_FAILURE: "UPDATE_USER_REGION_FAILURE",

  UPDATE_SUBSCRIBE_USER_BY_ID: "UPDATE_SUBSCRIBE_USER_BY_ID"
};


export const filterConstants = {
  SET_SORT_BY: "SET_SORT_BY"
}


export const applicationChangeProfileConstants = {
  SET_MODAL_TYPE_APPLICATION_CHANGE_PROFILE: "SET_MODAL_TYPE_APPLICATION_CHANGE_PROFILE",
  VISIBLE_REGISTER_MODAL_APPLICATION_CHANGE_PROFILE: "VISIBLE_REGISTER_MODAL_APPLICATION_CHANGE_PROFILE",
  PASSWORDS_MATCH_APPLICATION_CHANGE_PROFILE_SUCCESS: "PASSWORDS_MATCH_APPLICATION_CHANGE_PROFILE_SUCCESS",

  CREATE_APPLICATION_CHANGE_PROFILE_REQUEST: "CREATE_APPLICATION_CHANGE_PROFILE_REQUEST",
  CREATE_APPLICATION_CHANGE_PROFILE_SUCCESS: "CREATE_APPLICATION_CHANGE_PROFILE_SUCCESS",
  CREATE_APPLICATION_CHANGE_PROFILE_FAILURE: "CREATE_APPLICATION_CHANGE_PROFILE_FAILURE",

  GET_APPLICATION_CHANGE_PROFILE_BY_USER_REQUEST: "GET_APPLICATION_CHANGE_PROFILE_BY_USER_REQUEST",
  GET_APPLICATION_CHANGE_PROFILE_BY_USER_SUCCESS: "GET_APPLICATION_CHANGE_PROFILE_BY_USER_SUCCESS",
  GET_APPLICATION_CHANGE_PROFILE_BY_USER_FAILURE: "GET_APPLICATION_CHANGE_PROFILE_BY_USER_FAILURE",

  GET_APPLICATION_CHANGE_PROFILE_BY_ID_REQUEST: "GET_APPLICATION_CHANGE_PROFILE_BY_ID_REQUEST",
  GET_APPLICATION_CHANGE_PROFILE_BY_ID_SUCCESS: "GET_APPLICATION_CHANGE_PROFILE_BY_ID_SUCCESS",
  GET_APPLICATION_CHANGE_PROFILE_BY_ID_FAILURE: "GET_APPLICATION_CHANGE_PROFILE_BY_ID_FAILURE",

  ADD_SAMPLE_PHOTO_REQUEST: "ADD_SAMPLE_PHOTO_REQUEST",
  ADD_SAMPLE_PHOTO_SUCCESS: "ADD_SAMPLE_PHOTO_SUCCESS",
  ADD_SAMPLE_PHOTO_FAILURE: "ADD_SAMPLE_PHOTO_FAILURE",

  CLOSE_ALERT_TO_APPLICATION_CHANGE_PROFILE: "CLOSE_ALERT_TO_APPLICATION_CHANGE_PROFILE",
}

export const applicationCallMeConstants = {
  SET_MODAL_TYPE_APPLICATION_CALL_ME: "SET_MODAL_TYPE_APPLICATION_CALL_ME",
  VISIBLE_REGISTER_MODAL_APPLICATION_CALL_ME: "VISIBLE_REGISTER_MODAL_APPLICATION_CALL_ME",
  PASSWORDS_MATCH_APPLICATION_CALL_ME_SUCCESS: "PASSWORDS_MATCH_APPLICATION_CALL_ME_SUCCESS",

  CREATE_APPLICATION_CALL_ME_REQUEST: "CREATE_APPLICATION_CALL_ME_REQUEST",
  CREATE_APPLICATION_CALL_ME_SUCCESS: "CREATE_APPLICATION_CALL_ME_SUCCESS",
  CREATE_APPLICATION_CALL_ME_FAILURE: "CREATE_APPLICATION_CALL_ME_FAILURE",

  GET_APPLICATION_CALL_ME_BY_USER_REQUEST: "GET_APPLICATION_CALL_ME_BY_USER_REQUEST",
  GET_APPLICATION_CALL_ME_BY_USER_SUCCESS: "GET_APPLICATION_CALL_ME_BY_USER_SUCCESS",
  GET_APPLICATION_CALL_ME_BY_USER_FAILURE: "GET_APPLICATION_CALL_ME_BY_USER_FAILURE",

  ADD_SAMPLE_PHOTO_REQUEST: "ADD_SAMPLE_PHOTO_REQUEST",
  ADD_SAMPLE_PHOTO_SUCCESS: "ADD_SAMPLE_PHOTO_SUCCESS",
  ADD_SAMPLE_PHOTO_FAILURE: "ADD_SAMPLE_PHOTO_FAILURE",

  CLOSE_ALERT_TO_APPLICATION_CALL_ME: "CLOSE_ALERT_TO_APPLICATION_CALL_ME",
}
export const applicationConstants = {
  SET_MODAL_TYPE_APPLICATION: "SET_MODAL_TYPE_APPLICATION",
  VISIBLE_REGISTER_MODAL_APPLICATION: "VISIBLE_REGISTER_MODAL_APPLICATION",
  PASSWORDS_MATCH_APPLICATION_SUCCESS: "PASSWORDS_MATCH_APPLICATION_SUCCESS",

  CREATE_APPLICATION_REQUEST: "CREATE_APPLICATION_REQUEST",
  CREATE_APPLICATION_SUCCESS: "CREATE_APPLICATION_SUCCESS",
  CREATE_APPLICATION_FAILURE: "CREATE_APPLICATION_FAILURE",

  GET_APPLICATION_BY_USER_REQUEST: "GET_APPLICATION_BY_USER_REQUEST",
  GET_APPLICATION_BY_USER_SUCCESS: "GET_APPLICATION_BY_USER_SUCCESS",
  GET_APPLICATION_BY_USER_FAILURE: "GET_APPLICATION_BY_USER_FAILURE",

  ADD_SAMPLE_PHOTO_REQUEST: "ADD_SAMPLE_PHOTO_REQUEST",
  ADD_SAMPLE_PHOTO_SUCCESS: "ADD_SAMPLE_PHOTO_SUCCESS",
  ADD_SAMPLE_PHOTO_FAILURE: "ADD_SAMPLE_PHOTO_FAILURE",

  CLOSE_ALERT_TO_APPLICATION: "CLOSE_ALERT_TO_APPLICATION",
}



export const manufactureConstants = {
  GET_MANUFACTURE_REQUEST: "GET_MANUFACTURE_REQUEST",
  GET_MANUFACTURE_SUCCESS: "GET_MANUFACTURE_SUCCESS",
  GET_MANUFACTURE_FAILURE: "GET_MANUFACTURE_FAILURE",
}

export const mainImageConstants = {
  GET_MAIN_IMAGE_REQUEST: "GET_MAIN_IMAGE_REQUEST",
  GET_MAIN_IMAGE_SUCCESS: "GET_MAIN_IMAGE_SUCCESS",
  GET_MAIN_IMAGE_FAILURE: "GET_MAIN_IMAGE_FAILURE",
}

export const comeToUsConstants = {
  GET_COME_TO_US_REQUEST: "GET_COME_TO_US_REQUEST",
  GET_COME_TO_US_SUCCESS: "GET_COME_TO_US_SUCCESS",
  GET_COME_TO_US_FAILURE: "GET_COME_TO_US_FAILURE",
}

export const weWorkOnlineConstants = {
  GET_WE_WORK_ONLINE_REQUEST: "GET_WE_WORK_ONLINE_REQUEST",
  GET_WE_WORK_ONLINE_SUCCESS: "GET_WE_WORK_ONLINE_SUCCESS",
  GET_WE_WORK_ONLINE_FAILURE: "GET_WE_WORK_ONLINE_FAILURE",
}




export const promotionConstants = {
  GET_PROMOTION_REQUEST: "GET_PROMOTION_REQUEST",
  GET_PROMOTION_SUCCESS: "GET_PROMOTION_SUCCESS",
  GET_PROMOTION_FAILURE: "GET_PROMOTION_FAILURE",

  GET_PROMOTION_DETAILS_BY_ID_REQUEST: "GET_PROMOTION_DETAILS_BY_ID_REQUEST",
  GET_PROMOTION_DETAILS_BY_ID_SUCCESS: "GET_PROMOTION_DETAILS_BY_ID_SUCCESS",
  GET_PROMOTION_DETAILS_BY_ID_FAILURE: "GET_PROMOTION_DETAILS_BY_ID_FAILURE",

}

export const paymentConstants = {
  GET_PAYMENT_REQUEST: "GET_PAYMENT_REQUEST",
  GET_PAYMENT_SUCCESS: "GET_PAYMENT_SUCCESS",
  GET_PAYMENT_FAILURE: "GET_PAYMENT_FAILURE",

}

export const contactConstants = {
  GET_CONTACT_REQUEST: "GET_CONTACT_REQUEST",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_FAILURE: "GET_CONTACT_FAILURE",
}

export const graniteMaterialConstants = {
  GET_GRANITE_MATERIAL_REQUEST: "GET_GRANITE_MATERIAL_REQUEST",
  GET_GRANITE_MATERIAL_SUCCESS: "GET_GRANITE_MATERIAL_SUCCESS",
  GET_GRANITE_MATERIAL_FAILURE: "GET_GRANITE_MATERIAL_FAILURE",
}

export const paymentLIConstants = {
  GET_PAYMENT_LI_REQUEST: "GET_PAYMENT_LI_REQUEST",
  GET_PAYMENT_LI_SUCCESS: "GET_PAYMENT_LI_SUCCESS",
  GET_PAYMENT_LI_FAILURE: "GET_PAYMENT_LI_FAILURE",
}

export const costDeliveryConstants = {
  GET_COST_DELIVERY_REQUEST: "GET_COST_DELIVERY_REQUEST",
  GET_COST_DELIVERY_SUCCESS: "GET_COST_DELIVERY_SUCCESS",
  GET_COST_DELIVERY_FAILURE: "GET_COST_DELIVERY_FAILURE",
}

export const tombstoneCurbConstants = {
  GET_TOMBSTONE_CURB_REQUEST: "GET_TOMBSTONE_CURB_REQUEST",
  GET_TOMBSTONE_CURB_SUCCESS: "GET_TOMBSTONE_CURB_SUCCESS",
  GET_TOMBSTONE_CURB_FAILURE: "GET_TOMBSTONE_CURB_FAILURE",
}

export const galleryConstants = {
  GET_GALLERY_REQUEST: "GET_GALLERY_REQUEST",
  GET_GALLERY_SUCCESS: "GET_GALLERY_SUCCESS",
  GET_GALLERY_FAILURE: "GET_GALLERY_FAILURE",

  GET_GALLERY_BY_ID_REQUEST: "GET_GALLERY_BY_ID_REQUEST",
  GET_GALLERY_BY_ID_SUCCESS: "GET_GALLERY_BY_ID_SUCCESS",
  GET_GALLERY_BY_ID_FAILURE: "GET_GALLERY_BY_ID_FAILURE",

  UPDATE_GALLERY_VIEW_REQUEST: "UPDATE_GALLERY_VIEW_REQUEST",
  UPDATE_GALLERY_VIEW_SUCCESS: "UPDATE_GALLERY_VIEW_SUCCESS",
  UPDATE_GALLERY_VIEW_FAILURE: "UPDATE_GALLERY_VIEW_FAILURE",

  UPDATE_GALLERY_PICTURE_VIEW_REQUEST: "UPDATE_GALLERY_PICTURE_VIEW_REQUEST",
  UPDATE_GALLERY_PICTURE_VIEW_SUCCESS: "UPDATE_GALLERY_PICTURE_VIEW_SUCCESS",
  UPDATE_GALLERY_PICTURE_VIEW_FAILURE: "UPDATE_GALLERY_PICTURE_VIEW_FAILURE",
};


