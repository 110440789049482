import React, { useEffect, useState } from 'react'
import HeaderMainPage from '../mainPage/HeaderMainPage'
import FooterMainPage from '../mainPage/FooterMainPage'
import { createApplicationGallery, getItemByIdWithoutUser } from '../actions'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { goToTop } from '../components/GoToTop';
import { getGalleryById } from '../actions';
import MasonryGallery from '../components/Gallery/MasonryGallery';
import { addToGalleryImgUser, getGalleryImgUserItems, removeGalleryImgUserItem } from '../actions/galleryImgUser';
import activeAddButton from '../containers/Products/ActiveAddButton';
import AlertComponent from '../components/Alert';
import { setShowAlertGalleryImgUser } from '../reducers/galleryImgUser';
import ApplicationGalleryModal from './Loft/components/ApplicationGalleryModal';


const ContainerPage = ({
    children,
    setShowFooter
}) => {
    const { user, authenticate } = useSelector((state) => state.auth)
    const {
        galleryImagesUser,
        variantAlert,
        showAlert,
        textAlert
    } = useSelector((state) => state.galleryImgUser)

    const { mainPageObj, loading } = useSelector((state) => state.mainPage)
    const { galleryObj } = useSelector((state) => state.gallery)


    const [showModal, setShowModal] = useState(false)
    const [dataProcessingConsent, setDataProcessingConsent] = useState(false)
    const [deleteAllItem, setDeleteAllItem] = useState(true)
    const [slideIndex, setSlideIndex] = useState(null)
    const [localStorageOk, setLocalStorageOk] = useState(false)
    const [localStorageLiked, setLocalStorageLiked] = useState([])

    const [applicationForm, setApplicationForm] = useState({
        fullName: "",
        descriptionProblem: "",
        nameCompany: "",
        email: "",
        contactNumber: "",
        galleryId: ""
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()
    let { mainPageId } = useParams();

    const setUserObj = () => {
        setApplicationForm({
            fullName: user.fullName,
            descriptionProblem: "",
            nameCompany: "",
            contactNumber: user.contactNumber,
            email: user.email,
            galleryId: mainPageObj.galleryId
        })
    }

    useEffect(() => {
        const galleryItemData = JSON.parse(localStorage.getItem('galleryItemData')) || [];
        setLocalStorageLiked(galleryItemData)
    }, [localStorageOk])

    useEffect(() => {
        setUserObj()
    }, [authenticate])

    useEffect(() => {
        dispatch(getItemByIdWithoutUser("mainPage", mainPageId))
    }, [])

    useEffect(() => {
        if (!mainPageObj.galleryId) return
        dispatch(getGalleryById(mainPageObj.galleryId, false, true))
    }, [mainPageObj])

    useEffect(() => {
        setShowFooter(false)
    }, [])

    useEffect(() => {
        if (authenticate) {
            dispatch(getGalleryImgUserItems())
        }
    }, [authenticate])

    const handleBack = () => {
        navigate("/")
        goToTop("onlyTop", "0")
    }
    const galleryItemData = JSON.parse(localStorage.getItem('galleryItemData')) || [];

    const addGalleryImgUser = (item) => {
        const { _id, img } = item;
        const payload = {
            userId: user._id,
            galleryItemId: _id,
            img
        };

        if (authenticate) {
            if (activeAddButton(item.img, galleryImagesUser.galleryImgUserItems, "img") === item.img) {
                dispatch(removeGalleryImgUserItem(payload));
            } else {
                dispatch(addToGalleryImgUser(payload));
            }
        } else {
            const existingItemIndex = galleryItemData.findIndex((data) => data.img === item.img);

            if (existingItemIndex !== -1) {
                galleryItemData.splice(existingItemIndex, 1);
                setLocalStorageOk(!localStorageOk)
            } else {
                galleryItemData.push(item);
                setLocalStorageOk(!localStorageOk)
            }
            localStorage.setItem('galleryItemData', JSON.stringify(galleryItemData));
        }
    };

    const clear = () => {
        setApplicationForm({
            fullName: user.fullName,
            descriptionProblem: "",
            nameCompany: "",
            contactNumber: user.contactNumber,
            email: user.email,
            galleryId: galleryObj._id
        })
    }
    const submitApplicationGallery = () => {
        const payload = {
            applicationForm,
            deleteAllItem,
            dataProcessingConsent,
        };
        if (authenticate) {
            payload.createdBy = user._id
            payload.galleryPictures = galleryImagesUser.galleryImgUserItems
        } else {
            payload.galleryPictures = localStorageLiked
        }
        dispatch(createApplicationGallery(payload));
        setShowModal(false)
        clear()
    };

    const removeGalleryImgItem = (galleryItemId) => {
        const payload = {
            galleryItemId
        }
        dispatch(removeGalleryImgUserItem(payload))
    }
    return (
        <div className='containerPage'>
            <HeaderMainPage
                background
            />
            <div className="containerPage__childrenContent"
            >
                {loading
                    ? <Loader />
                    : <>
                        <div className="containerPage__mainContentBox">
                            <div className="containerPage__title">
                                {mainPageObj.title}
                            </div>
                            <div className="containerPage__description">
                                {mainPageObj.description}
                            </div>
                            <div className="containerPage__buttonText">
                                <div className="containerPage__goBack"
                                    onClick={handleBack}
                                >
                                    Вернуться назад на главную
                                </div>
                            </div>
                        </div>
                        {children}
                    </>
                }
                <MasonryGallery
                    localStorageLiked={localStorageLiked}
                    galleryImagesUser={galleryImagesUser}
                    addGalleryImgUser={addGalleryImgUser}
                    itemsPicture={galleryObj.galleryPictures}
                    galleryId={galleryObj._id}
                    contentItemType="mainPages"
                    user={user}
                    authenticate={authenticate}
                />
            </div>
            <FooterMainPage
                background
            />
            <AlertComponent
                dispatchCloseAlert={setShowAlertGalleryImgUser}
                color={variantAlert}
                showObj={showAlert}
                text={textAlert}
            />
            <ApplicationGalleryModal
                setDeleteAllItem={setDeleteAllItem}
                deleteAllItem={deleteAllItem}
                submitApplicationGallery={submitApplicationGallery}
                removeGalleryImgItem={removeGalleryImgItem}
                galleryPictures={!authenticate ? localStorageLiked : galleryImagesUser.galleryImgUserItems}
                slideIndex={slideIndex}
                applicationForm={applicationForm}
                setApplicationForm={setApplicationForm}
                showModal={showModal}
                setShowModal={setShowModal}
                itemsLength={!authenticate ? localStorageLiked?.length : galleryImagesUser.galleryImgUserItems?.length}
                dataProcessingConsent={dataProcessingConsent}
                setDataProcessingConsent={setDataProcessingConsent}
            />
        </div>
    )
}

export default ContainerPage