import axios from "../helpers/axios";
import { galleryImgUserConstants } from "./constants";
import store from "../store";

const getGalleryImgUserItems = (sort = "all") => {
  return async (dispatch) => {
    try {
      dispatch({ type: galleryImgUserConstants.GET_GALLERY_IMAGES_REQUEST });

      const { auth: { authenticate } } = store.getState();
      if (authenticate) {
        const res = await axios.get(`/galleryImgUser/get`);
        if (res.status === 200) {
          dispatch({
            type: galleryImgUserConstants.GET_GALLERY_IMAGES_SUCCESS,
            payload: res.data,
          });
        }
      } else {
        const localGalleryImagesUser = JSON.parse(localStorage.getItem('galleryItemData')) || [];
        console.log("localGalleryImagesUser", localGalleryImagesUser)
        dispatch({
          type: galleryImgUserConstants.GET_GALLERY_IMAGES_SUCCESS,
          payload: { galleryImagesUser: localGalleryImagesUser },
        });
      }
    } catch (error) {
      dispatch({
        type: galleryImgUserConstants.GET_GALLERY_IMAGES_FAILURE,
        payload: { error },
      });
    }
  };
};


export const addToGalleryImgUser = (payload) => {
  return async (dispatch) => {
    const {
      galleryImgUser: { galleryImgUserItems },
      auth,
    } = store.getState();

    try {
      if (auth.authenticate) {
        dispatch({ type: galleryImgUserConstants.ADD_TO_GALLERY_IMG_REQUEST });

        const res = await axios.post("/galleryImgUser/add", {
          galleryItems: [payload],
        });

        if (res.status === 200) {
          dispatch(getGalleryImgUserItems("all"));
        }
      } else {
        const updatedGalleryImgUserItems = [...galleryImgUserItems, payload];
        localStorage.setItem("galleryItemData", JSON.stringify(updatedGalleryImgUserItems));

        dispatch({
          type: galleryImgUserConstants.ADD_TO_GALLERY_IMG_SUCCESS,
          payload: { galleryImgUserItems: updatedGalleryImgUserItems },
        });
      }
    } catch (e) {
      dispatch({
        type: galleryImgUserConstants.ADD_TO_GALLERY_IMG_FAILURE,
        payload: { galleryImgUserItems },
      });
    }
  };
};


export const removeGalleryImgUserItem = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: galleryImgUserConstants.REMOVE_ITEM_REQUEST });
      const res = await axios.post("/galleryImgUser/removeItem", payload);
      if (res.status === 200) {
        dispatch({
          type: galleryImgUserConstants.REMOVE_ITEM_SUCCESS,
          payload: res.data
        });
        dispatch(getGalleryImgUserItems());
      } else {
        dispatch({
          type: galleryImgUserConstants.REMOVE_ITEM_FAILURE,
          payload: res.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateGalleryImgUserItem = () => {
  return async (dispatch) => {
    const { auth } = store.getState();
    let galleryImgUserItems = localStorage.getItem("galleryItemData")
      ? JSON.parse(localStorage.getItem("galleryItemData"))
      : null;

    if (auth.authenticate) {
      if (galleryImgUserItems && galleryImgUserItems.length > 0) {
        const payload = {
          galleryItems: galleryImgUserItems.map((item) => ({
            galleryItemId: item._id,
            img: item.img,
          })),
        };

        const res = await axios.post("/galleryImgUser/add", payload);

        if (res.status === 200) {
          localStorage.removeItem("galleryItemData");
          dispatch(getGalleryImgUserItems());
        }
      } else {
        dispatch(getGalleryImgUserItems());
      }
    } else {
      if (galleryImgUserItems) {
        dispatch({
          type: galleryImgUserConstants.ADD_TO_GALLERY_IMG_SUCCESS,
          payload: { galleryImgUserItems },
        });
        dispatch(getGalleryImgUserItems());
      }
    }
  };
};


export { getGalleryImgUserItems };
