import { warrantyConstants } from '../../actions/constants';



const initialState = {
    warranty: [],
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case warrantyConstants.GET_ALL_WARRANTY_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case warrantyConstants.GET_ALL_WARRANTY_SUCCESS:
            state = {
                ...state,
                warranty: action.payload.warranty,
                loading: false
            }
            break
        case warrantyConstants.GET_ALL_WARRANTY_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
    }
    return state
}