import React, { useState, useEffect } from 'react';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';


const ScrollBlock = () => {

    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 1500) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    if (!isVisible) return null
    return (
        <div className={`scroll-block  ${isVisible ? 'visible' : ''}`}
            onClick={scrollToTop}
        >
            <VerticalAlignTopIcon />
        </div>
    );
};

export default ScrollBlock;
