import React, { useState, useEffect } from 'react'
import ContainerCheckoutList from './ContainerCheckoutList';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputFullBorder from './../../../components/Input/InputFullBorder';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import RenderSuccessDataContent from './RenderSuccessDataContent';

const CheckoutUserForm = ({
    setDisabledButtonPayment,
    setDisabledButton,
    disabledButton,
    setSuccessData,
    successData,
    setUserType,
    userType,
    setUserData,
    showSuccessButtons
}) => {

    const auth = useSelector((state) => state.auth)
    const { user, authenticate } = auth
    const [fullName, setFullName] = useState(authenticate ? user.fullName : "")
    const [email, setEmail] = useState(authenticate ? user.email : "")
    const [mobileNumber, setMobileNumber] = useState(auth.authenticate ? auth.user.number : "")

    useEffect(() => {
        if (authenticate) {
            setFullName(user.fullName)
            setEmail(user.email)
            setMobileNumber(user.contactNumber)
        }
    }, [authenticate])

    useEffect(() => {
        setUserData({
            fullName,
            email,
            mobileNumber,
        });

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\+?\d+$/;

        if (fullName === "" || !emailRegex.test(email) || !phoneRegex.test(mobileNumber)) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }
    }, [fullName, email, mobileNumber]);

    useEffect(() => {
        if (successData) {
            setDisabledButtonPayment(false)
        } else {
            setDisabledButtonPayment(true)
        }
    }, [successData])

    const arrayUserType = [
        {
            inputValue: "individual",
            inputText: "Физическое лицо"
        },
        {
            inputValue: "entity",
            inputText: "Юридическое лицо"
        }
    ]

    const renderRadioInputBlock = () => {
        return (
            <div className="renderRadioInputBlock">
                <div className="renderRadioInputBlock__mainBox">
                    {arrayUserType.map((obj, index) => (
                        <div className="custom-radio"
                            key={index}
                        >
                            <label>
                                <input
                                    type="radio"
                                    name="radio"
                                    value={obj.inputValue}
                                    checked={userType === obj.inputValue}
                                    onChange={(e) => setUserType(e.target.value)}
                                />
                                <div className="custom-radio__label">
                                    <strong>{obj.inputText}</strong>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const renderContentDelivery = () => {
        return (
            <div>
                <Row style={{
                    marginBottom: "20px"
                }}>
                    <Col md="12">
                        {renderRadioInputBlock()}
                    </Col>
                </Row>
                <InputFullBorder
                    label="Ф.И.О"
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                />

                <Row>
                    <Col md="6">
                        <InputFullBorder
                            label="E-Mail"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Col>
                    <Col md="6">
                        <InputFullBorder
                            label="Телефон"
                            type="text"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="12"
                        style={{
                            color: "#777777",
                            lineHeight: "20px",
                        }}
                    >
                        Здесь приведены все ваши данные, которые были автоматически получены
                        из вашего профиля. Если вам необходимо внести изменения, вы можете
                        сделать это легко.
                    </Col>
                </Row>
            </div>
        )
    }
    const renderSuccessDataContent = () => {
        let userDataItems = [
            {
                title: "ФИО",
                content: fullName
            },
            {
                title: "Номер",
                content: mobileNumber
            },
            {
                title: "Email",
                content: email
            },
        ]
        return successData
            ? userDataItems.map((item, index) => <RenderSuccessDataContent
                key={index}
                title={item.title}
                content={item.content}
            />)
            : renderContentDelivery()
    }
    return (
        <ContainerCheckoutList
            disabledButton={disabledButton}
            setSuccessData={setSuccessData}
            successData={successData}
            title="Тип покупателя и его данные"
            icon={<AccountCircleIcon />}
            children={successData ? renderSuccessDataContent() : renderContentDelivery()}
            showSuccessButtons={showSuccessButtons}
            heightType={successData && "150px"}
        />
    )
}

export default CheckoutUserForm