import React, { useEffect } from 'react'
import ContainerMainComponent from '../../ContainerMainComponent';
import SliderMiniImg from '../../../components/Slider/SliderMiniImg';
import TwoButtonsComponent from '../../../components/TwoButtonsComponent';
import RequestForCalculating from '../../../components/RequestForCalculating';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPortfolioDetailsById } from '../../../actions';
import LinkButtonBlock from '../../../components/button/LinkButtonBlock';
import { CircularProgress } from '@mui/material';
import CategoryListComponent from './../../../components/Category/CategoryListComponent';

const PortfolioPageDetails = () => {
    const portfolio = useSelector((state) => state.portfolio)

    let { portfolioId } = useParams();

    const dispatch = useDispatch()


    useEffect(() => {
        const payload = {
            params: {
                portfolioId
            }
        }
        dispatch(getPortfolioDetailsById(payload))
    }, [])


    let portfolioObj = portfolio.portfolioDetails
    if (!portfolio) {
        return <div>
            <h1>Загрузка...</h1>
        </div>
    }

    if (Object.keys(portfolio.portfolioDetails).length === 0) {
        return null;
    }
    return (
        <ContainerMainComponent
            title={portfolioObj.title}
            titleItem={portfolioObj.title}
            namePage="Портфолио"
            href="/portfolioPage"
            namePageHref="/portfolioPage"
            bread
        >
            <div className="portfolioPageDetails">
                {portfolioObj
                    ?
                    <div className="row" key={portfolioObj._id}>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div className="portfolioPageDetails__imageContainer">
                                {/* <div className="portfolioPageDetails__imageBlock">
                            <img src={item.image} alt="" />
                        </div> */}
                                <SliderMiniImg
                                    itemObj={portfolioObj.images}
                                    // onClick={(e) => Edit(e)}
                                    miniImg
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                            <div className="portfolioPageDetails__textContainer">
                                <div className="portfolioPageDetails__textBlock">
                                    <h4>{portfolioObj.title}</h4>
                                    <h2>{portfolioObj.titleTwo}</h2>
                                    <p>{portfolioObj.description}</p>
                                    <div className="portfolioPageDetails__theProjectPresent">
                                        <h3>
                                            в проекте <br /> представлены
                                        </h3>
                                        <div className="portfolioPageDetails__categoryHrefBLock" >
                                            {/* {item.titleTwo.map((obj) => ( */}
                                            <a href={`/categories/${portfolioObj._id}`}>
                                                <span> {portfolioObj.title},  </span>
                                            </a>
                                            {/* ))} */}
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    :
                    <CircularProgress />
                }
                <TwoButtonsComponent />
                <RequestForCalculating />
                <CategoryListComponent />
            </div>
        </ContainerMainComponent >

    )
}

export default PortfolioPageDetails