import React from 'react'
import OrderCatalogButton from './button/OrderCatalogButton'
import TitleComponent from './TitleComponent'
import { motion } from 'framer-motion'
import { opacityAnimation } from './Animation/Animation';

const TitleHeaderComponent = ({
    tooltip,
    icon,
    description,
    greyColorTitle,
    title,
    handleClick,
    text,
    aHref,
    onlyButton,
    onClick,
    iconView
}) => {

    return (
        <>
            <motion.div
                initial="hidden"
                whileInView="visible"
                className="titleHeaderComponent">
                <motion.div
                    variants={opacityAnimation}
                >
                    <TitleComponent
                        greyColorTitle={greyColorTitle || null}
                        title={title}
                        description={description}
                        tooltip={tooltip}
                        icon={icon}
                        iconView={iconView}
                        handleClick={handleClick}
                    />
                </motion.div>
                <motion.div
                    variants={opacityAnimation}
                    className="portfolio__buttonBlock">
                    {onlyButton
                        ? <OrderCatalogButton
                            onClick={onClick}
                            whiteBackground
                            text={text} />
                        : text ? <a
                            href={aHref ? aHref : "/productsCategory/all"}>
                            <OrderCatalogButton
                                onClick={() => { }}
                                whiteBackground
                                text={text} />
                        </a> : null}
                </motion.div>
            </motion.div>
        </>
    )
}

export default TitleHeaderComponent
