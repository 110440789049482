import React, { useEffect, useState } from 'react'
import ContainerMainComponent from '../ContainerMainComponent'
import ProductList from './ProductList'
import RenderProductListItems from './RenderProductListItems'
import { useSelector, useDispatch } from 'react-redux';
import { addToCart, addToHistoryProduct, getAllCategory, getProducts, getProductsBySlug, updateProductView } from './../../actions';
import TitleHeaderComponent from './../../components/TitleHeaderComponent';
import RenderFullImgModal from './../../components/modal/RenderFullImgModal';
import SortProduct from '../../components/SortProduct/SortProduct';
import { useParams } from 'react-router-dom';
import { setCloseAlert, setShowModalCart } from '../../reducers/cart.reducer';
import { addToDesires, removeDesiresItem } from './../../actions/desires';
import { setShowAlertDesires } from './../../reducers/desires';
import RenderAskQModal from '../../components/modal/RenderAskQModal';
import { setShowCategoryBlock } from '../../reducers/category.reducer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const Products = () => {
    const { user } = useSelector((state) => state.auth);
    const {
        products,
        loading,
        currentPage,
        pageSizeItem,
        totalAll,
        currentCategory,
        openSearchModalProduct
    } = useSelector((state) => state.product)
    const { showCategoryBlock } = useSelector((state) => state.category)
    const category = useSelector((state) => state.category.categories.filter((obj) => obj.children.visibleCategory === false ? null : obj))
    const desires = useSelector((state) => state.desires)
    const cart = useSelector((state) => state.cart)
    const auth = useSelector((state) => state.auth)

    const { variantAlert, showAlert, textAlert } = desires

    const [slideIndex, setSlideIndex] = useState(0)
    const [indexProductImg, setIndexProductImg] = useState(null)
    const [showModalImg, setShowModalImg] = useState(false);
    const [categoryObj, setCategoryObj] = useState(null)
    const [itemProductImg, setItemProductImg] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(pageSizeItem === undefined ? 25 : pageSizeItem);
    const [sortMaterial, setSortMaterial] = useState([]);
    const [sort, setSort] = useState("updatedAtMinus");
    const dispatch = useDispatch();

    const { slug } = useParams()

    useEffect(() => {
        if (slug === "all") {
            dispatch(getProducts())
        }
        if (slug !== "all") {
            dispatch(getProductsBySlug(slug, sort, pageNumber, pageSize, sortMaterial))
        }
    }, [slug])

    useEffect(() => {
        dispatch(getAllCategory())
    }, [currentCategory])

    useEffect(() => {
        if (showModalImg) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showModalImg]);

    const handleCloseModalImg = () => {
        setShowModalImg(false)
    }
    const refCategory = React.useRef(null);

    const buttonHandler = (ref) => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    };
    const handleViewProduct = (productId) => {
        const payload = {
            productId,
            userId: user._id
        }
        dispatch(updateProductView(payload))
    }
    const handleHistoryProduct = (obj) => {
        const { _id } = obj
        dispatch(addToHistoryProduct({ _id }))
    }

    const handleCartButton = (obj) => {
        const payload = {
            _id: obj._id,
            fullName: obj.fullName,
            img: obj.reducedImage
        }
        dispatch(addToCart(payload))
        dispatch(setShowModalCart())
    }

    const handleEnableLike = (obj) => {
        const { _id } = obj
        dispatch(addToDesires({ _id }));
    }
    const handleDisableLike = (productId) => {
        const payload = {
            productId
        }
        dispatch(removeDesiresItem(payload))
    }
    return (
        <ContainerMainComponent
            dispatchCloseAlert={showAlert ? setShowAlertDesires : setCloseAlert}
            colorAlert={showAlert ? variantAlert : cart.variantAlert}
            showAlert={showAlert ? showAlert : cart.showAlert}
            textAlert={showAlert ? textAlert : cart.textAlert}
        >
            <div className="products" >
                <TitleHeaderComponent
                    title={`Всего товаров - ${totalAll}`}
                    aHref="/productsCategory/all"
                    description="Все Категории"
                    text="Все товары"
                    greyColorTitle
                    iconView
                    show={showCategoryBlock}
                    handleClick={() => dispatch(setShowCategoryBlock())}
                    tooltip={`${showCategoryBlock ? "Скрыть" : "Отобразить"} категорию`}
                    icon={!showCategoryBlock ? <KeyboardArrowDownIcon
                        color="secondary" />
                        : <KeyboardArrowUpIcon
                            color="secondary" />}
                />
                <div>
                    <ProductList
                        showCategoryBlock={showCategoryBlock}
                        currentCategoryId={currentCategory}
                        setCategoryObj={setCategoryObj}
                        setPageNumber={setPageNumber}
                        buttonHandler={buttonHandler}
                        setSlideIndex={setSlideIndex}
                        categoryObj={categoryObj}
                        refCategory={refCategory}
                        slideIndex={slideIndex}
                        category={category}
                    />
                    <SortProduct
                        setSort={setSort}
                        sort={sort}
                        setSortMaterial={setSortMaterial}
                        sortMaterial={sortMaterial}
                        setPageNumber={setPageNumber}
                        refCategory={refCategory}
                        setPageSize={setPageSize}
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                    />
                </div>
                <RenderProductListItems
                    showCategoryBlock={showCategoryBlock}
                    handleHistoryProduct={handleHistoryProduct}
                    setIndexProductImg={setIndexProductImg}
                    setItemProductImg={setItemProductImg}
                    handleViewProduct={handleViewProduct}
                    handleDisableLike={handleDisableLike}
                    handleCartButton={handleCartButton}
                    handleEnableLike={handleEnableLike}
                    desiresItems={desires.desiresItems}
                    setShowModalImg={setShowModalImg}
                    indexProductImg={indexProductImg}
                    setPageNumber={setPageNumber}
                    setPageSize={setPageSize}
                    categoryObj={categoryObj}
                    currentPage={currentPage}
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    totalAll={totalAll}
                    product={products}
                    loading={loading}
                    slug={slug}
                />
            </div>
            <RenderAskQModal
                colorModal="black"
                auth={auth}
            />
            <RenderFullImgModal
                visibleButtonsBottom
                handleCloseModalImg={handleCloseModalImg}
                items={itemProductImg.productPictures}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
                product={itemProductImg}
            />
        </ContainerMainComponent>
    )
}

export default Products
