import { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateSiteThemeType } from '../actions'; // Импортируем нужное действие

// Custom hook to manage site theme with local storage support
const useSiteTheme = () => {
    const dispatch = useDispatch();
    const { user, authenticate } = useSelector((state) => state.auth);
    const [localSiteTheme, setLocalSiteTheme] = useState(() => {
        // Initialize state from local storage or default to "white"
        const savedState = window.localStorage.getItem('localSiteTheme');
        return savedState !== null ? savedState : 'white';
    });

    const siteTheme = useMemo(() => {
        return authenticate ? user.siteThemeType : localSiteTheme;
    }, [authenticate, user.siteThemeType, localSiteTheme]);

    useEffect(() => {
        // Save localSiteTheme to local storage whenever it changes
        window.localStorage.setItem('localSiteTheme', localSiteTheme);
    }, [localSiteTheme]);

    const toggleSiteTheme = () => {
        if (authenticate) {
            // Dispatch action to update site theme
            const newTheme = user.siteThemeType === 'white' ? 'black' : 'white';
            dispatch(updateSiteThemeType({ siteThemeType: newTheme }));
        } else {
            setLocalSiteTheme(prevTheme => {
                const newTheme = prevTheme === 'white' ? 'black' : 'white';
                window.localStorage.setItem('localSiteTheme', newTheme);
                return newTheme;
            });
        }
    };

    return [siteTheme, toggleSiteTheme];
};

export default useSiteTheme;
