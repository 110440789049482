import React, { useEffect } from 'react'
import SliderMiniImg from './../../../components/Slider/SliderMiniImg';
import RenderCharacteristic from './RenderCharacteristic';
import RenderDeliveryPG from './RenderDeliveryPG';
import RenderDescription from './RenderDescription';
import PurpleButton from '../../../components/button/PurpleButton';
import { priceSum } from './PriceSum';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../../../actions';
import { setShowModalCart } from '../../../reducers/cart.reducer';
import LikedBlock from './LikedBlock';
import RenderProductMaterial from './RenderProductMaterial';
import activeAddButton from './../ActiveAddButton';
import TextView from '../../../components/TextView';
import DeliveryPWDetails from './DeliveryPWDetails';
import { useState } from 'react';
import { getPaymentList, getDeliveryInfo, getWarranty } from '../../../actions';
import RenderOtherOffers from './RenderOtherOffers';

const RenderItemProductDetails = ({
    setShowImgButtonBlock,
    setCurrentIdPicture,
    showImgButtonBlock,
    handleMouseClose,
    setShowModalImg,
    categoryObj,
    productObj,
    cartItems,
    product,
    auth,
}) => {
    const prod = useSelector((state) => state.product.productDetails)
    const { deliveryInfo } = useSelector((state) => state.deliveryInfo)
    const { paymentList } = useSelector((state) => state.paymentList)
    const { warranty } = useSelector((state) => state.warranty)

    const [itemType, setTypeItem] = useState("delivery")

    const dispatch = useDispatch()

    useEffect(() => {
        switch (itemType) {
            case "warranty":
                dispatch(getWarranty());
                break;
            case "payment":
                dispatch(getPaymentList());
                break;
            default:
                dispatch(getDeliveryInfo());
                break;
        }
    }, [itemType]);

    const itemsDeliveryPG = [
        {
            name: "Доставка",
            type: "delivery",
            description: <DeliveryPWDetails
                itemContent={deliveryInfo}
            />
        },
        {
            name: "Гарантии",
            type: "warranty",
            description: <DeliveryPWDetails
                itemContent={warranty}
            />
        },
        {
            name: "Оплата",
            type: "payment",
            description: <DeliveryPWDetails
                itemContent={paymentList}
            />
        },
    ];

    const handleChange = () => {
        dispatch(setShowModalCart());
        const payload = {
            _id: prod._id,
            fullName: prod.fullName,
            img: prod.reducedImage
        }
        dispatch(addToCart(payload))
    };
    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="productDetails__productGroup">
                        <TextView
                            itemViews={prod.viewsLength}
                        />
                        <SliderMiniImg
                            setShowModalImg={setShowModalImg}
                            setCurrentIdPicture={setCurrentIdPicture}
                            showImgButtonBlock={showImgButtonBlock}
                            setShowImgButtonBlock={setShowImgButtonBlock}
                            handleMouseClose={handleMouseClose}
                            itemObj={productObj.productPictures}
                            miniImg
                        />

                    </div>
                    <div className="productDetails__productGroup">
                        <RenderDescription obj={product.productDetails} />
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="productDetails__productGroup"
                        style={{
                            minHeight: "130px",

                        }}
                    >
                        <RenderProductMaterial
                            productMaterials={product.productDetails.material}
                        />
                    </div>
                    <div className="productDetails__productGroup  priceBlock">
                        <div className="productDetails__group-item">
                            <div className="productDetails__name">
                                <h2>
                                    {productObj.fullName}
                                </h2>
                                <div className="productDetails__availability">
                                    {productObj.quantity > 0 ? `В наличии ${productObj.quantity} шт.` : "Под заказ"}

                                </div>
                                <div className="productDetails__vendorCode">
                                    <p>Артикул</p>
                                    <h5>{productObj.fullName}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="productDetails__group-item">
                            <div className="productDetails__price priceLikedBox">
                                <div className="productDetails__priceBox">
                                    {categoryObj?.visibleAllProductPrice ?
                                        <>
                                            {
                                                !productObj.visiblePrice
                                                    ?
                                                    <>
                                                        <h2>
                                                            {!productObj.price ? "Уточнить цену" : `от ${productObj.price} Валюта`}
                                                        </h2>
                                                        <h3>
                                                            {!productObj.price ? "Уточнить цену" : `${priceSum(productObj.price, 7)} Валюта`}
                                                        </h3>
                                                    </>
                                                    : <h2>
                                                        Уточнить цену
                                                    </h2>
                                            }
                                        </>
                                        : <h2>
                                            Уточнить цену
                                        </h2>
                                    }
                                </div>
                                <LikedBlock
                                    auth={auth}
                                />
                            </div>
                        </div>

                        <div className="productDetails__group-item">
                            <div className="productDetails__buttonBlock">
                                <PurpleButton
                                    text={`${activeAddButton(prod._id, cartItems) === prod._id ? "Уже в корзине" : "Купить"}`}
                                    onClick={() => handleChange()}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="productDetails__productGroup">
                        <RenderCharacteristic
                            productObj={productObj}
                            title="Характеристики"
                            type="all"
                        />
                    </div>
                    <div style={{ minHeight: "400px" }}
                        className="productDetails__productGroup">
                        <RenderDeliveryPG
                            setTypeItem={setTypeItem}
                            items={itemsDeliveryPG}
                        />
                    </div>
                </div>
            </div>
            <RenderOtherOffers
                productSlug={productObj.categorySlug}
            />
        </>
    )
}

export default RenderItemProductDetails