import React, { useState, useEffect } from 'react';
import NewHeader from '../../components/NewHeader';

const Layout = (props) => {
    const [noneFooter, setNoneFooter] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 500) {
                setNoneFooter(true);
            } else {
                setNoneFooter(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <NewHeader noneFooter={noneFooter} />
            {props.children}
        </>
    );
};

export default Layout;
