import React from 'react'
import RenderProductOrder from './RenderProductOrder';
import OrderFinalContent from './OrderFinalContent';

const RenderDropdownDesc = ({
    setShowDetailsProductModal,
    setProdDetailsObj,
    showOrderModal,
    addressObj,
    setType,
    type,
    obj,

}) => {
    const renderAddressInfo = () => {
        if (!addressObj) return null
        let arrayInfoAddress = [
            {
                name: "Область",
                content: addressObj?.state
            },
            {
                name: "Город",
                content: addressObj?.locality
            },
            {
                name: "Район",
                content: addressObj?.cityDistrictTown
            },
            {
                name: "Улица-адрес",
                content: addressObj?.address
            },
        ]

        return <div className="m-2 p-1">
            {arrayInfoAddress.map((item, index) => (
                <div className="renderDropdownDesc__row"
                    key={index}
                >
                    <div className="renderDropdownDesc__addressInfoName">
                        {item.name}
                    </div>
                    <span></span>
                    <div className="renderDropdownDesc__addressIInfoContent">
                        {item.content}
                    </div>
                </div>
            ))}
            <div> {addressObj?.contactNumber} </div>
        </div>
    }
    return (
        <div className="renderDropdownDesc">
            <div className='container'
                onClick={e => e.stopPropagation()}>
                <div className="row">
                    <div className="col-sm-4">
                        <p>Информация о заказе</p>
                        <div className="m-2 p-1">
                            <div>График Работы</div>
                            <div> Пн-Сб: 10:00-21:00</div>
                            <div>Вс: 10:00-18:00</div>
                        </div>
                        {renderAddressInfo()}
                        <div className="m-2 p-1">
                            <div
                                className="renderDropdownDesc__orderHistoryButton"
                                onClick={() => showOrderModal(obj)}>
                                История заказа
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <span className="renderDropdownDesc__titleProductItems">Товары Нашего производства</span>
                        <div className="renderDropdownDesc__productItems">
                            {obj.items.length && obj.items.map((item) => (
                                <RenderProductOrder
                                    obj={obj}
                                    setShowDetailsProductModal={setShowDetailsProductModal}
                                    setProdDetailsObj={setProdDetailsObj}
                                    key={item._id}
                                    item={item}
                                />
                            )
                            )}
                        </div>
                        <div className="renderDropdownDesc__totalAmount">
                            <div className="renderDropdownDesc__totalAmount__title">
                                Общая:
                            </div>
                            <div className="renderDropdownDesc__totalAmount__sum"
                                style={{
                                    textDecoration: obj.discountForBuyers ? 'line-through' : 'none',
                                    textDecorationColor: 'red',
                                }}
                            >
                                {obj.totalAmount > 0 ? obj.totalAmount : "Не посчитано"}
                                {obj.totalAmount > 0 ? " $" : null}
                            </div>
                        </div>
                        {
                            !obj.discountedAmount
                                ? null
                                : <div className="renderDropdownDesc__totalAmount">
                                    <div className="renderDropdownDesc__totalAmount__title">
                                        Скидка
                                    </div>
                                    <div className="renderDropdownDesc__totalAmount__sum">
                                        {obj.discountedAmount > 0 ? obj.discountedAmount : "Не посчитано"}
                                        {obj.discountedAmount > 0 ? " %" : null}
                                    </div>
                                </div>
                        }
                        {
                            !obj.discountForBuyers
                                ? null
                                : <div className="renderDropdownDesc__totalAmount">
                                    <div className="renderDropdownDesc__totalAmount__title">
                                        Со скидкой:
                                    </div>
                                    <div className="renderDropdownDesc__totalAmount__sum">
                                        {obj.discountForBuyers > 0 ? obj.discountForBuyers : "Не посчитано"}
                                        {obj.discountForBuyers > 0 ? " $" : null}
                                    </div>
                                </div>
                        }
                        <OrderFinalContent
                            type={type}
                            setType={setType}
                            order={obj}
                            showOrderModal={showOrderModal}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RenderDropdownDesc