import { cartConstants } from "../actions/constants";

const initState = {
    cartItems: {},
    updatingCart: false,
    showModal: false,
    error: null,
    textAlert: "",
    variantAlert: "success",
    showAlert: false,

};

export default (state = initState, action) => {
    const mes = action.payload?.message === undefined ? state.textAlert : action.payload?.message
    switch (action.type) {
        case cartConstants.UPDATE_CART_ITEM_QUANTITY_REQUEST:
            state = {
                ...state,
                updatingCart: true,
            }
            break;
        case cartConstants.UPDATE_CART_ITEM_QUANTITY_SUCCESS:
            state = {
                ...state,
                cartItems: action.payload && action.payload.updatedCart ? action.payload.updatedCart : state.cartItems,
                updatingCart: false,
                textAlert: mes,
                showAlert: true,
                variantAlert: "success",
                loading: false
            }
            break;
        case cartConstants.UPDATE_CART_ITEM_QUANTITY_FAILURE:
            state = {
                ...state,
                updatingCart: false,
                textAlert: mes,
                variantAlert: "warning",
                showAlert: true,
                loading: false,
            }
            break;
        case cartConstants.ADD_TO_CART_REQUEST:
            state = {
                ...state,
                updatingCart: true,
            }
            break;
        case cartConstants.ADD_TO_CART_SUCCESS:
            state = {
                ...state,
                updatingCart: false,
                textAlert: mes,
                showAlert: true,
                variantAlert: "success",
                cartItems: action.payload.cartItems,
                loading: false
            }
            break;
        case cartConstants.ADD_TO_CART_FAILURE:
            state = {
                ...state,
                updatingCart: false,
                textAlert: mes,
                variantAlert: "warning",
                showAlert: true,
                loading: false,
                // error: action.payload.error
            }
            break;
        case cartConstants.GET_CART_ITEMS_REQUEST:
            state = {
                ...state,
                updatingCart: true,
            }
            break;
        case cartConstants.GET_CART_ITEMS_SUCCESS:
            state = {
                ...state,
                cartItems: action.payload.cartItems,
                updatingCart: false,
            }
            break;
        case cartConstants.GET_CART_ITEMS_FAILURE:
            state = {
                ...state,
                updatingCart: false,
                error: action.payload.error
            }
            break;
        case cartConstants.UPDATE_TO_CART_SUCCESS:
            state = {
                ...state,
                textAlert: "Корзина обновлена!",
                showAlert: true,
                updatingCart: false,
            }
            break;

        case cartConstants.SHOW_MODAL_CART:
            state = {
                ...state,
                showModal: !state.showModal
            }
            break;
        case cartConstants.SHOW_MODAL_CART_FALSE:
            state = {
                ...state,
                showModal: false
            }
            break;


        case cartConstants.REMOVE_CART_ITEM_REQUEST:
            state = {
                ...state,
                updatingCart: true,
            }
            break;
        case cartConstants.REMOVE_CART_ITEM_SUCCESS:
            state = {
                ...state,
                updatingCart: false,
                showAlert: true,
                textAlert: "Товар успешно удален с корзины!",
            }
            break;
        case cartConstants.REMOVE_CART_ITEM_FAILURE:
            state = {
                ...state,
                updatingCart: false,
                showAlert: true,
                textAlert: "Что-то пошло не так!",
                variantAlert: "warning",
            }
            break;
        case cartConstants.SET_CLOSE_ALERT:
            state = {
                ...state,
                showAlert: false,
            }
            break;
        case cartConstants.RESET_CART:
            state = {
                ...initState
            }
    }
    return state;
}

export const setCloseAlert = (payload) => ({
    type: cartConstants.SET_CLOSE_ALERT,
    payload
})
export const setShowModalCart = (payload) => ({
    type: cartConstants.SHOW_MODAL_CART,
    payload
})
export const setHideModalCart = (payload) => ({
    type: cartConstants.SHOW_MODAL_CART_FALSE,
    payload
})