
import { partnerConstants } from './../../actions/constants';
const initState = {
  partners: []
};
export default (state = initState, action) => {
  switch (action.type) {
    case partnerConstants.CREATE_PARTNER_FAILURE:
      state = {
        ...state,
        loading: false
      };
      break;
  }

  return state;
};
 