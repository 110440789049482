import React, { useEffect, useState } from 'react';
import ContainerMainComponent from '../ContainerMainComponent';
import { useSelector, useDispatch } from 'react-redux';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Tooltip } from '@mui/material';
import SettingIconBlock from './../../components/button/SettingIconBlock';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Loader from '../../components/Loader';
import DarkLineComponent from './../../components/DarkLineComponent';

const Contact = () => {
    const { contact, loading } = useSelector((state) => state.contact);
    const dispatch = useDispatch();
    const [copiedText, setCopiedText] = useState(null);

    const copyContentFunc = (item) => {
        const textarea = document.createElement('textarea');
        textarea.value = item;

        document.body.appendChild(textarea);
        textarea.select();

        try {
            document.execCommand('copy');
            setCopiedText(item);
        } catch (err) {
            console.error('Не удалось скопировать текст', err);
        }

        document.body.removeChild(textarea);
    };

    const tooltipNumber = (className, item) => {
        const isCopied = copiedText === item;

        return (
            <div className="contact__content">
                <a
                    className={`contact__contentHref ${className ? className : ''}`}
                    href={`${className === 'email' ? 'mailto:' : 'tel:'}${item}`}
                >
                    {item}
                </a>
                <SettingIconBlock
                    tooltip={isCopied ? "Скопировано" : "Копировать"}
                    icon={isCopied ? <FileCopyIcon /> : <ContentCopyIcon />}
                    onClick={() => copyContentFunc(item)}
                    top="7px"
                />
            </div>
        );
    };

    return (
        <>
            <ContainerMainComponent title="Контакты" description="Как с нами связаться">
                {loading ? (
                    <Loader />
                ) : (
                    <div className="contact">
                        <div className="contact__row">
                            <div className="contact__mapContent">
                                <YMaps>
                                    <Map
                                        defaultState={{
                                            center: [67.612058, 33.668236],
                                            zoom: 10,
                                        }}
                                        style={{
                                            width: '95%',
                                            height: '400px',
                                            margin: '0 auto',
                                            boxShadow: "0 0 10px 5px rgba(194, 194, 194, 0.4)",
                                        }}
                                    >
                                        <Placemark geometry={[67.612058, 33.668236]} />
                                    </Map>
                                </YMaps>
                            </div>
                            <div className="contact__textContent">

                                {contact.map((item) => (
                                    <div key={item._id} className="contact__textContainer">
                                        <div className="contact__textContainer__left">
                                            <div className="contact__textContainer__title">{item.city}</div>
                                            <p>{item.cityIndex}, {item.country}</p>
                                            <p>{item.address}</p>
                                        </div>
                                        <div className="contact__textContainer__right">
                                            <div className="contact__textContainer__title">Связь с нами</div>
                                            {tooltipNumber("email", item.email)}
                                            {tooltipNumber("number", item.number)}
                                            {tooltipNumber("number", item.numberTwo)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </ContainerMainComponent>
            <DarkLineComponent
                titleTop="МЫ"
                titleBottom="ПРЕДЛАГАЕМ:"
                descriptionTop="ШИРОКУЮ ЛИНЕЙКУ ГОТОВЫХ МАФОВ"
                descriptionCenter="РАЗРАБОТКУ ИЗДЕЛИЙ ПОД КОНКРЕТНЫЙ"
                descriptionBottom="ПРОЕКТ"
            />
        </>
    );
};

export default Contact;
