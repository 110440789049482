import React from 'react'
import { NavLink } from 'react-router-dom';

const SeasonsCatalog = ({
    noneTitle,
    blackColor,
    obj,
    title
}) => {

    const blackColorStyles = () => blackColor ? "black" : "#ffc75f"

    return (
        <div className="seasonCatalog">
            {!noneTitle && <div className={blackColorStyles ? "seasonCatalog__title-block-black" : "seasonCatalog__title-block"}>
                <span>
                    {title || "Летний сезон"}
                </span>
            </div>}
            <ul className="seasonCatalog__items">
                {obj ? obj.map((obj, index) => (
                    <li
                        className={blackColorStyles ? "seasonCatalog__block-black" : "seasonCatalog__block"}
                        key={index}>
                        <a href={`/productsCategory/${obj.slug}?cid=${obj._id}`}>
                            {obj.name.length > 20 ? obj.name.slice(0, 20) + "..." : obj.name}
                        </a>
                    </li>
                )) : "Loading....."}
            </ul>
        </div>
    )
}

export default SeasonsCatalog
