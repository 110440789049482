import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPickupPoints } from '../../../../actions/components/pickupPoints';
import Loader from './../../../../components/Loader';
import Button from '@mui/material/Button';
import { generatePublicUrl } from '../../../../urlConfig';
import RenderSuccessDataContent from '../RenderSuccessDataContent';

const ItemsPickupPoints = ({
    setPickupPointsId,
    pickupPointsId,
    setDisabledButton,
    successData
}) => {
    const dispatch = useDispatch();
    const pickupPoints = useSelector(state => state.pickupPoints.pickupPoints);

    const [currentObj, setCurrentObj] = useState(null)

    useEffect(() => {
        if (pickupPointsId !== null) {
            setDisabledButton(false)
        } else {
            setDisabledButton(true)
        }
    }, [pickupPointsId])

    useEffect(() => {
        dispatch(getPickupPoints());
    }, [dispatch]);

    const handleClick = (obj, checkId) => {
        setCurrentObj(obj)
        setPickupPointsId(checkId ? null : obj._id);
    };

    return (
        <div className="itemsPickupPoints">
            <div className="itemsPickupPoints__title">Пункты самовывоза:</div>
            {pickupPoints.length === 0 ? (
                <Loader allFunctional descriptionEmpty="Пока нет данных!" />
            ) : (successData
                ? <RenderSuccessDataContent
                    title="Со склада"
                    content={currentObj.address}
                />
                : pickupPoints.map(obj => (
                    <div
                        className={`itemPickupPoints ${obj._id === pickupPointsId && "currentId"}`}
                        key={obj._id}
                    >
                        <div className="itemPickupPoints__contentBox">
                            <div className="itemPickupPoints__address">
                                <span>{obj.city}</span>, <span>{obj.address}</span>
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleClick(obj, obj._id === pickupPointsId)}
                            >
                                {obj._id === pickupPointsId ? 'Отмена' : 'Выбрать'}
                            </Button>
                        </div>
                        {obj._id === pickupPointsId && (
                            <div className="itemPickupPoints__row">
                                <div className="itemPickupPoints__descriptionBox">
                                    <div className="itemPickupPoints__address">Address: {obj.address}</div>
                                    <div className="itemPickupPoints__address">Number: {obj.number}</div>
                                    <div className="itemPickupPoints__address">City: {obj.city}</div>
                                </div>
                                <div className="itemPickupPoints__imgContainer">
                                    <img
                                        src={generatePublicUrl(obj.itemPictures[0].img)}
                                        alt={obj.number}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    );
};

export default ItemsPickupPoints;
