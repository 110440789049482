import { applicationChangeProfileConstants } from './constants'
import axios from '../helpers/axios';
import swal from 'sweetalert';


export const createApplicationChangeProfile = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationChangeProfileConstants.CREATE_APPLICATION_CHANGE_PROFILE_REQUEST });
      const res = await axios.post("applicationChangeProfile/create", payload);
      if (res.status === 201) {
        dispatch({ type: applicationChangeProfileConstants.CREATE_APPLICATION_CHANGE_PROFILE_SUCCESS });
        dispatch(getApplicationsChangeProfileByUser());

        swal({
          title: "Успешно отправено",
          text: "Оператор свяжется с вами в ближайшее время",
          icon: "success",
        });
      } else {
        dispatch({ type: applicationChangeProfileConstants.CREATE_APPLICATION_CHANGE_PROFILE_FAILURE });
        swal({
          title: "Ошибка отправки",
          text: "Попробуйте снова",
          icon: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      swal({
        title: "Ошибка отправки",
        text: "Попробуйте снова",
        icon: "warning",
      });
    }
  };
};

export const getApplicationsChangeProfileByUser = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/applicationChangeProfile/getByUser");
      dispatch({ type: applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_USER_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_USER_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_USER_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getApplicationsChangeProfileById = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/applicationChangeProfile/user/getById/${id}`);
      dispatch({ type: applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_ID_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_ID_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: applicationChangeProfileConstants.GET_APPLICATION_CHANGE_PROFILE_BY_ID_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addSamplePhotoApplicationChangeProfile = (_id, files) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      for (let pic of files) {
        formData.append("samplePhoto", pic);
      }
      const res = await axios.post(`/applicationChangeProfile/${_id}/addSamplePhoto`, formData);
      console.log(res.data)
      dispatch({ type: applicationChangeProfileConstants.ADD_SAMPLE_PHOTO_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: applicationChangeProfileConstants.ADD_SAMPLE_PHOTO_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: applicationChangeProfileConstants.ADD_SAMPLE_PHOTO_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
