
import React, { useState, useRef, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';

import { motion } from 'framer-motion'
import { opacityAnimation } from './../Animation/Animation';

export const FullModal = ({
    visible,
    typeCloseIcon,
    type,
    minH,
    children,
    onClose,

}) => {
    useEffect(() => {
        if (visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [visible]);

    if (!visible) {
        return null
    }

    const renderFullModalChildren = () => {
        let propsType
        switch (type) {
            case "cartFullMini":
                propsType = <div
                    className="fullModal__modalContainer cartFullMini" onClick={e => e.stopPropagation()}>
                    {children}
                </div>
                break;
            case "miniModal":
                propsType = <div
                    style={minH ? { minHeight: "200px" } : null}
                    className="fullModal__modalContainer miniModal" onClick={e => e.stopPropagation()}>
                    {children}
                </div>
                break;
            case "transparent":
                propsType = <div className="fullModal__modalContainer transparent" onClick={e => e.stopPropagation()}>
                    {children}
                </div>
                break;
            default:
                propsType = <div className="fullModal__modalContainer" onClick={e => e.stopPropagation()}>
                    {children}
                </div>
        }
        return propsType
    }

    const closeBlock = () => {
        return <div className={`fullModal__modalClose ${typeCloseIcon && typeCloseIcon}`} onClick={onClose}>
            <CloseIcon fontSize='large' />
        </div>
    }

    return (
        <motion.div
            className="fullModal"
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.3, once: true }}
            variants={opacityAnimation}
            onClick={onClose}
        >
            <div className="fullModal__mainContainer">
                {closeBlock()}
                {renderFullModalChildren()}
            </div>
        </motion.div>
    );
}
