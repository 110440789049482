import React from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const FavoriteIconComponent = ({
    addGalleryImgUser,
    item,
    galleryImagesUser,
    activeAddButton,
}) => {

    const _handleClick = (e) => {
        e.stopPropagation()
        addGalleryImgUser(item)
    }
    return (
        <div className="favoriteIcon"
            onClick={_handleClick}
        >
            {
                activeAddButton(item.img, galleryImagesUser, "img") === item.img ? (
                    <FavoriteIcon style={{ color: "red" }} />
                ) : (
                    <FavoriteBorderIcon />
                )
            }
        </div>
    )
}

export default FavoriteIconComponent