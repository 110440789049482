import { galleryConstants, mainActionsConstants } from "../../actions/constants"


const initialState = {
    galleries: [],
    galleryObj: {},
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case galleryConstants.GET_GALLERY_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case galleryConstants.GET_GALLERY_BY_ID_SUCCESS:
            state = {
                ...state,
                galleryObj: action.payload.gallery,
                loading: false
            }
            break;
        case galleryConstants.GET_GALLERY_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case mainActionsConstants.GET_ALL_ITEMS_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case mainActionsConstants.GET_ALL_ITEMS_SUCCESS:
            state = {
                ...state,
                galleries: action.payload.galleries,
                loading: false
            }
            break;
        case mainActionsConstants.GET_ALL_ITEMS_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case mainActionsConstants.GET_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                galleryObj: action.payload?.item,
                loading: false
            }
            break;
        case mainActionsConstants.GET_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        // case mainActionsConstants.UPDATE_VIEW_BY_ID_REQUEST:
        //     state = {
        //         ...state,
        //     }
        //     break;
        // case mainActionsConstants.UPDATE_VIEW_BY_ID_SUCCESS:
        //     state = {
        //         ...state,
        //         galleries: action.payload.galleries,
        //     }
        //     break;
        // case mainActionsConstants.UPDATE_VIEW_BY_ID_FAILURE:
        //     state = {
        //         ...state,
        //         galleries: action.payload.galleries,
        //     }
        //     break;
    }
    return state
}
