import React, { useState } from 'react'
import TitleHeaderComponent from '../../../components/TitleHeaderComponent';
import RenderModal from './RenderModal';

const RenderMailList = ({
    subscriptions
}) => {

    const [show, setShow] = useState(false)
    const [typeModal, setTypeModal] = useState("")

    const handleShow = (typeM) => {
        setTypeModal(typeM)
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }
    return (
        <div className="renderMainList">
            <TitleHeaderComponent
                title="Подписаться на все обновления"
                description="Обновления"
                text="Подписаться"
                onlyButton
                onClick={() => handleShow("test")}

            />
            <TitleHeaderComponent
                title="Ваши рассылки на электронную почту"
                description="рассылка"
                text="Настройка"
                onlyButton
                onClick={() => handleShow("subscriptions")}
            />
            <RenderModal
                handleClose={handleClose}
                visible={show}
                typeModal={typeModal}
                subscriptions={subscriptions}
            />
        </div>
    )
}

export default RenderMailList 