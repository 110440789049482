import React, { useEffect } from 'react';
import TitleHeaderComponent from '../../../components/TitleHeaderComponent';
import Loader from '../../../components/Loader';
import AlertComponent from '../../../components/Alert';
import { useSelector } from 'react-redux';
import useTrafficState from '../../../hooks/useTrafficState';
import useSiteTheme from '../../../hooks/useSiteTheme';
import { setCloseAlertAuth } from '../../../reducers/auth.reducer'

const RenderSettingLists = () => {
    const {
        loading,
        variantAlert,
        showAlert,
        message,
        showSettingModal
    } = useSelector((state) => state.auth);

    const [trafficState, toggleTrafficState] = useTrafficState();
    const [siteTheme, toggleSiteTheme] = useSiteTheme();

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', siteTheme);
    }, [siteTheme]);

    const items = [
        {
            title: "Экономия трафика",
            description: "Трафик",
            text: trafficState ? "Отключить" : "Включить",
            handleClick: () => toggleTrafficState()
        },
        // {
        //     title: "Главная тема всего сайта",
        //     description: "Тема",
        //     text: siteTheme === "black" ? "Светлая" : "Темная",
        //     handleClick: () => toggleSiteTheme()
        // }
    ];

    const listArray = () => {
        return items.map((item, index) => !loading
            ? (
                <TitleHeaderComponent
                    key={index}
                    title={item.title}
                    description={item.description}
                    text={item.text}
                    onlyButton
                    onClick={item.handleClick}
                />
            )
            : <Loader key={index} />);
    };

    return (
        <>
            {listArray()}
            <AlertComponent
                dispatchCloseAlert={setCloseAlertAuth}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
        </>
    );
};

export default RenderSettingLists;
