import React from 'react';
import RenderCharacteristic from './RenderCharacteristic';

const RenderDescription = ({ obj }) => {

    return (
        <div className="renderDescription">
            <div className="renderDescription__titleContainer">
                <div className="renderDescription__title">Описание</div>
                <div className="renderDescription__titleText">
                    {obj.descriptionNew === undefined || obj.descriptionNew === ""
                        ? `Подробная информация к товару: "${obj.fullName}"  пока не заполнена, ожидайте этого в ближайшее время.`
                        : obj.descriptionNew}
                </div>
            </div>
            <RenderCharacteristic
                productObj={obj}
                title="Особенности"
                type=""
            />
        </div>
    );
};

export default RenderDescription;
