import React, { useState } from 'react'
import { generatePublicUrl } from '../../../urlConfig'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MoreIconButtonCurrentId from '../../../components/button/MoreIconButtonCurrentId';

const RenderProductOrder = ({
    setShowDetailsProductModal,
    setProdDetailsObj,
    item,
}) => {
    const [showMoreMenu, setShowMoreMenu] = useState(false);

    const handleShowDetailsModal = () => {
        setProdDetailsObj(item)
        setShowDetailsProductModal(true)
        setShowMoreMenu(false)
    }

    const itemMoreArray = [

        // {
        //     title: "Добавить правку",
        //     icon: <DriveFileRenameOutlineIcon />,
        //     onClick: () => setShowDetailsProductModal(true)
        // },
        {
            title: "Инфо о товаре",
            icon: <DriveFileRenameOutlineIcon />,
            onClick: handleShowDetailsModal
        },

    ]

    return (
        <div className="renderProductOrder"
        >
            <div className="renderProductOrder__moreIcon">
                <MoreIconButtonCurrentId
                    objId={item._id}
                    item={item}
                    itemArray={itemMoreArray}
                    closeMenu={showMoreMenu}
                    setCloseMenu={setShowMoreMenu}
                />
            </div>
            <div className="renderProductOrder__row">
                <div className='renderProductOrder__imgBox'>
                    <img src={generatePublicUrl(item.productId.productPictures[0].img)} alt={item.name} />
                </div>
                <div className="renderProductOrder__name">
                    <a href={`/productDetails/${item.productId._id}`}>
                        {item.productId.fullName}
                    </a>
                </div>
                <div className="renderProductOrder__contentBox">

                    <div className="renderProductOrder__quantity">
                        <span className="renderProductOrder__title">Кол-во:</span>
                        <div className="renderProductOrder__value">{item.purchasedQty} шт.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RenderProductOrder
