import React from 'react'
import DropDownMainMenu from './DropDownMainMenu'
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

const BurgerMenu = (props) => {

    const { show, setShow, setEditColorHeader } = props

    const styleForButton = {
        'height': '40px',
        "width": '40px',
    };

    const editHeaderColor = () => {
        setShow(true)
        setEditColorHeader("#ffc75f1")
    }
    const closeAndEditHeaderColor = () => {
        setShow(false)
        setEditColorHeader("")
    }
    return (
        <div>
            {!show ?
                <Tooltip title="Открыть меню">
                    <div
                        className="mainBurgerIcon"
                        onClick={editHeaderColor}>
                        <div className="burgerIcon">
                            <div className="burgerIcon__burger-btn" >
                                <span></span>
                            </div>

                        </div>
                    </div>
                </Tooltip>
                :
                <CloseIcon style={styleForButton} className="burgerIcon-close" onClick={closeAndEditHeaderColor} />
            }
            <DropDownMainMenu
                show={show}
                setShow={setShow}
            />
        </div>

    )
}

export default BurgerMenu
