import React, { useState, useEffect } from 'react'
import CompanyTitle from '../../components/CompanyTitle'
import BurgerMenu from '../../components/BurgerMenu'
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreateUser from '../../components/modal/CreateUserModal';
import { useSelector, useDispatch } from 'react-redux';
import { getDesiresItems, signout } from '../../actions';
import RenderCartModal from './RenderCartModal';
import MiniUserModal from './MiniUserModal';
import { SearchProductComponent } from '../../components/Search/SearchProduct';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { setCloseAlert, setShowModalCart } from '../../reducers/cart.reducer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { setCloseAlertAuth, setSearchShow, setShowModalUser } from '../../reducers/auth.reducer';
import AlertComponent from './../../components/Alert';
import GrayButtonIcon from '../../components/GrauButtonCategoryComponent/GrayButtonIcon';
import { setSearchName } from '../../reducers/product.reducer';
import SettingModal from '../CabinetPage/SettingPage/SettingModal';

const HeaderNav = ({ setEditColorHeader }) => {
    const desires = useSelector((state) => state.desires)
    const {
        variantAlertAuth,
        showAlertAuth,
        textAlertAuth,
        loading,
        searchShow,
        authenticate,
        user
    } = useSelector((state) => state.auth);
    const cart = useSelector((state) => state.cart);
    const contactNumber = useSelector((state => state.contact.contact[0]?.number))

    const { variantAlert, showAlert, textAlert, updatingCart } = cart

    const showCart = cart.showModal
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const [signup, setSignup] = useState(false);
    const [buttonIndex, setButtonIndex] = useState(1)
    const [showMiniUserModal, setShowMiniUserModal] = useState(false)
    const [colorAA, setColorAA] = useState("")
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [searchClassName, setSearchClassName] = useState("");

    useEffect(() => {
        if (show || showCart) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [show, showCart]);
    useEffect(() => {
        if (authenticate) {
            dispatch(getDesiresItems())
        }
    }, [authenticate]);

    const logout = () => {
        dispatch(signout());
        setSignup(false)
    }

    const handleShowUserModal = (item, index) => {
        if (item.text === "Войти") {
            setSignup(false)
        } else {
            setSignup(true)
        }
        setButtonIndex(index + 1)
        dispatch(setShowModalUser())
        setShowMiniUserModal(false)
    }

    const closeShowMiniModal = () => {
        setShowMiniUserModal(!showMiniUserModal)
    }
    const closeCartMenuModal = () => {
        dispatch(setShowModalCart())
    }
    useEffect(() => {
        setColorAA(variantAlertAuth)
    }, [variantAlertAuth])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const alertColor = colorAA !== "" ? variantAlertAuth : variantAlert

    const handleSearchShow = (state) => {
        dispatch(setSearchShow(state))
        if (!state) {
            dispatch(setSearchName(""))
        }
    }

    return (
        <>
            <div className='headerNav'>
                <div>
                    <AlertComponent
                        dispatchCloseAlert={setCloseAlert || setCloseAlertAuth}
                        color={alertColor}
                        showObj={showAlert ? showAlert : showAlertAuth}
                        text={textAlert ? textAlert : textAlertAuth}
                    />
                </div>
                <BurgerMenu
                    blackColor
                    show={show}
                    setShow={setShow}
                    setEditColorHeader={setEditColorHeader}
                />
                <GrayButtonIcon />
                <div className={`${searchClassName} ${searchShow ? "searchShow" : ""}`}>
                    <SearchProductComponent
                        handleSearchShow={handleSearchShow}
                        setSearchClassName={setSearchClassName}
                        windowWidth={windowWidth}
                    />
                </div>
                <CompanyTitle
                    colorWhite
                    show={show}
                />
                <div className="headerNav__iconContainer">
                    <div
                        className="headerNav__searchIcon"
                        onClick={() => handleSearchShow(!searchShow ? true : false)}
                    >
                        {!searchShow ? <SearchIcon /> : <CloseIcon />}
                    </div>
                    <div
                        onClick={() => setShowMiniUserModal(!showMiniUserModal)}
                        className={showMiniUserModal
                            ? "headerNav__personContainer activePersonName"
                            : "headerNav__personContainer"
                        }>
                        <PersonIcon />
                        <span>
                            {authenticate ? user.firstName : " Мой профиль"}
                        </span>
                    </div>
                    <div
                        onClick={closeCartMenuModal}
                        className="headerNav__cartIcon">
                        <ShoppingCartIcon />
                        <span>
                            {Object.keys(cart.cartItems).length}
                        </span>

                    </div>
                    <a
                        href='/desiresPage'
                        className="headerNav__cartIcon">
                        <FavoriteBorderIcon />
                        <span>
                            {Object.keys(desires.desiresItems).length}
                        </span>
                    </a>
                    <MiniUserModal
                        contactNumber={contactNumber}
                        auth={authenticate}
                        buttonIndex={buttonIndex}
                        handleShowUserModal={handleShowUserModal}
                        setShowMiniUserModal={setShowMiniUserModal}
                        signup={signup}
                        closeShowMiniModal={closeShowMiniModal}
                        visible={showMiniUserModal}
                        logout={logout}
                    />
                </div>
                <SettingModal
                    visible={true}

                />
                <RenderCartModal
                    loading={updatingCart}
                    textAlert={textAlert}
                    cartItems={cart.cartItems}
                    showCart={showCart}
                />
                <CreateUser
                    loading={loading}
                    signup={signup}
                    setSignup={setSignup}
                    setShowMiniUserModal={setShowMiniUserModal}
                />
            </div>
        </>
    )
}

export default HeaderNav
