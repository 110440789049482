import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { stylesBox } from '../../scss/styles';
import IconButtonClose from '../IconButtons/IconButtonClose';


const ModalForEveryThing = ({
    visible,
    handleClose,
    children
}) => {

    return (
        <Modal
            style={{ zIndex: "999920000", }}
            open={visible}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={stylesBox}>
                <IconButtonClose
                    onClick={handleClose} />
                {children}
            </Box>
        </Modal>
    )
}

export default ModalForEveryThing