import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Address from './../Chekout/Address';
import { getAddress } from './../../actions/user.action';
import { getCartItems } from '../../actions';

const ContainerAddress = ({ children }) => {
    const auth = useSelector((state) => state.auth);
    const user = useSelector((state) => state.user);
    const { cartItems } = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    const [address, setAddress] = useState([]);
    const [newAddress, setNewAddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const onAddressSubmit = (addr) => {
        setSelectedAddress(addr);
        // setConfirmAddress(true);
        // setOrderSummary(true);
    };
    const enableAddressEditForm = (addr) => {
        const updatedAddress = address.map((adr) =>
            adr._id === addr._id ? { ...adr, edit: true } : { ...adr, edit: false }
        );
        setAddress(updatedAddress);
    };
    const disableAddressEditForm = (addr) => {
        const updatedAddress = address.map((adr) =>
            adr._id === addr._id ? { ...adr, edit: false } : { ...adr, edit: false }
        );
        setAddress(updatedAddress);
    };
    const selectAddress = (addr) => {
        const isAlreadySelected = addr.selected;
        const updatedAddress = address.map((adr) =>
            adr._id === addr._id
                ? { ...adr, selected: !isAlreadySelected }
                : { ...adr, selected: false }
        );
        setAddress(updatedAddress);
    };
    useEffect(() => {
        auth.authenticate && dispatch(getAddress());
        auth.authenticate && cartItems.length !== 0 && dispatch(getCartItems());
    }, [auth.authenticate]);

    useEffect(() => {
        const address = user.address.map((adr) => ({
            ...adr,
            selected: false,
            edit: false,
        }));
        setAddress(address);
        user.address.length === 0 ? setNewAddress(true) : setNewAddress(false);

    }, [user.address]);
    return (
        <div>
            {address.length > 0
                ? address.map((adr) => (
                    <>
                        <Address
                            disableAddressEditForm={disableAddressEditForm}
                            setNewAddress={setNewAddress}
                            selectAddress={selectAddress}
                            enableAddressEditForm={enableAddressEditForm}
                            onAddressSubmit={onAddressSubmit}
                            adr={adr}
                        />
                    </>
                ))
                : <p>{user.error}</p>
            }
            {children}
        </div>
    )
}

export default ContainerAddress