import React, { useEffect } from 'react'
import Sidebar from './Sidebar';
import SeasonsCatalog from './SeasonsCatalog';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCategory } from './../actions/category.action';
import logo from '../assets/TestW.png'
import { useNavigate } from 'react-router-dom';
const DropDownMainMenu = ({ show }) => {
    const category = useSelector((state) => state.category.categories)
    const navigate = useNavigate()
    const categoryFilter = category.filter((item) => item.name !== "Галерея всей продукции")

    const handleNavigateMainPage = () => {
        navigate("/")
    }
    return (
        <div className={show ? 'dropdownMenu active' : 'dropdownMenu'}>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-5">
                        <img src={logo} alt=""
                            onClick={handleNavigateMainPage}
                            className="dropdownMenu__logo"
                        />
                        <Sidebar style={{
                            textAlign: "center"

                        }} socialMediaSites />
                    </div>
                    <div className="dropdownMenu__catalogBox">
                        {categoryFilter ? categoryFilter.map((item, index) => (
                            <div
                                key={index}
                                className="dropdownMenu__catalog">

                                <SeasonsCatalog blackColor item={item} title={item.name} obj={item.children} />
                            </div>

                        )) : "Loading...."}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DropDownMainMenu
