import React from 'react'
import { generatePublicUrl } from '../../urlConfig'
import { motion } from 'framer-motion'
import { rightAnimation } from '../../components/Animation/Animation';
import WhiteButton from '../../components/button/WhiteButton';

const RenderPortfolio = ({ items }) => {
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.3, once: true }}

            className="row portfolio__MainContainerCartBlock">
            {items.map((item, index) => (
                <motion.div
                    custom={index + 1}
                    variants={rightAnimation}
                    className="col-12 col-lg-4 col-md-6 col-sm-6 mb-4"
                    key={index}>
                    <a href={`/blog`}>
                        <div className="portfolio__cartBlock">
                            <div className="portfolio__imgAndText">
                                <div className="portfolio__blockImg">
                                    <img src={generatePublicUrl(item.images[0].img)} alt={item.name} />
                                </div>
                                <div className="portfolio__containerText">
                                    <h3>
                                        {item.title}
                                    </h3>
                                    <div className="portfolio__textData">
                                        {item.createdAt.slice(0, 10)}
                                    </div>
                                    <div className="portfolio__description">
                                        {item.description.slice(0, 200)}...
                                    </div>
                                </div>
                            </div>
                            <div className="portfolio__blockText text-center">
                                {/* <h3>
                                    {item.title}
                                </h3> */}
                                {/* <div className="portfolio__textData">
                                    14.12.2021
                                </div> */}
                                {/* <div className="portfolio__description">
                                    {item.description.slice(0, 100)}...
                                </div> */}
                                <div className="portfolio__buttonBlockInside">
                                    <WhiteButton onClick={() => { }} whiteBackground text="Читать" />
                                </div>
                            </div>
                        </div>
                    </a>
                </motion.div>
            ))}
        </motion.div>
    )
}

export default RenderPortfolio
