import React, { useEffect, useState } from 'react'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ContainerCheckoutList from './ContainerCheckoutList';
import { useSelector, useDispatch } from 'react-redux';
import Loader from './../../../components/Loader';
import { getDeliveryInfo } from './../../../actions'
import RenderSuccessDataContent from './RenderSuccessDataContent';

const CheckoutDeliveryBlock = ({
    disabledButton,
    setDisabledButton,
    setSuccessData,
    successData,
    showSuccessButtons,
    setPickupFromWarehouse,
    setOrderOnOperator,
    setDeliveryId,

}) => {
    const deliveryInfo = useSelector((state) => state.deliveryInfo.deliveryInfo)

    const [deliveryType, setDeliveryType] = useState("Оставить оператору")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDeliveryInfo())
    }, [])

    useEffect(() => {
        if (deliveryType === "Самовывоз" && successData) {
            setPickupFromWarehouse(true)
        } else {
            setPickupFromWarehouse(false)
        }
        if (deliveryType === "Оставить оператору") {
            setOrderOnOperator(true)
        } else {
            setOrderOnOperator(false)
        }
        if (deliveryType !== null) {
            setDisabledButton(false)
        } else {
            setDisabledButton(true)
        }
    }, [deliveryType, successData])

    const handleDeliveryType = (e, id) => {
        setDeliveryType(e.target.value)
        if (deliveryType !== "Самовывоз") {
            setDeliveryId(id)
        }
    }
    const renderContentDelivery = () => {
        return (
            <div className="renderContentDelivery">
                {deliveryInfo.length > 0 && (
                    <div className="radioArrayItems">
                        <div class="custom-radio">
                            <label>
                                <input
                                    type="radio"
                                    name="delivery"
                                    value="Оставить оператору"
                                    onChange={(e) => handleDeliveryType(e)}
                                    checked={deliveryType === "Оставить оператору"}
                                />
                                <div class="custom-radio__label">
                                    <strong>Оставить оператору</strong>
                                    <p>Если у вас есть неопределенности в выборе способа доставки, не беспокойтесь! Наш оператор свяжется с вами, и вы сможете обсудить все детали во время разговора. Мы готовы помочь вам выбрать наиболее удобный и подходящий способ доставки.</p>
                                </div>
                            </label>
                        </div>
                    </div>
                )}
                {deliveryInfo.length > 0 && (
                    <div className="radioArrayItems">
                        <div class="custom-radio">
                            <label>
                                <input
                                    type="radio"
                                    name="delivery"
                                    value="Самовывоз"
                                    onChange={(e) => handleDeliveryType(e)}
                                    checked={deliveryType === "Самовывоз"}
                                />
                                <div class="custom-radio__label">
                                    <strong>{"Самовывоз"}</strong>
                                    <p>{"Можно оформить самовывоз с наших складов!"}</p>
                                </div>
                            </label>
                        </div>
                    </div>
                )}
                {deliveryInfo.length === 0 ? (
                    <Loader allFunctional descriptionEmpty="Пока нет данных!" />
                ) : (
                    deliveryInfo.map((obj) => (
                        <div
                            className="radioArrayItems"
                            key={obj._id}
                        >
                            <div class="custom-radio">
                                <label>
                                    <input
                                        type="radio"
                                        name="delivery"
                                        value={obj.title}
                                        onChange={(e) => handleDeliveryType(e, obj._id)}
                                        checked={deliveryType === obj.title}
                                    />
                                    <div class="custom-radio__label">
                                        <strong>{obj.title}</strong>
                                        <p>{obj.description}</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    ))
                )}
            </div>
        );
    };
    const renderChildren = () => {
        return successData
            ? <RenderSuccessDataContent
                title="Доставка"
                content={deliveryType}
            />
            : renderContentDelivery()
    }
    return (
        <ContainerCheckoutList
            disabledButton={disabledButton}
            setDisabledButton={setDisabledButton}
            setSuccessData={setSuccessData}
            successData={successData}
            showSuccessButtons={showSuccessButtons}
            title="Способ доставки"
            displayFlex
            icon={<LocalShippingIcon />}
            children={renderChildren()}
            heightType={successData && "150px"}
        />
    )
}

export default CheckoutDeliveryBlock