import { historyProductConstants } from "../actions/constants";

const initState = {
    historyProductItems: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "success",
    error: null
};

export default (state = initState, action) => {

    switch (action.type) {
        case historyProductConstants.GET_TO_HISTORY_PRODUCT_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case historyProductConstants.GET_TO_HISTORY_PRODUCT_SUCCESS:
            state = {
                ...state,
                historyProductItems: action.payload.historyProductItems,
                loading: false,
            }
            break;

        case historyProductConstants.GET_TO_HISTORY_PRODUCT_FAILURE:
            state = {
                ...state,
                loading: false,
                // error: action.payload.error
            }
            break;

        case historyProductConstants.REMOVE_HISTORY_PRODUCT_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case historyProductConstants.REMOVE_HISTORY_PRODUCT_SUCCESS:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
            }
            break;
        case historyProductConstants.REMOVE_HISTORY_PRODUCT_FAILURE:
            state = {
                ...state,
                loading: false,
                variantAlert: "warning",
                message: action.payload.message,
                showAlert: true,
            }
            break;
        case historyProductConstants.CLOSE_ALERT_TO_HISTORY_PRODUCT:
            state = {
                ...state,
                showAlert: false
            };
            break;
        case historyProductConstants.SHOW_MODAL_HP:
            state = {
                ...state,
                showModal: !state.showModal
            }
            break;
        case historyProductConstants.RESET_HISTORY_PRODUCT:
            state = {
                ...initState
            }
    }
    return state;
}

export const setShowModalHistoryProduct = (payload) => ({ type: historyProductConstants.SHOW_MODAL_HP, payload })
export const setCloseAlertHistoryProduct = (payload) => ({ type: historyProductConstants.CLOSE_ALERT_TO_HISTORY_PRODUCT, payload })