import React from 'react'
import { Button } from '@mui/material';
import { PersonalDataCheckbox } from '../MaterialUi';
import { stylesSelect } from '../../scss/styles';
import ButtonContainer from './ButtonContainer';

const FutureHours = ({
    setIsCallTomorrow,
    handleChangeTwo,
    isCallTomorrow,
    handleAnyTime,
    handleChange,
    minutesItems,
    setAnyTime,
    handleTime,
    hoursItems,
    setTime,
    anyTime,
    minutes,
    time,
    hour,
}) => {



    return (
        <div
            className="selectContainer"
            style={{
                display: "block"
            }}
        >
            <div>
                Когда вам позвонить?
            </div>
            {(() => {
                switch (true) {
                    case time:
                        return (
                            <>
                                <h5>
                                    Выбранное время {hour} : {minutes}
                                </h5>
                                <Button
                                    variant="contained"
                                    onClick={() => setTime(false)}
                                >
                                    Отменить
                                </Button>
                            </>
                        );
                    case anyTime:
                        return (
                            <>
                                <h5>
                                    Вы выбрали звонок в любое время
                                </h5>
                                <Button
                                    variant="contained"
                                    onClick={() => setAnyTime(false)}
                                >
                                    Отменить
                                </Button>
                            </>
                        );
                    default:
                        return (
                            <>
                                <PersonalDataCheckbox
                                    labelText="Оставьте заявку на удобное для Вас время, ведь операторы сегодня уже завершили свою работу. Но не переживайте, наши специалисты с радостью свяжутся с Вами в начале рабочего дня."
                                    blackText
                                    fullWidth
                                    data={isCallTomorrow}
                                    setData={() => setIsCallTomorrow(!isCallTomorrow)}
                                />
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-around",
                                    width: "100%",
                                    maxWidth: "250px"
                                }}>
                                    {isCallTomorrow && (
                                        <>
                                            <select
                                                labelId="hour"
                                                id="hour"
                                                value={hour}
                                                label="Часы"
                                                onChange={handleChange}
                                                style={stylesSelect}
                                            >
                                                <option>Часы</option>
                                                {hoursItems.map((item) => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                            <select
                                                style={stylesSelect}
                                                labelId="minutes"
                                                id="minutes"
                                                value={minutes}
                                                label="Минуты"
                                                onChange={handleChangeTwo}
                                                disabled={hour === "Часы"}
                                            >
                                                <option>Минуты</option>
                                                {minutesItems.map((item) => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                            <ButtonContainer
                                                hour={hour}
                                                minutes={minutes}
                                                handleTime={handleTime}
                                                handleAnyTime={handleAnyTime}
                                            />
                                        </>
                                    )}
                                </div>
                            </>
                        );
                }
            })()}
        </div>
    );

}

export default FutureHours