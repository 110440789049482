import React from 'react'
const Catalog = () => {


    return (
        <>
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <h2>Лавочки</h2>
                </div>
            </div>

        </div>

        </>

    )
}

export default Catalog
