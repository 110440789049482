import React from 'react'

const ButtonPlusBackground = ({ buttonOn, setShow }) => {
  return (
    <div className="buttonPlusBackground">

      <div className="buttonPlusBackground__contentContainer">
        <div className="buttonPlusBackground__priceBox">
          {/* <div className="buttonPlusBackground__price">
            {resultPrice > 0 ? resultPrice : <p>Уточнить цену</p>}
            
            <p>Уточнить цену</p>
          </div> */}
        </div>
        {buttonOn ? <div
          onClick={setShow}
          className="buttonPlusBackground__buttonContainer">
          <span>
            Оформить
          </span>
        </div> : <a
          href="/checkoutPage"
          // onClick={onClick}
          className="buttonPlusBackground__buttonContainer">
          <span>
            Оформить заказ
          </span>
        </a>}

      </div>
    </div>
  )
}

export default ButtonPlusBackground