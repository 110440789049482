import React from 'react'
import TransparentButton from '../../components/button/TransparentButton'
import TitleComponent from '../../components/TitleComponent'
import { motion } from 'framer-motion'
import { rightAnimation } from '../../components/Animation/Animation'
import landscaping from '../../assets/landscaping.jpg'
import maf from '../../assets/maf.jpg'
const SeasonCatalog = () => {

    const item = [
        {
            id: "21321",
            img: landscaping,
            title: "НОВОГОДНЕЕ ОФОРМЛЕНИЕ",
            href: "/productsCategory/all",
            // year: "2022",
            description: "Благоустройство городской среды"
        },
        {
            id: "76453",
            img: maf,
            href: "/galleryPage",
            title: "БЛАГОУСТРОЙСТВО ГОРОДОВ",
            // year: "2023",
            description: "Наши готовые проекты"

        },

    ]
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.3 }}
            className="seasonCatalogs">
            <div className="container">
                <div className="row">
                    {item.map((obj, index) => (
                        <motion.a
                            href={obj.href}
                            custom={index + 1}
                            variants={rightAnimation}
                            key={index}
                            style={{ textDecoration: "none" }}
                            className="col-12 col-sm-12 col-md-6" >
                            <div className="seasonCatalogs__textContainer">
                                <TitleComponent
                                    title={`ПЕРЕЙТИ НА`}
                                    description={obj.description}
                                />
                            </div>
                            <div className="seasonCatalogs__imgContainer">

                                <img src={obj.img} alt={obj.year} />
                                <div className="seasonCatalogs__blockText">
                                    <TransparentButton text="КАТАЛОГ" />
                                </div>
                            </div>
                        </motion.a>
                    ))}

                </div>
            </div>
        </motion.div>
    )
}

export default SeasonCatalog 
