
import React, { useState } from 'react'
import OrderCatalogButton from '../button/OrderCatalogButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToHistoryProduct, updateProductView } from '../../actions';

const RenderHrefContent = ({ itemObj, setShow }) => {

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth)

    const navigate = useNavigate()

    const handleClick = (obj) => {
        const { _id } = obj
        dispatch(addToHistoryProduct({ _id }))
        const payload = {
            productId: obj._id,
            userId: user._id
        }
        dispatch(updateProductView(payload))
        navigate(`/productDetails/${obj._id}`)
    }

    if (!itemObj) return null
    return (
        <div className="renderHrefContent"
            onClick={() => setShow(false)}
        >
            <div className="renderHrefContent__containerHref">
                <div className="renderHrefContent__mainBox">
                    <h3>{itemObj.fullName !== "" ? itemObj.fullName : "Не указано"}</h3>
                    <OrderCatalogButton
                        onClick={() => handleClick(itemObj)}
                        whiteBackground
                        text={"Перейти"} />
                </div>
            </div>
        </div>
    )
}

export default RenderHrefContent