import React from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { addToDesires, removeDesiresItem } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { setShowModalUser } from '../../../reducers/auth.reducer';
import AlertComponent from './../../../components/Alert';
import { setShowAlertDesires } from './../../../reducers/desires';
import activeAddButton from './../ActiveAddButton';


const LikedBlock = ({

}) => {
    const desires = useSelector((state) => state.desires)

    const prod = useSelector((state) => state.product.productDetails)
    const auth = useSelector((state) => state.auth.authenticate)
    const { _id } = prod;
    const dispatch = useDispatch()

    const handleDesires = () => {
        dispatch(addToDesires({ _id }));
    }
    const handleDisableLike = () => {
        let productId = _id
        const payload = { productId }
        dispatch(removeDesiresItem(payload))
    }
    const openShowMenu = () => {
        dispatch(setShowModalUser())
    }
    const handleClick = () => {
        if (activeAddButton(_id, desires.desiresItems) === _id) {
            handleDisableLike()
        } else {
            handleDesires()
        }
    }
    return (
        <div className={`likedBlock ${activeAddButton(_id, desires.desiresItems) === _id ? "active" : ""}`}>
            <div className="likedBlock__iconContainer">
                {auth ? null : <div className="likedBlock__promptBlock">
                    <div onClick={openShowMenu}>
                        Войдите на сайт &nbsp;
                    </div>
                    <p>
                        чтобы добавить товар в желанные
                    </p>
                </div>}
                <div className="likedBlock__iconDesiresBox"

                    onClick={handleClick}
                >
                    <div className="likedBlock__iconBox"
                    >
                        {activeAddButton(_id, desires.desiresItems) === _id
                            ? <FavoriteIcon />
                            : <FavoriteBorderIcon />
                        }

                    </div>
                    <div className='likedBlock__saveButtonBlock'
                        onClick={handleDisableLike}
                    >
                        {activeAddButton(_id, desires.desiresItems) === _id
                            ? "Уже добавлено"
                            : "Сохранить"
                        }
                    </div>
                </div>
            </div>
            <AlertComponent
                dispatchCloseAlert={setShowAlertDesires}
                color={desires.variantAlert}
                showObj={desires.showAlert}
                text={desires.textAlert}
            />
        </div>

    )
}

export default LikedBlock