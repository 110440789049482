import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material';
import RenderFullImgModal from './../../../components/modal/RenderFullImgModal';
import FileUploadModal from './FileUploadModal';
import { getProductDetailsById } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/Loader';

const RenderDetailsApplication = ({
    applicationObj,
    setShowPhoto,
    selectedItemId,
    samplePhoto,
    loading,
    showPhoto,
    callMe
}) => {
    const { productDetails } = useSelector((state) => state.product)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [typeModal, setTypeModal] = useState("");

    const dispatch = useDispatch()

    useEffect(() => {
        if (applicationObj.productId) {
            const payload = {
                params: {
                    productId: applicationObj.productId
                }
            };
            dispatch(getProductDetailsById(payload));
        }
    }, [applicationObj.productId]);


    if (!applicationObj) return null

    const handleShowStatusModal = (type) => {
        if (type === "addPhoto") {
            setIsModalOpen(true)
        } else {
            setIsModalOpen(true)
        }
        setTypeModal(type)
    }
    const itemsDetailsContent = [
        {
            label: "Название компании",
            text: applicationObj.nameCompany ? applicationObj.nameCompany : "Не указано",
            callMe: "application"
        },
        {
            label: "Номер указаный в заявке",
            text: applicationObj.contactNumber,
            callMe: "all"
        },
        {
            label: "Email указаный в заявке",
            text: applicationObj.email,
            callMe: "all"
        },
        {
            label: "Полное имя",
            text: applicationObj.fullName,
            callMe: "all"
        },
        {
            label: "Указаное вами время для звонка",
            text: applicationObj.timeMethod === "anyTime"
                ? "В любое время"
                : applicationObj.hour + ":" + applicationObj.minutes,
            callMe: "applicationCallMe"
        },
        {
            label: "В какой день вам позвонить?",
            text: applicationObj.isCallTomorrow
                ? "На след день после оформления"
                : "В день оформления заявки",
            callMe: "applicationCallMe"
        },
        {
            label: "Дата и время оформления",
            text: `${applicationObj.createdAt?.slice(0, 10)} ${applicationObj.createdAt?.slice(11, 19)}`,
            callMe: "all"
        },
        {
            label: "Статус заявки",
            text: <Button onClick={() => handleShowStatusModal("statusModal")}>Подробнее</Button>,
            callMe: "all"
        },
        {
            label: "К этому продукту",
            text: <Button onClick={() => handleShowStatusModal("productDetailsModal")}>Подробнее</Button>,
            callMe: applicationObj.productId ? "application" : "none"
        },
        {
            label: "Добавленные фото",
            text: samplePhoto?.length > 0 ? (
                <div>
                    <Button onClick={() => setShowPhoto(true)}>Посмотреть фото</Button>
                    <Button onClick={() => handleShowStatusModal("addPhoto")}>Добавить фото</Button>
                </div>
            ) : (
                <Button onClick={() => handleShowStatusModal("addPhoto")}>Добавить фото</Button>
            ),
            callMe: "application"
        },
        {
            label: "Описание проблемы",
            text: applicationObj.descriptionProblem,
            callMe: "application"
        },

    ];

    const renderContent = () => {
        let content, status;
        content = callMe
            ? itemsDetailsContent.filter((item) => item.callMe === "all" || item.callMe === "applicationCallMe")
            : itemsDetailsContent.filter((item) => item.callMe === "all" || item.callMe === "application")
        status = callMe ? applicationObj.applicationCallMeStatus : applicationObj.applicationStatus
        return { content, status }
    }
    const handleClose = () => {
        setTypeModal("")
        setIsModalOpen(false)
    }
    if (loading) return <Loader />
    return (
        <>
            <div className="applicationPage__renderDetails">
                {renderContent().content.map((item, index) => (
                    <div
                        className="applicationPage__detailsContent"
                        key={index}
                    >
                        <div className="applicationPage__detailsLabel">
                            {item.label}
                        </div>
                        <div className={`applicationPage__detailsText ${item.text === "Не указано" ? "noneText" : ""} `}>
                            {item.text}
                        </div>
                    </div>
                ))}
            </div >
            {!callMe &&
                samplePhoto.length > 0 && (
                    <RenderFullImgModal
                        setShowModalImg={setShowPhoto}
                        handleCloseModalImg={() => setShowPhoto(false)}
                        showModalImg={showPhoto}
                        items={samplePhoto}
                    />
                )
            }
            {
                isModalOpen && (
                    <FileUploadModal
                        productDetails={productDetails}
                        typeModal={typeModal}
                        isOpen={isModalOpen}
                        onClose={handleClose}
                        itemObj={applicationObj}
                        itemObjStatus={renderContent().status}
                    />
                )

            },
        </>
    );
};

export default RenderDetailsApplication