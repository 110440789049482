import axios from "../../helpers/axios"
import { materialConstants } from '../constants';


export const getMaterial = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: materialConstants.GET_MATERIAL_REQUEST })
            const res = await axios.get("material/get")
            if (res.status === 200) {
                const { material } = res.data
                dispatch({
                    type: materialConstants.GET_MATERIAL_SUCCESS,
                    payload: {
                        material
                    }
                })
            } else {
                dispatch({ type: materialConstants.GET_MATERIAL_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}