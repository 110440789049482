import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { setShowModalUser } from '../../reducers/auth.reducer';
import CreateUser from '../../components/modal/CreateUserModal';
import ContainerCheckoutList from './CheckoutPage1/ContainerCheckoutList';
import LoginIcon from '@mui/icons-material/Login';
import { signout } from '../../actions';
import RenderSuccessDataContent from './CheckoutPage1/RenderSuccessDataContent';

const RegularOrNewClient = ({
    showSuccessButtons,
    setSuccessData,
    successData,
    setTypeClient,
    typeClient
}) => {
    const auth = useSelector((state) => state.auth)
    const { loading } = useSelector((state) => state.auth)

    const [textTitle, setTextTitle] = useState("");
    const [isRegularActive, setIsRegularActive] = useState(true);
    const [isNewActive, setIsNewActive] = useState(false);
    const [signup, setSignup] = useState(true);

    const dispatch = useDispatch()

    useEffect(() => {
        if (auth.authenticate) {
            setTextTitle(`${successData ? "Аккаунт" : `Вы вошли под профилем: ${auth.user.email}`}`)
        } else {
            setTextTitle("Вы должны войти или пройти регистрацию для оформления заказа")
        }
    }, [auth.authenticate, successData])

    useEffect(() => {
        setIsRegularActive(typeClient === "regular");
        setIsNewActive(typeClient === "new");
    }, [typeClient, auth.authenticate]);

    useEffect(() => {
        if (typeClient === "new") {
            setSignup(false);
        } else {
            setSignup(true);
        }
    }, [typeClient, auth.authenticate]);

    const handleClick = (type) => {
        setTypeClient(type);
    };
    const showModalUser = () => {
        dispatch(setShowModalUser());
    }
    const renderContent = () => {
        return (
            <div className="regularOrNewClient">
                {auth.authenticate
                    ? <Button
                        variant="contained"
                        onClick={() => dispatch(signout())}
                    >
                        Выйти или сменить профиль
                    </Button>
                    : <>
                        <div className="regularOrNewClient__buttonBlock">
                            <Button
                                variant="text"
                                onClick={() => handleClick("regular")}
                                className={isRegularActive ? "active" : ""}
                                disabled={isRegularActive}
                            >
                                Новый покупатель
                            </Button>
                            <Button
                                variant="text"
                                onClick={() => handleClick("new")}
                                className={isNewActive ? "active" : ""}
                                disabled={isNewActive}
                            >
                                Постоянный клиент
                            </Button>
                        </div>
                        <Button
                            variant="contained"
                            onClick={showModalUser}
                        >
                            {!signup ? "Войти" : "Пройти регистрацию"}
                        </Button>
                        <CreateUser
                            loading={loading}
                            signup={signup}
                            setSignup={setSignup}
                            setShowMiniUserModal={showModalUser}
                        />
                    </>
                }

            </div>
        )
    }
    const renderSuccessDataContent = () => {
        let userDataItems = [
            {
                title: "Ваш профиль",
                content: auth.user.email
            },
        ]
        return userDataItems.map((item, index) => <RenderSuccessDataContent
            key={index}
            title={item.title}
            content={item.content}
        />)
    }
    return (
        <ContainerCheckoutList
            // disabledButton={disabledButton}
            setSuccessData={setSuccessData}
            successData={successData}
            title={textTitle}
            icon={<LoginIcon />}
            children={successData ? renderSuccessDataContent() : renderContent()}
            showSuccessButtons={showSuccessButtons}
            heightType={"150px"}
        />
    );
};

export default RegularOrNewClient;
