import React, { useEffect, useState } from 'react'
import ContainerCabinetComponent from '../../ContainerCabinetComponent';
import OrderMenuSearchSort from '../OrderMenuSearchSort';
import RenderItemOrder from './RenderItemOrder';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/Loader';
import RenderDetailsOrderProductModal from './RenderDetailsOrderProductModal';
import { getOrders, findAllOrderByOrderNumber } from './../../../actions/order.action';
import AlertComponent from './../../../components/Alert';
import { setCloseAlertOrder } from '../../../reducers/order.reducer';
import OrderInfoModal from './OrderInfoModal';

const OrderPage = () => {
    const user = useSelector((state) => state.user)
    const {
        orders,
        loading,
        variantAlert,
        showAlert,
        message
    } = useSelector((state) => state.order)

    const dispatch = useDispatch()
    const [type, setType] = useState("")
    const [showDetailsProductModal, setShowDetailsProductModal] = useState(false);
    const [prodDetailsObj, setProdDetailsObj] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [sort, setSort] = useState("AllTime");
    const [search, setSearch] = useState("");
    const [currentId, setCurrentId] = useState("");


    useEffect(() => {
        dispatch(getOrders(sort));
    }, [sort]);

    const showOrderModal = (orderItem) => {
        setOrderDetails(orderItem);
        setModalShow(!modalShow);
    };

    const textContent = search && !orders.length ? message : "У вас пока нет оформленных заказов!"

    const onCloseModal = () => {
        setModalShow(false)
        setType("")
    }
    const toggle = (orderId) => {
        setCurrentId(currentId === orderId ? "" : orderId)
    }

    return (
        <ContainerCabinetComponent
            title={"Мои Заказы"}
        >
            <div className="orderPage">
                <OrderMenuSearchSort
                    setSort={setSort}
                    sort={sort}
                    dispatchSearch={findAllOrderByOrderNumber}
                    get={getOrders}
                    search={search}
                    setSearch={setSearch}
                />
                {search && orders.length && message ? <p>Всего {orders.length} шт.</p> : null}

                {loading
                    ? <Loader />
                    : orders.length > 0 ? orders.map((obj, index) => (
                        <RenderItemOrder
                            setShowDetailsProductModal={setShowDetailsProductModal}
                            setProdDetailsObj={setProdDetailsObj}
                            showOrderModal={showOrderModal}
                            currentId={currentId}
                            loading={loading}
                            setType={setType}
                            toggle={toggle}
                            type={type}
                            key={index}
                            user={user}
                            obj={obj}
                        />)) : <p>{textContent}</p>}

                <OrderInfoModal
                    onClose={onCloseModal}
                    isOpen={modalShow}
                    type={type}
                    itemObj={orderDetails}
                />
                <RenderDetailsOrderProductModal
                    visible={showDetailsProductModal}
                    setShow={setShowDetailsProductModal}
                    onClose={() => setShowDetailsProductModal(false)}
                    item={prodDetailsObj}
                />
                <AlertComponent
                    dispatchCloseAlert={setCloseAlertOrder}
                    color={variantAlert}
                    showObj={showAlert}
                    text={message}
                />
            </div>
        </ContainerCabinetComponent>
    )
}

export default OrderPage 