import { useEffect } from 'react';

function useBodyOverflow(showSettingModal) {
    useEffect(() => {
        if (showSettingModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showSettingModal]);
}

export default useBodyOverflow;
