import { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateTrafficSavings } from '../actions';

// Custom hook to manage traffic state with local storage support
const useTrafficState = () => {
    const dispatch = useDispatch(); // Получаем dispatch из хука useDispatch
    const { user, authenticate } = useSelector((state) => state.auth);
    const [localTrafficState, setLocalTrafficState] = useState(() => {
        // Initialize state from local storage or default to true
        const savedState = window.localStorage.getItem('localTrafficState');
        return savedState !== null ? JSON.parse(savedState) : true;
    });

    const trafficState = useMemo(() => {
        return authenticate ? user.trafficSavings : localTrafficState;
    }, [authenticate, user.trafficSavings, localTrafficState]);

    useEffect(() => {
        // Save localTrafficState to local storage whenever it changes
        window.localStorage.setItem('localTrafficState', JSON.stringify(localTrafficState));
    }, [localTrafficState]);

    const toggleTrafficState = () => {
        if (authenticate) {
            // Dispatch action to update traffic savings
            dispatch(updateTrafficSavings({ trafficSavings: !user.trafficSavings }));
        } else {
            setLocalTrafficState(prevState => !prevState);
        }
    };

    return [trafficState, toggleTrafficState];
};

export default useTrafficState;
