import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../actions';
import RemoveIcon from '@mui/icons-material/Remove';

const HandleQuantityChange = ({
    _id,
    isIncrement,
    qty,
    setQty,
    isQtyValid,
    setIsInputActive
}) => {

    const { cartItems } = useSelector((state) => state.cart);

    const dispatch = useDispatch();
    const handleClick = () => {
        if (!isIncrement && !isQtyValid) {
            return null;
        }
        const product = cartItems.find((cart) => cart._id === _id);
        if (product) {
            setQty(isIncrement ? qty + 1 : qty - 1);
            const quantityChange = isIncrement ? 1 : -1;
            dispatch(addToCart(product, quantityChange));
            if (setIsInputActive) {
                setIsInputActive(false); // Закрываем input при изменении количества
            }
        }
    }

    return (
        <div
            onClick={handleClick}
            className={`${isIncrement
                ? "minusPlusComponent__plus"
                : `minusPlusComponent__minus ${isQtyValid ? "" : "disabled"}`
                }`}
        >
            {isIncrement ? "+" : <RemoveIcon />}
        </div>
    )
};

export default HandleQuantityChange;
