import React from 'react'
import blog from '../../reducers/components/blog';
import { generatePublicUrl } from '../../urlConfig';
import PurpleButton from './../../components/button/PurpleButton';

const RenderBlogItems = ({ blog }) => {


    return (
        <div className="renderBlogItems">
            <div className="row">
                {blog.map((item, index) => (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                        <div key={index} className="renderBlogItems__itemBox">

                            <div className="renderBlogItems__imgContainer">
                                <img src={generatePublicUrl(item.images[0].img)} alt={item.slug} />
                            </div>
                            <div className="renderBlogItems__contentBox">
                                <div className="renderBlogItems__titleContainer">
                                    {item.title}
                                </div>
                                <div className="renderBlogItems__descriptionContainer">
                                    {item.description.slice(0, 150)}...
                                </div>
                            </div>
                            <a href={`blogDetails/${item._id}`}>
                                <div className="renderBlogItems__itemBoxHover">
                                    <div>
                                        <div className="renderBlogItems__titleHoverBox">
                                            {item.title}
                                        </div>
                                        <PurpleButton onClick={() => { }} text="Читать" />
                                    </div>
                                </div>
                            </a>
                            <div className="renderBlogItems__buttonContainerForMobile">
                                <PurpleButton onClick={() => { }} text="Читать" />
                            </div>
                        </div>

                    </div>

                ))}
            </div>
        </div>
    )
}

export default RenderBlogItems