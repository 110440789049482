import { motion } from 'framer-motion'
import { forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getBenefits } from '../actions';
import Loader from './Loader';

const Benefits = forwardRef(({ }, ref) => {

    const benefits = useSelector((state) => state.benefits)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getBenefits())
    }, [])
    const textAnimation = {
        hidden: {
            y: 50,
            opacity: 0,
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition: { delay: custom * 0.3 }
        }),
    }
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.3, once: true }}
            className="benefits" >
            <motion.div className="benefits__titleContainer">
                <motion.h1 custom={1} variants={textAnimation} style={{
                    textAlign: "left"
                }}>ПРЕИМУЩЕСТВА</motion.h1>
                <motion.div custom={2} variants={textAnimation} className="benefits__title">
                    Что мы можем предложить для вас как для покупателя
                </motion.div>
            </motion.div>
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.3, once: true }}
                className="row"
            >
                {benefits.benefits ? benefits.benefits.map((item, index) => (
                    <div
                        custom={index + 1}
                        ref={ref}
                        key={index}
                        className="col-12 col-sm-12 col-md-6  col-lg-4 mb-5"
                    >
                        <div className={item.colorGrey ? "benefits__blockContainer grey" : "benefits__blockContainer"}>
                            <h2>
                                {index + 1}. {item.title}
                            </h2>
                            <p>
                                {item.description}
                            </p>
                        </div>
                    </div>
                )) : null}
            </motion.div>
        </motion.div>
    )
}
)
const MBenefits = motion(Benefits)

export default MBenefits
