import React, { useState, useRef, useEffect } from 'react'

const MainButtonSite = (props) => {

    return (
        <>

            <div className={!props.medium ? "mainButtonSite" : "minButtonSite medium"} onClick={props.onClick}>
                {props.icon ? props.icon : null}
                <span>{props.text}</span>
            </div>
        </>
    )
}

export default MainButtonSite
