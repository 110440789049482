import React from 'react'
import Breadcrumb from './../Breadcrumb';
import ComeBackButton from './ComeBackButton';

const LinkButtonBlock = ({
    categorySlug,
    categoryName,
    titleItem,
    productId,
    namePageHref,
    namePage,
    href,
    type,
    text,

}) => {
    return (
        <div className="linkButtonBlock">
            <div className="linkButtonBlock__container">
                <div>
                    <Breadcrumb
                        categorySlug={categorySlug}
                        categoryName={categoryName}
                        hrefPage={href}
                        titleItem={titleItem}
                        namePage={namePage}
                        productId={productId}
                        namePageHref={namePageHref}
                        type={type}
                    />
                </div>
                <ComeBackButton
                    categorySlug={categorySlug}
                    href={href}
                    text={text}
                />

            </div>
        </div>
    )
}

export default LinkButtonBlock