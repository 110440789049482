import React, { useEffect } from 'react'
import ContainerMainComponent from './../ContainerMainComponent';
import { useSelector, useDispatch } from 'react-redux';
import { generatePublicUrl } from '../../urlConfig';
import { useParams } from 'react-router-dom';
import RenderVideoYoutube from './../VideoPortfolioPage/RenderVideoYoutube';
import { getBlogDetailsById } from '../../actions/components/blog';
import DarkLineComponent from './../../components/DarkLineComponent';
import LinkButtonBlock from './../../components/button/LinkButtonBlock';

const BlogDetailsPage = () => {

    const blog = useSelector((state) => state.blog)
    const dispatch = useDispatch()

    let { blogId } = useParams();

    useEffect(() => {
        const payload = {
            params: {
                blogId
            }
        }
        dispatch(getBlogDetailsById(payload))
    }, [])
    let blogObj = blog.blogDetails
    if (!blog) {
        return <div>
            <h1>Загрузка...</h1>
        </div>
    }

    if (Object.keys(blog.blogDetails).length === 0) {
        return null;
    }

    return (
        <ContainerMainComponent
            title={blogObj.title}
            description="ЧИТАЙТЕ ИНТЕРЕСНЫЕ СТАТЬИ, СМОТРИТЕ ВДОХНОВЛЯЮЩИЕ ПОДБОРКИ"
        >
            <LinkButtonBlock
                href="/blog"
                namePage="блог"
                titleItem={blogObj.title.slice(0, 20)}
            />
            <div className="blogDetailsPage">
                <div className="row">
                    <div className="col-12">
                        <div className="blogDetailsPage__imgContainer">
                            <img src={generatePublicUrl(blogObj.images[0].img)} alt="" />
                        </div>
                        <div className="blogDetailsPage__contentContainer">
                            <h3>
                                {blogObj.title}
                            </h3>
                            <p>
                                {blogObj.description}
                            </p>
                            <div className="blogDetailsPage__videoContainer">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                        <div className="blogDetailsPage__videoBox">
                                            <RenderVideoYoutube videoHref={blogObj.videoHref} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                        <h1>Посмотрите видео</h1>
                                        <p>
                                            {blogObj.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <DarkLineComponent
                buttonBlock
                titleTop="НАШИ ИЗДЕЛИЯ"
                titleCenter="В ОНЛАЙН КАТАЛОГЕ"
                titleBottom="«БЛАГОУТРОЙСТВО ГОРОДСКОЙ СРЕДЫ»"
            />
        </ContainerMainComponent>
    )
}

export default BlogDetailsPage