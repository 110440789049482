import React from 'react'

const ContainerContentCabinet = (props) => {


    return (
        <div className="containerContentCabinet">
            <h1>{props.title}</h1>
            {props.children}
        </div>
    )
}

export default ContainerContentCabinet