import React, { useEffect, useState } from 'react'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ContainerCheckoutList from './ContainerCheckoutList';
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentList } from '../../../actions';
import Loader from '../../../components/Loader';
import DescriptionItemLine from '../../../components/DescriptionItemLine';
import RenderSuccessDataContent from './RenderSuccessDataContent';

const CheckoutPaymentMethod = ({
    successUserData,
    disabledButton,
    setSuccessData,
    successData,
    showSuccessButtons,
    setPaymentId,
    userType,
    setPaymentOrderOnOperator,
}) => {
    const { paymentList } = useSelector((state) => state.paymentList)
    const [paymentType, setPaymentType] = useState("Оставить оператору")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPaymentList())
    }, [])

    useEffect(() => {
        if (userType === "entity" && paymentType === "На карту Банка" && successUserData) {
            setPaymentType("Оставить оператору")
        }
    }, [userType, paymentType, successUserData])


    const handlePaymentType = (e, id) => {
        setPaymentId(id)
        setPaymentType(e.target.value);
    }

    const paymentSort = () => {
        let filteredList = paymentList;
        if (userType === "entity" && successUserData) {
            filteredList = filteredList.filter(payment => payment.title !== "На карту Банка");
        }
        return filteredList;
    };

    const filteredPaymentList = paymentSort();
    const renderContentPayment = () => {
        return (
            <div className="renderContentDelivery">
                {filteredPaymentList.length > 0 && (
                    <div className="radioArrayItems">
                        <div class="custom-radio">
                            <label>
                                <input
                                    type="radio"
                                    name="payment"
                                    value="Оставить оператору"
                                    onChange={(e) => {
                                        handlePaymentType(e)
                                        setPaymentOrderOnOperator(true)
                                    }}
                                    checked={paymentType === "" || paymentType === "Оставить оператору"}
                                />
                                <div class="custom-radio__label">
                                    <strong>Оставить оператору</strong>
                                    <p>Если вы не знаете, какой способ оплаты выбрать, не волнуйтесь! Наши операторы свяжутся с вами, чтобы обсудить все детали и помочь выбрать наиболее удобный и подходящий способ оплаты.</p>
                                </div>
                            </label>
                        </div>
                    </div>
                )}

                {filteredPaymentList.length === 0
                    ? <Loader
                        allFunctional
                        descriptionEmpty="Пока нет данных!"
                    />
                    : filteredPaymentList.map((obj) => (
                        <div className="radioArrayItems"
                            key={obj._id}
                        >
                            <div class="custom-radio">
                                <label
                                    style={{
                                        width: "200px"
                                    }}
                                >
                                    <input
                                        type="radio"
                                        name="payment"
                                        value={obj.title}
                                        checked={obj.title === paymentType}
                                        onChange={(e) => {
                                            handlePaymentType(e, obj._id)
                                            setPaymentOrderOnOperator(false)
                                        }}
                                    />
                                    <div class="custom-radio__label">
                                        <strong>{obj.title}</strong>
                                        {/* <p>{obj.description}</p> */}
                                    </div>
                                </label>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }
    const renderChildren = () => {
        return successData
            ? <RenderSuccessDataContent
                title="Оплата"
                content={paymentType}
            />
            : renderContentPayment()
    }
    return (
        <ContainerCheckoutList
            disabledButton={disabledButton}
            setSuccessData={setSuccessData}
            successData={successData}
            title="Способы оплаты"
            icon={<AccountBalanceWalletIcon />}
            displayFlex
            children={renderChildren()}
            showSuccessButtons={showSuccessButtons}
            heightType={successData && "150px"}
        />
    )
}

export default CheckoutPaymentMethod
