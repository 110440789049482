import React from 'react'
import ContainerCabinetComponent from './../../ContainerCabinetComponent';
import RenderMainList from './RenderMailList';
import { useSelector } from 'react-redux';

const MailingList = () => {
    const { user } = useSelector((state) => state.auth)

    return (
        <ContainerCabinetComponent
            title="Рассылки"
        >
            <div className='mailingLists'>
                <RenderMainList
                    subscriptions={user.subscriptions}
                />
            </div>

        </ContainerCabinetComponent>
    )
}

export default MailingList