import React from 'react'
import IconDownUpContainer from './../../../components/Icon/IconDownUpContainer';
import StatusColorText from './../StatusColorText';

const ApplicationPageItem = ({
    item,
    selectedItemId,
    toggle,
    children
}) => {
    const isOpen = selectedItemId === item._id;



    const filterOrderStatusColor = (text) => {
        let color;
        switch (text) {
            case "Оформлена":
                color = "gray";
                break;
            case "На рассмотрении":
                color = "orange";
                break;
            case "Подтверждена оператором":
                color = "green";
                break;
            default: color = "green";
        }
        return color
    }
    if (!item) return null
    return (
        <div
            className={`applicationPage__contentBlock ${isOpen ? " active" : ""}`}

        >
            <div className="applicationPage__headerContent">
                <div className="applicationPage__descriptionProblemHeader"
                    onClick={() => toggle(item._id)}
                >
                    <div className="applicationPage__descSliceText">
                        {`${item.descriptionProblem ? item.descriptionProblem.slice(0, 20) + "..." : item.contactNumber}`}

                    </div>
                    <StatusColorText
                        filterOrderStatusColor={filterOrderStatusColor}
                        status={item.applicationStatus || item.applicationCallMeStatus}
                    />
                </div>
                <div className="applicationPage__data">
                    {item.createdAt.slice(0, 10)}
                </div>
            </div>
            <div className="applicationPage__iconBox"
                onClick={() => toggle(item._id)}>
                <IconDownUpContainer
                    up={!isOpen}
                />
            </div>

            {isOpen && children}
        </div >
    );
};

export default ApplicationPageItem;
