import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { generatePublicUrl } from '../../urlConfig';
import { getAllCategory, getCategoryDetailsById } from './../../actions/category.action';
import { useNavigate } from 'react-router-dom';

const CategoryListComponent = () => {

    const [slideIndex, setSlideIndex] = useState(null)
    const [mainCategoryIndex, setMainCategoryIndex] = useState(1)
    const [obj, setObj] = useState(null)

    const category = useSelector((state) => state.category.categories)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handlerCategoryList = (index, childrenCategory) => {
        setSlideIndex(index + 1)
        setObj(childrenCategory)
        dispatch(getCategoryDetailsById(childrenCategory._id))

    }
    const currentCategory = (obj, index) => {
        if (obj.name === "Галерея всей продукции") {
            navigate("/galleryPage")
        }
        setMainCategoryIndex(index + 1)
    }

    useEffect(() => {
        const data = window.localStorage.getItem('TEST')
        setObj(JSON.parse(data))
    }, [])
    useEffect(() => {
        window.localStorage.setItem('TEST', JSON.stringify(obj))
    }, [obj])

    return (
        <div className="productList 2">


            <div
                className="productList__containerMainCategory">
                {category ? category.map((obj, index) => (
                    <div
                        key={index}>
                        <div className="productList__containerIcon">
                            <div
                                onClick={() => currentCategory(obj, index)}
                                className={mainCategoryIndex === index + 1 ? "productList__mainCategory active" : "productList__mainCategory"}>
                                {obj.name}
                            </div>
                        </div>
                    </div>
                )) : null}
            </div>
            <div className="productList__container">
                {category.map((obj, index) => (
                    <div className="productList__categoryMainContainer" key={index}>
                        <div
                            className={mainCategoryIndex === index + 1 ? "productList__categoryItemContainer active" : "productList__categoryItemContainer"}>
                            {obj.children.map((childrenCategory, index) => (
                                <a
                                    style={{
                                        textDecoration: "none"
                                    }}
                                    key={index}
                                    onClick={() => handlerCategoryList(index, childrenCategory)}
                                    href={`/productsCategory/${childrenCategory.slug}?${childrenCategory._id}`}
                                    className={slideIndex === index + 1 ? "productList__categoryItemBox active" : "productList__categoryItemBox"}
                                >

                                    <div className="productList__imgContainer">
                                        <img
                                            src={generatePublicUrl(childrenCategory.categoryImage)}
                                            alt={childrenCategory.name}
                                        />
                                    </div>
                                    <div className="productList__text">
                                        {childrenCategory.name}
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                ))}
                <div>
                </div>
            </div >
        </div >
    )
}

export default CategoryListComponent