import React from 'react'
import YouTube from 'react-youtube';

const RenderVideoYoutube = (props) => {
    const size = {
        height: '90%',
        width: '100%',
        maxWidth: '600px',
        margin: "0 auto"
    }
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };
    const _onReady = (event) => {
        event.target.pauseVideo();
    }
    return (
        <div className="renderVideoYoutube">
            <YouTube
                className="renderVideoYoutube__youTubeContainer"
                style={size}
                videoId={props.videoHref}
                opts={opts}
                onReady={_onReady}
            />
        </div>
    )
}

export default RenderVideoYoutube




