// const DEFAULT_IMAGE_URL = 'http://localhost:2001/public/default-image.jpg';
import DEFAULT_IMAGE_URL from '../src/assets/noPhoto1.png'

// export const api = 'https://admin-mafkirovsk51.online/api';

// export const generatePublicUrl = (fileName) => {
//     if (!fileName) {
//         return DEFAULT_IMAGE_URL;
//     }
//     return `https://admin-mafkirovsk51.online/public/${fileName}`;
// }

// export const api = 'http://localhost:2001/api';
// export const generatePublicUrl = (filename) => {
//     if (!filename) {
//         return DEFAULT_IMAGE_URL;
//     }
//     return `http://localhost:2001/public/${filename}`;
// };



export const api = 'https://admin-stlkirovsk51.ru/api';
export const generatePublicUrl = (fileName) => {
    if (!fileName) {
        return DEFAULT_IMAGE_URL;
    }
    return `https://admin-stlkirovsk51.ru/public/${fileName}`;
}



