import React, { useEffect, useState } from 'react'
import { ButtonMaterialUi } from './../../../components/MaterialUi/index';


import RenderDeleteOrder from './RenderDeleteOrder';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getItemById } from '../../../actions';

const OrderFinalContent = ({
    order,
    showOrderModal,
    setType,
    type
}) => {

    const dispatch = useDispatch()
    const { orderStatus, _id } = order
    const status = orderStatus.filter((item) => item.isCompleted ? item : null)

    const showOrderModalType = (typeModal, id) => {
        setType(typeModal);
        showOrderModal(order);
        if (typeModal !== "statusOrder") {
            if (id === order.paymentId) {
                dispatch(getItemById("paymentList", id, "-__v", "components"));
            }
            if (id === order.deliveryId) {
                dispatch(getItemById("deliveryInfo", id));
            }
            if (id === order.pickupPointsId) {
                dispatch(getItemById("pickupPoints", id, "-__v", "components"));
            }
        }
    };
    const arrayFinalItem = [
        !order.paymentId ? null :
            {
                text: "Оплата",
                description: <Button onClick={() => showOrderModalType("payment", order.paymentId)}>Тип оплаты</Button>
            },
        !order.deliveryId ? null :
            {
                text: "Доставка",
                description: <Button onClick={() => showOrderModalType("delivery", order.deliveryId)}>Тип доставки</Button>
            },
        {
            text: "Статус заказа",
            description: <Button onClick={() => showOrderModalType("statusOrder", "")}>{status.at(-1).text}</Button>
        },
        order.pickupPointsId
            ?
            {
                text: "Точка самовывоза",
                description: <Button onClick={() => showOrderModalType("pickupPoints", order.pickupPointsId)}>Точка самовывоза</Button>

            } : null
    ]
    return (
        <>

            <div className="renderDropdownDesc__orderFinalContent">
                {arrayFinalItem.map((item, index) => (
                    <>
                        {item === null ? null :
                            <div className="renderDropdownDesc__orderFinalContent__row"
                                key={index}
                            >
                                <div className="renderDropdownDesc__orderFinalContent__text">
                                    {item?.text}
                                </div>
                                <div className="renderDropdownDesc__orderFinalContent__description">
                                    {item?.description}
                                </div>
                            </div>
                        }
                    </>
                ))}
                <div className="renderDropdownDesc__buttonContainer">
                    <ButtonMaterialUi
                        onClick={() => showOrderModalType("leaveFeedback", "")}
                        title="Оставить отзыв"
                    />
                    <ButtonMaterialUi
                        onClick={() => alert("hello two world")}
                        title="Повторить заказ"
                    />
                </div>
            </div>
            <RenderDeleteOrder
                orderId={_id}
            />
        </>
    )
}

export default OrderFinalContent