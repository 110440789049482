import React, { useState } from 'react'

const CategoryContainer = () => {

    const [categoryIndex, setCategoryIndex] = useState(1)

    const items = [
        {
            name: "ВСЕ"
        },
        {
            name: "#НОВЫЙ ГОД"
        },
        {
            name: "#УРБАНИСТИКА"
        },
        {
            name: "#АРХИТЕКТУРА"
        },
        {
            name: "HTML-КАРТА"
        },
    ]

    return (
        <div className="categoryContainer">
            <div className="categoryContainer__mainContainer">
                <h2>Категории</h2>
                <div className="categoryContainer__mainContainer">
                    {
                        items.map((item, index) => (
                            <div onClick={() => setCategoryIndex(index + 1)} className={categoryIndex === index + 1 ? "categoryContainer__itemBox active" : "categoryContainer__itemBox"}>
                                <div className="categoryContainer__item">
                                    {item.name}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default CategoryContainer