import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HandshakeIcon from '@mui/icons-material/Handshake';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RestoreIcon from '@mui/icons-material/Restore';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import SettingsIcon from '@mui/icons-material/Settings';

export const hoursItems = ["08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18"]
export const minutesItems = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]
export const galleryItem = {
    title: "Галлерея",
    description: "Фотографии размещены случайным образом, так что каждый раз вы можете найти что-то новое и интересное. Мы гарантируем качество нашей продукции и поможем вам сделать правильный выбор. Приятных покупок!",
    namePage: "Все товары"
}

export const characteristicList = (obj, type) => [
    { title: 'Длина', key: 'length', unit: 'мм', condition: obj.categorySlug === 'Skamejki-XZDNVwQ-gl' },
    { title: 'Ширина', key: 'width', unit: 'мм' },
    { title: 'Высота', key: 'height', unit: 'мм' },
    { title: 'Вес', key: 'weight', unit: 'кг' },
    { title: 'Размеры', key: 'size', unit: 'мм' },
    type === "all" && { title: 'Размер упаковки', key: 'size', unit: 'мм' },
    type === "all" && { title: 'Цвет', key: 'color', unit: '' },
].map(item => {
    if (item && item.condition) {
        item.content = `от ${obj[item.key] || 'Уточнить'} ${item.unit}`;
    } else if (item) {
        item.content = `${obj[item.key] || 'Уточнить'} ${item.unit}`;
    }
    return item;
});



export const loggedInMenuItems = (auth, handleClickButton) => [
    auth && {
        text: "Мой кабинет",
        href: "/personalInformation",
        icon: "",
    },
    auth && {
        text: "Наши соцсети",
        href: "/contactM",
        icon: "",
    },
    auth && {
        text: "Стать партнером",
        href: "/partnerPage",
        icon: ""
    },
    auth && {
        text: "Мои заказы",
        href: "/orderPage",
        icon: "",
    },
    !auth && {
        text: "Центр сообщений",
        href: "#",
        icon: ""
    },
    {
        text: "Перейти к скидкам",
        href: "#",
        icon: ""
    },
    {
        text: "Связаться с нами",
        href: "/contacts",
        icon: ""
    },
    {
        text: "Настройки",
        href: "/user/settingPage",
        icon: "",
        handleClickType: auth ? "href" : "button",
        handleClick: () => handleClickButton("Настройки")
    },
];

export const itemsCabinetList = [
    {
        href: "/orderPage",
        text: "Мои заказы",
        icon: <ListAltIcon />
    },
    {
        href: "/desiresPage",
        text: "Список желаний",
        icon: <FavoriteBorderIcon />
    },

    {
        href: "/historyProductPage",
        text: "Просмотренные товары",
        icon: <RestoreIcon />
    },
    {
        href: "/applicationCallMePage",
        text: "Заявки на звонок",
        icon: <AppSettingsAltIcon />
    },
    {
        href: "/applicationPage",
        text: "Ваши вопросы",
        icon: <DeviceUnknownIcon />
    },
    // {
    //     href: "/applicationPage!",
    //     text: "Заявки (галлереи)",
    //     icon: <DeviceUnknownIcon />
    // },
    {
        href: "/mailingList",
        text: "Рассылка",
        icon: <NotificationsNoneIcon />
    },
    {
        href: "/partnerPage",
        text: "Партнерсво",
        icon: <HandshakeIcon />
    },
    {
        href: "/user/settingPage",
        text: "Настройки",
        icon: <SettingsIcon />
    },

]


// useEffect(() => {
//     document.addEventListener("scroll", scrollHandler)

//     return function () {
//         document.removeEventListener("scroll", scrollHandler)
//     }
// }, [])

// const scrollHandler = (e) => {
//     if (e.target.documentElement.scrollTop > 250) {
//         setFixedClass(true)
//     }
//     if (e.target.documentElement.scrollTop < 250) {
//         setFixedClass(false)
//     }
// }

// ${fixedClass ? " fixedClass" : ""}