import React, { useState, useRef, useEffect } from 'react'

const ButtonProduct = ({
    _className,
    onClick,
    color,
    href,
    text,

}) => {

    return (

        <button className={`buttonProduct ${_className}`}
            onClick={onClick ? onClick : null}
        >
            <a
                className="buttonProduct__hrefContainer"
                href={href ? href : null} >
                <span>
                    {text}
                </span>
            </a>
        </button >
    )
}

export default ButtonProduct
