import axios from "../../helpers/axios"
import { contactMConstants } from "../constants";

export const getContactM = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: contactMConstants.GET_CONTACT_M_REQUEST });
      const res = await axios.get("contactM/get");
      if (res.status === 200) {
        const { contactM } = res.data;
        dispatch({
          type: contactMConstants.GET_CONTACT_M_SUCCESS,
          payload: {
            contactM,
          },
        });
      } else {
        dispatch({ type: contactMConstants.GET_CONTACT_M_FAILURE });
      }
    } catch (e) {
      console.log(e);
    }
  };
};
