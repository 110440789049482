import React, { useState } from 'react'
import ConfirmDialog from './../../../components/ConfirmDialog';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateOrderVisibleDiscountedTotal } from '../../../actions/order.action';
import { useDispatch } from 'react-redux';
import { goToTop } from './../../../components/GoToTop';

const RenderDeleteOrder = ({
    orderId
}) => {
    const [showConfirm, setShowConfirm] = useState(false)
    const dispatch = useDispatch()

    const handleRemoverOrderById = () => {
        const payload = {
            orderId
        }
        dispatch(updateOrderVisibleDiscountedTotal(payload))
            .then(() => {
                setShowConfirm(false)
                goToTop("onlyTop", 120)
            })

    }

    return (
        <div>
            <Button
                style={{
                    marginTop: "20px"
                }}
                color="error"
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => setShowConfirm(true)}
            >
                Удалить заказ
            </Button>
            <ConfirmDialog
                title="Подтвердить удаление этого заказа?"
                open={showConfirm}
                handleClose={() => setShowConfirm(false)}
                handleSubmit={handleRemoverOrderById}
            />
        </div>
    )
}

export default RenderDeleteOrder