import React, { useEffect } from 'react'
import { Modal } from '../../../components/MaterialUi'
import RenderSettingLists from './RenderSettingLists'
import { useDispatch, useSelector } from 'react-redux'
import { setShowSettingModal } from '../../../reducers/auth.reducer'

const SettingModal = ({
    visible,
    onClose,
}) => {
    const { showSettingModal } = useSelector((state) => state.auth)


    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(setShowSettingModal(false))
    }
    useEffect(() => {

    })
    return (
        <Modal visible={showSettingModal} onClose={handleClose}>
            <h2>Основные настроки сайта</h2>
            <p>В этом месте вы можете изменять настройки сайта, если у вас возникают вопросы, можете нажать на троиточие рядом с названием настройки</p>
            <RenderSettingLists />
        </Modal>
    )
}

export default SettingModal