import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const RenderStatusArray = ({
    statusArray
}) => {
    return (
        <div className="renderStatusArray">
            <div className="renderStatusArray__content">
                {statusArray?.map((item, index) => (
                    <div className="renderStatusArray__row"
                        key={index}
                    >
                        <div className="renderStatusArray__text">
                            {item.text}
                        </div>
                        {item.isCompleted && (
                            <div className="renderStatusArray__date">
                                {item.date.slice(0, 10)} {item.date.slice(11, 19)}
                            </div>
                        )}
                        {item.isCompleted && <div className="renderStatusArray__iconContainer">
                            <CheckCircleOutlineIcon />
                        </div>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RenderStatusArray