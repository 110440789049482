import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const CheckboxItem = ({
    item,
    subscriptionsArray,
    handleCheckboxChange
}) => {
    const { _id, title } = item;

    const checkedStatus = subscriptionsArray.some((obj) => obj.mainPage === _id && obj.isCompleted);
    const handleChecked = (e) => {
        handleCheckboxChange(e, _id, !checkedStatus);
    };

    return (
        <div key={_id}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkedStatus}
                        onChange={handleChecked}
                    />
                }
                label={title}
            />
        </div>
    );
};

export default CheckboxItem;