import React from 'react'
import Layout from './Layout/Layout'
import LinkButtonBlock from './../components/button/LinkButtonBlock';
import AlertComponent from './../components/Alert';


const ContainerMainComponent = ({
    categorySlug,
    categoryName,
    description,
    titleItem,
    namePage,
    children,
    title,
    bread,
    href,
    dispatchCloseAlert,
    colorAlert,
    showAlert,
    textAlert,
    productId,
    namePageHref,
    type
}) => {

    return (
        <Layout>
            <div className="containerMainComponent">
                {title ? <h1 className="containerMainComponent__title">{title}</h1> : null}
                {description ? <h4 className="containerMainComponent__description  container">{description}</h4> : null}
                {bread ?
                    <LinkButtonBlock
                        categoryName={categoryName}
                        categorySlug={categorySlug}
                        titleItem={titleItem}
                        namePageHref={namePageHref}
                        href={href}
                        namePage={namePage}
                        productId={productId}
                        type={type}
                    />
                    :
                    null}
                <div>
                    {children}
                </div>
            </div>
            <AlertComponent
                dispatchCloseAlert={dispatchCloseAlert}
                color={colorAlert}
                showObj={showAlert}
                text={textAlert}
            />
        </Layout>
    )

}

export default ContainerMainComponent
