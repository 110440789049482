import React, { useState, useEffect } from 'react'
import { MaterialInput, Modal, PersonalDataCheckbox, ButtonMaterialUi } from './../MaterialUi/index';
import { createApplication } from './../../actions/application.action';
import { useDispatch, useSelector } from 'react-redux'
import BRenderAddPictures from './BRenderAddPictures';
import CheckboxText from './../MaterialUi/CheckboxText';
import ErrorTitle from '../Title/ErrorTitle';
import { setVisibleRegModal, setModalType } from '../../reducers/application.reducer';
import UserDataInput from './UserDataInput';
import PasswordError from './../PasswordError';
import InputFullBorder from './../Input/InputFullBorder';
import SearchIcon from '@mui/icons-material/Search';
import { signup as _signup } from '../../actions';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const RenderModalApplication = ({
    setShowModal,
    showModal,
    buttonText,
    modalTitle
}) => {

    const application = useSelector((state) => state.application)
    const auth = useSelector((state) => state.auth)
    const { visibleRegModal, modalType } = application

    const { user } = auth


    const dispatch = useDispatch()
    const [applicationForm, setApplicationForm] = useState({
        fullName: auth.authenticate ? auth.user.fullName : "",
        descriptionProblem: "",
        nameCompany: "",
        email: auth.authenticate ? auth.user.email : "",
        contactNumber: "",
    })
    const [passwordCheck, setPasswordCheck] = useState("")
    const [password, setPassword] = useState("")
    const [region, setRegion] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dataProcessingConsent, setDataProcessingConsent] = useState(false)
    const [registerModal, setRegisterModal] = useState(false)
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [typePassword, setTypePassword] = useState(false);
    const [samplePhoto, setSamplePhoto] = useState([])

    const handleClose = () => {
        setShowModal(false)
        clear()
        dispatch(setModalType(""))
        setRegisterModal(false)
    }

    const setUserObj = () => {
        setApplicationForm({
            fullName: user.fullName,
            descriptionProblem: "",
            nameCompany: "",
            contactNumber: user.contactNumber,
            email: user.email,

        })
    }
    const clear = () => {
        setApplicationForm({
            fullName: "",
            descriptionProblem: "",
            nameCompany: "",
            contactNumber: "",
            email: "",

        })
        setSamplePhoto([])
        setDataProcessingConsent(false)
    }
    useEffect(() => {
        if (applicationForm.fullName !== undefined && applicationForm.fullName !== "") {
            const nameParts = applicationForm.fullName.split(" ");
            setFirstName(nameParts[0] || "");
            setLastName(nameParts[1] || "");
        }
    }, [applicationForm.fullName]);

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showModal]);

    useEffect(() => {
        setUserObj()
    }, [auth.authenticate])

    const submitApplicationForm = () => {
        if (registerModal) {
            dispatch(setVisibleRegModal())
            dispatch(setModalType("password"))
        } else {
            const form = new FormData();
            form.append("fullName", applicationForm.fullName);
            form.append("descriptionProblem", applicationForm.descriptionProblem);
            form.append("nameCompany", applicationForm.nameCompany);
            form.append("email", applicationForm.email);
            form.append("contactNumber", applicationForm.contactNumber);
            form.append("dataProcessingConsent", dataProcessingConsent);
            for (let pic of samplePhoto) {
                form.append("samplePhoto", pic);
            }
            dispatch(createApplication(form))
                .then(() => handleClose())
        }
    };

    const submitApplicationAndRegisterForm = async () => {
        const user = {
            firstName,
            lastName,
            email: applicationForm.email,
            password,
            contactNumber: applicationForm.contactNumber,
            region,
        };

        const form = new FormData();
        form.append("fullName", applicationForm.fullName);
        form.append("descriptionProblem", applicationForm.descriptionProblem);
        form.append("nameCompany", applicationForm.nameCompany);
        form.append("email", applicationForm.email);
        form.append("contactNumber", applicationForm.contactNumber);
        form.append("dataProcessingConsent", dataProcessingConsent);
        for (let pic of samplePhoto) {
            form.append("samplePhoto", pic);
        }

        try {
            await dispatch(_signup(user));
            await auth.authenticate
            await dispatch(createApplication(form));
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };


    switch (modalType) {
        case "password":
            return <>
                <Modal visible={showModal} onClose={handleClose}>
                    <KeyboardBackspaceIcon onClick={() => dispatch(setModalType(""))} />
                    <h1 className="text-center" style={{ marginBottom: "20px" }}>
                        Доп-поля
                    </h1>
                    <MaterialInput
                        label="пароль"
                        type={typePassword ? "text" : "password"}
                        onClickIcon={() => setTypePassword(!typePassword)}
                        value={passwordCheck}
                        noneRequiredColor
                        onChange={(e) => setPasswordCheck(e.target.value)}
                        iconBlockPassword
                        autocompletePassword
                    />
                    <div style={{
                        position: "relative"
                    }}>
                        <MaterialInput
                            label="пароль повторно"
                            onClickIcon={() => setTypePassword(!typePassword)}
                            noneRequiredColor
                            type={typePassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            iconBlockPassword
                            autocompletePassword
                        />
                        <PasswordError
                            password={password}
                            passwordCheck={passwordCheck}
                            passwordMatch={passwordMatch}
                            setPasswordMatch={setPasswordMatch}
                        />
                    </div>
                    <InputFullBorder
                        typeInput="allCity"
                        icon={<SearchIcon />}
                        label="Ваша область"
                        type="text"
                        value={region}
                        setValue={setRegion}
                    />
                    <ButtonMaterialUi
                        title="Регистрация + заявка"
                        data={dataProcessingConsent}
                        onClick={submitApplicationAndRegisterForm}
                    />
                </Modal>
            </>

        case "openModal":
            return <>
                <div className="renderBlackModal">
                    <h1 className="text-center pb-4">РАССЧИТАТЬ СТОИМОСТЬ ПРОЕКТА</h1>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="renderBlackModal__inputContainer">
                                <UserDataInput
                                    setForm={setApplicationForm}
                                    form={applicationForm}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <BRenderAddPictures
                                setPictures={setSamplePhoto}
                                pictures={samplePhoto}
                            />
                            <div>
                                <textarea
                                    id="One"
                                    placeholder='Коментарии'
                                    value={applicationForm.descriptionProblem}
                                    onChange={(e) => setApplicationForm({ ...applicationForm, descriptionProblem: e.target.value })}

                                />
                            </div>
                            <div className="renderBlackModal__checkboxBlock">
                                <PersonalDataCheckbox
                                    checked1={"Dcfdddsc"}
                                    fullWidth

                                />
                            </div>
                            <div className="renderBlackModal__checkboxBlock">
                                <PersonalDataCheckbox
                                    checked1={"Dcfdddsc"}
                                    fullWidth

                                />
                            </div>
                            <ButtonMaterialUi
                                title="Отправить"
                                data={dataProcessingConsent}
                                onClick={submitApplicationForm}
                            />
                        </div>
                    </div>
                </div>
            </>
        default:
            return <>
                <Modal visible={showModal} onClose={handleClose}>
                    <h2 className="text-center" style={{ marginBottom: "20px", color: "white" }}>{modalTitle ? modalTitle : "Возникли вопросы?"}</h2>
                    <UserDataInput
                        setForm={setApplicationForm}
                        form={applicationForm}
                    />

                    <BRenderAddPictures
                        setPictures={setSamplePhoto}
                        pictures={samplePhoto}
                    />
                    <textarea
                        style={{ height: "100px", width: "100%" }}
                        placeholder="Дополнительно"
                        type="text"
                        value={applicationForm.descriptionProblem}
                        onChange={(e) => setApplicationForm({ ...applicationForm, descriptionProblem: e.target.value })}
                    />
                    <PersonalDataCheckbox
                        data={dataProcessingConsent}
                        setData={setDataProcessingConsent}
                        checked1="dscdscdsc"
                    />
                    <CheckboxText
                        visible={auth.authenticate}
                        data={registerModal}
                        setData={setRegisterModal}
                        label="Пройти сразу региcтрацию?"
                    />
                    <ButtonMaterialUi
                        title={buttonText ? buttonText : "Заказать"}
                        onClick={submitApplicationForm}
                        BDisabled={dataProcessingConsent ? false : true}
                    />
                </Modal>
            </>
    }
}

export default RenderModalApplication