import React, { useState, useEffect } from 'react'
import { FormControlLabel, Checkbox } from '@mui/material';
import IconButtonClose from './../IconButtons/IconButtonClose';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


export const Modal = (props) => {

    useEffect(() => {
        if (props.visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [props.visible]);
    if (!props.visible) {
        return null;
    }
    return (
        <>
            <div className="modalFixedBg">
                <div style={{ position: "relative" }}>
                    <div className="modalClose">
                        <IconButtonClose
                            onClick={props.onClose}
                        />

                    </div>

                    <div className="modalContainer" onClick={e => e.stopPropagation()}>
                        {props.children}</div>
                </div>
            </div>
        </>
    );
}

export const ModalCallMe = (props) => {

    useEffect(() => {
        if (props.visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [props.visible]);

    if (!props.visible) {
        return null;
    }

    return (
        <>
            <div className="modalFixedBg-callMe">
                <div style={{ position: "relative" }}>
                    <div className="modalFixedBg-callMe__modalClose" onClick={props.onClose}>
                        X
                    </div>
                    <div className="modalFixedBg-callMe__modalContainer" onClick={e => e.stopPropagation()}>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}



export const MaterialInput = ({
    value,
    requiredColorRed,
    label,
    textLabelEmpty,
    noneRequiredColor,
    onChange,
    type,
    autocompletePassword,
    iconBlockPassword,
    iconBlock,
    blackText,
    onClickIcon,
    widthProps
}) => {
    const [focus, setFocus] = useState(value === "" ? false : true)
    const [touch, setTouch] = useState(false);
    const blackTextFunc = () => blackText ? "#000" : "#fff"
    const requiredColorInput = () => requiredColorRed ? "red" : "#fff"

    return (
        <div className="materialInput" style={{
            width: widthProps ? widthProps : ""
        }}>
            <label
                className={`label ${focus || value !== "" ? "focus" : ""}`}
                style={{
                    top: 0,
                    left: 0,
                    lineHeight: "none",
                    color: blackTextFunc(),
                }}
            >
                {label && textLabelEmpty ? label : `Введите ${label}`}
            </label>
            <div
                style={{
                    display: "flex",
                }}
            >
                <input
                    style={{
                        color: blackTextFunc()
                    }}
                    className="input"
                    autoComplete={autocompletePassword ? "new-password" : ""}
                    type={type}
                    value={value}
                    onChange={onChange}
                    onFocus={(e) => {
                        setFocus(true);
                        setTouch(true);
                    }}
                    onBlur={(e) => {
                        if (e.target.value === "") {
                            setFocus(false);
                        } else {
                            setTouch(false);
                        }
                    }}
                />

                {iconBlockPassword && <div className="materialInput__iconBlockPassword"
                    onClick={onClickIcon}
                >
                    {type === "text"
                        ? <VisibilityOffIcon />
                        : <VisibilityIcon />
                    }
                </div>}
            </div>
            {
                noneRequiredColor ? null : touch && (
                    <div
                        style={{
                            fontSize: "12px",
                            color: requiredColorInput(),
                            fontWeight: 500,
                        }}
                    >{`Поле ${label} обязательно`}</div>
                )
            }
        </div >
    );
};

export const PersonalDataCheckbox = (props) => {

    const { data, setData, labelText } = props

    const colorText = () => props.blackText ? "#000" : "#fff"
    const fullWidth = () => props.fullWidth ? "100%" : props.plus10W ? "110%" : "85%"

    const [checked, setChecked] = useState(data)


    const handleChange = (e) => {
        setData(e.target.checked)
        setChecked(e.target.checked)
    }

    return (
        <>
            <div
                className="checkboxText"
                style={{
                    color: colorText(),
                    width: fullWidth(),
                    margin: "0 auto",
                    ...props.style,
                }}>
                <FormControlLabel
                    style={{
                        marginLeft: "0"
                    }}
                    control={<Checkbox checked={checked} onChange={handleChange} />}
                    label={labelText ? labelText : "Я ознакомлен(а) с Политикой конфиденциальности и даю согласие на обработку моих персональных данных. Я принимаю условия  Пользовательского соглашения"}

                />
            </div>

        </>

    )
}

export const ButtonMaterialUi = (props) => {
    const onClick = () => {
        props.onClick && props.onClick();
    };

    return (
        <div className="text-center">
            <button
                disabled={props.BDisabled ? true : false}
                className="materialButton"
                onClick={onClick}
            >
                {props.icon && props.icon}
                {props.title && props.title}
            </button>
        </div>
    );
};

export const ButtonMaterialUiOval = (props) => {


    const onClick = () => {
        props.onClick && props.onClick();
    };

    return (
        <div className="">
            <button
                className="materialButtonOval"
                onClick={onClick}
            >
                {props.icon && props.icon}
                {props.title && props.title}
            </button>
        </div>
    );
};

// export const DropdownMenu = (props) => {
//     return (
//         <div className="headerDropdownContainer">
//             {props.menu}
//             <div className="dropdown">
//                 <div className="upArrow"></div>
//                 {props.firstMenu}
//                 <ul className="headerDropdownMenu">
//                     {props.menus &&
//                         props.menus.map((item, index) => (
//                             <li key={index}>
//                                 <a
//                                     onClick={(e) => {
//                                         if (item.onClick) {
//                                             e.preventDefault();
//                                             item.onClick && item.onClick();
//                                         }
//                                     }}
//                                     href={`${item.href}`}
//                                 >
//                                     {item.label}
//                                 </a>
//                             </li>
//                         ))}
//                 </ul>
//             </div>
//         </div>
//     );
// };


