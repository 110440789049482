import React, { useState, useEffect } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ContainerCabinetComponent from '../../ContainerCabinetComponent';
import { useSelector, useDispatch } from 'react-redux';
import { getHistoryProductItems, removeHistoryProductItem } from '../../../actions';
import Loader from '../../../components/Loader';
import RenderProductContainer from './../components/RenderProductContainer';
import AlertComponent from '../../../components/Alert';
import { setCloseAlertHistoryProduct } from './../../../reducers/historyProduct';

const HistoryProductPage = () => {
    const { historyProductItems, variantAlert, showAlert, message, loading } = useSelector((state) => state.historyProduct)

    const dispatch = useDispatch()

    const [sort, setSort] = useState("newest");
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [currentId, setCurrentId] = useState("");

    const handleChange = (event) => {
        setSort(event.target.value);
    };

    const selectItem = [
        {
            value: "newest",
            text: "Самые новые"
        },
        {
            value: "oldest",
            text: "Самые старые"
        },
        {
            value: "name",
            text: "От а-я"
        },
        {
            value: "nameMinus",
            text: "От я-а"
        },
    ]

    const clear = () => {
        setCheckedAll(false);
        setCheckedItems([]);
        setCurrentId("")
    }
    useEffect(() => {
        dispatch(getHistoryProductItems(sort, "all"))
    }, [sort])


    useEffect(() => {
        if (checkedItems.length > 0) {
            setRemoveButtonDisabled(false)
        } else {
            setRemoveButtonDisabled(true)
        }
    }, [checkedItems])

    const removeHistoryProduct = () => {
        let payload;
        if (currentId !== "" && checkedItems.length === 0) {
            payload = {
                productId: currentId
            }
        }
        if (checkedItems.length > 0) {
            payload = {
                productIds: checkedItems,
            }
        }
        dispatch(removeHistoryProductItem(payload)).then(() => clear())

    }
    return (
        <ContainerCabinetComponent
            title={"История просмотра"}
        >
            <div className="desiresPage">
                <div className="desiresPage__desiresNameAndMoreRow">
                    <div className="desiresPage__nameListDesires">
                        <h2>Все посещенные товары</h2>
                        <div className="desiresPage__defaultNameGrayText">
                            ( Всего {historyProductItems.length} шт. )
                        </div>
                    </div>
                </div>
                <div className="desiresPage__mainContainer">
                    <div className="desiresPage__headerMenu">
                        <div className="desiresPage__settingContainer">
                            <div
                                className="desiresPage__chooseAll"
                                onClick={() => {
                                    if (!checkedAll) {
                                        setCheckedItems(historyProductItems.map((item) => item._id));
                                    } else {
                                        setCheckedItems([]);
                                    }
                                    setCheckedAll(!checkedAll);
                                }}
                            >
                                {checkedAll ? "Отменить" : "Выбрать все"}
                            </div>
                            {!removeButtonDisabled && <div className="desiresPage__delete"
                                onClick={removeHistoryProduct}
                            >
                                <DeleteOutlineIcon />
                                Удалить
                            </div>}


                        </div>
                        <div className="orderMenuSearchSort__selectContainer">
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="demo-simple-select-helper-label">сортировка</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={sort}
                                    label="Сортировка"
                                    onChange={handleChange}
                                >
                                    {selectItem.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item.text}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                {loading
                    ? <Loader />
                    : historyProductItems.length ? <div className="desiresPage__desiresProduct">
                        <RenderProductContainer
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            setCheckedItems={setCheckedItems}
                            checkedItems={checkedItems}
                            deleteObj={removeHistoryProduct}
                            items={historyProductItems}
                        />
                    </div>
                        : <p className="desiresPage__emptyProd">Ваша история просмотра пустая!</p>
                }

            </div>
            <AlertComponent
                dispatchCloseAlert={setCloseAlertHistoryProduct}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
        </ContainerCabinetComponent>
    )
}

export default HistoryProductPage