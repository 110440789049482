import React from 'react';
import MainImageHeaderItem from './components/MainImageHeaderItem';

const OfflinePage = () => {
    const reloadPage = () => {
        window.location.reload();
    };
    let itemOfflinePage = {
        name: "ALOTB",
        header: "Что-то пошло не так!",
        description: "Пожалуйста, проверьте ваше подключение к сети и попробуйте снова.",
        descriptionButton: "Потеряно соединение с интернетом",
        buttonText: "Перезагрузить",
    }
    return (
        <MainImageHeaderItem
            item={itemOfflinePage}
            handleClick={reloadPage}
            type="other"
        />
    );
};

export default OfflinePage;
