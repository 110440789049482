import React, { useEffect } from 'react'
import MBenefits from '../../components/Benefits'
import ContainerMainComponent from '../ContainerMainComponent'
import DarkLineComponent from './../../components/DarkLineComponent';
import { motion } from 'framer-motion'
import { rightAnimation, } from './../../components/Animation/Animation';
import { useSelector, useDispatch } from 'react-redux';
import { getAboutUs } from '../../actions';
import { generatePublicUrl } from '../../urlConfig';
import Loader from '../../components/Loader';
import CategoryListComponent from '../../components/Category/CategoryListComponent';

const AboutUs = () => {
    const mainAboutUs = useSelector((state) => state.aboutUs)
    const { aboutUs, loading } = mainAboutUs

    const dispatch = useDispatch()



    useEffect(() => {
        dispatch(getAboutUs())
    }, [])


    const aboutUsItem = aboutUs[0]

    if (aboutUs.length === 0) {
        return <Loader />
    }
    return (
        <ContainerMainComponent
            title={aboutUsItem.title}
            description={aboutUsItem.titleDescription}
        >
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.3, once: true }}
                className="aboutUs">
                <div className="container">

                    <motion.div custom={1} variants={rightAnimation} className="aboutUs__containerImage">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="aboutUs__blockImage">
                                    <img src={generatePublicUrl(aboutUsItem.aboutUsPicture)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="aboutUs__blockText">
                                    <motion.h2 custom={2} variants={rightAnimation}>
                                        {aboutUsItem.companyName}
                                    </motion.h2>
                                    <motion.p custom={3} variants={rightAnimation}>
                                        {aboutUsItem.description}
                                    </motion.p>
                                </div>
                            </div>
                        </div>

                    </motion.div>
                </div>
                <DarkLineComponent
                    buttonBlock
                    titleTop="НАШИ ИЗДЕЛИЯ"
                    titleCenter="В ОНЛАЙН КАТАЛОГЕ"
                    titleBottom="«БЛАГОУТРОЙСТВО ГОРОДСКОЙ СРЕДЫ»"
                />

                <MBenefits />
            </motion.div>
            <CategoryListComponent />
        </ContainerMainComponent>

    )
}

export default AboutUs
