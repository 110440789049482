import { pickupPointsConstants, mainActionsConstants } from "../../actions/constants"


const initialState = {
    pickupPoints: [],
    pickupPointsObj: {},
    loading: false
}


export default (state = initialState, action) => {
    switch (action.type) {
        case pickupPointsConstants.GET_PICKUP_POINTS_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case pickupPointsConstants.GET_PICKUP_POINTS_SUCCESS:
            state = {
                ...state,
                pickupPoints: action.payload.pickupPoints,
                loading: false
            }
            break;
        case pickupPointsConstants.GET_PICKUP_POINTS_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                pickupPointsObj: action.payload?.item,
                loading: false,
            };
            break;
        default:
            return state

    }
    return state
}