import React, { useEffect } from 'react'
import ContentLabelP from '../../../components/ContentLabelP'
import InputFullBorder from '../../../components/Input/InputFullBorder'
import { Row } from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';

const PersonalData = ({
    user,
    region,
    setRegion,
    showEdit,
    setForm,
    setVariantForm,
    form,
    setShowModalCabinet,
}) => {

    useEffect(() => {
        if (showEdit) {
            setVariantForm("personal")
        }
    }, [showEdit])

    return (
        <div onClick={e => e.stopPropagation()}>
            <Row>
                <ContentLabelP
                    labelText="Имя"
                    valueText={form.firstName}
                    handleChange={(e) =>
                        setForm({ ...form, firstName: e.target.value })
                    }
                    showEdit={showEdit}
                />
                <ContentLabelP
                    labelText="Фамилия"
                    valueText={form.lastName}
                    handleChange={(e) =>
                        setForm({ ...form, lastName: e.target.value })
                    }
                    showEdit={showEdit}
                />
            </Row>
            <Row>
                <ContentLabelP
                    labelText="Пол"
                    valueText={form.gender}
                    handleChange={(e) =>
                        setForm({ ...form, gender: e.target.value })
                    }
                    showEdit={showEdit}
                />
                <ContentLabelP
                    labelText="Дата Рождения"
                    valueText={form.birthData}
                    handleChange={(e) =>
                        setForm({ ...form, birthData: e.target.value })
                    }
                    showEdit={showEdit}
                />
            </Row>
            {!showEdit
                ? <ContentLabelP
                    labelText="Область"
                    valueText={user.regionName}
                />
                : <>
                    <InputFullBorder
                        typeInput="allCity"
                        icon={<SearchIcon />}
                        label="Выбрать из списка район"
                        type="text"
                        value={region}
                        setValue={setRegion}
                    />

                </>}

            <Row>
                <ContentLabelP

                    fullWidth
                    labelText="Роль"
                    valueText={user.role === "user" && "Покупатель"}
                    onClickIcon={() => setShowModalCabinet(true)}
                    showIcon
                />
            </Row >
        </div >
    )
}

export default PersonalData