import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAboutUs } from './../actions/components/aboutUs';


const CompanyTitle = (props) => {
    const { show } = props
    const aboutUs = useSelector((state) => state.aboutUs.aboutUs);
    const nameCompany = aboutUs.map((obj) => obj.nameCompany);
    const colorWhite = () => props.colorWhite ? "white" : "#ffc75f";
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAboutUs())
    }, [])

    return (
        <>
            {!props.button ?
                <a
                    href='/'
                    className={show ? "headerNav__logo active" : "headerNav__logo"}
                    style={{
                        color: colorWhite(),
                        letterSpacing: "5px",
                        marginLeft: "20px"
                    }}
                >
                    <h2>{nameCompany}</h2>
                </a> : <div
                    onClick={props.onClick}
                    className={show ? "headerNav__logo active" : "headerNav__logo"}
                    style={{
                        color: colorWhite(),
                        letterSpacing: "5px",
                        marginLeft: "20px"
                    }}
                >
                    <h2>{nameCompany}</h2>
                </div>
            }
        </>


    )
}

export default CompanyTitle
