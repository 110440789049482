import React from 'react';
import Button from '@mui/material/Button';

const ContainerCheckoutList = ({
    disabledButton,
    titleChildren,
    setSuccessData,
    successData,
    showSuccessButtons,
    buttonText,
    heightType,
    displayFlex,
    errorBlock,
    children,
    onClick,
    title,
    icon,
}) => {
    const handleClick = () => {
        setSuccessData(!successData);
    };
    const renderSuccessButtons = () => {
        if (showSuccessButtons)
            return (
                <div className="containerCheckoutList__buttonSuccessBlock">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        disabled={disabledButton ? true : false}
                    >
                        {!successData ? "Подтвердить" : "Назад"}
                    </Button>
                </div>
            );
    };

    return (
        <div
            className={`containerCheckoutList ${successData ? "successData" : ""} ${errorBlock ? "errorBlock" : ""}  ${heightType ? "heightType" : ""}`}
            style={{
                minHeight: heightType ? heightType : "300px",
                display: displayFlex ? "flex" : "block"
            }}
        >
            <div>
                <div className="containerCheckoutList__titleMainContainer">
                    <div className="containerCheckoutList__titleBox">
                        {icon && <div className="containerCheckoutList__icon">
                            {icon}
                        </div>}
                        <div className="containerCheckoutList__title">
                            {title}
                        </div>
                    </div>
                    {!buttonText ? null : <div className="containerCheckoutList__buttonBox"
                        onClick={onClick}
                    >
                        <span>{buttonText}</span>
                    </div>}
                </div>
                {titleChildren && <div className="containerCheckoutList__titleChildren">
                    {titleChildren}
                </div>}
                {children && <div className="containerCheckoutList__contentBlock"
                    style={{
                        padding: title === "Куда доставить?" ? "10px 25px" : ""
                    }}
                >
                    {children}
                </div>}
                {renderSuccessButtons()}
            </div>
        </div>
    );
};

export default ContainerCheckoutList;
