import React from 'react'

const WhiteButton = (props) => {

    return (
        <div className="whiteButton" onClick={props.onClick}>
            <span>{props.text}</span>
        </div>
    )
}

export default WhiteButton
