import React, { useState, useEffect, useRef } from 'react'
import { MaterialInput, Modal, PersonalDataCheckbox, ButtonMaterialUi } from './../MaterialUi/index';
import RenderModalApplication from './RenderModalApplication';

const PurpleButton = (props) => {

    const [showModal, setShowModal] = useState(false)
    const rootEl = useRef(null);

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setShowModal(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);

    return (
        <div >
            <div className="purpleButton" onClick={props.onClick ? props.onClick : () => setShowModal(true)} ref={rootEl}>
                <span>
                    {props.text}
                </span>
            </div>
            <RenderModalApplication
                buttonText="Рассчитать стоимость проекта"
                showModal={showModal}
                setShowModal={setShowModal} />
        </div>

    )
}

export default PurpleButton