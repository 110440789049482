import { applicationCallMeConstants, mainActionsConstants } from '../actions/constants';

const initState = {
  applications: [],
  applicationObj: {},
  visibleRegModal: false,
  // passwordsMatch: false,
  loading: false,
  modalType: "",
  message: "",
  showAlert: false,
  variantAlert: "success",
};
export default (state = initState, action) => {
  switch (action.type) {
    case applicationCallMeConstants.ADD_SAMPLE_PHOTO_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationCallMeConstants.ADD_SAMPLE_PHOTO_SUCCESS:
      state = {
        ...state,
        applicationObj: action.payload?.applicationCallMe,
        loading: false,
        showAlert: true,
        variantAlert: "success",
        message: action.payload?.message,
      };
      break;
    case applicationCallMeConstants.ADD_SAMPLE_PHOTO_FAILURE:
      state = {
        ...state,
        loading: false,
        showAlert: true,
        variantAlert: "warning",
        message: action.payload?.message,
      };
      break;
    case mainActionsConstants.GET_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case mainActionsConstants.GET_BY_ID_SUCCESS:
      state = {
        ...state,
        applicationObj: action.payload?.item,
        loading: false,
      };
      break;
    case mainActionsConstants.GET_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationCallMeConstants.GET_APPLICATION_CALL_ME_BY_USER_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationCallMeConstants.GET_APPLICATION_CALL_ME_BY_USER_SUCCESS:
      state = {
        ...state,
        applications: action.payload?.applicationsCallMe.reverse(),
        loading: false,
      };
      break;
    case applicationCallMeConstants.GET_APPLICATION_CALL_ME_BY_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationCallMeConstants.CREATE_APPLICATION_CALL_ME_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationCallMeConstants.CREATE_APPLICATION_CALL_ME_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationCallMeConstants.CREATE_APPLICATION_CALL_ME_FAILURE:
      state = {
        ...state,
        loading: false
      };
      break;
    case applicationCallMeConstants.VISIBLE_REGISTER_MODAL_APPLICATION_CALL_ME:
      state = {
        ...state,
        visibleRegModal: !state.visibleRegModal
      };
      break;
    case applicationCallMeConstants.SET_MODAL_TYPE_APPLICATION_CALL_ME:
      state = {
        ...state,
        modalType: action.payload
      }
      break;
    case applicationCallMeConstants.CLOSE_ALERT_TO_APPLICATION_CALL_ME:
      state = {
        ...state,
        showAlert: false
      }
      break;
    default:
      return state
  }

  return state;
};
export const setModalType = (payload) => ({
  type: applicationCallMeConstants.SET_MODAL_TYPE_APPLICATION_CALL_ME,
  payload
})
export const setVisibleRegModal = (payload) => ({
  type: applicationCallMeConstants.VISIBLE_REGISTER_MODAL_APPLICATION_CALL_ME,
  payload
})
export const setCloseAlertApplication = (payload) => ({
  type: applicationCallMeConstants.CLOSE_ALERT_TO_APPLICATION_CALL_ME,
  payload
})


