import { authConstants, cartConstants, desiresConstants } from "./constants";
import axios from "../helpers/axios";
import swal from 'sweetalert';
import { setShowModalUser } from "../reducers/auth.reducer";

export const signup = (user) => {
  return async (dispatch) => {
    try {
      dispatch({ type: authConstants.SIGNUP_REQUEST });
      const res = await axios.post(`/signup`, user);
      if (res.status === 201) {
        dispatch({ type: authConstants.SIGNUP_SUCCESS });
        const { token, user } = res.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: {
            token,
            user,
          },
        });
        dispatch(setShowModalUser());
        swal({
          title: `Регистрация прошла успешно`,
          text: `Приветствуем на нашем сайте  ${user.firstName} ${user.lastName}`,
          icon: "success",
        });
      } else {
        dispatch({ type: authConstants.SIGNUP_FAILURE, payload: { message: res.data.message } });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const login = (user) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });
    try {
      const res = await axios.post("/signin", { ...user });
      if (res.status === 200) {
        const { token, user } = res.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));

        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: {
            token,
            user,
          },
        });
        dispatch(setShowModalUser());
        swal({
          title: `Приветстуем на нашем сайте ${user.firstName} ${user.lastName}`,
          text: "Что вас интересует???",
          icon: "success",
        });
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : "Неправильный логин или пароль";
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: errorMessage
      });
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          user,
        },
      });
    } else {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: {
          error: "Failed to login",
          message: "Что-то пошло не так, попробуйте снова!"
        },
      });
    }
  };
};

export const signout = () => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGOUT_REQUEST });
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('galleryItemData');
    localStorage.removeItem('cart');
    const res = await axios.post(`/admin/signout`);
    localStorage.clear();
    dispatch({
      type: authConstants.LOGOUT_SUCCESS,
      payload: { message: res.data.message }
    });
    dispatch({ type: desiresConstants.RESET_DESIRES });
    dispatch({ type: cartConstants.RESET_CART });
  };
};
