import React from 'react'

const StatusColorText = ({
    filterOrderStatusColor,
    status,
    mini,

}) => {
    if (!filterOrderStatusColor) {
        const filterOrderStatusColorFunc = (text) => {
            let color;
            switch (text) {
                case "Оформлен":
                    color = "gray";
                    break;
                case "Оформлена":
                    color = "gray";
                    break;
                case "Подтвержден оператором":
                    color = "green";
                    break;
                case "Производство":
                    color = "greenDark";
                    break;
                case "На рассмотрении":
                    color = "greenDark";
                    break;
                case "Передан в службу доставки":
                    color = "blue";
                    break;
                case "Доставлен":
                    color = "gold";
                    break;
                case "Отклонено":
                    color = "red";
                    break;
                default: color = "green";
            }
            return color;
        };
        filterOrderStatusColor = filterOrderStatusColorFunc; // установить функцию по умолчанию
    }
    const statusText = () => {
        // Проверяем существует ли status и является ли он массивом перед вызовом метода filter
        if (status && Array.isArray(status)) {
            // Фильтруем только завершенные статусы и возвращаем последний из них
            return status.filter((status) => status.isCompleted).slice(-1);
        } else {
            // Возвращаем пустой массив, если status не существует или не является массивом
            return [];
        }
    };


    return (
        <div className={`statusColorText ${mini === "mini" ? "mini" : ""}`}>
            {statusText().map((item) => (
                <div key={item._id}>
                    <div className={`statusColorText__heading  ${mini ? "mini" : ""} ${filterOrderStatusColor(item.text)}`} />
                    <div className={`statusColorText__statusOrder  ${mini ? "mini" : ""} ${filterOrderStatusColor(item.text)}`}>
                        {item.text}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default StatusColorText;