import React, { useEffect } from 'react'
import ContentLabelP from '../../../components/ContentLabelP'
import { Row } from 'react-bootstrap';

const ContactData = ({
    showEdit,
    setVariantForm,
    setForm,
    form,
}) => {

    useEffect(() => {
        if (showEdit) {
            setVariantForm("contact")
        }
    }, [showEdit])

    return (
        <div onClick={e => e.stopPropagation()}>
            <Row>
                <ContentLabelP
                    labelText="Основной номер"
                    valueText={form.contactNumber}
                    handleChange={(e) =>
                        setForm({ ...form, contactNumber: e.target.value })
                    }
                    showEdit={showEdit}
                />
                <ContentLabelP
                    labelText="Доп-номер"
                    valueText={form.backupNumber}
                    handleChange={(e) =>
                        setForm({ ...form, backupNumber: e.target.value })
                    }
                    showEdit={showEdit}
                />
            </Row>
        </div>
    )
}

export default ContactData