import React from 'react'
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch } from 'react-redux';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AlertComponent = ({
    color,
    text,
    showObj,
    dispatchCloseAlert
}) => {
    const dispatch = useDispatch()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(dispatchCloseAlert())
    };
    return (
        <Stack
            sx={{ width: '100%' }}
            spacing={2}
        >
            <Snackbar
                style={{ zIndex: "999920001" }}

                open={showObj}
                autoHideDuration={4000}
                onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={color}
                    sx={{ width: '100%' }}>
                    {text}
                </Alert>
            </Snackbar>
        </Stack>
    )
}

export default AlertComponent