import React, { useState, useEffect } from 'react'
import ContainerCheckoutList from './ContainerCheckoutList';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputFullBorder from '../../../components/Input/InputFullBorder';
import { Row, Col } from 'react-bootstrap';
import Address from './../Address';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import { addAddress } from '../../../actions';
import { useDispatch } from 'react-redux';


const CheckoutAddressForm = ({
    setPresenceAddress,
    confirmDeliveryAddress,
    setSelectedAddress,
    setConfirmAddress,
    onAddressSubmit,
    selectedAddress,
    confirmAddress,
    setNewAddress,
    setAddress,
    setFormAddress,
    formAddress,
    errorBlock,
    address,
    addressObj,
    auth
}

) => {
    const [typeContentAddressModal, setTypeContentAddressModal] = useState("inputFields");
    const [thenAddAddress, setThenAddAddress] = useState(false);
    const [region, setRegion] = useState(null)
    const [isFormValid, setIsFormValid] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        const isRegionValid = region !== '';
        const isPinCodeValid = formAddress.pinCode !== '';
        const isAddressValid = formAddress.address !== '';
        setIsFormValid(isRegionValid && isPinCodeValid && isAddressValid);
    }, [region, formAddress]);

    useEffect(() => {
        let _address;
        if (thenAddAddress) {
            _address = address.slice(address.length - 1)[0];
            onAddressSubmit(_address)
        }
    }, [address])
    useEffect(() => {
        if (selectedAddress !== null) {
            setPresenceAddress(true)
        } else {
            setPresenceAddress(false)
        }
    }, [selectedAddress])

    if (!address) return null

    const arrayRadio = [
        {
            inputValue: "work",
            inputText: "Рабочий"
        },
        {
            inputValue: "home",
            inputText: "Домашний"
        }
    ]

    const renderRadioInputBlock = () => {
        return (
            <div className="renderRadioInputBlock">
                <div className="renderRadioInputBlock__title">
                    Выбрать тип адреса:
                </div>
                <div className="renderRadioInputBlock__mainBox">
                    {arrayRadio.map((obj, index) => (
                        <div className="custom-radio" key={index}>
                            <label>
                                <input
                                    type="radio"
                                    name="addressType"
                                    value={obj.inputValue}
                                    checked={formAddress.userType === obj.inputValue}
                                    onChange={(e) =>
                                        setFormAddress({ ...formAddress, userType: e.target.value })
                                    }
                                />
                                <div className="custom-radio__label">
                                    <strong>{obj.inputText}</strong>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    const enableAddressEditForm = (addr) => {
        const updatedAddress = address.map((adr) =>
            adr._id === addr._id ? { ...adr, edit: true } : { ...adr, edit: false }
        );
        setAddress(updatedAddress);
    };
    const disableAddressEditForm = (addr) => {
        const updatedAddress = address.map((adr) =>
            adr._id === addr._id ? { ...adr, edit: false } : { ...adr, edit: false }
        );
        setAddress(updatedAddress);
    };

    const selectAddress = (addr) => {
        const isAlreadySelected = addr.selected;
        const updatedAddress = address.map((adr) =>
            adr._id === addr._id
                ? { ...adr, selected: !isAlreadySelected }
                : { ...adr, selected: false }
        );
        setAddress(updatedAddress);
    };
    const handleAddressField = () => {
        setSelectedAddress(null)
        setConfirmAddress(false)
        setTypeContentAddressModal("inputFields")
        setThenAddAddress(false)

    }

    const onSubmitAddress = () => {
        const payload = {
            address: {
                addressType: formAddress.userType,
                pinCode: formAddress.pinCode,
                address: formAddress.address,
                cityDistrictTown: formAddress.cityDistrictTown,
                regionId: region.value,
                regionLabel: region.label,
            }
        }
        if (formAddress.landmark !== "") {
            payload.address.landmark = formAddress.landmark
        }
        dispatch(addAddress(payload)).then(() => setThenAddAddress(true))

        if (addressObj) {
            onAddressSubmit(addressObj)
            setConfirmAddress(true)
            setTypeContentAddressModal("")
        }
    }


    const renderContentDelivery = () => {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "0 auto",
                        justifyContent: "center"
                    }}
                >
                    <Button
                        style={{
                            outline: "none",
                            margin: "5px"
                        }}
                        variant={typeContentAddressModal === "newAddress" ? "outlined" : "contained"}
                        onClick={handleAddressField}
                    >
                        Ввести адрес самому

                    </Button>
                    <Button
                        style={{
                            outline: "none",
                            margin: "5px"
                        }}
                        variant={typeContentAddressModal !== "newAddress" ? "outlined" : "contained"}
                        onClick={() => setTypeContentAddressModal("newAddress")}
                        disabled={address.length > 0 ? false : true}
                    >
                        Выбрать из созданных
                    </Button>
                </div>
                {
                    typeContentAddressModal !== "inputFields"
                        ? <div>
                            {confirmAddress
                                ? <div className="stepCompleted">
                                    <div className="addressContainer__addressType">{selectedAddress.addressType}</div>
                                    <div className="stepComponent__contentBox">
                                        <h4>Выбранный адрес</h4>

                                        <div className="stepCompleted__text">
                                            {` ${selectedAddress.address} - ${selectedAddress.pinCode}`}
                                        </div>
                                    </div>
                                    <Button
                                        style={{
                                            margin: "20px",
                                        }}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddressField}
                                    >
                                        Отменить выбор
                                    </Button>
                                </div>
                                : <div style={{
                                    maxHeight: "400px",
                                    overflowX: "hidden"
                                }}>{address.map((adr) => (
                                    <Address
                                        auth={auth}
                                        disableAddressEditForm={disableAddressEditForm}
                                        setNewAddress={setNewAddress}
                                        confirmDeliveryAddress={confirmDeliveryAddress}
                                        selectAddress={selectAddress}
                                        enableAddressEditForm={enableAddressEditForm}
                                        onAddressSubmit={onAddressSubmit}
                                        adr={adr}
                                    />
                                ))}
                                </div>
                            }
                        </div>
                        : <div>
                            <Row style={{
                                marginBottom: "20px"
                            }}>
                                <Col md="12">
                                    {renderRadioInputBlock()}
                                </Col>
                            </Row>
                            <InputFullBorder
                                typeInput="allCity"
                                icon={<SearchIcon />}
                                label="Область"
                                type="text"
                                value={region}
                                setValue={setRegion}
                            />
                            <InputFullBorder
                                label="Почтовый индекс"
                                value={formAddress.pinCode}
                                onChange={(e) =>
                                    setFormAddress({ ...formAddress, pinCode: e.target.value })
                                }
                            />
                            <InputFullBorder
                                label="Город"
                                value={formAddress.cityDistrictTown}
                                onChange={(e) =>
                                    setFormAddress({ ...formAddress, cityDistrictTown: e.target.value })
                                }
                            />
                            <InputFullBorder
                                label="Адрес"
                                value={formAddress.address}
                                onChange={(e) =>
                                    setFormAddress({ ...formAddress, address: e.target.value })
                                }
                            />
                            <InputFullBorder
                                label="Ориентир (необязательно)"
                                value={formAddress.landmark}
                                onChange={(e) =>
                                    setFormAddress({ ...formAddress, landmark: e.target.value })
                                }
                            />
                            <Row>
                                <Col md="12"
                                    style={{
                                        color: "#777777",
                                        lineHeight: "20px",
                                    }}
                                >
                                    Чтобы продолжить, пожалуйста, заполните обязательные поля формы.
                                    После заполнения, появится кнопка с возможностью сохранить адрес для дальнейшей работы с ним.
                                </Col>
                            </Row>

                            {isFormValid && (
                                <Button
                                    style={{
                                        margin: "20px",
                                    }}
                                    variant="contained"
                                    color="primary"
                                    onClick={onSubmitAddress}
                                >
                                    Сохранить и продолжить
                                </Button>
                            )}
                        </div>
                }
            </>
        )
    }

    return (
        <ContainerCheckoutList
            errorBlock={errorBlock}
            title="Куда доставить?"
            icon={<AccountCircleIcon />}
            children={renderContentDelivery()}
        />
    )
}

export default CheckoutAddressForm