import { useState, useEffect } from 'react';
import SelectCountryList from './../Select/SelectCountryList';

const InputFullBorder = ({
    onChange,
    setValue,
    value,
    regionObj,
    label,
    icon,
    typeInput,
}) => {
    const [focus, setFocus] = useState(value === "" ? false : true)
    const [touch, setTouch] = useState(false)
    const [filled, setFilled] = useState(value === "" ? false : true)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (touch) {
                console.log('Value changed:', value);
            }
        }, 2000);

        return () => clearTimeout(timer);
    }, [value, touch]);

    return (
        <>
            {typeInput === "allCity"
                ? <SelectCountryList
                    setValue={setValue}
                    regionObj={regionObj}
                    value={value}
                    label={label}
                    setTouch={setTouch}
                    setFocus={setFocus}
                    setFilled={setFilled}
                    focus={focus}
                />
                :
                <div className={`inputFullBorder ${focus ? "focus" : ""} ${filled ? "filled" : false}`}>
                    <div className="inputFullBorder__labelBox">
                        <label className={`inputFullBorder__label ${focus ? "focus" : ""} `}>
                            {label}
                        </label>
                    </div>
                    <>
                        {icon && <div className="inputFullBorder__iconBox">
                            {icon}
                        </div>}


                        <input
                            className={`inputFullBorder__input ${focus ? "focus" : ""}`}
                            onChange={onChange}
                            value={value}
                            type="text"
                            onFocus={() => {
                                setFocus(true);
                                setTouch(true);
                            }}
                            onBlur={(e) => {
                                if (e.target.value === "") {
                                    setFocus(false);
                                    setFilled(false);

                                } else {
                                    setTouch(false);
                                    setFilled(true);
                                }
                            }}
                        />
                    </>
                </div>
            }
        </>
    )
}

export default InputFullBorder;
